import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { UploadTask } from 'src/app/classes/uploadtask';
import { UploadTaskTricycle } from 'src/app/classes/uploadtasktricycle';
export class OnboardingUtils {
    /**
     * Returns the path to which the file needs to be uploaded.
     * @param refNo The application reference number. All file names will be prefixed with this.
     * @param file - The file reference
     * @param fileName - The name to be used for saving the file in storage.
     */
    static getUploadRef(refNo: string, file: File, fileName: string) {
        const ext = file.name.split('.').pop();
        if (fileName) {
            const name = refNo + '-' + fileName; // + '.' + ext;
            const path = 'driverDetails/' + refNo + '/' + name;
            return path;
        }
        return null;
    }

    static getUploadRefNews(refNo: string, file: File, fileName: string) {
        const ext = file.name.split('.').pop();
        if (fileName) {
            const name = refNo + '-' + fileName; // + '.' + ext;
            const path = 'news/' + refNo + '/' + name;
            return path;
        }
        return null;
    }

    static getUploadRefCarousel(refNo: string, file: File, fileName: string) {
        const ext = file.name.split('.').pop();
        if (fileName) {
            const name = refNo + '-' + fileName; // + '.' + ext;
            const path = 'carousel/' + refNo + '/' + name;
            return path;
        }
        return null;
    }
    /**
     * This method constructs the object to be sent to the server. It replaces 'file' input
     * types with the corresponding paths in the storage bucket.
     * @param form The FormGroup object containing the controls
     * @param uploadTasks A map of form controls with file input type and their upload tasks
     */
    static updateFileRefs(form: FormGroup, uploadTasks: Map<FormControl, UploadTask>) {
        const values = form.value;
        Object.keys(form.value).forEach((key, index, arr) => {
            const control = form.get(key) as FormControl;
            const uploadTask = uploadTasks.get(control);
            if (uploadTask) { values[key] = uploadTask.path; }
        });
        return values;
    }
    static updateFileRefsTricycle(form: FormGroup, uploadTasks: Map<FormControl, UploadTaskTricycle>) {
        const values = form.value;
        Object.keys(form.value).forEach((key, index, arr) => {
            const control = form.get(key) as FormControl;
            const uploadTask = uploadTasks.get(control);
            if (uploadTask) { values[key] = uploadTask.path; }
        });
        return values;
    }
    /**
     * This method maps a given form control to a string. The returned string
     * will be used as a file name for uploading the selected file.
     * @param control
     */
    static getFileName(c: AbstractControl) {
        const control = c as FormControl;
        const controlName = this.getName(control);
        if (!controlName) { return null; }
        switch (controlName) {
            case 'imeiPhoto': return 'imei-photo';
            case 'nbiClearance': return 'nbi-clearance';
            case 'policeClearance': return 'police-o-brgy-clearance';
            case 'drivingLicense': return 'prof-driving-license';
            case 'officialReceipt': return 'motorcycle-or';
            case 'registrationReceipt': return 'motorcycle-cr';
            case 'govtIssuedID1': return 'govt-id-1';
            case 'govtIssuedID2': return 'govt-id-2';
            case 'driverPhoto': return 'driver-photo';
            case 'vehiclePhoto': return 'vehicle-photo';
            case 'vehiclePhoto1': return 'vehicle-photo1';
            case 'vehiclePhoto2': return 'vehicle-photo2';
            case 'bannerUrl': return 'banner-Url';
            case 'authorizationLetter': return 'authorization-letter';
            default: return null;
        }
    }
    /**
     * Helper method to get the name of the form control. There is no direct way to 
     * get the name of the form control. By far this is the most easiest way to get it done.
     * @param control
     */
    static getName(c: AbstractControl): string | null {
        const control = c as FormControl;
        const group = control.parent;
        let name: string;
        if (!group) { return null; }
        Object.keys(group.controls).forEach(k => {
            const child = group.get(k);
            if (child !== control) { return; }
            name = k;
        });
        return name;
    }
    static getStatusName(status: number): string | null {
        switch (status) {
            case 0: return 'Newly Applied';
            case 1: return 'Activated';
            case 2: return 'Cndly Activated';
            case 3: return 'Deactivated';
            case 4: return 'Scheduled';
            case 5: return 'Pending for Gear Releasing';
            case 6: return 'Pending for Activation';
            case 7: return 'Rejected';
            case 8: return 'Pre Releasing Done';
            default: return '';
        }
    }
    static getSubStatusName(subStatus: number): string | null {
        switch (subStatus) {
            case 11: return 'Active';
            case 12: return 'Probation';
            case 21: return 'Active';
            case 22: return 'Probation';
            case 31: return 'Suspended';
            case 32: return 'Banned';
            case 33: return 'Pulled out';
            case 41: return 'Unattended';
            case 42: return 'Attended';
            case 43: return 'Rejected';
            default: return '';
        }
    }
    static subStatusesForMainStatus(mainStatus: number): {value: number, name: string}[] {
        switch (mainStatus) {
            case 0: return [];
            case 1: return [ { value: 11, name: 'Active'}, { value: 12, name: 'Probation'}];
            case 2: return [ { value: 21, name: 'Active'}, { value: 22, name: 'Probation'}];
            case 3: return [ { value: 31, name: 'Suspended'}, { value: 32, name: 'Banned'}, { value: 33, name: 'Pulled out'}];
            case 4: return [ { value: 41, name: 'Unattended'}, { value: 42, name: 'Attended'}, { value: 43, name: 'Rejected'}];
            default: return [];
        }
    }

    static getTripVechicleTypeName(vehicletype: string) {
        switch (vehicletype) {
            case 'motorcycle': return 'Motorcycle';
            case 'mv_small': return 'MV - Small';
            case 'mv_medium': return 'MV - Medium';
            case 'mv_large': return 'MV - Large';
            default: return 'Motorcycle';
        }
    }
    static getAddonName(addon: string) {
        switch (addon) {
            case 'happybag': return 'Happy Bag';
            case 'jrbag': return 'JoyRide Bag';
            case 'cash_remittance': return 'Cash Remittance';
            case 'driver_help': return 'Driver Helps';
            case 'extra_help': return 'Extra Helper';
            case 'extra_help_2': return 'Extra Helper (2nd)';
            case 'mpv': return 'MPV';
            default: return 'Driver Helps';
        }
    }
    static getAreaName(area: string) {
      switch (area) {
            case 'Metro Manila': return 'Mega Manila';
            case 'Metro Cebu': return 'Metro Cebu';
            case 'Baguio': return 'Baguio';
            default: return '';
        }
    }
    static getOtppProviderName(provider: string) {
        switch (provider) {
            case 'smart': return 'Smart';
            case 'wavecell': return 'Wavecell';
            default: return 'Smart';
        }
      }
    static getRegions(): string[] {
        return [
          'Metro Manila',
          'Metro Cebu',
          'Baguio'
        ];
      }
    static getEMIConstAmount(region: string) {
        if (!region) { return null; }
        switch (region) {
            case 'Metro Manila': return 3200;
            case  'Metro Cebu': return  3500;
            default: return 0;
        }
    }
    static getOtppProvider(): string[] {
        return [
          'smart',
          'wavecell'
        ];
      }
      
    static getTripType(): string[] {
        return [
          'MCTaxi',
          'Delivery',
          'Pabili' ,
          'Taxicle'
        ];
      }
      static getTripTypeFare(): string[] {
        return [
          'MCTaxi',
          'Delivery',
          'Pabili' ,
          'Tricycle',
          'HMDelivery'
        ];
      }
      static getFranchiseAreaVehicleType(): string[] {
        return [
          'motorcycle',
          'mv_small',
          'mv_medium' ,
          'mv_large'
        ];
      }
      static getHMVehicleType(): string[] {
        return [
          'All',
          'motorcycle',
          'mv_small',
          'mv_medium' ,
          'mv_large'
        ];
      }
      static getFranchiseAreaVehicleTypeAddons(): string[] {
        return [
          'happybag',
          'cash_remittance',
          'mpv',
          'driver_help',
          'extra_help',
          'extra_help_2',
        ];
      }
      static getTripVechicleType(): string[] {
        return [
            'All',
            'tricycle',
            'motorcycle',
            'mv_small',
            'mv_medium',
            'mv_large'
        ];
      }
      static getDriverVechicleType(): string[] {
        return [
            'tricycle',
            'motorcycle',
            'mv_small',
            'mv_medium',
            'mv_large'
        ];
      }
      static getVehiclyName(type: string) {
        if (!type) { return ''; }
        switch (type) {
            case 'All': return 'All';
            case  'tricycle': return 'Taxicle';
            case  'motorcycle': return  'Motorcycle';
            case  'mv_small': return  'MV-Small';
            case  'mv_medium': return  'MV-Medium';
            case  'mv_large': return  'MV-Large';
            default: return '';
        }
      }
      static getVehiclyNameWithDescription(type: string) {
        if (!type) { return ''; }
        switch (type) {
            case 'All': return 'All';
            case  'tricycle': return 'Taxicle';
            case  'motorcycle': return  'Motorcycle';
            case  'mv_small': return  'MV - Small (Sedan, MPV/SUV)';
            case  'mv_medium': return  'MV - Medium (Van/L300)';
            case  'mv_large': return  'MV - Large (Trucks)';
            default: return '';
        }
      }
    }
