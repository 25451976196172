import { AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
export enum UploadTaskState {
    NotStarted = 1,
    Uploading,
    Success,
    Failed
}

export class UploadTaskTricycle {
    /** The file to be uploaded to the storage bucket */
    file: File;

    /** The storage bucket reference to where we will be uploading the file */
    path: string;

    pathset: string;

    /** AngularFireStorage reference used to start the file upload */
    storageRef: AngularFireStorageReference;

    /** The network task that will be lazily created when upload starts */
    uploadTask?: AngularFireUploadTask;

    /** Download URL of the image */
    downloadURLObservable?: Observable<any>;

    /** Observable returning the upload progress */
    uploadProgress?: Observable<number | undefined>;

    /** Provides the current status of the file upload */
    state: UploadTaskState;

    constructor(file: File, path: string, storage: AngularFireStorage) {
        if (environment.endpoint === 'https://us-central1-joyride-prod.cloudfunctions.net') {
            this.pathset ='https://firebasestorage.googleapis.com/v0/b/joyride-prod.appspot.com/o/';
        } else {
            this.pathset ='https://firebasestorage.googleapis.com/v0/b/joyride-dev-bb1bd.appspot.com/o/';
        }
        const link = '?alt=media&token=2068abc8-0c50-4cc0-98f9-4afc5a00488e';
        var res = path.replace(/[/]/g, "%2F");
        this.file = file;
        this.path = this.pathset + res + link;
        this.storageRef = storage.ref(path);
        this.state = UploadTaskState.NotStarted;
    }

    // Check whether we have an upload in progress 
    private canBeginUpload() {
        return (this.state === UploadTaskState.NotStarted || this.state === UploadTaskState.Failed);
    }

    /**
     * This method does a couple of initializations.
     * 1. Starts the file upload to storage bucket
     * 2. Initializes the percentage changes listener to track upload progress
     */
    beginUpload() {
        if (!this.canBeginUpload()) {
            return console.log('skipping upload ' + this.state + this.uploadTask);
        }

        this.state = UploadTaskState.Uploading;
        this.uploadTask = this.storageRef.put(this.file);
        this.uploadProgress = this.uploadTask.percentageChanges();
        this.downloadURLObservable = this.storageRef.getDownloadURL();
        this.uploadTask.then(snapshot => this.state = UploadTaskState.Success)
            .catch(e => this.state = UploadTaskState.Failed)
    }

    urlObservable() {
        return this.storageRef.getDownloadURL();
    }
}
