import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
//import { FareUtils } from 'src/app/pages/onboarding/driver/fare.utils';
import { AlertModel, FareSettings, FareSlab, FareSettingsFranchiseArea } from 'src/app/interfaces/interfaces';
import { UpdateFareSettingsAPI } from 'src/app/api/fareSettings.api';

@Component({
  selector: 'app-vehicleaddon',
  templateUrl: './vehicleaddon.component.html',
  styleUrls: ['./vehicleaddon.component.css']
})
export class VehicleAddonSettingsComponent implements OnInit {
  serviceEnable: any;
  areas: any;
  defaultArea: any;
  areaTripType: any;
  fareSettingsFranchiseArea: any;
  private fareSettingsObservable: Observable<FareSettings>;
  private updateFareSettingsAPI: UpdateFareSettingsAPI;
  FareSettingsFranchiseArea: FareSettingsFranchiseArea;
  isSaving = true;
  isEditing = false;
  isLoading = false;
  isFareTableValid = true;
  onboardingRegions = [];
  tripTypecombo = [];
  vehicleTypecombo = [];
  addonscombo = [];
  defaultTripType: string;
  defaultVehicleType: string;
  OnboardingUtils:any;
  get disableField(): boolean {
    return this.updateFareSettingsAPI.isLoading;
  }
  get fareTable(): FareSlab[] {
    return this.fareSettings.fareTable;
  }
  alert: AlertModel;
  fareSettings: any;
  fareSettingsCopy: any;
  addOnfareSettings: any;
  constructor(private modalService: NgbModal, private dbService: DatabaseService, private apiService: ApiService,  public utils: UtilsService) {
    this.OnboardingUtils = OnboardingUtils;
    this.tripTypecombo = OnboardingUtils.getTripTypeFare();
    this.vehicleTypecombo = OnboardingUtils.getFranchiseAreaVehicleType();
    this.addonscombo = OnboardingUtils.getFranchiseAreaVehicleTypeAddons();
    this.defaultTripType = 'HMDelivery';
    this.defaultVehicleType = this.vehicleTypecombo[0];
  }
 ngOnInit() {
   this.loadFranchiseArea();
 }

 ifChecked(item){
    if(this.fareSettings) {
      return this.fareSettings.available_addons.includes(item)
    }
  }

  updateCheckedOptions(option, event) {
     if(event.target.checked == true &&  this.fareSettings.available_addons) {
       this.fareSettings.available_addons.push(option)
     } else if (event.target.checked == false &&  this.fareSettings.available_addons) {
       this.removeArea(option)
     } else {
       this.fareSettings.available_addons = []
       this.fareSettings.available_addons.push(option)
     }

     this.saveAddon();
  }

  removeArea(area) {
    var index = this.fareSettings.available_addons.indexOf(area);  
    if (index !== -1) {
        this.fareSettings.available_addons.splice(index, 1);
    }
  }

  saveAddon() {
    this.dbService.updateFareSettingsHMDeliveryValues(this.fareSettings, this.defaultArea, this.defaultVehicleType).then(res => {
        this.showAlert('success', 'Updating vehicle add-ons is successful');
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
          this.showAlert('danger', 'Updating vehicle add-ons failed');
      });
  }

 loadFranchiseArea() {
    this.dbService.getFranchiseArea().then(results => {
      this.areas = results
      this.defaultArea = this.areas[0]
      this.areaFilter();
    }).catch(err => {
      console.log(err);
    })
 }

 areaFilter() {
   this.isLoading = true
   const area = this.defaultArea
   const tripType = this.defaultTripType
     this.dbService.filterFranchiseAreaByTriptype(area, tripType).then(res => {
            this.isLoading = false
            this.vehicleFilter();
            
        })
        .catch(e => {
            this.isLoading = false
            this.fareSettings = 1;
        });
 }

 vehicleFilter() {
   this.isLoading = true
   const area = this.defaultArea
   const vehicle = this.defaultVehicleType;
   this.dbService.filterFranchiseAreaByVehicletype(area, vehicle).then(res => {
          this.isLoading = false
          this.fareSettings = res.data();
          this.fareSettingsCopy = this.clone(res.data());
      })
      .catch(e => {
          this.isLoading = false
          this.fareSettings = 1;
      });
 }


  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  clone(obj: any): any {
    return (JSON.parse(JSON.stringify(obj)));
  }
}
