import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingApplication,  DriverLocation,  DriverLocationProfile, AlertModel
} from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI, DriverLocationAPI, DriverLocationStatusUpdateAPI } from 'src/app/api/driverList.api';
import { PermissionService } from 'src/app/services/permission.service';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-driver-tripavailability',
  templateUrl: './driver-tripavailability.component.html',
  styleUrls: ['./driver-tripavailability.component.css']
})
export class DriverTripAvailabilityComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  api: DriversListAPI;
  phone = new FormControl('');
  bikerid = new FormControl('');
  alert: AlertModel;
  isVisiableTripStatus: any;
  driverTripStatusForm: FormGroup;
  driverLocationAPI: DriverLocationAPI;
  driverLocation: DriverLocation;
  driverLocationStatusUpdateAPI: DriverLocationStatusUpdateAPI;
  driverLocationdata: DriverLocationProfile;
  saving: boolean;
  constructor(private fb: FormBuilder, private dbService: DatabaseService,
              public permissions: PermissionService, private apiService: ApiService) {
    this.isVisiableTripStatus = false;
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.paginator = new DriverProfilePaginator(1, 10, drivers);
    });
    this.driverLocationAPI = new DriverLocationAPI(this.dbService, true, (success, profile) => {
      if (success && profile) {
        this.driverLocationdata = profile;
        this.driverTripStatusForm.controls.online.setValue(this.driverLocationdata.d.online);
        this.driverTripStatusForm.controls.status.setValue(this.driverLocationdata.d.status);
      } else if (success) {
        this.showAlert('danger', 'No Biker was found with the searched ID');
      } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Biker profile'); }
    });
    this.driverLocationStatusUpdateAPI = new DriverLocationStatusUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.saving = false;
        this.showAlert(alertType, message);
        this.driverTripStatusForm.markAsPristine();
      } else {
        this.showAlert(alertType, message);
        this.driverTripStatusForm.enable();
      }
      this.saving = false;
    });
  }
  ngOnInit() {
    this.createTriStatusForm();
  }
  get applications(): OnboardingApplication[] {
    if (this.paginator) {
      return this.paginator.getItemsWithFilter('');
    } else {
      return null;
    }
  }
  private async loadDriverApplications(phone?: string, bikerid?: string) {
    this.api.loadDriverTripAvailability(phone, bikerid);
  }
  getDriverSearch() {
    this.isVisiableTripStatus = false;
    this.loadDriverApplications(this.phone.value, this.bikerid.value);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  getDriverTripStatus(driverId) {
    this.isVisiableTripStatus = true;
    this.driverLocationAPI.fetchDriverLocation(driverId);
  }
  private createTriStatusForm() {
    this.driverTripStatusForm = this.fb.group({
      online: [(this.driverLocationdata === undefined ? '' : this.driverLocationdata.d.online), Validators.required],
      status: [(this.driverLocationdata === undefined ? '' : this.driverLocationdata.d.status), Validators.required],
      driverId: [''],
      uid: ['']
    });
  }
  saveDriverLocationStatus() {
    this.driverTripStatusForm.controls.driverId.setValue(this.driverLocationdata.d.uid);
    this.driverTripStatusForm.controls.uid.setValue(this.driverLocationdata.d.uid);
    const value = this.driverTripStatusForm.value;
    this.saving = true;
    this.driverLocationStatusUpdateAPI.updateDriverLocationStatus(this.driverLocationdata.d.uid, value);
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  canSaveTriStatusForm(): boolean {
    return this.driverTripStatusForm.valid && this.driverTripStatusForm.dirty;
  }
}
