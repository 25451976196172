import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { TopupHistory } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { MetroBankTopupAPI  } from 'src/app/api/metrobank.api';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-metro-topupinquiry',
  templateUrl: './metrobank-topupinquiry-history.component.html'
})
export class MetroTopupInquiryComponent implements OnInit {
  drivers: TopupHistory[];
  filter = new DriverMetroFilter();
  driverphone = new FormControl('');
  dirverRefNo = new FormControl('');
  amount = new FormControl('');
  type = new FormControl('');
  transactionCode = new FormControl('');
  metroBankTopupAPI: MetroBankTopupAPI ;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;

constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService) {
this.drivers = [];
this.paginationCount = 0;
this.metroBankTopupAPI = new MetroBankTopupAPI(dbService, (drivers) => {
    this.drivers = drivers;
    this.getPaginations();
  });

}

ngOnInit() {
}
getPaginations() {
  if (this.selectedFilter > this.drivers.length) {
    this.disableInitial = false;
  }
  if (this.pageCount === 'previous') {
    this.paginationCount--;
  } else if (this.pageCount === 'next') {
    this.paginationCount++;
  }
  this.disableLoad = false;
  this.disableNext = false;
  this.disablePrevious = false;
}
applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') { this.pageCount = 'load'; this.paginationCount = 0; }
    if (buttonAction === 'previous') { this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') { this.pageCount = 'next', this.disableNext = true; }
    this.metroBankTopupAPI.bindTopUpInquiry(this.driverphone.value, this.dirverRefNo.value, Number(this.amount.value), this.type.value,
    this.transactionCode.value, this.filter.from.selectedDate, this.filter.to.selectedDate, Number(this.selectedFilter), buttonAction);
}
filterpage(status: number) {
  this.selectedFilter = status;
}

}
/*** FILTER for Trips */
class DriverMetroFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
}
setStatus(status: number) {
}
reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
}
}
