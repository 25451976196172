import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CarouselBannerAPI } from 'src/app/api/carousel.api';
import { DatabaseService } from 'src/app/services/database.service';
import { CarouselBanner, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/utils.service';
import { CreateCarouselBannerComponent } from 'src/app/modals/carousel-banner/create-carousel-banner.component';
import { EditCarouselBannerComponent } from 'src/app/modals/carousel-banner/edit-carousel-banner.component';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-carousel-banner',
  templateUrl: './carousel-banner.component.html',
  styleUrls: ['./carousel-banner.component.css']
})
export class CarouselBannerComponent implements OnInit {
  carouselData: any = [];
  loading: boolean = false;
  carouselFormFilter: FormGroup;
  api: CarouselBannerAPI;
  date:any;
  regions = OnboardingUtils.getRegions()

  constructor(public utils: UtilsService, private dbService: DatabaseService, private modalService: NgbModal, private fb: FormBuilder) {
                this.api = new CarouselBannerAPI(dbService, (result) => {
                  this.carouselData = result;
                });
   }

  ngOnInit() {
    this.createMerchantFormFilter()
  }

  createMerchantFormFilter() {
    const date: Date = new Date();
    this.carouselFormFilter = this.fb.group({
      bannerName: [ '' ],
      region: [ '' ],
      status: []
    });
    this.loadCarouselBannerList()
  }

  loadCarouselBannerList() {
    const status = this.carouselFormFilter.value.status;
    const bannerName = this.carouselFormFilter.value.bannerName;
    const region = this.carouselFormFilter.value.region;
    this.api.getCarouselBannerSearch(status, bannerName, region);
  }

  covertDateFormat(data: string){
    return moment(data).format('MMM DD, YYYY')
  }

  clear() {
    this.carouselFormFilter.reset()
    this.loadCarouselBannerList()
  }

  createCarouselBannerModal() {
    const modalRef =  this.modalService.open(CreateCarouselBannerComponent);
    modalRef.result
    .then((result) => { this.loadCarouselBannerList();  }, (reason) => { this.loadCarouselBannerList() });
  }

  editCarouselBannerModal(data) {
    const modalRef =  this.modalService.open(EditCarouselBannerComponent);
    modalRef.componentInstance.loadedData = data;
    modalRef.result
    .then((result) => { this.loadCarouselBannerList(); }, (reason) => { this.loadCarouselBannerList() });
  }

  deleteCarouselBanner(data: CarouselBanner, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete '+data.bannerName+'?';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteCarouselBanner(data.id);
      })
      .then(() => {
        this.loadCarouselBannerList();
      })
      .catch(e => {
        console.log(e);
      });
  }
}


