import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { Customer, CustomerSpecialRate } from '../interfaces/interfaces';
import { Subscription } from 'rxjs';

export class CustomerAPI extends BaseAPI {
    private handler: ([Customer]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;

    constructor(dbService: DatabaseService, completion: ([Customer]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadCustomerbyPhone(phone?: string) {
        this.state = APIState.loading;
        this.dbService.getCustomersbyPhone(phone).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [Customer]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadCustomerSearch(phone?: string, firstname?: string, emailId?: string) {
        this.state = APIState.loading;
        this.dbService.getCustomersbySearch(phone, firstname, emailId).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [Customer]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    getCustomer(from?: Date, to?: Date, lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, status?: string) {

        this.state = APIState.loading;
        const dataLength = 1;
        if (dataLength === 1) {
            this.state = APIState.successWithData;
            return this.dbService.getAllCustomer(from, to, lastResponse, prviousStart, firstResonse, limit, status);
        } else {
            this.state = APIState.failed;
        }
    }
    async loadCustomerSummary(phone?: string, from?: Date, to?: Date) {
        this.state = APIState.loading;
        await this.dbService.getCustomersbySummary(phone, from, to).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [Customer]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadCustomerWalletBalance() {
        this.state = APIState.loading;
        this.dbService.getAllCustomerRegistrationsBalance().then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [Customer]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadCustomerLowWalletBalance() {
        this.state = APIState.loading;
        this.dbService.getAllCustomerRegistrationsLowBalance().then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [Customer]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}
export class CustomerRateAPI extends BaseAPI {
    private handler: (CustomerSpecialRate) => void;
    private unsubscribe: Subscription;

    constructor(dbService: DatabaseService, completion: (CustomerSpecialRate) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadCustomerSpecialRate(uid: string) {
        this.state = APIState.loading;
        this.dbService.getCustomerSpecialRate(uid).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [CustomerSpecialRate]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}
export class CustomerProfileAPI extends BaseAPI {
    private handler: (success: boolean, profile?: Customer) => void;
    private subscription: Subscription;
    private listenForChanges = false;
    constructor(dbService: DatabaseService, listenForChanges: boolean = false,
                completion: (success: boolean, profile?: Customer) => void) {
        super(dbService);
        this.handler = completion;
        this.listenForChanges = listenForChanges;
    }
    fetchCustomerProfile(customerId: string) {
        if (!customerId) { return; }
        this.state = APIState.loading;
        this.dbService.getCustomer(customerId).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data() as Customer);
                    this.beginListening(customerId);
                } else {
                    this.fetchCustomerProfileByWallet(customerId);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
    fetchCustomerProfileByWallet(customerId: string) {
        if (!customerId) { return; }
        this.state = APIState.loading;
        this.dbService.getCustomerByWalletAdj(customerId).get().toPromise()
            .then(snapshot => {
                if (snapshot.empty) {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
                snapshot.forEach(doc => {
                    customerId = doc.data().uid;
                    this.state = APIState.successWithData;
                    this.handler(true, doc.data() as Customer);
                    this.beginListening(customerId);
                });
            })
            .catch(err => {
                console.log(err);
                this.state = APIState.failed;
            });
    }
    beginListening(customerId: string) {
        if (!this.listenForChanges) { return; }
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getCustomer(customerId).valueChanges().subscribe(value => {
            this.handler(true, value as Customer);
        }, e => { console.error(e); });
    }
    endListening() {
        if (this.subscription) { this.subscription.unsubscribe(); }
    }
}
export class CustomerPayMayaTopupHistoryAPI extends BaseAPI {
    private handler: ([any]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([any]) => void) {
        super(dbService);
        this.handler = completion;
    }
    bindTopupHistory(uid?: string, phone?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaCustomerTopupLinkHistory(uid, phone, fromDate, toDate, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
}

