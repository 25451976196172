import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { DatePipe } from '@angular/common';
import { DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { ApiService } from 'src/app/services/api.service';
import { AlertModel } from 'src/app/interfaces/interfaces';
import * as moment from 'moment';
@Component({
  selector: 'app-emi-management',
  templateUrl: './emi-management.component.html',
  styleUrls: ['./emi-management.component.css']
})
export class DriverEmiManagementComponent implements OnInit {
  alert: AlertModel;
  filter = new DriverEmiFilter();
  driverAPI: DriverProfileUpdateAPI;
  constructor(public permissions: PermissionService, public datepipe: DatePipe, apiService: ApiService) {
    this.driverAPI = new DriverProfileUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
      } else {
      }
      this.showAlert(alertType, message);
    });
  }
  ngOnInit() {
  }
  applyFilter() {
    this.driverAPI.updateEMI(this.datepipe.transform(this.filter.from.selectedDate, 'yyyy-MM-dd'));
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
class DriverEmiFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
  }
}