import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { TricycleDropoff, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-tricycle-dropoff',
  templateUrl: './edit-tricycle-dropoff.component.html'
})
export class EditTricycleDropoffComponent implements OnInit {
  tricycleDropoffForm: FormGroup;
  tricycleDropoffModel: TricycleDropoff;
  alert: AlertModel;
  saving: boolean;
  onboardingRegions = [];
  onboardingCity: SupportedRegions[];
  loadedData: any = [];
  pickupcitycode: any;
  pickupcity: any;
  dropoffcity: any;
  region: any;
  latlong: any;
  docID: any;
  isactive: any;
  tricyclePickup: any[];
  cityCode: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.pickupcitycode = this.loadedData.pickup_city_code;
    this.pickupcity =  this.loadedData.pickup_city;
    this.region = this.loadedData.region;
    this.latlong = this.loadedData.latlong;
    this.dropoffcity = this.loadedData.dropoff_city;
    this.docID = this.loadedData.id;
    this.isactive = this.loadedData.is_active;
    this.getRegions();
    this.loadTricyclePickupCitiesRegion();
    this.createForm();
  }
  private createForm() {
    this.tricycleDropoffForm = this.fb.group({
        pickup_city_code: ['', Validators.required],
        pickup_city: ['', Validators.required],
        dropoff_city: ['', Validators.required],
        region: ['', Validators.required],
        latlong: ['', [Validators.required, ValidatorService.cannotContainSpace]]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveTricyclePickup(): boolean {
    return this.tricycleDropoffForm.valid && this.tricycleDropoffForm.dirty;
  }

  async loadTricyclePickupCities() {
    this.dbService.getTricyclePickupCities(this.tricycleDropoffForm.controls.region.value)
    .then(results => {
      this.tricyclePickup = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  async loadTricyclePickupCitiesRegion() {
    this.dbService.getTricyclePickupCities(this.region)
    .then(results => {
      this.tricyclePickup = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  cityCodeValues(cityname: string) {
    let code = this.tricyclePickup.filter(t => t.pickup_city === cityname);
    this.pickupcitycode = code[0].pickup_city_code;
  }
  createTricycleDropoffCity() {
    this.tricycleDropoffModel = {
      id: this.docID,
      pickup_city_code: this.tricycleDropoffForm.controls.pickup_city_code.value,
      pickup_city: this.tricycleDropoffForm.controls.pickup_city.value,
      dropoff_city: this.tricycleDropoffForm.controls.dropoff_city.value,
      region: this.tricycleDropoffForm.controls.region.value,
      latlong: this.tricycleDropoffForm.controls.latlong.value,
      is_active: this.isactive,
      updated_at: new Date()
    };
    this.tricycleDropoffForm.disable();
    this.saving = true;
    this.dbService.hasPickupCityCodeDropoff(this.tricycleDropoffForm.controls.region.value,
      this.tricycleDropoffForm.controls.pickup_city.value, this.tricycleDropoffForm.controls.dropoff_city.value)
    .then(result => {
      if (result) {
        this.showAlert('danger', 'The entered Pickup City and Drop off city already exists in the database');
        this.tricycleDropoffForm.enable(); // Enable editing
        return Promise.reject();
      } else {
        return this.dbService.editTricycleDropoffCities(this.tricycleDropoffModel,
          this.tricycleDropoffForm.controls.region.value, this.docID);
      }
    })
      .then(() => {
        this.showAlert('success', 'Taxicle Dropoff City Updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the Taxicle Dropoff City - ' + e.message);
        this.tricycleDropoffForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  getRegions() {
    this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }
}
