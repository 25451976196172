import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { DriversListAPI, DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { UtilsService } from 'src/app/services/utils.service';
import { DatabaseService } from 'src/app/services/database.service';
import { DatePipe } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { EMIHistoryAPI } from 'src/app/api/wallet.api';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { WalletUtils, EmiTransactionType } from 'src/app/utils/wallet.utils';
@Component({
  selector: 'app-driver-emi-history',
  templateUrl: './driver-emi-history.component.html',
  styleUrls: ['./driver-emi-history.component.css']
})
export class DriverEmiHistorySearchComponent implements OnInit {
  emiHistory: any[];
  api: DriversListAPI;
  driverAPI: DriverProfileUpdateAPI;
  filter = new DriverEmiFilter();
  emiHistrory: EMIHistoryAPI;
  emiHistoryLoading: boolean;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  alert: AlertModel;
  dateValues: any;
  historyEMI: any;
  constructor(private utils: UtilsService, private dbService: DatabaseService, public datepipe: DatePipe, private db: AngularFirestore) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.emiHistoryLoading = false;
    this.emiHistory = [];
    this.emiHistrory = new EMIHistoryAPI(this.dbService, (history) => {
      if (history) {
        this.emiHistory = history.filter(val => val.length > 0);
        this.emiHistoryLoading = false;
        this.regionFilter();
      } else {
        this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.');
      }
    });
  }
  ngOnInit() {
  }
  applyFilter() {
    this.emiHistoryLoading = true;
    this.emiHistory = [];
    const multiDate = this.filterDateRange(this.filter.from.selectedDate, this.filter.to.selectedDate);
    this.dateValues = multiDate;
    this.emiHistrory.getAllEMIHistory(this.dateValues);
  }
  filterDateRange(startDate, endDate) {
    const result = [];
    const emiStartDate = new Date(startDate);
    const emiEndDate = new Date(endDate);
    if (emiStartDate === emiEndDate) {
      result.push(emiStartDate.toISOString().substr(0, 10));
    } else {
      while (emiStartDate < emiEndDate) {
        emiStartDate.setUTCDate(emiStartDate.getUTCDate() + 1);
        result.push(emiStartDate.toISOString().substr(0, 10));
      }
    }
    return result;
  }
  regionFilter() {
  for( var i=0; i < this.emiHistory.length; i++) {
     if (this.region.value === 'Metro Manila') {
      this.emiHistory = this.emiHistory[i].filter( val => (val.region === undefined)
        || (val.region === this.region.value));
    } else {
      this.emiHistory = this.emiHistory[i].filter(regionFilter => regionFilter.region === this.region.value);
    }
  }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  getEmiTransactionType(type: EmiTransactionType) {
        return WalletUtils.getEmiTransactionType(type);
  }
}
/*** FILTER for Driver EMI  */
class DriverEmiFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
  }
}
