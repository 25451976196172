import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { CustomerAPI } from 'src/app/api/customer.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { Customer } from 'src/app/interfaces/interfaces';
import { TripListAPI } from 'src/app/api/trip.api';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerSummaryComponent implements OnInit {
  loading = false;
  customerphone  = new FormControl('');
  customerUid: any;
  trip: Trip[];
  api: CustomerAPI;
  tripapi: TripListAPI;
  filter = new CustomerFilter();
  customers: any[] = [];
  rideRequested: any = 0;
  searchDriver: any = 0;
  driverAssigned: any = 0;
  driverArrived: any = 0;
  tripStarted: any = 0;
  tripCancelRider: any = 0;
  tripCancelEarlyRider: any = 0;
  tripCancelDriver: any = 0;
  tripCompleted: any = 0;
  noDriversFound: any = 0;
  serverError: any = 0;
  adminCancel: any = 0;

  constructor(private dbService: DatabaseService, private modalService: NgbModal,
              public utils: UtilsService, public permissions: PermissionService) {
                this.customers = [];
                this.api = new CustomerAPI(dbService, (customer) => {
                    this.customers = [];
                    this.customerUid = '';
                    this.customers = customer;
                    this.customerUid = this.customers[0].uid;
                });
                this.trip = [];
                this.tripapi = new TripListAPI(dbService, (trip) => {
                    this.trip = [];
                    this.rideRequested = 0;
                    this.searchDriver = 0;
                    this.driverAssigned = 0;
                    this.driverArrived = 0;
                    this.tripStarted = 0;
                    this.tripStarted = 0;
                    this.tripCancelRider = 0;
                    this.tripCancelEarlyRider = 0;
                    this.tripCancelDriver = 0;
                    this.tripCompleted = 0;
                    this.noDriversFound = 0;
                    this.serverError = 0;
                    this.adminCancel = 0;
                    this.trip = trip;
                    this.loadValues();
                });
   }

  ngOnInit() {
  }
 async loadCustomers() {
      this.loading = true;
      this.customers = [];
      this.trip = [];
      await this.api.loadCustomerSummary(this.customerphone.value, this.filter.from.selectedDate, this.filter.to.selectedDate);
      if (this.customerUid !== 0) {
        await  this.tripapi.allCustomerTripsRef(this.customerUid, this.filter.from.selectedDate, this.filter.to.selectedDate);
      }
  }
  loadValues() {
  const val = this.trip;
  if ( this.trip.length > 0) {
  for ( let i = 0;  this.trip.length > i; i++ ) {
    const valStatus =  this.trip[i].status;
    switch (valStatus) {
      case 0:
        this.rideRequested = this.rideRequested + 1;
        break;
      case 1:
        this.searchDriver = this.searchDriver + 1;
        break;
      case 2:
        this.driverAssigned = this.driverAssigned + 1;
        break;
      case 3:
        this.driverArrived = this.driverArrived + 1;
        break;
      case 4:
        this.tripStarted = this.tripStarted + 1;
        break;
      case 5:
        this.tripCancelRider = this.tripCancelRider + 1;
        break;
      case 6:
        this.tripCancelEarlyRider = this.tripCancelEarlyRider + 1;
        break;
      case 7:
        this.tripCancelDriver = this.tripCancelDriver + 1;
        break;
      case 8:
        this.tripCompleted = this.tripCompleted + 1;
        break;
      case 9:
        this.noDriversFound = this.noDriversFound + 1;
        break;
      case 10:
        this.serverError = this.serverError + 1;
        break;
      case 11:
        this.adminCancel = this.adminCancel + 1;
        break;

    }
  }
  }
  }
  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
}

/**
 * FILTER for Customers
 */
class CustomerFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    setStatus(status: number) {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
  }
