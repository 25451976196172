import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { NewUserPromo, NewPromoCode, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { PromoUtils, PromoDiscountType } from 'src/app/utils/promo.utils';
import { UtilsService } from 'src/app/services/utils.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'edit-franchise-area',
  templateUrl: './edit-franchise-area.component.html',
  styleUrls: ['./create-promo-code.component.css']
})
export class EditFranchiseAreaComponent implements OnInit {
  areas:any;
  promoUserForm: FormGroup;
  promoUserModel: NewUserPromo;
  alert: AlertModel;
  promoCode: any;
  id:any;
  allowedUsers: any;
  verified: boolean = false;
  saving: boolean = false;
  onboardingRegions = [];
  valid: any = '';
  onboardingCity: SupportedRegions[];
  franchiseAreaCheckbox:any = [];
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    public utils: UtilsService,
    private modalService: NgbModal
  ) {
    
  }
  ngOnInit() {
    this.loadCustomers();
    this.loadFranchiseArea();
  }
  loadFranchiseArea() {
      this.dbService.getFranchiseArea().then(results => {
        this.areas = results
      }).catch(err => {
        console.log(err);
      })
  }

  saveFranchiseArea() {
   this.saving = true;
    this.dbService.editPromoCode(this.promoCode, this.promoCode.id)
      .then(() => {
        this.showAlert('success', 'Updated successfully');
      })
      .catch(e => {
        this.showAlert('danger', 'Error - ' + e.message);
      })
      .finally(() => {
        this.saving = false;
      });
  }

  updateCheckedOptions(option, event) {
     if(event.target.checked == true &&  this.promoCode.franchise_area) {
       this.promoCode.franchise_area.push(option)
     } else if (event.target.checked == false &&  this.promoCode.franchise_area) {
       this.removeArea(option)
     } else {
       this.promoCode.franchise_area = []
       this.promoCode.franchise_area.push(option)
     }
  }

  removeArea(area) {
    var index = this.promoCode.franchise_area.indexOf(area);  
    if (index !== -1) {
        this.promoCode.franchise_area.splice(index, 1);
    }
  }

  loadCustomers() {
    this.dbService.getCustomersbyPromocode(this.promoCode.id)
      .then(result => {
        this.allowedUsers = result;
    })
  }
 
  ifChecked(item){
    if(this.promoCode.franchise_area) {
      return this.promoCode.franchise_area.includes(item)
    }
  }
  addUserToPromo() {
    this.dbService.createUserPromoCode(this.promoUserModel, this.promoCode.id)
      .then(result => {
        this.loadCustomers()
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while saving user to promo code - ' + e.message);
      })
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

}
