import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { NewsArticle, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import { UploadTask } from 'src/app/classes/uploadtask';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AngularFireStorage } from '@angular/fire/storage';
import { ImageResult, ResizeOptions } from 'ng2-imageupload';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-news-article',
  templateUrl: './news-articles-edit.component.html'
})
export class EditNewsArticleComponent implements OnInit {
  newsArticleForm: FormGroup;
  newsArticle: NewsArticle;
  alert: AlertModel;
  saving: boolean;
  appReferenceNumber: string;
  uploadReferences: Map<FormControl, UploadTask>;
  loadedData: any;
 
  docID: any;
  title: any;
  shortDescription: any;
  regions = OnboardingUtils.getRegions();
  fullContent: any;
  newsAuthor: any;
  publishDate: any;
  expiryDate: any;
  newsType: any;
  linkUrl: any;
  linkType: any;
  bannerUrl: any;
  newsUserType: any;
  isActive: any;
  region: any;
  resizeOptions: ResizeOptions = {
      resizeMaxHeight: 500,
  };


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    private storage: AngularFireStorage
  ) {
    this.uploadReferences = new Map();
    this.appReferenceNumber = this.generateApplicationID();
  }
  ngOnInit() {
    this.createForm();

    this.docID = this.loadedData.id;
    this.title = this.loadedData.title;
    this.shortDescription = this.loadedData.shortDescription;
    this.fullContent = this.loadedData.fullContent;
    // this.fullContent = this.loadedData.fullContent;
    this.newsAuthor = this.loadedData.newsAuthor;
    this.publishDate = this.loadedData.publishDate;
    this.expiryDate = this.loadedData.expiryDate;
    this.newsType = this.loadedData.newsType;
    this.linkUrl = this.loadedData.linkUrl;
    this.linkType = this.loadedData.linkType;
    this.bannerUrl = this.loadedData.bannerUrl;
    this.newsUserType = this.loadedData.newsUserType;
    this.isActive = this.loadedData.isActive;
    this.region = this.loadedData.region;
  }

  private createForm() {
    this.newsArticleForm = this.fb.group({
        id: '',
        title: ['', [Validators.required]],
        shortDescription: ['', Validators.required],
        fullContent: ['', Validators.required],
        newsAuthor: ['', [Validators.required]],
        publishDate: ['', [Validators.required]],
        expiryDate: ['', [Validators.required]],
        newsType: ['', [Validators.required]],
        region: ['', [Validators.required]],
        linkUrl: ['', [Validators.required]],
        linkType: ['', [Validators.required]],
        bannerUrl: [null, [Validators.required]],
        newsUserType: ['', [Validators.required]],
        isActive: false,

    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveNewsArticles(): boolean {
    return this.newsArticleForm.valid && this.newsArticleForm.dirty;
  }

  private generateApplicationID() {
    const timestamp = Math.round(new Date().getTime() / 1000.0);
    const random = Math.floor(1000000 + Math.random() * 9000000);
    return (timestamp + random) + ' ';
  }


  async editNewsArticles() {

    this.newsArticleForm.disable();
    this.saving = true;
    const values = this.newsArticleForm.controls.bannerUrl.value;
    const bannerPhotoUploadTask = this.uploadReferences.get(this.newsArticleForm.controls.bannerUrl as FormControl);
    const urls = async () => {
      if (!bannerPhotoUploadTask) {
        delete values.bannerUrl;
      } else {
        const bannerPhotoURL = await bannerPhotoUploadTask.urlObservable().toPromise();
        values.bannerUrl = bannerPhotoURL;
        this.newsArticleForm.get('bannerUrl').setValue(bannerPhotoURL);
      }
    };
    await urls();

    this.newsArticle = {
        id: this.docID,
        title: this.newsArticleForm.controls.title.value,
        shortDescription: this.newsArticleForm.controls.shortDescription.value,
          fullContent: this.newsArticleForm.controls.fullContent.value,
          newsAuthor: this.newsArticleForm.controls.newsAuthor.value,
          publishDate: this.newsArticleForm.controls.publishDate.value,
          expiryDate: this.newsArticleForm.controls.expiryDate.value,
          newsType: this.newsArticleForm.controls.newsType.value,
          linkUrl: this.newsArticleForm.controls.linkUrl.value,
          region: this.newsArticleForm.controls.region.value,
          linkType: this.newsArticleForm.controls.linkType.value,
          bannerUrl: this.bannerUrl,
          newsUserType: this.newsArticleForm.controls.newsUserType.value,
          createDate: this.loadedData.createDate,
          updateDate: new Date(),
          isActive: this.newsArticleForm.controls.isActive.value,
      };

    this.dbService.editNewsArticles(this.newsArticle, this.docID)
    .then(result => {
    })
      .then(() => {
        this.showAlert('success', 'News and Article updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while updating the News and Article - ' + e.message);
        this.newsArticleForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  upload(event, c: AbstractControl) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const name = OnboardingUtils.getFileName(control);
    const path = OnboardingUtils.getUploadRefNews(this.appReferenceNumber, file, name);
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
      uploadTask.beginUpload();
    }
  }
  uploadProgress(c: AbstractControl) {
    const control = c as FormControl;
    const uploadTask = this.uploadReferences.get(control);
    if (uploadTask) { return uploadTask.uploadProgress; }
    return null;
  }
  selected(imageResult: ImageResult) {
    this.bannerUrl = imageResult.resized.dataURL;
  }
}
