import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { AdditionalSurcharge, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-create-additional-surcharge',
  templateUrl: './create-additional-surcharge.component.html'
})
export class CreateAdditionalSurchargeComponent implements OnInit {
  additionalSurchargeForm: FormGroup;
  additionalSurchargeModel: AdditionalSurcharge;
  alert: AlertModel;
  saving: boolean;
  defaultVehicleType: any;
  defaultArea:any;
  defaultTripType:any;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.createForm();
  }
  private createForm() {
    this.additionalSurchargeForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      amount: [0, [Validators.required]],
      date: [null,[Validators.required]],
      start_time: [null, [Validators.required]],
      end_time: [null, [Validators.required]],
      status: [null]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveAdditionalSurcharge(): boolean {
    return this.additionalSurchargeForm.valid && this.additionalSurchargeForm.dirty;
  }

  createAdditionalSurcharge () {
    this.additionalSurchargeModel = {
      id: '',
      name: this.additionalSurchargeForm.controls.name.value,
      amount: this.additionalSurchargeForm.controls.amount.value,
      date: this.additionalSurchargeForm.controls.date.value,
      start_time: this.additionalSurchargeForm.controls.start_time.value,
      end_time: this.additionalSurchargeForm.controls.end_time.value,
      status: 1
    };
    const TripType = this.defaultTripType;
    const vehicle = this.defaultTripType === 'HMDelivery' ? this.defaultVehicleType : 'fares';
    this.additionalSurchargeForm.disable();
    this.saving = true;
    this.dbService.createNewAdditionalSurcharge(this.additionalSurchargeModel,this.defaultArea, TripType, vehicle)
    .then(result => {
      this.showAlert('success', 'Additional Surcharge created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
    })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the additional surcharge - ' + e.message);
        this.additionalSurchargeForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
