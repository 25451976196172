import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TripListAPI, TripListExportAllAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-trip-management',
  templateUrl: './trip-managementsearch.component.html',
  styleUrls: ['./trip-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TripManagementSearchComponent implements OnInit {
  tripListAPI: TripListAPI;
  tripListExportAllAPI: TripListExportAllAPI;
  trips = [];
  tripsexport = [];
  // tslint:disable-next-line: no-use-before-declare
  filter = new TripHistoryFilter();
  drivername = new FormControl('');
  customername = new FormControl('');
  driverphone  = new FormControl('');
  customerphone  = new FormControl('');
  tripid  = new FormControl('');
  promocode  = new FormControl('');
  orderNumber  = new FormControl('');
  merchantReference = new FormControl('');
  vehicleType = new FormControl();
  tripType = new FormControl();
  dateFrom: any = '';
  dateTo: any = '';
  selectedFilter = 50;
  firstInResponse: any = [];
  lastInResponse: any = [];
  previousStart: any = [];
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  onboardingRegions = [];
  vehicleTypecombo = [];
  defaultVehicleType:string;
  franchise_area = new FormControl();
  driverId = new FormControl('');
  defaultRegion: any = 'Metro Manila';
  pageCount: any;
  defaultRegionCebu = 'Metro Cebu';
  isOnboardingProfile = false;
  areas: any = ["Metro Manila","Metro Cebu"];
  dateFilter: string = 'Date Booked';
  dateFilterIndex: number = 0;
  get selectedStatus(): string {
    return (this.filter.status) ? this.utils.tripStatusText(this.filter.status) : 'ALL TRIPS';
  }
  get fromDate(): any {
    return (this.dateFrom) ? this.filter.from.selectedDate : ''
  }
  get toDate(): any {
    return (this.dateTo) ? this.filter.to.selectedDate : ''
  }
  defaultTripType: number;
  constructor(private dbService: DatabaseService,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.paginationCount = 0;
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultTripType = this.utils.tripTypesManagement[0];
    this.vehicleTypecombo = OnboardingUtils.getHMVehicleType();
    this.defaultVehicleType = this.vehicleTypecombo[0];
    this.tripListAPI = new TripListAPI(dbService, (trips) => {
      this.trips = [];
      this.trips = trips;
      this.getPaginations();
    });
    this.tripListExportAllAPI = new TripListExportAllAPI(dbService, (tripsexport) => {
      this.tripsexport = [];
      this.tripsexport = tripsexport;
    });
    this.loadFranchiseArea();
}
ngOnInit() {
}
changeDateFrom(){
  this.dateFrom = moment(this.filter.from.date).format('DD-MM-YYYY hh:mm A')
}
changeDateTo(){
  this.dateTo = moment(this.filter.to.date).format('DD-MM-YYYY hh:mm A')
}
loadFranchiseArea() {
    this.dbService.getFranchiseArea().then(results => {
      this.areas = results
      this.defaultRegion = this.areas[4]
    }).catch(err => {
      console.log(err);
    })
 }
 filterdatetype(dateTypeIndex: number, dateType: string) {
    this.dateFilter = dateType;
    this.dateFilterIndex = dateTypeIndex;
}
filterpage(status: number) {
    this.selectedFilter = status;
}
applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.tripListAPI.loadTripsPagingRegion(this.dateFilterIndex, this.vehicleType.value, this.franchise_area.value, this.driverId.value, this.tripType.value, this.filter.status,
        this.fromDate, this.toDate, this.drivername.value, this.customername.value, this.driverphone.value,
        this.customerphone.value, this.promocode.value.toUpperCase(), this.tripid.value, this.selectedFilter, buttonAction, this.orderNumber.value, this.merchantReference.value);
   }
getDriverVehicleName(status: string): string | null {
  return OnboardingUtils.getVehiclyName(status);
}
getPaginations() {
    if (this.selectedFilter > this.trips.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.previousStart = [];
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }
export() {
    const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
    exporter.exportTripManagement(this.trips as Trip[]);
}
async exportAll() {
  await this.tripListExportAllAPI.loadTripsExport(this.dateFilterIndex, this.vehicleType.value, this.franchise_area.value, this.driverId.value, this.tripType.value, this.filter.status,
  this.fromDate, this.toDate, this.drivername.value, this.customername.value, this.driverphone.value,
  this.customerphone.value, this.promocode.value.toUpperCase(), this.tripid.value, this.orderNumber.value);
  this.allExportValues();
}
allExportValues() {
  setTimeout(() => {
  const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
  const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
  exporter.exportTripManagement(this.tripsexport as Trip[]);
}, 8000);
}
openTripInfoModal(trip: Trip) {
      const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true, backdrop : 'static', keyboard : false});
      modalRef.componentInstance.trip = trip;
}
isNull(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return ''; }
    return path;
}
reset() {
    this.drivername.reset();
    this.customername.reset();
    this.driverphone.reset();
    this.customerphone.reset();
    this.filter.reset();
    this.promocode.reset();
    this.tripid.reset();
    this.orderNumber.reset();
    this.merchantReference.reset();
    this.dateFrom = '';
    this.dateTo = '';
  }
}
class TripHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
    this.status = status;
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
