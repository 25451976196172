import { Component, OnInit } from '@angular/core';
import { LoadTransactionList } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'moment';
import { DriverLoadTransactionListListener } from 'src/app/api/merchant.api';
import { ApiService } from 'src/app/services/api.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
@Component({
  selector: 'app-driver-load-transaction',
  templateUrl: './driver-load-transaction.component.html',
  styleUrls: ['./driver-load-transaction.component.css']
})
export class DriverLoadTransactionComponent implements OnInit {
  loading: boolean = false;
  customerFormFilter: FormGroup;
  api: DriverLoadTransactionListListener;
  DriverLoadTransactionList: LoadTransactionList;
  date:any;
  selectedFilter = 20;
  filter = new DriverFilter();

  constructor(private apiService: ApiService, private modalService: NgbModal, private fb: FormBuilder, public utils: UtilsService) {
                this.api = new DriverLoadTransactionListListener(apiService, (success, list) => {
                  this.DriverLoadTransactionList = list;
                });
   }

  ngOnInit() {
    this.createDriverFormFilter();
  }
  filterpage(status: number) {
    this.selectedFilter = status;
    this.applyFilter();
  }

  createDriverFormFilter() {
    const date: Date = new Date();
    this.applyFilter();
  }

  applyFilter() {
      this.loading = true;
      const from = moment(this.filter.from.date).format('YYYY-MM-DD')
      const to = moment(this.filter.to.date).format('YYYY-MM-DD')
      this.api.loadDriverLoadTransactionList(this.selectedFilter,from,to);
  }

  covertDateFormat(data: string){
    return moment(data).format('MMM DD, YYYY hh:ss a')
  }

}

class DriverFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    setStatus(status: number) {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
  }


