import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTricyclePickupComponent } from 'src/app/modals/tricycle/create-tricycle-pickup.component';
import { EditTricyclePickupComponent } from 'src/app/modals/tricycle/edit-tricycle-pickup.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import {  AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-tricycle-pickup',
  templateUrl: './tricycle-pickup-cities.component.html'
})
export class TricyclePickpComponent implements OnInit {
  tricyclePickup: any = [];
  loading: boolean = false;
  onboardingRegions = [];
  defaultRegion: any;
  region = new FormControl();
  onboardingCity: SupportedRegions[];
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
  }
  ngOnInit() {
    this.getRegionCityList();
    this.loadTricyclePickupCities();
  }

  createTricyclePickup() {
    const modalRef =  this.modalService.open(CreateTricyclePickupComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.result
    .then((result) => { this.loadTricyclePickupCities();  }, (reason) => { });
  }
  editTricyclePickup(data) {
    const modalRef =  this.modalService.open(EditTricyclePickupComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.loadedData = data;
    modalRef.result
    .then((result) => { this.loadTricyclePickupCities(); }, (reason) => { });
  }
  async loadTricyclePickupCities() {
    this.loading = true;
    this.dbService.getTricyclePickupCities(this.defaultRegion)
    .then(results => {
      this.tricyclePickup = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'DEACTIVATE';
      case false: return 'DISABLED';
      default: return '';
    }
  }
  deactivateTricyclePickup(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Deactivation Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to de-activate this  taxicle pickup city:' + data.pickup_city +
    '? \nOnce de-activated, the taxicle pickup city fee cannot be enabled again.';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deactivateTricyclePickupCities(data.id, this.region.value);
      })
      .then(() => {
        this.loadTricyclePickupCities();
      })
      .catch(e => {
        console.log(e);
      });
  }
  deleteTricyclePickup(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete the taxicle pickup city';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteTricyclePickupCities(data.id, this.region.value);
      })
      .then(() => {
        this.loadTricyclePickupCities();
      })
      .catch(e => {
        console.log(e);
      });
  }
  getRegionCityList() {
    this.dbService.getOnboardingRegionList().then(regions => {
    const data = Object(regions)['regions'];
    this.onboardingCity = data;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }

}


