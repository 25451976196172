import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { ApiService } from 'src/app/services/api.service';
import { DatabaseService } from 'src/app/services/database.service';
import { EMIdetails, AlertModel } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-driver-emi-adjustment-bulk',
  templateUrl: './driver-emi-adjustment-bulk.component.html',
  styleUrls: ['./driver-emi-adjustment-bulk.component.css']
})
export class DriverEmiAdjustmentBulkComponent implements OnInit {
	public arrFinalData = [];
	file_to_upload: File;
  emi_remarks: any;
	importEMIBulkForm: FormGroup;
	data: any;
	excelHeader: any;
	uploadedFileName: any;
	driverEMI: any = [];
	isDisabledImport = false;
	isConfirm = false;
	isConfirmDisable = true;
	EMIdetails:any = [];
	isConfirmExport = false;
	isLoad = false;
	alert: AlertModel;
  defaultRemarks: any;

  constructor(private fb: FormBuilder, 
  	public http: HttpClient, 
  	private apiService: ApiService, private dbService: DatabaseService) { 

  }

  ngOnInit() {
  	this.createImportGCashForm();
    this.loadEMIRemarks();
  }
  private createImportGCashForm() {
    this.importEMIBulkForm = this.fb.group({
      importemi: [null, Validators.required],
      remarks: [null, Validators.required]
    });
  }
  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.file_to_upload = target.files[0];
    this.uploadedFileName = target.files[0].name;
	this.isDisabledImport = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);

  }

  loadEMIRemarks() {
    this.dbService.getEMIRemarks().then(results => {
    this.emi_remarks = results.data().list
    this.defaultRemarks = results.data().list[0]
    }).catch(err => {
    })
 }

  importExcelData() {
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];

    const jsonObjectKeys = ['phone_no', 'deduction'];
    if (this.excelHeader.length === 2) {
        for (let i = 1; i <= this.data.length - 1; i++) {
          const excelData = {};
          for (let j = 0; j < this.excelHeader.length; j++) {
            excelData[jsonObjectKeys[j]] = this.data[i][j];
          }
          this.isConfirm = true;
          this.arrFinalData.push(excelData);
          this.driverEMI = this.arrFinalData;
        }
    } else {
    }
  }

  confirmData() {
  	this.isLoad = true;
    let EMIdetails = []
  	this.apiService.processBulkEmiUpdate(this.file_to_upload, this.defaultRemarks).then(res => {
      if(res['isSuccess'] === true) {
        for(let x = 0; x<res['data'][0].adjusted.length; x++) {
          for (let y = 0; y<res['data'][0].adjusted[x].length; y++) {
            EMIdetails.push(res['data'][0].adjusted[x][y])
            if(x == res['data'][0].adjusted.length-1 && y == res['data'][0].adjusted[x].length-1) {
              this.EMIdetails = EMIdetails;
              this.isConfirmExport = true;
              this.isConfirm = false;
              this.showAlert('success', 'Import successfully processed');
            }
          }
        }
        
      } else {
        this.showAlert('danger', res['error']);
      }
        this.isLoad = false;
    })
    .catch(err => { this.showAlert('danger', 'There is an error on processing import'); });
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  exportToCSV(){
  	this.isConfirmExport = false;
  	const filename = () => 'DRIVER_EMI_BULK_AJUSTMENT' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), ['DATE CREATED','DRIVERID','NEW BALANCE', 'MOBILE NUMBER','PREVIOUS BALANCE']);
    exporter.exportEmiDetails(this.EMIdetails as [EMIdetails]);
  }


}
