import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { GCashImport } from 'src/app/interfaces/interfaces';
import { DriversListAPI, DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import * as fs from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-import-gcashdata',
  templateUrl: './import-gcashdata.component.html',
  styleUrls: ['./import-gcashdata.component.css']
})
export class ImportGcashDataComponent implements OnInit {
  public arrFinalData = [];
  public arrFinalStatusWithData = [];
  importGCashForm: FormGroup;
  loopCount = 0;
  data: any;
  excelHeader: any;
  uploadedFileName: any;
  driverGcash: any = [];
  isDisabledImport = false;
  isConfirm = false;
  isConfirmDisable = true;
  isConfirmExport = false;
  isLoad = false;
  tempDriverUid: any;
  processStatus: any;
  processStatusText: any;
  driverListapi: DriversListAPI;
  personalInfo: DriverProfile[];
  driverProfileUpdateAPI: DriverProfileUpdateAPI;
  gcashNo: string;
  gCashImport: GCashImport;
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService, private fb: FormBuilder) {
  }
  ngOnInit() {
    this.createImportGCashForm();
  }
  private createImportGCashForm() {
    this.importGCashForm = this.fb.group({
      importgcash: [null, Validators.required]
    });
  }
  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.uploadedFileName = target.files[0].name;
    if (target.files.length === 1 && (target.files[0].type === '.xlsx' ||
      target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      target.files[0].type === 'application/vnd.ms-excel')) {
      this.isDisabledImport = true;
      this.uploadedFileName = target.files[0].name;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
    }
  }
  importExcelData() {
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];
    const jsonObjectKeys = ['phone', 'gcash', 'bikerrId'];
    if (this.excelHeader.length === 3) {
      if (this.data.length <= 100) {
        for (let i = 1; i <= this.data.length - 1; i++) {
          const excelData = {};
          for (let j = 0; j < this.excelHeader.length; j++) {
            excelData[jsonObjectKeys[j]] = this.data[i][j];
          }
          this.isConfirm = true;
          this.arrFinalData.push(excelData);
          this.driverGcash = this.arrFinalData;
        }
      } else {
      }
    } else {
    }
  }
  async confirmData() {
    this.isConfirmDisable = false;
    this.isLoad = true;
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      await this.importDriverProfileData(this.arrFinalData[i], i);
    }
    this.isConfirmExport = true;
    this.isLoad = false;
  }
  async importDriverProfileData(arrFinalData: any, iVal) {
    // tslint:disable-next-line: max-line-length
    const driverPhotoURL = 'https://firebasestorage.googleapis.com/v0/b/joyride-prod.appspot.com/o/driverDetails%2F1579236169%2F1579236169-vehicle-photo?alt=media&token=bffb4d35-c6d9-4177-a870-3d9b7d20a144';
    if (this.arrFinalData[iVal].phone !== undefined) {
      await this.dbService.getProfileInfo(this.arrFinalData[iVal].phone.toString(), this.arrFinalData[iVal].phone.toString()
      ).then(forms => {
        this.tempDriverUid = (forms[0] === undefined || forms[0] === null) ? '' : forms[0].uid;
        this.apiService.updateImportDriverProfile(this.tempDriverUid, this.arrFinalData[iVal].phone.toString(),
          this.arrFinalData[iVal].gcash.toString(),
          this.arrFinalData[iVal].bikerrId.toString(), driverPhotoURL, this.getTodayDate()).then(response => {
            this.updateDriverCreatingSuccessStatus(this.arrFinalData[iVal], response);
          })
          .catch(response => {
            this.updateDriverCreatingFailureStatus(this.arrFinalData[iVal], response);
          }
          );
      }).catch(err => {
        console.log(err);
      });
    }
  }
  getTodayDate() {
    const d = new Date();
    return d.toISOString().split('T')[0];
  }
  updateDriverCreatingSuccessStatus(currentRow: any, responseStatus: any) {
    const excelSuccessData = {};
    for (let j = 0; j < this.excelHeader.length; j++) {
      if (j === this.excelHeader.length - 1) {
        const phone = 'phone';
        const gcash = 'gcash';
        const bikerId = 'bikerId';
        const Success = 'isSuccess';
        const processStatus = 'processStatus';
        const data = 'data';
        const code = 'code';
        const processStatusText = 'processStatusText';
        const message = 'message';
        const error = 'error';
        excelSuccessData[phone] = currentRow.phone;
        excelSuccessData[gcash] = currentRow.gcash;
        excelSuccessData[bikerId] = currentRow.bikerrId;
        if (responseStatus[Success] === true) {
          excelSuccessData[processStatus] = responseStatus[data][code];
          excelSuccessData[processStatusText] = responseStatus[data][message];
        } else {
          excelSuccessData[processStatus] = 'Failure';
          excelSuccessData[processStatusText] = responseStatus[error][message];
        }
      }
    }
    this.arrFinalStatusWithData.push(excelSuccessData);
  }
  updateDriverCreatingFailureStatus(currentRow: any, responseStatus: any) {
    const excelFailureData = {};
    for (let j = 0; j < this.excelHeader.length; j++) {
      if (j === this.excelHeader.length - 1) {
        const phone = 'phone';
        const gcash = 'gcash';
        const bikerId = 'bikerId';
        const Success = 'isSuccess';
        const processStatus = 'processStatus';
        const data = 'data';
        const code = 'code';
        const processStatusText = 'processStatusText';
        const message = 'message';
        const error = 'error';
        excelFailureData[phone] = currentRow.phone;
        excelFailureData[gcash] = currentRow.gcash;
        excelFailureData[bikerId] = currentRow.bikerrId;
        if (responseStatus[Success] === true) {
          excelFailureData[processStatus] = responseStatus[data][code];
          excelFailureData[processStatusText] = responseStatus[data][message];
        } else {
          excelFailureData[processStatus] = 'Failure';
          excelFailureData[processStatusText] = (responseStatus[error][message] === null ||
            responseStatus[error][message] === undefined ?
            'Phone number is undefined' : responseStatus[error][message]);
        }
      }
    }
    this.arrFinalStatusWithData.push(excelFailureData);
  }
  downloadResultAsXLSX() {
    this.setExportExcelFileData(this.arrFinalStatusWithData);
  }
  setExportExcelFileData(excelExportData) {
    const excelHeader = ['Phone', 'GCash', 'Biker ID', 'Process Status', 'Issue'];
    const exportExcelHeader = ['phone', 'gcash', 'bikerId', 'processStatus', 'processStatusText'];
    const exportExcelData = [];
    let exportExcelHeaderData = {};
    let i = 0;
    let columnsKeyNames = [];
    for (i = 0; i < excelExportData.length; i++) {
      const singleRow = excelExportData[i];
      columnsKeyNames = Object.keys(singleRow);
      exportExcelHeaderData = {};
      for (let j = 0; j < columnsKeyNames.length; j++) {
        exportExcelHeaderData[excelHeader[j]] = singleRow[exportExcelHeader[j]];
      }
      exportExcelData.push(exportExcelHeaderData);
    }
    this.exportAsExcelFile(exportExcelData, 'GCASH');
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    fs.saveAs(data, fileName + '' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
