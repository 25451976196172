import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { FareSettings, DriverSettings, WalletAdjustment, TopupManual, EMIAdjustment } from '../interfaces/interfaces';
import { ApiService } from '../services/api.service';

export class FareSettingsAPI extends BaseAPI {
    constructor(dbService: DatabaseService) {
        super(dbService);
    }
}
export class UpdateFareSettingsAPI extends BaseAPI {
    private handler: (success: boolean) => void;
    constructor(dbService: DatabaseService, callback: (success: boolean) => void) {
        super(dbService);
        this.handler = callback;
    }
    updateFareSettings(newSettings: FareSettings) {
        this.state = APIState.loading;
        this.dbService.updateFareSettings(newSettings)
            .then(() => {
                this.state = APIState.successWithData;
                this.handler(true);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false);
            });
    }
    updateFareSettingsValues(newSettings: any, tripRegion: string, tripType?: string) {
        this.state = APIState.loading;
        this.dbService.updateFareSettingsValues(newSettings, tripRegion, tripType)
            .then(() => {
                this.state = APIState.successWithData;
                this.handler(true);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false);
            });
    }
}
export class UpdateDriverSettingsAPI extends BaseAPI {
    private handler: (success: boolean) => void;
    constructor(dbService: DatabaseService, callback: (success: boolean) => void) {
        super(dbService);
        this.handler = callback;
    }
    updateDriverSettings(driverId: string, newSettings: DriverSettings) {
        this.state = APIState.loading;
        this.dbService.updateDriverSettings(driverId, newSettings)
            .then(() => {
                this.state = APIState.successWithData;
                this.handler(true);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false);
            });
    }
}

export class DriverWalletAdjustmentAPI extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
     constructor(apiService: ApiService, callback: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = callback;
    }
    adjustWalletAmount(driverId: string, adjustment: WalletAdjustment) {
        this.state = APIState.loading;
        this.apiService.adjustWallet(adjustment)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update wallet amount ' + err.message);
            });
    }
    adjustEMIAmount(driverId: string, adjustment: EMIAdjustment) {
        this.state = APIState.loading;
        this.apiService.emiAdjustment(adjustment)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update wallet amount ' + err.message);
            });
    }

}

export class DriverTopupManualAPI extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
    constructor(apiService: ApiService, callback: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = callback;
    }
    topupManualWalletAmount(driverId: string, topupManual: TopupManual) {
        this.state = APIState.loading;
        this.apiService.topupManualWallet(topupManual)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update wallet amount ' + err.message);
            });
    }
}
export class CustomerWalletAdjustmentAPI extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
     constructor(apiService: ApiService, callback: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = callback;
    }
    adjustWalletAmount(driverId: string, adjustment: WalletAdjustment) {
        this.state = APIState.loading;
        this.apiService.adjustWalletCustomer(adjustment)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update wallet amount ' + err.message);
            });
    }

}
