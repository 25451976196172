import { BaseAPI, APIState } from './base.api';
import { DriverTripStat } from '../interfaces/interfaces';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';

export class DriverStatsAPI extends BaseAPI {
    handler: (success: boolean, stat?: DriverTripStat) => void;
    constructor(dbService: DatabaseService, completion: (success: boolean, stat?: DriverTripStat) => void) {
        super(dbService);
        this.handler = completion;
    }
}
export class DriverAllTimeStatsAPI extends DriverStatsAPI {
    fetchAllTimeStats(driverId: string) {
        this.state = APIState.loading;
        this.dbService.allTimeStatsForDriver(driverId).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class DriverDailyStatsAPI extends DriverStatsAPI {
    fetchStatsOnDate(driverId: string, date: string) {
        this.state = APIState.loading;
        this.dbService.dailyStatsForDriver(driverId, date).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class DriverWeeklyStatsAPI extends DriverStatsAPI {
    fetchStatsOnDate(driverId: string, week: string) {
        this.state = APIState.loading;
        this.dbService.weeklyStatsForDriver(driverId, week).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class DriverMonthlyStatsAPI extends DriverStatsAPI {
    fetchStatsOnDate(driverId: string, month: string) {
        this.state = APIState.loading;
        this.dbService.monthlyStatForDriver(driverId, month).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class TripDailyStatsAPI extends DriverStatsAPI {
    fetchStatsOnDate(date: string, region: string) {
        this.state = APIState.loading;
        this.dbService.allTimeDailyStats(date, region).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class TripSummaryRegion extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([DriverTripStat]) => void;

    constructor(dbService: DatabaseService, completion: ([DriverTripStat]) => void) {
        super(dbService);
        this.handler = completion;
    }
    fetchTripSummaryRegion(date: [], region: string) {
        this.state = APIState.loading;
        this.dbService.tripSummaryReportRegion(date, region).then(forms => {
             // this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverTripStat]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}

