import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { ImageUploadModule } from 'ng2-imageupload';
// Firebase / Firestore
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
// Environment
import { environment } from '../environments/environment';
// Services
import { AuthService } from './services/auth.service';
import { UtilsService } from './services/utils.service';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
// Guards
import { AuthGuard } from './guards/auth.guard';
import { OnboardingExitGuard } from './guards/onboarding-exit.guard';
// Components
import { DashboardComponent } from './pages/home/children/dashboard/dashboard.component';
import { DatabaseService } from './services/database.service';
import { CustomerComponent } from './pages/home/children/customer/customer.component';
import { CustomerLoadTransactionComponent } from './pages/home/children/customer/customer-load-transaction.component';
import { CustomerRateBulkUpdateComponent } from './pages/home/children/customer/customer-rate-bulk-update.component';
import { DriverLoadTransactionComponent } from './pages/home/children/drivers/driver-load-transaction/driver-load-transaction.component';
import { SpinnerComponent } from './controls/spinner/spinner.component';
import { CustomerProfileComponent } from './modals/customer/customer-profile/customer-profile.component';
import { PromoComponent } from './pages/home/children/promo/promo.component';
import { HmHolidaySurchargeComponent } from './pages/home/children/hm-holiday-surcharge/hm-holiday-surcharge.component';
import { CreatePromoCodeComponent } from './modals/promo/create-promo-code/create-promo-code.component';
import { AlertModalComponent } from './controls/alertmodal/alertmodal.component';
import { FareComponent } from './pages/home/children/fare/fare.component';
import { DriverOnboardingComponent } from './pages/onboarding/driver/driver-onboarding.component';
import { DriverOnboardingJrhmComponent } from './pages/onboarding/driver/driver-onboarding-jrhm.component';
import { UploadFilesComponent } from './pages/onboarding/upload-files/upload-files.component';
import { UploadFilesJrhmComponent } from './pages/onboarding/upload-files/upload-files-jrhm.component';
import { UploadFilesTricycleComponent } from './pages/onboarding/upload-files/upload-files-tricycle.component';
import { RegisteredDriversComponent } from './pages/home/children/drivers/registered-drivers/registered-drivers.component';
import { ImportGcashDataComponent } from './pages/home/children/drivers/import-gcashdata/import-gcashdata.component';
import { RegisteredDriversPOCComponent } from './pages/home/children/drivers/registered-drivers/registered-drivers-poc.component';
import { OnboardedDriversComponent } from './pages/home/children/drivers/onboarded-drivers/onboarded-drivers.component';
import { QuicksearchDriversComponent } from './pages/home/children/drivers/quicksearch-drivers/quicksearch-drivers.component';
import { OnboardingComponent } from './pages/home/children/drivers/onboarding/onboarding.component';
import { DriverProfileComponent } from './modals/driver/driver-profile/driver-profile.component';
import { JRTextInputComponent } from './controls/input/jrtext-input/jrtext-input.component';
import { EmptyResultsComponent, APIErrorComponent } from './controls/emptyresults/emptyresults.component';
import { CashoutRequestComponent } from './pages/home/children/wallet/cashout-request/cashout-request.component';
import { TopupRequestComponent } from './pages/home/children/wallet/topup-request/topup-request.component';
import { TripManagementComponent } from './pages/home/children/trip-management/trip-management.component';
import { TripMgmtReportComponent } from './pages/home/children/trip-management/trip-management-report.component';
import { DriverGCashComponent } from './pages/home/children/drivers/driver-gcash/driver-gcash.component';
import { TripQaComponent } from './pages/home/children/trip-management/trip-qa.component';
// Controls
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { TripStatusComponent } from './modals/trip-status/trip-status.component';
import { TripStopsComponent } from './modals/trip-stops/trip-stops.component';
import { TripQaDetailsComponent } from './modals/trip-qa-details/trip-qa-details.component';
import { CustomerRatesAddComponent } from './modals/users/customer-rates/customer-rates-add.component';
import { AssignDriverComponent } from './modals/assign-driver/assign-driver.component';
import { WalletRequestFilterComponent } from './controls/wallet-request-filter/wallet-request-filter.component';
import { OnlineDriversComponent } from './pages/home/children/drivers/online-drivers/online-drivers.component';
import { DriverStatsComponent } from './pages/home/children/drivers/driver-stats/driver-stats.component';
import { DriverStatsCardComponent } from './controls/driver-stats-card/driver-stats-card.component';
import { WalletAdjustmentComponent } from './pages/home/children/wallet/wallet-adjustment/wallet-adjustment.component';
import { DriverProfileFilterComponent } from './controls/driver-profile-filter/driver-profile-filter.component';
import { UserManagementComponent } from './pages/home/children/user-management/user-management.component';
import { CreateUserComponent } from './modals/users/create-user/create-user.component';
import { EditRolesComponent } from './modals/users/edit-roles/edit-roles.component';
import { UnauthorizedComponent } from './pages/home/children/unauthorized/unauthorized.component';
import { FileValueAccessor } from './custom/file-control-value-accessor';
import { ExportWalletAdjustmentComponent } from './pages/home/children/wallet/export-wallet-adjustment/export-walletadjustment.component';
import { ExportWalletBalanceComponent } from './pages/home/children/wallet/export-wallet-balance/export-walletbalance.component';
import { SearchCretriyaComponent } from './pages/home/children/drivers/updateold-drivers/search-cretiriya.component';
import { TopupManualComponent } from './pages/home/children/wallet/topup-manual/topup-manual.component';
import { DriverTripComponent } from './pages/home/children/trip-management/trip-management-driver-trips.component';
import { DriverTripAvailabilityComponent } from './pages/home/children/drivers/driver-tripavailability/driver-tripavailability.component';
import { ExportWalletCommissionComponent } from './pages/home/children/wallet/export-wallet-commission/export-wallet-commission.component';
import { DriverWalletHistoryComponent } from './pages/home/children/wallet/driver-wallet-history/driver-wallet-history.component';
import { DriverIndividualActivationComponent } from './pages/home/children/drivers/driver-individual-activation/driver-individual-activation.component';
import { DriverActiveReportComponent } from './pages/home/children/trip-management/driver-active-report.component';
import { DriverInactiveReportComponent } from './pages/home/children/trip-management/driver-inactive-report.component';
import { TripManagementSearchComponent } from './pages/home/children/trip-management/trip-managementsearch.component';
import { TripLocationsDriverComponent } from './pages/home/children/trip-management/driver-trip-locationstrack.component';
import { UpdateOldTripReportComponent } from './pages/home/children/drivers/updateold-drivers/updateold-tripreport.component';
import { WalletBalanceLowComponent } from './pages/home/children/wallet/wallet-balance-low/wallet-balance-low.component';
import { TripSummarySearchComponent } from './pages/home/children/trip-management/trip-summary-search.component';
import { CustomerSearchComponent } from './pages/home/children/customer/customer-search.component';
import { ImportTopupManualComponent } from './pages/home/children/wallet/topup-manual/import-topup.component';
import { DriverEmiSearchComponent } from './pages/home/children/emi/driver-emi-search/emi-search.component';
import { DriverEmiHistorySearchComponent } from './pages/home/children/emi/driver-emi-history/driver-emi-history.component';
import { EmiWalletHistoryComponent } from './pages/home/children/emi/emi-wallet-history/emi-wallet-history.component';
import { DriverEmiManagementComponent } from './pages/home/children/emi/emi-management/emi-management.component';
import { TripHoursManagementComponent } from './pages/home/children/trip-management/trip-hoursmanagement.component';
import { DriverTripLocationCountComponent } from './pages/home/children/trip-management/driver-location-report.component';
import { ExportTopupComponent } from './pages/home/children/wallet/export-topup-request/export-topup.component';
import { ExportCashoutComponent } from './pages/home/children/wallet/export-cashout-request/export-cashout.component';
import { DriverEMIAdjustmentComponent } from './pages/home/children/emi/driver-emi-adjustment/driver-emi-adjustment.component';
import { DriverEmiAdjustmentBulkComponent } from './pages/home/children/emi/driver-emi-adjustment-bulk/driver-emi-adjustment-bulk.component';
import { CustomerSummaryComponent } from './pages/home/children/customer/customer-summary.component';
import { DriverLogComponent } from './pages/home/children/drivers/driver-log/driver-log.component';
import { DeliveryTermsComponent } from './pages/home/children/unauthorized/delivery-terms.component';
import { ServiceUnavailableComponent } from './pages/home/children/unauthorized/service-unavailable.component';
import { DriverDeactivationComponent } from './pages/home/children/drivers/driver-deactivation/driver-deactivation.component';
import { UserManagementPasswordComponent } from './pages/login/user-managementnew.component';
import { DriverAccountComponent } from './pages/home/children/metrobank/metrobank-drivers-account.component';
import { MetroTopupHistoryComponent } from './pages/home/children/metrobank/metrobank-topup-history.component';
import { MetroTopupInquiryComponent } from './pages/home/children/metrobank/metrobank-topupinquiry-history.component';
import { BindAccHistoryComponent } from './pages/home/children/metrobank/bindacc-history.component';
import { BindAccInquiryHistoryComponent } from './pages/home/children/metrobank/bindacc-inquiry-history.component';
import { UnBindAccHistoryComponent } from './pages/home/children/metrobank/unbindacc-history.component';
import { BindAccInquiryComponent } from './pages/home/children/metrobank/bindacc-inquiry.component';
import { MetroBankTopUpInquiryComponent } from './pages/home/children/metrobank/metrobank-topupinquiry.component';
import { DriverWorkingHoursComponent } from './pages/home/children/drivers/driver-workinghours/driver-workinghours.component';
import { PaymayaWalletLinkComponent } from './pages/home/children/paymaya-reports/paymaya-driver-walletlink.component';
import { PaymayaWalletLinkHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-walletlink-history.component';
import { PaymayTopupHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-topup-history.component';
import { PaymayTopupInquiryHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-topup-inquiry.component';
import { TopupInquiryComponent } from './pages/home/children/paymaya-reports/topup-inquiry.component';
import { EditPromoCodeComponent } from './modals/promo/create-promo-code/edit-promo-code.component';
import { AddUserPromoCodeComponent } from 'src/app/modals/promo/create-promo-code/add-user-promo-code.component';
import { EditFranchiseAreaComponent } from 'src/app/modals/promo/create-promo-code/edit-franchise-area.component';
import { PaymayTransactionsFeesComponent } from './pages/home/children/paymaya-reports/paymaya-transactionsfees.component';
import { CreateHolidaySurchargeComponent } from 'src/app/modals/holiday-surcharge/create-holiday-surcharge.component';
import { EditHolidaySurchargeComponent } from 'src/app/modals/holiday-surcharge/edit-holiday-surcharge.component';
import { CreateHmHolidaySurchargeComponent } from 'src/app/modals/hm-holiday-surcharge/create-holiday-surcharge.component';
import { EditHmHolidaySurchargeComponent } from 'src/app/modals/hm-holiday-surcharge/edit-holiday-surcharge.component';
import { CreateAdditionalSurchargeComponent } from 'src/app/modals/additional-surcharge/create-additional-surcharge.component';
import { EditAdditionalSurchargeComponent } from 'src/app/modals/additional-surcharge/edit-additional-surcharge.component';
import { HolidaySurchargeComponent } from './pages/home/children/holiday-surcharge/holiday-surcharge.component';
import { FareSettingsComponent } from './pages/home/children/fare/faresettings.component';
import { VehicleAddonSettingsComponent } from './pages/home/children/vehicleaddon/vehicleaddon.component';
import { AdditionalSurchargeComponent } from './pages/home/children/additional_surcharge/additional_surcharge.component';
import { AfterhoursFeeComponent } from './pages/home/children/afterhours-surcharge/afterhours-surcharge.component';
import { TripOnlineReportComponent } from './pages/home/children/trip-management/driver-trip-location.component';
import { DriverOnlineReportComponent } from './pages/home/children/drivers/driver-online-log/driver-online-log.component';
import { TricyclePickpComponent } from './pages/home/children/tricycle/tricycle-pickup-cities.component';
import { CreateTricyclePickupComponent } from './modals/tricycle/create-tricycle-pickup.component';
import { TricycleDropoffComponent } from './pages/home/children/tricycle/tricycle-dropoff-cities.component';
import { CreateTricycleDropoffComponent } from './modals/tricycle/create-tricycle-dropoff.component';
import { EditTricyclePickupComponent } from './modals/tricycle/edit-tricycle-pickup.component';
import { EditTricycleDropoffComponent } from './modals/tricycle/edit-tricycle-dropoff.component';
import { ImportPermissionDataComponent } from './pages/home/children/drivers/updateold-drivers/import-permission-services.component';
import { DriverOnboardingTricycleComponent } from './pages/onboarding/driver/driver-onboarding-tricycle.component';
import { CustomerAppServiceComponent } from './pages/home/children/customerapp-service/customerapp-service.component';
import { EditCustomerAppServicesComponent } from './modals/customerapp-services/edit-customerapp-services.component';
import { PaymayaTopupWebhookComponent } from './pages/home/children/paymaya-reports/paymaya-topup-webhook-reports.component';
import { CustomerWalletAdjustmentComponent } from './pages/home/children/customer-wallet/export-adjustments/customer-adjustments.component';
import { CustomerWalletHistoryComponent } from './pages/home/children/customer-wallet/wallet-history/wallet-history.component';
import { CustomerWalletAdjustmentsComponent } from './pages/home/children/customer-wallet/wallet-adjustments/wallet-adjustments.component';
import { CustomerWalletBalanceComponent} from './pages/home/children/customer-wallet/export-balance/customer-export-balance.component';
import { CustomerWalletLowBalanceComponent} from './pages/home/children/customer-wallet/wallet-balance-low/customer-low-balance.component';
import { CustomerTopupHistoryComponent} from './pages/home/children/customer-wallet/customer-topup-history/customer-topup-history.component';
import { UserClaimsComponent } from './pages/home/children/user-management/user-claims.component';
import { UserClaimsRolesComponent } from './pages/home/children/user-management/user-claims-roles.component';
import { CreateUserClaimsComponent } from './modals/user-claims/create-user-claim.component';
import { EditUserClaimsComponent } from './modals/user-claims/edit-user-claim.component';
import { UserRolesComponent } from './pages/home/children/user-management/user-roles.component';
import { UserRolesManageComponent } from './pages/home/children/user-management/user-roles-manage.component';
import { CreateUserRolesComponent } from './modals/user-claims/create-user-roles.component';
import { EditUserRolesComponent } from './modals/user-claims/edit-user-roles.component';
import { PaymayaNumberUpdationComponent } from './pages/home/children/paymayanumber-updation/paymayanumber-updation.component';
import { EditUserComponent } from './modals/users/edit-user/edit-user.component';
import { SetPasswordComponent } from './modals/customer/customer-profile/set-password.component';
import { NewsArticlesComponent } from './pages/home/children/news/news-articles.component';
import { CreateNewsArticleComponent } from './modals/news/news-articles-create.component';
import { EditNewsArticleComponent } from './modals/news/news-articles-edit.component';
import { CarouselBannerComponent } from './pages/home/children/carousel-banner/carousel-banner.component';
import { CreateCarouselBannerComponent } from './modals/carousel-banner/create-carousel-banner.component';
import { EditCarouselBannerComponent } from './modals/carousel-banner/edit-carousel-banner.component';
import { DriverUpdateServicesComponent } from './pages/home/children/drivers/driver-update-services/driver-update-services.component';
import { DriverUpdateBagsComponent } from './pages/home/children/drivers/driver-update-bags/driver-update-bags.component';
import { OtpProviderComponent } from './pages/home/children/otp-provider/otp-provider.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    CustomerComponent,
    CustomerLoadTransactionComponent,
    CustomerRateBulkUpdateComponent,
    DriverLoadTransactionComponent,
    SpinnerComponent,
    CustomerProfileComponent,
    PromoComponent,
    HmHolidaySurchargeComponent,
    CreatePromoCodeComponent,
    AlertModalComponent,
    FareComponent,
    DriverOnboardingComponent,
    DriverOnboardingJrhmComponent,
    UploadFilesComponent,
    UploadFilesJrhmComponent,
    UploadFilesTricycleComponent,
    RegisteredDriversComponent,
    RegisteredDriversPOCComponent,
    OnboardedDriversComponent,
    DriverProfileComponent,
    QuicksearchDriversComponent,
    OnboardingComponent,
    JRTextInputComponent,
    EmptyResultsComponent,
    APIErrorComponent,
    CashoutRequestComponent,
    TopupRequestComponent,
    TripManagementComponent,
    TripQaComponent,
    TripMgmtReportComponent,
    TripStatusComponent,
    TripStopsComponent,
    TripQaDetailsComponent,
    CustomerRatesAddComponent,
    AssignDriverComponent,
    WalletRequestFilterComponent,
    OnlineDriversComponent,
    DriverStatsComponent,
    DriverStatsCardComponent,
    WalletAdjustmentComponent,
    ExportWalletAdjustmentComponent,
    ExportWalletBalanceComponent,
    ExportCashoutComponent,
    DriverTripLocationCountComponent,
    ExportTopupComponent,
    SearchCretriyaComponent,
    DriverProfileFilterComponent,
    UserManagementComponent,
    CreateUserComponent,
    EditRolesComponent,
    UnauthorizedComponent,
    FileValueAccessor,
    DriverGCashComponent,
    ImportGcashDataComponent,
    TopupManualComponent,
    DriverTripComponent,
    DriverTripAvailabilityComponent,
    ExportWalletCommissionComponent,
    DriverWalletHistoryComponent,
    DriverIndividualActivationComponent,
    DriverActiveReportComponent,
    DriverInactiveReportComponent,
    TripManagementSearchComponent,
    TripLocationsDriverComponent,
    UpdateOldTripReportComponent,
    WalletBalanceLowComponent,
    TripSummarySearchComponent,
    CustomerSearchComponent,
    ImportTopupManualComponent,
    DriverEmiSearchComponent,
    DriverEmiHistorySearchComponent,
    EmiWalletHistoryComponent,
    DriverEmiManagementComponent,
    TripHoursManagementComponent,
    DriverEMIAdjustmentComponent,
    DriverEmiAdjustmentBulkComponent,
    CustomerSummaryComponent,
    DriverLogComponent,
    DeliveryTermsComponent,
    ServiceUnavailableComponent,
    DriverDeactivationComponent,
    UserManagementPasswordComponent,
    DriverAccountComponent,
    MetroTopupHistoryComponent,
    MetroTopupInquiryComponent,
    BindAccHistoryComponent,
    BindAccInquiryHistoryComponent,
    UnBindAccHistoryComponent,
    BindAccInquiryComponent,
    MetroBankTopUpInquiryComponent,
    DriverWorkingHoursComponent,
    PaymayaWalletLinkComponent,
    PaymayaWalletLinkHistoryComponent,
    PaymayTopupHistoryComponent,
    PaymayTopupInquiryHistoryComponent,
    TopupInquiryComponent,
    EditPromoCodeComponent,
    AddUserPromoCodeComponent,
    EditFranchiseAreaComponent,
    PaymayTransactionsFeesComponent,
    CreateHolidaySurchargeComponent,
    EditHolidaySurchargeComponent,
    CreateHmHolidaySurchargeComponent,
    EditHmHolidaySurchargeComponent,
    CreateAdditionalSurchargeComponent,
    EditAdditionalSurchargeComponent,
    HolidaySurchargeComponent,
    FareSettingsComponent,
    VehicleAddonSettingsComponent,
    AdditionalSurchargeComponent,
    AfterhoursFeeComponent,
    TripOnlineReportComponent,
    DriverOnlineReportComponent,
    TricyclePickpComponent,
    CreateTricyclePickupComponent,
    TricycleDropoffComponent,
    CreateTricycleDropoffComponent,
    EditTricyclePickupComponent ,
    EditTricycleDropoffComponent ,
    ImportPermissionDataComponent,
    DriverOnboardingTricycleComponent,
    CustomerAppServiceComponent,
    EditCustomerAppServicesComponent,
    PaymayaTopupWebhookComponent,
    CustomerWalletAdjustmentComponent,
    CustomerWalletHistoryComponent,
    CustomerWalletAdjustmentsComponent,
    CustomerWalletBalanceComponent,
    CustomerWalletLowBalanceComponent,
    CustomerTopupHistoryComponent,
    UserClaimsComponent,
    CreateUserClaimsComponent,
    EditUserClaimsComponent,
    UserClaimsRolesComponent,
    UserRolesComponent,
    UserRolesManageComponent,
    CreateUserRolesComponent,
    EditUserRolesComponent,
    PaymayaNumberUpdationComponent,
    EditUserComponent,
    SetPasswordComponent,
    NewsArticlesComponent,
    CreateNewsArticleComponent,
    EditNewsArticleComponent,
    CarouselBannerComponent,
    CreateCarouselBannerComponent,
    EditCarouselBannerComponent,
    DriverUpdateServicesComponent,
    OtpProviderComponent,
    DriverUpdateBagsComponent
   ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ImageUploadModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDyfjb8xd81UkprJxQ27loaN6kYYg0hiO4' }),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularDateTimePickerModule
  ],
  providers: [
    AuthService,
    UserService,
    DatabaseService,
    AuthGuard,
    OnboardingExitGuard,
    ApiService,
    UtilsService,
    DatePipe
    ],
  entryComponents: [
    CustomerProfileComponent,
    DriverProfileComponent,
    CreatePromoCodeComponent,
    AlertModalComponent,
    UploadFilesComponent,
    UploadFilesJrhmComponent,
    UploadFilesTricycleComponent,
    TripStatusComponent,
    TripStopsComponent,
    TripQaDetailsComponent,
    CustomerRatesAddComponent,
    AssignDriverComponent,
    CreateUserComponent,
    EditRolesComponent,
    EditPromoCodeComponent,
    AddUserPromoCodeComponent,
    EditFranchiseAreaComponent,
    CreateHolidaySurchargeComponent,
    EditHolidaySurchargeComponent,
    CreateHmHolidaySurchargeComponent,
    EditHmHolidaySurchargeComponent,
    CreateAdditionalSurchargeComponent,
    EditAdditionalSurchargeComponent,
    CreateTricyclePickupComponent,
    CreateTricycleDropoffComponent,
    EditTricyclePickupComponent ,
    EditTricycleDropoffComponent,
    DriverOnboardingTricycleComponent,
    EditCustomerAppServicesComponent,
    CreateUserClaimsComponent,
    EditUserClaimsComponent,
    CreateUserRolesComponent,
    EditUserRolesComponent,
    EditUserComponent,
    SetPasswordComponent,
    CreateNewsArticleComponent,
    EditNewsArticleComponent,
    CarouselBannerComponent,
    CreateCarouselBannerComponent,
    EditCarouselBannerComponent,
    OtpProviderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
