
export enum PromoDiscountType {
    no_discount_type = undefined,
    percentage = 0,
    fixed = 1
}

export class PromoUtils {

    /**
     * Retuns a human understandable string for the given delivery status
     * @param status;
     */
    static getPromoDiscountType(status: number): string | null {
        switch (status) {
            case PromoDiscountType.fixed: return 'Fixed Value';
            case PromoDiscountType.percentage: return 'Percentage';
            case PromoDiscountType.no_discount_type: return 'Percentage';
            default: return '';
        }
    }
}
