import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, ValidationErrors  } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  // Promo Code Name Validation
  static promoCode(c: AbstractControl) {
    const control = c as FormControl
    if (control.value) {
      const matches = control.value.match(/^[a-zA-Z0-9_.-]*$/);
      return matches ? null : { 'invalidName': true };
    } else {
      return null;
    }
  }
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if((control.value as string).indexOf(' ') >= 0) {
       return {cannotContainSpace: true}
    }

    return null;
}
}
