import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import {
    DriverProfile, DriverLocation, OnboardingPersonalDetails, EMIWallet,
    UpdatePersonalDetailsRequestPayload, UpdateVehicleDetailsRequestPayload, vehiclePermission,
    UpdateGCashDetailsRequestPayload, UpdateDriverPhotosRequestPayload, DriverLogSearch,
    UpdateVehiclePhotosRequestPayload, DriverLocationProfile, UpdateDriverStatusPayload, DriverWrokingHours
} from '../interfaces/interfaces';
import { Trip } from '../interfaces/trip-interfaces';
import { AssignDriver } from 'src/app/interfaces/interfaces';
import { Subscription, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

export class DriversListAPI extends BaseAPI {
    private handler: ([DriverProfile]) => void;
    constructor(dbService: DatabaseService, completion: ([DriverProfile]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadDriverByPhone(mobile: string) {
        this.state = APIState.loading;
        this.dbService.getDriversbyPhone(mobile).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverProfiles(status: number, region: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverRegistrations(status, region).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverProfilesstatus(status: number) {
        this.state = APIState.loading;
        this.dbService.getAllDriverRegistrationsStatus(status).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverProfilesVechicle(status: number, vechicletype: string, region: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverRegistrationsVechicle(status, vechicletype, region).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverProfilesbyQuickSearch(firstname?: string, lastname?: string, phone?: string, bikerid?: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverRegistrationsbyQuickSearch(firstname, lastname, phone, bikerid).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;

            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverTripAvailability(phone?: string, bikerid?: string) {
        this.state = APIState.loading;
        this.dbService.getDriverTripAvailability(phone, bikerid).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverWalletBalance(region: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverRegistrationsBalance(region).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverWalletBalanceLow(region: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverWalletBalanceLow(region).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadDriverProfile(phone: string, gcash: string) {
        this.dbService.getProfileInfo(phone, gcash).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    loadActivatedDriverProfiles(mainstatus: number, substatus: number) {
        this.state = APIState.loading;
        this.dbService.getAllActivatedDriverRegistrations(mainstatus, substatus).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    searchDriverEMI(activeDate: string, driverId?: string) {
        this.state = APIState.loading;
        this.dbService.searchDriverEMI(activeDate, driverId).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [EMIWallet]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    searchDriverEMIRegion(activeDate: string, driverId?: string, region?: string) {
        this.state = APIState.loading;
        this.dbService.searchDriverEMIRegion(activeDate, driverId, region).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [EMIWallet]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
    driverLogSearch(startDate: Date, endDate: Date) {
        this.state = APIState.loading;
        this.dbService.allDriverLogProfile(startDate, endDate).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverLogSearch]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}
export class DriverTripHistoryAPI extends BaseAPI {
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadDriversTrips(driverId: string) {
        this.state = APIState.loading;
        this.dbService.allTripsForDriverRef(driverId).get().toPromise()
            .then(snapshot => snapshot.docs.map(c => c.data()))
            .then(data => {
                this.state = (data.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(data as [Trip]);
            })
            .catch(err => {
                console.log(err);
                this.state = APIState.failed;
            });
        this.dbService.allTripsForDriverRef(driverId)
            .valueChanges()
            .subscribe(docChanges => {
            });
    }
}
export class DriverLocationListener {
    private handler: (locations: DriverLocation[]) => void;
    private dbService: DatabaseService;
    private observable: Observable<DriverLocation[]>;
    private subscription: Subscription;
    constructor(dbService: DatabaseService, completion: (locations: [DriverLocation]) => void) {
        this.dbService = dbService;
        this.handler = completion;
    }
    beginListening() {
        this.observable = this.dbService.allDriversLocation().valueChanges();
        this.subscription = this.observable.subscribe(docChanges => {
            this.handler(docChanges as DriverLocation[]);
        }, e => {
            console.error(e);
        });
    }
    endListening() {
        this.subscription.unsubscribe();
    }
}
export class DriverProfileAPI extends BaseAPI {
    private handler: (success: boolean, profile?: DriverProfile) => void;
    private subscription: Subscription;
    private listenForChanges = false;
    constructor(dbService: DatabaseService, listenForChanges: boolean = false,
                completion: (success: boolean, profile?: DriverProfile) => void) {
        super(dbService);
        this.handler = completion;
        this.listenForChanges = listenForChanges;
    }
    fetchDriverProfile(driverId: string) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.dbService.getDriver(driverId).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data() as DriverProfile);
                    this.beginListening(driverId);
                } else {
                    this.fetchDriverProfileByWallet(driverId);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
    fetchDriverProfileByWallet(driverId: string) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.dbService.getDriverByWalletAdj(driverId).get().toPromise()
            .then(snapshot => {
                if (snapshot.empty) {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
                snapshot.forEach(doc => {
                    driverId = doc.data().uid;
                    this.state = APIState.successWithData;
                    this.handler(true, doc.data() as DriverProfile);
                    this.beginListening(driverId);
                });
            })
            .catch(err => {
                console.log(err);
                this.state = APIState.failed;
            });
    }
    beginListening(driverId: string) {
        if (!this.listenForChanges) { return; }
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getDriver(driverId).valueChanges().subscribe(value => {
            this.handler(true, value as DriverProfile);
        }, e => { console.error(e); });
    }
    endListening() {
        if (this.subscription) { this.subscription.unsubscribe(); }
    }
}
export class DriverProfileUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;
    dbServicenew: DatabaseService;
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    assignDriver(payload: AssignDriver) {
        if (!payload) { return; }
        this.state = APIState.loading;
        this.apiService.assignDriver(payload)
            .then(response => {
                const success = 'isSuccess';
                const isSuccess = response[success];
                const error = 'error';
                const message = 'message';
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'Trip has been successfully assigned to driver');

                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                if (e.error.error) {
                    this.state = APIState.failed;
                    this.handler(false, e.error.error.message);

                } else {
                    this.state = APIState.failed;
                    this.handler(false, 'There was an error while re-assigning driver to this trip. Please try again');
                }
            });

    }
    updateDriverProfile(driverId: string, profile: UpdatePersonalDetailsRequestPayload) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateDriverPersonalData(driverId, profile)
            .then(response => {
                const success = 'isSuccess';
                const isSuccess = response[success];
                const error = 'error';
                const message = 'message';
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s profile was updated successfully.');

                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
            });

    }
    updateDriverProfileGcshData(driverId: string, profile: OnboardingPersonalDetails) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateDriverPersonalGcash(driverId, profile)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s profile was updated successfully.');

                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
            });

    }
    updateEMI(selectedDate: string) {
        this.state = APIState.loading;
        this.apiService.updateEMI(selectedDate)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The drivers\'s emi was updated successfully.');

                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving emi data of the driver. Please try again');
            });
    }
}
export class DriverVehicleUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;

    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateVehicleProfile(driverId: string, vehicle: UpdateVehicleDetailsRequestPayload, vehiclePermission: vehiclePermission) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateDriverVehiclePermission(vehiclePermission)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.apiService.updateDriverVehicleData(driverId, vehicle)
                        .then(response => {
                            const success = 'isSuccess';
                            const error = 'error';
                            const message = 'message';
                            const isSuccess = response[success];
                            if (isSuccess) {
                                this.state = APIState.successWithData;
                                this.handler(true, 'The biker\'s vehicle details were updated successfully');
                            } else {
                                this.state = APIState.failed;
                                this.handler(false, response[error][message]);
                            }
                        })
                        .catch(e => {
                            console.error(e);
                            this.state = APIState.failed;
                            this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
                        });

                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
            });
    }

}
export class DriverGCashUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateGCashDetails(driverId: string, phone: string, gcash: UpdateGCashDetailsRequestPayload) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateDriverGCashData(driverId, phone, gcash)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s gcash details were updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving gcash data of the driver. Please try again');
            });
    }
}
export class GetDriverAndTripAPI extends BaseAPI {
    handler: (success: boolean, message: string, driver?: DriverProfile, trip?: Trip) => void;
    constructor(dbService: DatabaseService, completion: (success: boolean, message: string, driver?: DriverProfile, trip?: Trip) => void) {
        super(dbService, null);
        this.handler = completion;
    }
    async loadDriverAndTripDetails(driverId: string, tripId: string) {
        if (!driverId) { return; }
        try {
            let driverProfile = null;
            let trip = null;
            this.state = APIState.loading;
            driverProfile = (await this.dbService.getDriver(driverId).get().toPromise()).data();
            if (tripId) {
                trip = (await this.dbService.tripRef(tripId).get().toPromise()).data();
            }
            this.state = APIState.successWithData;
            this.handler(true, '', driverProfile, trip);
        } catch (e) {
            this.state = APIState.failed;
            this.handler(false, null, null, e.message);
        }
    }
}
export class DriversWalletListAPI extends BaseAPI {
    private handler: ([DriverProfile]) => void;
    constructor(dbService: DatabaseService, completion: ([DriverProfile]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadDriverWalletDetails(uid?: string, firstname?: string, phone?: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverWalletAdj(uid, firstname, phone).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}
export class DriverPhotoUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateDriverPhoto(driverId: string, driverphoto: UpdateDriverPhotosRequestPayload) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateDriverPhotoData(driverId, driverphoto)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s vehicle details were updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
            });
    }
}
export class DriverVehiclePhotoUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateDriverVehiclePhoto(driverId: string, vehiclephoto: UpdateVehiclePhotosRequestPayload) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.apiService.updateVehiclePhotoData(driverId, vehiclephoto)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s vehicle details were updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving profile data of the driver. Please try again');
            });
    }
}
export class DriversWalletHistoryListAPI extends BaseAPI {
    private handler: ([DriverProfile]) => void;

    constructor(dbService: DatabaseService, completion: ([DriverProfile]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadDriverWalletHistory(from?: string, to?: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverWalletAdj(from, to).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverProfile]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }
}
export class DriverLocationAPI extends BaseAPI {
    private handler: (success: boolean, profile?: DriverLocationProfile) => void;
    private subscription: Subscription;
    private listenForChanges = false;

    constructor(dbService: DatabaseService, listenForChanges: boolean = false,
                completion: (success: boolean, profile?: DriverLocationProfile) => void) {
        super(dbService);
        this.handler = completion;
        this.listenForChanges = listenForChanges;
    }
    GetDriverLocation(driverId: string) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.dbService.getDriverLocation(driverId).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data() as DriverLocationProfile);
                    this.beginListening(driverId);
                } else {
                    this.fetchDriverLocation(driverId);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
    fetchDriverLocation(driverId: string) {
        if (!driverId) { return; }
        this.state = APIState.loading;
        this.dbService.fetchDriverLocation(driverId).get().toPromise()
            .then(snapshot => {
                if (snapshot.empty) {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
                snapshot.forEach(doc => {
                    driverId = doc.data().uid;
                    this.state = APIState.successWithData;
                    this.handler(true, doc.data() as DriverLocationProfile);
                    this.beginListening(driverId);
                });
            })
            .catch(err => {
                console.log(err);
                this.state = APIState.failed;
            });
    }
    beginListening(driverId: string) {
        if (!this.listenForChanges) { return; }
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getDriver(driverId).valueChanges().subscribe(value => {
            this.handler(true, value as DriverLocationProfile);
        }, e => { console.error(e); });
    }

    endListening() {
        if (this.subscription) { this.subscription.unsubscribe(); }
    }
}
export class DriverLocationStatusUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;
    callback: (isSuccess: boolean, message: string) => void;

    constructor(apiService: ApiService, callback: (isSuccess: boolean, message: string) => void) {
        super(null, apiService);
        this.callback = callback;
    }
    updateDriverLocationStatus(driverId: string, payload: UpdateDriverStatusPayload) {
        this.state = APIState.loading;
        this.apiService.updateDriverLocationStatus(driverId, payload)
            .then(response => {
                const success = 'isSuccess';
                const data = 'data';
                const message = 'message';
                const error = 'error';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(isSuccess, (isSuccess) ? response[data][message] : response[error][message]);

                } else {
                    this.state = APIState.failed;
                    this.callback(isSuccess, (isSuccess) ? response[data][message] : response[error][message]);

                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the location requests. Please try again. ' + err);
            });

    }
}
export class DriversWorkingListAPI extends BaseAPI {
    private handler: ([DriverWrokingHours]) => void;
    constructor(dbService: DatabaseService, completion: ([DriverWrokingHours]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadDriverWorkingHours(phone: string, driverId: string, tripdate: string) {
        this.state = APIState.loading;
        this.dbService.loadDriverWorkingHours(phone, driverId, tripdate).then(forms => {
            this.state = (forms.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(forms as [DriverWrokingHours]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
    }

}
