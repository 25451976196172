import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { HolidaySurcharge, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-hm-holiday-surcharge',
  templateUrl: './edit-holiday-surcharge.component.html'
})
export class EditHmHolidaySurchargeComponent implements OnInit {
  holidaySurchargeForm: FormGroup;
  holidaySurchargeModel: HolidaySurcharge;
  alert: AlertModel;
  saving: boolean;
  loadedData: any = [];
  holidayname: any;
  holidaydate: any;
  surchargeamount: any;
  description: any;
  isactive: any;
  updatedat: any;
  docID: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.holidayname = this.loadedData.holiday_name;
    this.holidaydate = this.loadedData.holiday_date;
    this.surchargeamount = this.loadedData.surcharge_amount;
    this.description = this.loadedData.description;
    this.isactive = this.loadedData.is_active;
    this.updatedat = this.loadedData.updated_at;
    this.docID = this.loadedData.id;
    this.createForm();
  }
  private createForm() {
    this.holidaySurchargeForm = this.fb.group({
      holiday_name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      surcharge_amount: [0],
      holiday_date: [null],
      description: [''],
    });
  }
  c(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveHolidayFee(): boolean {
    return this.holidaySurchargeForm.valid && this.holidaySurchargeForm.dirty;
  }

  editHolidaySurcharge() {
    this.holidaySurchargeModel = {
      id: this.docID,
      holiday_name: this.holidaySurchargeForm.controls.holiday_name.value,
      surcharge_amount: this.holidaySurchargeForm.controls.surcharge_amount.value,
      holiday_date: this.holidaySurchargeForm.controls.holiday_date.value,
      description: this.holidaySurchargeForm.controls.description.value,
      is_active: this.isactive,
      updated_at: this.updatedat
    };
    this.holidaySurchargeForm.disable();
    this.saving = true;
    this.dbService.editNewHmHolidaySurcharge(this.holidaySurchargeModel, this.docID)
      .then(() => {
        this.showAlert('success', 'Holiday updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while update the holiday - ' + e.message);
        this.holidaySurchargeForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
