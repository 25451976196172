import { Pipe, PipeTransform,Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';
import {  DriverProfile, AlertModel, UpdatePersonalDetailsRequestPayload, OnboardingForm,
  UpdateVehicleDetailsRequestPayload, vehiclePermission, GCashCity, SupportedRegions
} from 'src/app/interfaces/interfaces';
import { DownloadTask } from 'src/app/classes/downloadtask';
import { AngularFireStorage } from '@angular/fire/storage';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { UtilsService } from 'src/app/services/utils.service';
import { UploadTask } from 'src/app/classes/uploadtask';
import { UpdateDriverSettingsAPI } from 'src/app/api/fareSettings.api';
import { ApiService } from 'src/app/services/api.service';
import {  DriverTripHistoryAPI, DriverProfileUpdateAPI, DriverVehicleUpdateAPI,
  DriverGCashUpdateAPI, DriverPhotoUpdateAPI, DriverVehiclePhotoUpdateAPI
} from 'src/app/api/driverList.api';
import { Trip, TripStatus } from 'src/app/interfaces/trip-interfaces';
import * as moment from 'moment';
import { OnboardingAdminApprovalAPI } from 'src/app/api/onboarding.api';
import { PermissionService } from 'src/app/services/permission.service';
import uniqBy from 'lodash/uniqBy';
import * as _ from 'lodash'
@Pipe({
    name: 'orderBy'
})
@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.css'],
})
export class DriverProfileComponent implements OnInit {
  bagSettings: any;
  isOnboardingProfile = false;
  driverIDsaved: boolean = false;
  @Input() customer: OnboardingForm;
  driverProfile: DriverProfile;
  trips: Trip[];
  customquery: any;
  personalInfoForm: FormGroup;
  driverIdentificationForm: FormGroup;
  vehicleDetailsForm: FormGroup;
  vehiclePermissionForm: FormGroup;
  otherInfoForm: FormGroup;
  adminForm: FormGroup;
  settingsForm: FormGroup;
  gcashDetailForm: FormGroup;
  driverPhotoForm: FormGroup;
  vehiclePhotoForm: FormGroup;
  statusUpdateForm: FormGroup;
  verificationForm: FormGroup;
  servicesettingsForm: FormGroup;
  deliverybagsettingsForm: FormGroup;
  gearReleasingForm: FormGroup;
  motorCyclecCheckForm: FormGroup;
  skillsAssessmentForm: FormGroup;
  orientationForm: FormGroup;
  traningForm: FormGroup;
  isDriverSupportDocAvailable = true;
  saving: boolean;
  savingDriverPhoto = false;
  savingVehiclePhoto = false;
  isSubStatusVisiable = true;
  files: Map<string, DownloadTask>;
  uploadReferences: Map<FormControl, UploadTask>;
  alert: AlertModel;
  areYouToOwnerYes: string;
  areYouToOwnerNo: string;
  status: { value: number, name: string }[];
  subStatus: { [key: number]: { value: number, name: string }[] };
  myFiles = [];
  // APIS
  driverSettingsAPI: UpdateDriverSettingsAPI;
  driverTripsAPI: DriverTripHistoryAPI;
  adminApprovalAPI: OnboardingAdminApprovalAPI;
  driverProfileUpdateAPI: DriverProfileUpdateAPI;
  driverVehicleUpdateAPI: DriverVehicleUpdateAPI;
  driverGCashUpdateAPI: DriverGCashUpdateAPI;
  driverPhotoUpdateAPI: DriverPhotoUpdateAPI;
  driverVehiclePhotoUpdateAPI: DriverVehiclePhotoUpdateAPI;
  buttonDisabled = true;
  ddlGCashCities = [];
  ddlGCashTown = [];
  ddlGCashRegions = [];
  ddlGCashPCities = [];
  ddlGCashPTown = [];
  ddlGCashPRegions = [];
  perRegion: any;
  perCity: any;
  perTown: any;
  perzip: any;
  perStreet: any;
  gfirstDisable: any = true;
  curRegion: any;
  curCity: any;
  curTown: any;
  curzip: any;
  curStreet: any;
  mainStatus: number;
  Substatusvalue: any;
  GCashCities: GCashCity[];
  GCashPCities: GCashCity[];
  GCashCurrentZipCode: any;
  gcashnoExist = false;
  imeiExist = false;
  gearReleasetabShow: any;
  selectedFilter: number;
  isHelmatQty: any;
  isVestQty: any;
  isBeltbagQty: any;
  isJerseyQty: any;
  isFaceMaskQty: any;
  isHairCapQty: any;
  isSprayQty: any;
  isTouQty: any;
  statusChangedisable: any;
  subStatusShow: any;
  bikerApp: any;
  onboardingRegions = [];
  onboardingCityList = [];
  onboardingCity: SupportedRegions[];
  ifregionChange: boolean;
  isDriverPhotoURL: boolean;
  isVehiclePhotoURL: boolean;
  isLicense: boolean;
  vehicleType: any;
  vehicle_type: string;
  mpv: boolean;
  constructor(public activeModal: NgbActiveModal, private userService: UserService, private apiService: ApiService,
              private fb: FormBuilder, private storage: AngularFireStorage, private dbService: DatabaseService,
              public permissions: PermissionService, public utils: UtilsService) {

    this.files = new Map();
    this.uploadReferences = new Map();
    this.trips = [];
    this.subStatusShow = false;
    this.vehicleType = OnboardingUtils.getDriverVechicleType();
    this.driverSettingsAPI = new UpdateDriverSettingsAPI(dbService, (success) => {
      const alertType = success ? 'success' : 'danger';
      const message = success ? 'Driver settings were updated successfully' : 'Failed to update driver settings. Please try again.';
      this.showAlert(alertType, message);
      this.settingsForm.enable();
      if (success) { this.settingsForm.markAsPristine(); }
    });
    this.driverTripsAPI = new DriverTripHistoryAPI(dbService, (trips) => {
      this.trips = trips;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) { this.adminForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.adminForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) { this.statusUpdateForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.statusUpdateForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) { this.skillsAssessmentForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.skillsAssessmentForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) { this.orientationForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.orientationForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {this.verificationForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.verificationForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {this.gearReleasingForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.gearReleasingForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {this.servicesettingsForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.servicesettingsForm.enable();
      this.saving = false;
    });
    this.adminApprovalAPI = new OnboardingAdminApprovalAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {this.deliverybagsettingsForm.markAsPristine(); }
      this.showAlert(alertType, message);
      this.deliverybagsettingsForm.enable();
      this.saving = false;
    });
    this.driverProfileUpdateAPI = new DriverProfileUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.personalInfoForm.markAsPristine();
        this.personalInfoForm.disable();
      } else {
        this.personalInfoForm.enable();
      }
      this.showAlert(alertType, message);
    });
    this.driverVehicleUpdateAPI = new DriverVehicleUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.vehicleDetailsForm.markAsPristine();
        this.vehicleDetailsForm.disable();
      } else {
        this.vehicleDetailsForm.enable();
      }
      this.showAlert(alertType, message);
    });
    this.driverGCashUpdateAPI = new DriverGCashUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.gcashnoExist = false;
        this.gcashDetailForm.markAsPristine();
        this.adminForm.markAsPristine();
      } else {
     }
      this.showAlert(alertType, message);
    });
    this.driverPhotoUpdateAPI = new DriverPhotoUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.driverPhotoForm.disable();
        this.driverPhotoForm.markAsPristine();
      } else {
      }
      this.showAlert(alertType, message);
      this.savingDriverPhoto = false;
    });
    this.driverVehiclePhotoUpdateAPI = new DriverVehiclePhotoUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.vehiclePhotoForm.disable();
        this.vehiclePhotoForm.markAsPristine();
      } else {
      }
      this.showAlert(alertType, message);
      this.savingVehiclePhoto = false;
    });
  }
  ngOnInit() {
    this.loadBagSettings()
    if (this.driverProfile.form.driverPhoto !== undefined  &&
      this.driverProfile.form.driverPhoto !== null) {
    if (this.driverProfile.form.driverPhoto !== undefined  &&
    this.driverProfile.form.driverPhoto !== null) {
      this.isDriverPhotoURL = true;
    } else {
      this.isDriverPhotoURL = false;
    }
  }
    if (this.driverProfile.form.vehiclePhoto !== undefined  &&
    this.driverProfile.form.vehiclePhoto !== null) {
    if (this.driverProfile.form.vehiclePhoto !== undefined  &&
    this.driverProfile.form.vehiclePhoto !== null) {
      this.isVehiclePhotoURL = true;
    } else {
      this.isVehiclePhotoURL = false;
    }
    }
    if(this.driverProfile.identification.drivingLicense !==undefined && this.driverProfile.identification.drivingLicense !== null
      && Object.keys(this.driverProfile.identification.drivingLicense).length !== 0) {
    if (this.driverProfile.identification.drivingLicense.startsWith('https')) {
        this.isLicense = true;
      } else {
        this.isLicense = false;
      }
    }
    this.createPersonalInfoForm();
    this.createDriverIdentificationForm();
    this.createVehicleDetailsForm();
    this.createOtherInfoForm();
    this.createAdminForm();
    this.createSettingsForm();
    this.createGCashForm();
    this.createdriverPhotoForm();
    this.createvehiclePhotoForm();
    this.listenForProfileChanges();
    this.fetchDriversTrips();
    this.createVerificatioForm();
    this.createGearReleasingForm();
    this.createMotorcycleCheckform();
    this.createSkillsAssessmentForm();
    this.createOrientationForm();
    this.createTraningForm();
    this.setBikerAppvalue();
    this.createservicesettingsForm();
    this.createdeliverybagservicesettingsForm();
    
  }
 
  getVehicleName(status: string) {
    return OnboardingUtils.getVehiclyName(status);
  }

  getDistanceText(distance: number) {
    return (distance/1000).toFixed(1)
  }

  private createPersonalInfoForm() {
    this.getRegions();
    
    this.personalInfoForm = this.fb.group({
      driverId: [this.driverProfile.uid],
      firstname: [this.driverProfile.personal.firstname, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      middlename: [this.driverProfile.personal.middlename],
      lastname: [this.driverProfile.personal.lastname, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      address: [this.driverProfile.personal.address],
      barangay: [this.driverProfile.personal.barangay],
      region: [this.driverProfile.personal.region === undefined ? 'Metro Manila' :
        this.driverProfile.personal.region, Validators.required],
      town: [this.driverProfile.personal.town],
      dob: [this.driverProfile.personal.dob],
      gender: [this.driverProfile.personal.gender, Validators.required],
      birthplace: [this.driverProfile.personal.birthplace],
      email: [this.driverProfile.personal.email, [Validators.required, Validators.email]],
      phone: [this.driverProfile.personal.phone, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      gCashPhone: [this.driverProfile.personal.phone],
      alternatePhone: [this.driverProfile.personal.alternatePhone],
      phoneBrand: [this.driverProfile.personal.phoneBrand],
      imei: [this.driverProfile.personal.imei],
      civilStatus: [this.driverProfile.personal.civilStatus],
      tin: [this.driverProfile.personal.tin],
      sss: [this.driverProfile.personal.sss],
      mFirstname: [this.driverProfile.personal.mFirstname],
      mMiddlename: [this.driverProfile.personal.mMiddlename],
      mLastname: [this.driverProfile.personal.mLastname],
      // tslint:disable-next-line: max-line-length
      emergencyContactName: [this.driverProfile.personal.emergencyContactName],
      // tslint:disable-next-line: max-line-length
      emergencyContactPhone: [this.driverProfile.personal.emergencyContactPhone],
      ltfrbControlNo: [this.driverProfile.personal.ltfrbControlNo === undefined ? '' : this.driverProfile.personal.ltfrbControlNo],
      referencenumber: [this.driverProfile.personal.referencenumber === undefined ? '' : this.driverProfile.personal.referencenumber],
      operatorName: [this.driverProfile.personal.operatorName === undefined ? '' : this.driverProfile.personal.operatorName],
      operatorPhoneNumber: [this.driverProfile.personal.operatorPhoneNumber === undefined ? '' : this.driverProfile.personal.operatorPhoneNumber],
      paymayaNumber: [this.driverProfile.personal.paymayaNumber === undefined ? '' : this.driverProfile.personal.paymayaNumber]

    });
    this.getCitiesbyRegionEdit(this.driverProfile.personal.region);
    this.personalInfoForm.disable();
  }
  private createDriverIdentificationForm() {
    this.driverIdentificationForm = this.fb.group({
      nbiClearance: [this.driverProfile.identification.nbiClearance, Validators.required],
      policeClearance: [this.driverProfile.identification.policeClearance, Validators.required],
      drivingLicense: [this.driverProfile.identification.drivingLicense, [Validators.required]],
      officialReceipt: [this.driverProfile.identification.officialReceipt, Validators.required],
      registrationReceipt: [this.driverProfile.identification.registrationReceipt, Validators.required],
      areYouTheOwner: [this.driverProfile.identification.areYouTheOwner, Validators.required],
      authorizationLetter: this.fb.array([
        this.initCustomQuery()
      ]),
      govtIssuedID1: [this.driverProfile.identification.govtIssuedID1, Validators.required],
      govtIssuedID2: [this.driverProfile.identification.govtIssuedID2, Validators.required],
      imeiPhoto: [this.driverProfile.personal.imeiPhoto, Validators.required]
    });
    if (this.driverProfile.identification.areYouTheOwner === 'Yes') {
      this.areYouToOwnerYes = 'Yes';
    } else {
      this.areYouToOwnerNo = 'No';
    }
  }
  initCustomQuery() {
    return this.fb.group({
      fileName: ['']
    });
  }
  private createVehicleDetailsForm() {
    this.vehicleDetailsForm = this.fb.group({
      driverId: [this.driverProfile.uid],
      vehicleBrand: [this.driverProfile.vehicle.vehicleBrand, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      vehicleModel: [this.driverProfile.vehicle.vehicleModel, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      modelYear: [this.driverProfile.vehicle.modelYear, [Validators.required]],
      pistonDisplacement: [this.driverProfile.vehicle.pistonDisplacement, [Validators.required, Validators.maxLength(10)]],
      vehiclePlateNumber: [this.driverProfile.vehicle.vehiclePlateNumber, Validators.required],
      vehicleEngineNumber: [this.driverProfile.vehicle.vehicleEngineNumber, Validators.required],
      vehicleChasisNumber: [this.driverProfile.vehicle.vehicleChasisNumber, Validators.required],
      vehicleORExpiryDate: [this.driverProfile.vehicle.vehicleORExpiryDate, Validators.required],
      driverLicenseType: [this.driverProfile.vehicle.driverLicenseType, Validators.required],
      // tslint:disable-next-line: max-line-length
      driverLicenseNumber: [this.driverProfile.vehicle.driverLicenseNumber, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      driverLicenseExpiryDate: [this.driverProfile.vehicle.driverLicenseExpiryDate, Validators.required],
      vehicle_type: [this.driverProfile.vehicle.vehicle_type],
      mpv: [this.driverProfile.vehicle.mpv && this.driverProfile.vehiclePermission.mpv]
    });
    // Disabled by default
    this.vehicleDetailsForm.disable();


    //HM vehicle permission
    this.vehiclePermissionForm = this.fb.group({
      driverId: [this.driverProfile.uid],
      uid: [this.driverProfile.uid],
      motorcycle: [this.driverProfile.personal.vehicle_type === 'motorcycle' ? true : false],
      mv_small: [this.driverProfile.personal.vehicle_type === 'mv_small' ? true : false],
      mv_medium: [this.driverProfile.personal.vehicle_type === 'mv_medium' ? true : false],
      mv_large: [this.driverProfile.personal.vehicle_type === 'mv_large' ? true : false],
      mpv: [this.driverProfile.vehicle.mpv && this.driverProfile.vehiclePermission.mpv === true ? true : false],
      remarks: ['']
    })

    this.vehicle_type = this.driverProfile.personal.vehicle_type;
    this.mpv = this.driverProfile.vehicle.mpv && this.driverProfile.vehiclePermission.mpv === true ? true : false;

  }

  changeVehicle(){
    this.vehiclePermissionForm.value.motorcycle = this.vehicle_type === 'motorcycle' ? true : false;
    this.vehiclePermissionForm.value.mv_small = this.vehicle_type === 'mv_small' ? true : false;
    this.vehiclePermissionForm.value.mv_medium = this.vehicle_type === 'mv_medium' ? true : false;
    this.vehiclePermissionForm.value.mv_large = this.vehicle_type === 'mv_large' ? true : false;
    this.vehiclePermissionForm.value.mpv = this.vehicle_type !== 'mv_small' ? false : this.mpv;

    if(this.vehicle_type !== 'mv_small') { return this.mpv = false; }
  }

  changeMPV() {
    if(this.vehicle_type !== 'mv_small')   {
      return this.mpv = false;
    }
  }

  private createOtherInfoForm() {
    this.otherInfoForm = this.fb.group({
      currentJob: [this.driverProfile.others.currentJob, Validators.required],
      currentJobOthers: [this.driverProfile.others.currentJobOthers],
      riderGroup: [this.driverProfile.others.riderGroup, Validators.required],
      riderGroupOthers: [this.driverProfile.others.riderGroupOthers],
      referredBy: [this.driverProfile.others.referredBy, Validators.required],
      referredByOthers: [this.driverProfile.others.referredByOthers],
      gCashNumberReferrer: [this.driverProfile.others.gCashNumberReferrer],
      availability: [this.driverProfile.others.availability, Validators.required],
      whenAttendingTraining: [this.driverProfile.others.whenAttendingTraining, Validators.required],
      whenAttendingTrainingComment: [this.driverProfile.others.whenAttendingTrainingComment],
      infoSource: [this.driverProfile.others.infoSource],
      infoSourceOthers: [this.driverProfile.others.infoSourceOthers]
    });
  }
  private createdriverPhotoForm() {
     this.driverPhotoForm = this.fb.group({
      driverPhoto: [null, Validators.required]
    });
  }
  private createvehiclePhotoForm() {
    this.vehiclePhotoForm = this.fb.group({
      vehiclePhoto: [null, Validators.required],
      vehiclePhoto1: [null],
      vehiclePhoto2: [null]
    });
  }
  private createAdminForm() {
    this.adminForm = this.fb.group({
      activationDate: [this.driverProfile.form.activationDate, Validators.required],
      remarks: [this.driverProfile.form.remarks, Validators.maxLength(255)],
      gCashPhone: [this.driverProfile.form.gCashPhone],
      mainStatus: [this.driverProfile.form.mainStatus, Validators.required],
      subStatus: [this.driverProfile.form.subStatus],
      bikerapp: [this.driverProfile.form.bikerapp === undefined ? '' : this.driverProfile.form.bikerapp],
    });
    this.mainStatus = this.driverProfile.form.mainStatus;
    this.Substatusvalue = this.driverProfile.form.subStatus;
    if (this.mainStatus === 6 || this.mainStatus === 7 || this.mainStatus === 5 || this.mainStatus === 8) {
      this.isSubStatusVisiable = false;
    } else {
      this.isSubStatusVisiable = true;
    }
  }
  private createTraningForm() {
    this.traningForm = this.fb.group({
      trainingDate: [this.driverProfile.form.trainingDate, Validators.required],
      trainingremarks: [this.driverProfile.form.trainingremarks === undefined ? '' :
        this.driverProfile.form.trainingremarks, Validators.maxLength(255)],
      trainingmainStatus: [this.driverProfile.form.trainingmainStatus === undefined ? '' :
        this.driverProfile.form.trainingmainStatus, Validators.required],
      trainingsubStatus: [this.driverProfile.form.trainingsubStatus === undefined ? '' :
        this.driverProfile.form.trainingsubStatus, Validators.required]
    });
    this.mainStatus = this.driverProfile.form.mainStatus;
    this.Substatusvalue = this.driverProfile.form.subStatus;
  }
  private createSettingsForm() {
    this.settingsForm = this.fb.group({
      commission: [this.driverProfile.settings.commission, [Validators.required, Validators.min(0), Validators.max(100)]],
      minWalletBalance: [this.driverProfile.settings.minWalletBalance, [Validators.required, Validators.min(0)]]
    });
  }
  private createGCashForm() {
    this.gcashDetailForm = this.fb.group({
      // tslint:disable-next-line: max-line-length
      gcashnumber: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.gcashnumber)],
      firstname: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.firstname : this.driverProfile.gCash.firstname)],
      middlename: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.middlename : this.driverProfile.gCash.middlename)],
      lastname: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.lastname : this.driverProfile.gCash.lastname)],
      suffix: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.suffix)],
      // tslint:disable-next-line: max-line-length
      contactnumber: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.phone : this.driverProfile.gCash.contactnumber)],
      email: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.email : this.driverProfile.gCash.email)],
      gender: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.gender : this.driverProfile.gCash.gender)],
      nationalty: ['Filipino'],
      // tslint:disable-next-line: max-line-length
      maritalstatus: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.civilStatus : this.driverProfile.gCash.maritalstatus)],
      dob: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.dob : this.driverProfile.gCash.dob)],
      birthplace: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.birthplace : this.driverProfile.gCash.birthplace)],
      occupation: [(this.driverProfile.gCash === undefined ? this.driverProfile.others.currentJob : this.driverProfile.gCash.occupation)],
      idtype: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.idtype)],
      idnumber: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.idnumber)],
      idremarks: [(this.driverProfile.gCash === undefined ? 'N/A' : this.driverProfile.gCash.idremarks)],
      curaddresscountry: ['Philippines'],
      curaddressregion: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.curaddressregion)],
      curaddresscity: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.curaddresscity)],
      curaddresstown: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.curaddresstown)],
      curaddresszipcode: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.curaddresszipcode)],
      // tslint:disable-next-line: max-line-length
      curaddressstreet: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.address : this.driverProfile.gCash.curaddressstreet)],
      peraddresscountry: ['Philippines'],
      peraddressregion: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddressregion)],
      peraddresscity: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresscity)],
      peraddresstown: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresstown)],
      peraddresszipcode: [(this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresszipcode)],
      // tslint:disable-next-line: max-line-length
      peraddressstreet: [(this.driverProfile.gCash === undefined ? this.driverProfile.personal.address : this.driverProfile.gCash.peraddressstreet)],
      classification: ['Agent'],
     });
    if (this.driverProfile.gCash === undefined || this.GCashCities === undefined) {
      this.getGcashRegionList();
      this.perStreet = this.driverProfile.personal.address;
      if (this.driverProfile.personal.tin !== '' && this.driverProfile.personal.tin !== null
        && this.driverProfile.personal.tin !== undefined) {
        this.gcashDetailForm.controls.idtype.setValue('TIN');
        this.gcashDetailForm.controls.idnumber.setValue(this.driverProfile.personal.tin);
      } else if (this.driverProfile.personal.sss !== '' && this.driverProfile.personal.sss !== null
        && this.driverProfile.personal.sss !== undefined) {
        this.gcashDetailForm.controls.idtype.setValue('SSS');
        this.gcashDetailForm.controls.idnumber.setValue(this.driverProfile.personal.sss);
      } else {
        this.gcashDetailForm.controls.idtype.setValue('Wala pa');
      }
    } else {
      this.getGcashRegionList();
      this.gcashDetailForm.controls.curaddressregion.setValue(this.driverProfile.gCash.curaddressregion);
      this.curRegion = this.driverProfile.gCash.curaddressregion;
      this.getGcashCitiesbyRegion(this.driverProfile.gCash.curaddressregion);
      this.gcashDetailForm.controls.curaddresscity.setValue(this.driverProfile.gCash.curaddresscity);
      this.curCity = this.driverProfile.gCash.curaddresscity;
      this.getGcashTownbyCity(this.driverProfile.gCash.curaddresscity);
      this.gcashDetailForm.controls.curaddresstown.setValue(this.driverProfile.gCash.curaddresstown);
      this.curTown = this.driverProfile.gCash.curaddresstown;
      this.gcashDetailForm.controls.peraddressregion.setValue(this.driverProfile.gCash.peraddressregion);
      this.perRegion = this.driverProfile.gCash.peraddressregion;
      this.getGcashCitiesbyPRegion(this.driverProfile.gCash.peraddressregion);
      this.gcashDetailForm.controls.peraddresscity.setValue(this.driverProfile.gCash.peraddresscity);
      this.perCity = this.driverProfile.gCash.peraddresscity;
      this.getGcashTownbyPCity(this.driverProfile.gCash.peraddresscity);
      this.gcashDetailForm.controls.peraddresstown.setValue(this.driverProfile.gCash.peraddresstown);
      this.perTown = this.driverProfile.gCash.peraddresstown;
      this.perStreet = (this.driverProfile.gCash === undefined ? this.driverProfile.personal.address :
        this.driverProfile.gCash.peraddressstreet);
    }
    this.perRegion = this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddressregion;
    this.perzip = this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresszipcode;
    this.perCity = this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresscity;
    this.perTown = this.driverProfile.gCash === undefined ? '' : this.driverProfile.gCash.peraddresstown;
  }
  private createVerificatioForm() {
    this.verificationForm = this.fb.group({
      verifymc: [(this.driverProfile.gearRelease === undefined ? '' : this.driverProfile.gearRelease.verifymc)],
      verifyskills: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.verifyskills)],
      orientation: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.orientation)],
      gcash: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.gcash)],
      topup: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.topup)],
      idphoto: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.idphoto)],
      vehiclePhoto: [(this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.vehiclePhoto)],
      remarks: [this.driverProfile.gearRelease === undefined ? null : this.driverProfile.gearRelease.remarks, Validators.maxLength(255)]
    });
  }
  private createGearReleasingForm() {
     this.gearReleasingForm = this.fb.group({
      ishelmets: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.ishelmets)],
      helmetsqty: [(this.driverProfile.gearActivation === undefined ? null :
                this.driverProfile.gearActivation.helmetsqty)],
      isvest: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isvest)],
      vestqty: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.vestqty)],
      isbeltbag: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isbeltbag)],
      beltbagqty: [(this.driverProfile.gearActivation === undefined ? null :
                this.driverProfile.gearActivation.beltbagqty)],
      isjersey: [(this.driverProfile.gearActivation === undefined ? null :
                this.driverProfile.gearActivation.isjersey)],
      jerseyqty: [(this.driverProfile.gearActivation === undefined ? null :
                  this.driverProfile.gearActivation.jerseyqty)],
      isfacemask: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isfacemask)],
      facemaskqty: [(this.driverProfile.gearActivation === undefined ? null :
        this.driverProfile.gearActivation.facemaskqty)],
      isharecap: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isharecap)],
      haicapqtyty: [(this.driverProfile.gearActivation === undefined ? null :
                      this.driverProfile.gearActivation.haicapqtyty)],
      istou: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.istou)],
      touqty: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.touqty)],
      isspray: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isspray)],
      sprayqty: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.sprayqty)],
      bikerId: [(this.driverProfile.gearActivation === undefined ? null :
        this.driverProfile.form.bikerId), [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      isStatusChange: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.isStatusChange)],
      remarks: [(this.driverProfile.gearActivation === undefined ? null : this.driverProfile.gearActivation.remarks)]
    });
     const statusChange = 'isStatusChange';
     this.gearReleasingForm.controls[statusChange].disable();
     if (this.driverProfile.gearActivation !== undefined) {
      if (this.driverProfile.gearActivation.ishelmets === true) {
        this.isHelmatQty = true;
      }
      if (this.driverProfile.gearActivation.isvest === true) {
        this.isVestQty = true;
      }
      if (this.driverProfile.gearActivation.isbeltbag === true) {
        this.isBeltbagQty = true;
      }
      if (this.driverProfile.gearActivation.isjersey === true) {
        this.isJerseyQty = true;
      }
      if (this.driverProfile.gearActivation.isharecap === true) {
        this.isHairCapQty = true;
      }
      if (this.driverProfile.gearActivation.istou === true) {
        this.isTouQty = true;
      }
      if (this.driverProfile.gearActivation.isspray === true) {
        this.isSprayQty = true;
      }
      if (this.driverProfile.gearActivation.isfacemask === true) {
        this.isFaceMaskQty = true;
      }
    }
  }
  gearReleasingControlChange(event) {
    if (event.target.id === 'ishelmets') {
      if (event.target.checked) {
        this.isHelmatQty = true;
      } else {
        this.gearReleasingForm.controls.helmetsqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isHelmatQty = false;
      }
    } else if (event.target.id === 'isvest') {
      if (event.target.checked) {
        this.isVestQty = true;
      } else {
        this.gearReleasingForm.controls.vestqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isVestQty = false;
      }
    } else if (event.target.id === 'isbeltbag') {
      if (event.target.checked) {
        this.isBeltbagQty = true;
      } else {
        this.gearReleasingForm.controls.beltbagqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isBeltbagQty = false;
      }
    } else if (event.target.id === 'isjersey') {
      if (event.target.checked) {
        this.isJerseyQty = true;
      } else {
        this.gearReleasingForm.controls.jerseyqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isJerseyQty = false;
      }
    } else if (event.target.id === 'isfacemask') {
      if (event.target.checked) {
        this.isFaceMaskQty = true;
      } else {
        this.gearReleasingForm.controls.facemaskqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isFaceMaskQty = false;
      }
    } else if (event.target.id === 'isharecap') {
      if (event.target.checked) {
        this.isHairCapQty = true;
      } else {
        this.gearReleasingForm.controls.haicapqtyty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isHairCapQty = false;
      }
    } else if (event.target.id === 'istou') {
      if (event.target.checked) {
        this.isTouQty = true;
      } else {
        this.gearReleasingForm.controls.touqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isTouQty = false;
      }
    } else if (event.target.id === 'isspray') {
      if (event.target.checked) {
        this.isSprayQty = true;
      } else {
        this.gearReleasingForm.controls.sprayqty.setValue(null);
        this.gearReleasingForm.controls.isStatusChange.setValue(null);
        this.isSprayQty = false;
      }
    }
  }
  private createMotorcycleCheckform() {
    this.motorCyclecCheckForm = this.fb.group({
      mcname: [(this.driverProfile.motorcyclecheck === undefined ? '' : this.driverProfile.motorcyclecheck.mcname), Validators.required],
      mcdate: [(this.driverProfile.motorcyclecheck === undefined ? '' : this.driverProfile.motorcyclecheck.mcdate), Validators.required],
      mcstatus: [(this.driverProfile.motorcyclecheck === undefined ? '' :
                      this.driverProfile.motorcyclecheck.mcstatus), Validators.required],
      remarks: [(this.driverProfile.motorcyclecheck === undefined ? '' : this.driverProfile.motorcyclecheck.remarks)]
    });
  }
  private createSkillsAssessmentForm() {
    this.skillsAssessmentForm = this.fb.group({
      skillname: [(this.driverProfile.skillsassessment === undefined ? '' :
                      this.driverProfile.skillsassessment.skillname), Validators.required],
      skilldate: [(this.driverProfile.skillsassessment === undefined ? '' :
        this.driverProfile.skillsassessment.skilldate), Validators.required],
      skillstatus: [(this.driverProfile.skillsassessment === undefined ? '' :
        this.driverProfile.skillsassessment.skillstatus), Validators.required],
      remarks: [(this.driverProfile.skillsassessment === undefined ? '' : this.driverProfile.skillsassessment.remarks)]
    });
  }
  private createOrientationForm() {
    this.orientationForm = this.fb.group({
      date: [(this.driverProfile.orientation === undefined ? '' : this.driverProfile.orientation.date), Validators.required],
      orientationstatus: [(this.driverProfile.orientation === undefined ? '' :
        this.driverProfile.orientation.orientationstatus), Validators.required],
      remarks: [(this.driverProfile.orientation === undefined ? '' : this.driverProfile.orientation.remarks)]
    });
  }
  private createservicesettingsForm() {

    this.servicesettingsForm = this.fb.group({
      mctaxi: [(this.driverProfile.permission_services === undefined ? '' : this.driverProfile.permission_services.mctaxi)],
      delivery: [(this.driverProfile.permission_services === undefined ? null : this.driverProfile.permission_services.delivery)],
      pabili: [(this.driverProfile.permission_services === undefined ? null : this.driverProfile.permission_services.pabili)],
      tricycle: [(this.driverProfile.permission_services === undefined ? null : this.driverProfile.permission_services.tricycle)],
      hmdelivery: [(this.driverProfile.permission_services === undefined ? null : this.driverProfile.permission_services.hmdelivery)],
      remarks: [this.driverProfile.permission_services === undefined ? null :
        this.driverProfile.permission_services.remarks, Validators.maxLength(255)]
    });
  }
  private createdeliverybagservicesettingsForm() {
    this.deliverybagsettingsForm = this.fb.group({
      hm_bag: [(this.driverProfile.bags === undefined ? false : this.driverProfile.bags.hm_bag)],
      jr_bag: [(this.driverProfile.bags === undefined ? false : this.driverProfile.bags.jr_bag)]
    });
  }

  addAuthDocument() {
    this.buttonDisabled = false;
    this.addCustomQuery();
  }
  addCustomQuery() {
    const control = <FormArray>this.driverIdentificationForm.controls.authorizationLetter;
    control.push(this.initCustomQuery());
  }
  private listenForProfileChanges() {
    this.dbService.driverProfileSubscription(this.driverProfile.uid).subscribe((profile) => {
      this.driverProfile = profile;
      this.customquery = '';
      this.customquery = this.driverProfile.identification;
      let i: number;
      const control = <FormArray>this.driverIdentificationForm.controls.authorizationLetter;
      control.clear();
      if (this.customquery.authorizationLetter === null || this.customquery.authorizationLetter === undefined
        || this.customquery.authorizationLetter[0] === undefined || this.customquery.authorizationLetter[0].fileName === ''
        || this.customquery.authorizationLetter[0].fileName === null
      ) {
        this.buttonDisabled = false;
        this.isDriverSupportDocAvailable = false;
      } else
        if (this.customquery.authorizationLetter.length > 30) {
          // tslint:disable-next-line: max-line-length
          this.myFiles.push({ fileName: (this.customquery.authorizationLetter ? this.customquery.authorizationLetter.toString().replace(/authorizationLetter/gi, 'authorizationLetter0') : '') });
          this.customquery.authorizationLetter = this.myFiles;
          control.setValue(this.myFiles);
        } else {
          const replaceauthletter = this.customquery.authorizationLetter[0].fileName;
          this.customquery.authorizationLetter[0].fileName = replaceauthletter.toString().replace(/-0/gi, '0');
          // tslint:disable-next-line: triple-equals
          if (control.length == 0) {
            for (i = 0; i <= this.customquery.authorizationLetter.length - 1; i++) {
              this.addCustomQuery();
            }
          } else {
            for (i = 1; i <= this.customquery.authorizationLetter.length - 1; i++) {
              this.addCustomQuery();
            }
          }
        }
      this.personalInfoForm.reset(profile.personal);
      this.vehicleDetailsForm.reset(profile.vehicle);
      if (this.driverProfile.gCash !== undefined) {
        this.gcashDetailForm.reset(profile.gCash);
      }
      this.skillsAssessmentForm.reset(profile.skillsassessment);
      this.motorCyclecCheckForm.reset(profile.motorcyclecheck);
      this.orientationForm.reset(profile.orientation);
      this.verificationForm.reset(profile.gearRelease);
      this.gearReleasingForm.reset(profile.gearActivation);
      this.adminForm.reset(profile.form);
      this.traningForm.reset(profile.form);
      this.getCitiesbyRegionEdit(this.driverProfile.personal.region);
    }, (error) => {
      console.error(error);
      this.showAlert('danger', 'Failed to listen for profile changes');
    });
  }
  private fetchDriversTrips() {
    this.driverTripsAPI.loadDriversTrips(this.driverProfile.uid);
  }
  getDownloadTask(path: string) {
    let task = this.files.get(path);
    if (!task) {
      task = new DownloadTask(path, this.storage);
      this.files.set(path, task);
    }
    return task;
  }
  getURLSupport(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return null; }
    const task = this.getDownloadTask(path);
    return task.urlObservable;
  }
  getURL(path: string) {
    if (path === null || path === undefined || path.length === 0) { return null; }
   
    const task = this.getDownloadTask(path);
    return task.urlObservable;
   
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.untouched || c.disabled || c.valid;
  }
  fileNameFormArray(control: AbstractControl, i) {
    const c = control as FormControl;
    return c.value.fileName.name ? c.value.fileName.name : 'Choose File';
  }
  fileName(control: AbstractControl) {
    const c = control as FormControl;
    return c.value ? c.value : 'Choose File';
  }
  optional(val?: string, defaultVal?: string) {
    const defaultValue = (defaultVal) ? defaultVal : 'Not Specified';
    return (val !== null || val !== undefined) ? val : defaultValue;
  }
  otherInfo(val?: string, other?: string) {
    const value = this.optional(val);
    if (other != null || other !== undefined) {
      return value + ' (' + this.optional(other) + ')';
    }
    return value;
  }
  defaultExpiryDate() {
    const d = new Date();
    const newDate = new Date(d.setMonth(d.getMonth() + 1)); // 1 month to expire
    return newDate.toISOString().split('T')[0];
  }
  today() {
    const d = new Date();
    return d.toISOString().split('T')[0];
  }
  todayDate() {
    const d = new Date();
    return d;
  }
  maxDob() {
    const d = new Date();
    const newDate = new Date(d.setFullYear(d.getFullYear() - 18)); // 1 month to expire
    return newDate.toISOString().split('T')[0];
  }
  upload_SupportDoc(event, c: AbstractControl, index) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const control1 = <FormArray>this.driverIdentificationForm.controls.authorizationLetter;
    const name = 'authorizationLetter' + index;
    const path = OnboardingUtils.getUploadRef(this.driverProfile.form.id, file, name);
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
      uploadTask.beginUpload();
      if (this.customquery.authorizationLetter === null || this.customquery.authorizationLetter === undefined
        || this.customquery.authorizationLetter[0] === undefined || this.customquery.authorizationLetter[0].fileName === ''
        || this.customquery.authorizationLetter[0].fileName === null) {
        this.myFiles.push({ fileName: path });
        this.customquery.authorizationLetter.push({ fileName: path });
      } else {
        this.customquery.authorizationLetter.push({ fileName: path });
      }
    }
  }
  upload(event, c: AbstractControl) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const name = OnboardingUtils.getFileName(control);
    const controlName = OnboardingUtils.getName(control);
    const path = OnboardingUtils.getUploadRef(this.driverProfile.form.id, file, name);
    this.driverProfile.identification[controlName] = path;
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
      uploadTask.beginUpload();
      this.dbService.updateDriverIdentification(this.driverProfile.uid,this.driverProfile.identification)
    }
  }
  uploadProgressAuth(c: AbstractControl, index) {
    const control1 = <FormArray>this.driverIdentificationForm.controls.authorizationLetter;
    const item = control1.at(index);
    const control = item as FormControl;
    const uploadTask = this.uploadReferences.get(control);
    if (uploadTask) { return uploadTask.uploadProgress; }
    return null;
  }
  uploadProgress(c: AbstractControl) {
    const control = c as FormControl;
    const uploadTask = this.uploadReferences.get(control);
    if (uploadTask) { return uploadTask.uploadProgress; }
    return null;
  }
  saveIdentificationDetails() {
    const control1 = <FormArray>this.driverIdentificationForm.controls.authorizationLetter;
    if (this.customquery.authorizationLetter === null || this.customquery.authorizationLetter === undefined
      || this.customquery.authorizationLetter[0] === undefined || this.customquery.authorizationLetter[0].fileName === ''
      || this.customquery.authorizationLetter[0].fileName === null) {
      control1.setValue(this.myFiles);
    } else {
      control1.setValue(this.customquery.authorizationLetter);
    }
    if (this.driverIdentificationForm.controls.areYouTheOwner.value === 'Yes') {
      this.driverIdentificationForm.controls.areYouTheOwner.setValue('Yes');
    } else {
      this.driverIdentificationForm.controls.areYouTheOwner.setValue('No');
    }
    const result = this.dbService.updateDriverIdentificationAuthLetter(this.driverIdentificationForm.value, this.driverProfile.uid);
    if (result) {
      alert('Successfully Uploaded Supporting Documents');
      control1.clear();
      this.buttonDisabled = true;
    }
  }
  // EDIT VEHICLE INFO
  canSaveVehicleInfo(): boolean {
    return this.vehicleDetailsForm.valid && this.vehicleDetailsForm.dirty;
  }
  editVehicleDetails(canEdit: boolean) {
    const f = this.vehicleDetailsForm;
    if (canEdit) { f.enable(); } else {
      f.disable();
      f.reset(this.driverProfile.vehicle);
    }
  }

  saveVehicleDetails() {
    this.vehiclePermissionForm.value.mpv  = this.driverProfile.vehicle.mpv !== undefined  ? this.mpv : false;
    this.vehicleDetailsForm.disable();
    this.driverVehicleUpdateAPI.updateVehicleProfile(this.driverProfile.uid,
    this.vehicleDetailsForm.value as UpdateVehicleDetailsRequestPayload, this.vehiclePermissionForm.value as vehiclePermission);
  }

  // EDIT PERSONAL INFO
  canSavePersonalInfo(): boolean {
    return this.personalInfoForm.valid && this.personalInfoForm.dirty && !this.imeiExist;
  }
  canSaveTraning(): boolean {
    return this.traningForm.valid && this.traningForm.dirty;
  }
  editPersonalDetails(canEdit: boolean) {
    const f = this.personalInfoForm;
    if (canEdit) { f.enable(); } else {
      f.disable();
      this.imeiExist = false;
      f.reset(this.driverProfile.personal);
    }
  }
  savePersonalDetails() {
    this.personalInfoForm.disable();
    this.personalInfoForm.controls.town.setValue(this.personalInfoForm.controls.town.value);
    this.driverProfileUpdateAPI.updateDriverProfile(this.driverProfile.uid,
      this.personalInfoForm.value as UpdatePersonalDetailsRequestPayload);
  }
  cansaveDriverPhoto(): boolean {
    const hasImages = this.driverProfile.form.driverPhoto;
    return (hasImages) ? true : (this.driverPhotoForm.valid && this.driverPhotoForm.dirty);
  }
  cansaveVehiclePhoto(): boolean {
    const hasImages = this.driverProfile.form.vehiclePhoto;
    return (hasImages) ? true : (this.vehiclePhotoForm.valid && this.vehiclePhotoForm.dirty);
  }
  canSave(): boolean {
    return this.adminForm.valid && this.adminForm.dirty;
  }
  canSaveSettings(): boolean {
    return this.settingsForm.valid && this.settingsForm.dirty;
  }

  isDataFilledInAdminForm(): boolean {
    const result = (this.adminForm.controls.mainStatus.valid &&
      this.adminForm.controls.subStatus.valid &&
      this.adminForm.controls.activationDate.valid &&
      this.adminForm.controls.trainingDate.valid &&
      this.adminForm.controls.remarks.valid &&
      this.adminForm.controls.gCashPhone.valid &&
      this.adminForm.controls.bikerId.valid) && this.adminForm.dirty;
    return result;
  }
  onMainStatusChange(event) {
    const subStatusControl = this.adminForm.controls.subStatus as FormControl;
    subStatusControl.setValue(null);
    const c = this.adminForm.controls.mainStatus as FormControl;
    if (c.value === 6 || c.value === 7 || c.value === 5) {
      this.isSubStatusVisiable = false;
    } else {
      this.isSubStatusVisiable = true;
    }
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    const subStatusName = OnboardingUtils.getSubStatusName(status);
    return (subStatusName ? 'Sub Status - ' + subStatusName : '');
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  saveSettings() {
    this.settingsForm.disable();
    this.driverSettingsAPI.updateDriverSettings(this.driverProfile.uid, this.settingsForm.value);
  }
  isAvailableGCashNo(GCashNo: string) {
    return this.dbService.hasGCashNumber(GCashNo);
  }
  sendDriverID() {
    this.driverIDsaved = true;
    const bikerId = this.driverProfile.form.bikerId;
    this.apiService.sendDriverId(bikerId).then(results => {
      if(results) {
        this.showAlert('success', 'Biker ID successfully sent!');
      }
    }).catch(err => {
      this.showAlert('danger', 'Oops! Error on sending Biker ID. Please contact the administrator.');
      alert();
    })
  }
  async saveDriverPhoto() {
    const values = this.driverPhotoForm.value;
    const driverPhotoUploadTask = this.uploadReferences.get(this.driverPhotoForm.controls.driverPhoto as FormControl);
    const urls = async () => {
      if (!driverPhotoUploadTask) {
        delete values.driverPhoto;
      } else {
        const driverPhotoURL = await driverPhotoUploadTask.urlObservable().toPromise();
        values.driverPhoto = driverPhotoURL;
        this.driverProfile.form.driverPhoto = driverPhotoURL;
      }
    };
    await urls();

    this.savingDriverPhoto = true;
    this.driverPhotoForm.disable();

    this.driverPhotoUpdateAPI.updateDriverPhoto(this.driverProfile.uid, values);
  }
  async saveVehiclePhoto() {
    const values = this.vehiclePhotoForm.value;
    const vehiclePhotoUploadTask = this.uploadReferences.get(this.vehiclePhotoForm.controls.vehiclePhoto as FormControl);
    const vehiclePhotoUploadTask1 = this.uploadReferences.get(this.vehiclePhotoForm.controls.vehiclePhoto1 as FormControl);
    const vehiclePhotoUploadTask2 = this.uploadReferences.get(this.vehiclePhotoForm.controls.vehiclePhoto2 as FormControl);
    const urls = async () => {
      if (!vehiclePhotoUploadTask) {
       if (!this.driverProfile.form.vehiclePhoto) {
          delete values.vehiclePhoto;
         } else {
           values.vehiclePhoto = this.driverProfile.form.vehiclePhoto;
         }
      } else {
        const vehiclePhotoURL = await vehiclePhotoUploadTask.urlObservable().toPromise();
        values.vehiclePhoto = vehiclePhotoURL;
        this.driverProfile.form.vehiclePhoto = vehiclePhotoURL;
      }
      if (!vehiclePhotoUploadTask1) {
         if (!this.driverProfile.form.vehiclePhoto1) {
          delete values.vehiclePhoto1;
         } else {
           values.vehiclePhoto1 = this.driverProfile.form.vehiclePhoto1;
         }
      } else {
        const vehiclePhotoURL1 = await vehiclePhotoUploadTask1.urlObservable().toPromise();
        values.vehiclePhoto1 = vehiclePhotoURL1;
        this.driverProfile.form.vehiclePhoto1 = vehiclePhotoURL1;
      }
      if (!vehiclePhotoUploadTask2) {
        if (!this.driverProfile.form.vehiclePhoto2) {
          delete values.vehiclePhoto2;
         } else {
           values.vehiclePhoto2 = this.driverProfile.form.vehiclePhoto2;
         }
      } else {
        const vehiclePhotoURL2 = await vehiclePhotoUploadTask2.urlObservable().toPromise();
        values.vehiclePhoto2 = vehiclePhotoURL2;
        this.driverProfile.form.vehiclePhoto2 = vehiclePhotoURL2;
      }
    };
    await urls();
    this.savingVehiclePhoto = true;
    this.vehiclePhotoForm.disable();
    this.driverVehiclePhotoUpdateAPI.updateDriverVehiclePhoto(this.driverProfile.uid, values);
  }
  async save() {
    const values = this.adminForm.value;
    this.adminApprovalAPI.saveAdminApprovalData(this.driverProfile.uid, this.driverProfile.personal.phone, values);
    this.adminForm.markAsPristine();
    this.mainStatus = this.driverProfile.form.mainStatus;
    this.Substatusvalue = this.driverProfile.form.subStatus;
    this.bikerApp = this.driverProfile.gearRelease.bikerapp;
  }
  // MARK: Trip Helpers
  getTotalBikerEarnings(trip: Trip): string {
    if (trip && trip.receipt) {
      return (trip.receipt.tripFare - trip.receipt.commissionAmt) + '';
    }
    return '';
  }
  getDriverRating(trip: Trip): string {
    if (trip && trip.driver && trip.driver.ratingByRider) {
      return trip.driver.ratingByRider.rating + '';
    }
    return '';
  }
  getRidersComments(trip: Trip): string {
    if (trip && trip.driver && trip.driver.ratingByRider && trip.driver.ratingByRider.comments) {
      return trip.driver.ratingByRider.comments;
    }
    return '';
  }
  getReceipt(trip: Trip): any {
    if (trip && trip.receipt) { return trip.receipt; }
    return {};
  }
  formatDate(date: Date) {
    if (!date) { return ''; }
    return moment(date).format('MMM DD, YYYY h:mm:a');
  }
  formatTripStatus(trip: Trip) {
    switch (trip.status) {
      case TripStatus.rideRequested: return 'REQUESTED';
      case TripStatus.searchingForDrivers: return 'SEARCHING BIKERS';
      case TripStatus.driverAssigned: return 'BIKER ASSIGNED';
      case TripStatus.driverArrived: return 'BIKER ARRIVED';
      case TripStatus.tripStarted: return 'TRIP IN PROGRESS';
      case TripStatus.tripCompleted: return 'TRIP COMPLETED';
      case TripStatus.tripCancelledByRider: return 'RIDER CANCELLED';
      case TripStatus.tripCancelledEarlyByRider: return 'RIDER CANCELLED';
      case TripStatus.tripCancelledByDriver: return 'BIKER CANCELLED';
      case TripStatus.tripAbortNoDriversFound: return 'NO BIKERS FOUND';
      case TripStatus.tripAbortServerError: return 'SERVER ERROR';
      case TripStatus.tripAdminCancelled: return 'ADMIN CANCELLED';
      case TripStatus.requestingForBiker: return 'REQUESTING';
      default: return '';
    }
  }
  classForTripStatus(trip: Trip) {
    switch (trip.status) {
      case TripStatus.rideRequested: return { 'btn-primary': true };
      case TripStatus.searchingForDrivers: return { 'btn-primary': true };
      case TripStatus.driverAssigned: return { 'btn-primary': true };
      case TripStatus.driverArrived: return { 'btn-primary': true };
      case TripStatus.tripStarted: return { 'btn-warning': true };
      case TripStatus.tripCompleted: return { 'btn-success': true };
      case TripStatus.tripCancelledByRider: return { 'btn-danger': true };
      case TripStatus.tripCancelledEarlyByRider: return { 'btn-danger': true };
      case TripStatus.tripCancelledByDriver: return { 'btn-danger': true };
      case TripStatus.tripAbortNoDriversFound: return { 'btn-secondary': true };
      case TripStatus.tripAbortServerError: return { 'btn-dark': true };
      case TripStatus.tripAdminCancelled: return { 'btn-dark': true };
      case TripStatus.requestingForBiker: return { 'btn-primary': true };
      default: return '';
    }
  }
  // tslint:disable-next-line: ban-types
  areYouTheOwnerSelectionChanged(c: AbstractControl, enabled: Boolean) {
    const control = c as FormControl;
    if (control === this.driverIdentificationForm.controls.areYouTheOwner) {
    }
  }
  canShowAuthorizationLetterSection(): boolean {
    return this.driverIdentificationForm.controls.areYouTheOwner.value === 'No';
  }
  canSaveGCashDetails(): boolean {
    return this.gcashDetailForm.valid && this.gcashDetailForm.dirty;
  }
  checkIMEINoavailablity(control: AbstractControl) {
    const c = control as FormControl;
    let checkIMEINo = true;
    if (checkIMEINo) {
      if (c.value === this.driverProfile.personal.imei) {
        this.imeiExist = false; checkIMEINo = false;
      } else { checkIMEINo = true; }
    }
    if (checkIMEINo) {
      this.dbService.hasIMEINumber(c.value)
        .then(result => {
          if (result) {
            this.imeiExist = true;
            return Promise.reject();
          } else {
            this.imeiExist = false;
          }
        })
        .then(() => {
        })
        .finally(() => {

        });
    } else { }
  }
  getCitiesbyRegionEdit(region: string) {
    this.personalInfoForm.controls.town.setValue(this.driverProfile.personal.town);
    this.onboardingCityList = this.onboardingCity.filter(
      city => city.region === region);
    this.onboardingCityList = uniqBy(this.onboardingCityList, 'city');
  }
  getCitiesbyRegion(region: string) {
    this.personalInfoForm.controls.town.setValue(null);
    this.onboardingCityList = this.onboardingCity.filter(
      city => city.region === region);
    this.onboardingCityList = uniqBy(this.onboardingCityList, 'city');
  }
  getRegions() {
    this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }
  checkgcashnoavailablity(control: AbstractControl) {
    const c = control as FormControl;
    let checkGCashNo = true;
    checkGCashNo = (this.driverProfile.gCash === undefined ? true : false);
    if (!checkGCashNo) {
      if (c.value === this.driverProfile.gCash.gcashnumber) {
        this.gcashnoExist = false; checkGCashNo = false;
      } else { checkGCashNo = true; }
    }
    if (checkGCashNo) {
      this.dbService.hasGCashNumber(c.value)
        .then(result => {
          if (result) {
            alert('The entered GCash Number already exists in the database!');
            c.setValue((this.driverProfile.gCash === undefined ?
              '' : this.driverProfile.gCash.gcashnumber));
            return Promise.reject();
          } else {
            this.gcashnoExist = false;
          }
        })
        .then(() => {
        })
        .finally(() => {

        });
    } else { }

    return;
  }
  saveGCashDetails() {
    this.gcashDetailForm.controls.nationalty.setValue('Filipino');
    this.gcashDetailForm.controls.curaddresscountry.setValue('Philippines');
    this.gcashDetailForm.controls.classification.setValue('Agent');
    this.gcashDetailForm.controls.peraddresscountry.setValue('Philippines');
    if (this.gcashnoExist) {
      return;
    } else {
      this.driverGCashUpdateAPI.updateGCashDetails(this.driverProfile.uid, this.driverProfile.personal.phone, this.gcashDetailForm.value);
      this.gcashnoExist = false;
    }
  }
  getGcashRegionList() {
    this.GCashPCities = this.GCashCities;
    this.ddlGCashRegions = uniqBy(this.GCashCities, 'REGION');
    this.ddlGCashPRegions = uniqBy(this.GCashPCities, 'REGION');
  }
  getGcashCitiesbyRegion(region: string) {
    this.ddlGCashCities = this.GCashCities.filter(
      city => city.REGION === region);
    this.ddlGCashCities = uniqBy(this.ddlGCashCities, 'CITY');
  }
  getGcashTownbyCity(City: string) {
    const control1 = this.gcashDetailForm.controls.curaddressregion;
    this.ddlGCashTown = this.GCashCities.filter(
      city => city.REGION === control1.value && city.CITY === City);
  }
  getGcashZipbyTown(Town: string) {
    const controlcurregion = this.gcashDetailForm.controls.curaddressregion;
    const controlcurregioncity = this.gcashDetailForm.controls.curaddresscity;
    this.GCashCurrentZipCode = this.GCashCities.filter(
      city => city.REGION === controlcurregion.value && city.CITY === controlcurregioncity.value && city.TOWN === Town);
    this.gcashDetailForm.controls.curaddresszipcode.setValue(this.GCashCurrentZipCode[0]['ZIP CODE']);
  }
  getGcashCitiesbyPRegion(region: string) {
    this.ddlGCashPCities = this.GCashPCities.filter(
      city => city.REGION === region);
    this.ddlGCashPCities = uniqBy(this.ddlGCashPCities, 'CITY');
  }

  getGcashTownbyPCity(City: string) {
    const control1 = this.gcashDetailForm.controls.peraddressregion;
    this.ddlGCashPTown = this.GCashPCities.filter(
      city => city.REGION === control1.value && city.CITY === City);
  }
  getGcashZipbyPTown(Town: string) {
    const controlcurregion = this.gcashDetailForm.controls.peraddressregion;
    const controlcurregioncity = this.gcashDetailForm.controls.peraddresscity;
    this.GCashCurrentZipCode = this.GCashPCities.filter(
      city => city.REGION === controlcurregion.value && city.CITY === controlcurregioncity.value && city.TOWN === Town);
    this.gcashDetailForm.controls.peraddresszipcode.setValue(this.GCashCurrentZipCode[0]['ZIP CODE']);
  }
  changeAddress(event, val) {
    if (event.target.checked) {
      this.perRegion = val.curaddressregion;
      this.gcashDetailForm.controls.peraddressregion.setValue(val.curaddressregion);
      this.getGcashCitiesbyPRegion(this.perRegion);
      this.gcashDetailForm.controls.peraddresscity.setValue(val.curaddresscity);
      this.perCity = val.curaddresscity;
      this.getGcashTownbyPCity(this.perCity);
      this.gcashDetailForm.controls.peraddresstown.setValue(val.curaddresstown);
      this.perTown = val.curaddresstown;
      this.perzip = val.curaddresszipcode;
      this.perStreet = val.curaddressstreet;
    }
  }
  canEnableStatusChange() {
    this.gearReleasingForm.controls.isStatusChange.disable();
    if ((this.gearReleasingForm.value.beltbagqty != null) && (this.gearReleasingForm.value.facemaskqty != null)) {
      if ((this.gearReleasingForm.value.helmetsqty != null) && (this.gearReleasingForm.value.jerseyqty != null)) {
        if ((this.gearReleasingForm.value.sprayqty != null) && (this.gearReleasingForm.value.touqty != null)
          && (this.gearReleasingForm.value.haicapqtyty != null)) {
          if ((this.gearReleasingForm.value.vestqty != null) && (this.gearReleasingForm.value.bikerId != null)) {
            this.gearReleasingForm.controls.isStatusChange.enable();
          }
        }
      }
    } else {
      this.gearReleasingForm.controls.isStatusChange.disable();
      this.gearReleasingForm.controls.isStatusChange.setValue(null);
    }
  }
  canEnableVerifyMCVerificationForm() {
    if (this.driverProfile.motorcyclecheck !== undefined) {
      if (this.driverProfile.motorcyclecheck.mcstatus === 'Pass') {
        this.verificationForm.controls.verifymc.enable();
        this.verificationForm.controls.verifymc.setValue(true);
      } else {
        this.verificationForm.controls.verifymc.setValue(null);
        this.verificationForm.controls.verifymc.disable();
      }
    } else {
      this.verificationForm.controls.verifymc.disable();
      this.verificationForm.controls.verifymc.setValue(null);
    }
  }
  canEnableSkillsVerificationForm() {
    if (this.driverProfile.skillsassessment !== undefined) {
      if (this.driverProfile.skillsassessment.skillstatus === 'Pass') {
        this.verificationForm.controls.verifyskills.enable();
        this.verificationForm.controls.verifyskills.setValue(true);
      } else {
        this.verificationForm.controls.verifyskills.disable();
        this.verificationForm.controls.verifyskills.setValue(null);
      }
    } else {
      this.verificationForm.controls.verifyskills.disable();
      this.verificationForm.controls.verifyskills.setValue(null);
    }
  }
  canEnableOrientationVerificationForm() {
    if (this.driverProfile.orientation !== undefined) {
      if (this.driverProfile.orientation.orientationstatus === 'Pass') {
        this.verificationForm.controls.orientation.enable();
        this.verificationForm.controls.orientation.setValue(true);
      } else {
        this.verificationForm.controls.orientation.disable();
        this.verificationForm.controls.orientation.setValue(null);
      }
    } else {
      this.verificationForm.controls.orientation.disable();
      this.verificationForm.controls.orientation.setValue(null);
    }
  }
  canEnableAfterPreRelease() {
    if ((this.driverProfile.form.mainStatus === 8) || (this.driverProfile.form.mainStatus === 5)) {
      this.verificationForm.controls.vehiclePhoto.enable();
    } else {
      this.verificationForm.controls.vehiclePhoto.disable();
    }
  }

  saveMotorcycleCheck() {
    const values = this.motorCyclecCheckForm.value;
    this.saving = true;
    this.adminApprovalAPI.saveMotorCycleCheck(this.driverProfile.uid, values);
  }
  saveSkillsAssessement() {
    const value = this.skillsAssessmentForm.value;
    this.saving = true;
    this.adminApprovalAPI.saveSkillsAssessment(this.driverProfile.uid, value);
  }
  saveOrientations() {
    const value = this.orientationForm.value;
    this.saving = true;
    this.adminApprovalAPI.saveOrientations(this.driverProfile.uid, value);
  }
  canSaveMotorCycleCheck(): boolean {
    return this.motorCyclecCheckForm.valid && this.motorCyclecCheckForm.dirty;
  }
  canSavedriverStatus(): boolean {
    return this.statusUpdateForm.valid && this.statusUpdateForm.dirty;
  }
  canSaveGearActivation(): boolean {
     return this.gearReleasingForm.dirty && (this.gearReleasingForm.valid || this.gearReleasingForm.controls.bikerId.value === null);
  }
  canSaveskillsAssessment(): boolean {
    return this.skillsAssessmentForm.valid && this.skillsAssessmentForm.dirty;
  }
  canSaveOrientation(): boolean {
    return this.orientationForm.valid && this.orientationForm.dirty;
  }

  updateVerificationForm() {
    this.saving = true;
    this.mainStatus = null;
    if (this.driverProfile.form.mainStatus === 1) {
      this.mainStatus = 1;
    } else if ((this.verificationForm.value.verifymc === true) && (this.verificationForm.value.verifyskills === true) &&
      (this.verificationForm.value.orientation === true) && (this.verificationForm.value.gcash === true) &&
      (this.verificationForm.value.topup === true) && (this.verificationForm.value.idphoto === true)) {
      if ((this.verificationForm.value.vehiclePhoto === false) || (this.verificationForm.value.vehiclePhoto === undefined)) {
        this.mainStatus = 8;
      } else if ((this.verificationForm.value.vehiclePhoto === true) || (this.driverProfile.gearRelease.vehiclePhoto === true))  {
        this.mainStatus = 5;
      } else {
        this.mainStatus = 4;
      }
    } else {
      this.mainStatus = 4;
    }
    const result = this.adminApprovalAPI.saveGearReleaseForm(this.driverProfile.uid, this.mainStatus, this.verificationForm.value);
  }

  updateServiceSettingsForm() {
    this.saving = true;
    const result = this.adminApprovalAPI.saveServiceSettingsForm(this.driverProfile.uid, this.driverProfile.personal.phone,
      this.servicesettingsForm.value);
  }
  updateDeliveryBagSettingsForm() {
    this.saving = true;
    const result = this.adminApprovalAPI.saveDeliverybagSettingsForm(this.driverProfile.uid, this.deliverybagsettingsForm.value);
  }
  async checkBikerIdExists(c: AbstractControl) {
   return this.dbService.hasBikerId(c.value);
  }
  async updateGearReleasing() {
    this.saving = true;
    if (this.driverProfile.form.mainStatus === 1) {
      this.mainStatus = 1;
    } else if (this.gearReleasingForm.controls.isStatusChange.value === true) {
      this.mainStatus = 6;
      this.gearReleasingForm.controls.isStatusChange.setValue(true);
    } else {
      this.mainStatus = 5;
      this.gearReleasingForm.controls.isStatusChange.setValue(false);
    }
    const result = this.adminApprovalAPI.saveGearActivationForm(this.driverProfile.uid, this.mainStatus, this.gearReleasingForm.value);
  }
  async saveTraning() {
    const values = this.traningForm.value;
    this.saving = true;
    this.adminApprovalAPI.saveTraningData(this.driverProfile.uid, values);
  }
  setBikerAppvalue() {
       if (this.driverProfile.gearRelease !== undefined) {
          if (this.driverProfile.gearRelease.bikerapp !== undefined) {
            this.bikerApp = this.driverProfile.gearRelease.bikerapp;
          }
          if (this.driverProfile.form.bikerapp !== undefined ) {
            this.bikerApp = this.driverProfile.form.bikerapp;
          } else {
            this.bikerApp = '';
          }  
        } 
      }


  loadBagSettings() {
    this.dbService.getBagSettings().then(results => {
    this.bagSettings = results.data().list
    }).catch(err => {
    })
 }

}
