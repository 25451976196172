import { Component, OnInit } from '@angular/core';
import { Trip, TripStatus, UpdateTripStatusRequest } from 'src/app/interfaces/trip-interfaces';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-trip-stops',
  templateUrl: './trip-stops.component.html',
  styleUrls: ['./trip-stops.component.css']
})
export class TripStopsComponent implements OnInit {

  trip: Trip; // Currently viewing trip details

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder) {

  }

  ngOnInit() {

  }

  
}
