import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserClaimsComponent } from 'src/app/modals/user-claims/create-user-claim.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { EditUserClaimsComponent } from 'src/app/modals/user-claims/edit-user-claim.component';
import {AlertModel, Claim, ClaimsValue } from 'src/app/interfaces/interfaces';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UserClaimsRolesAPI } from 'src/app/api/user.api';
@Component({
  selector: 'app-user-roles-add',
  templateUrl: './user-roles.component.html'
})
export class UserRolesComponent implements OnInit {
  userClaimsId: any = [];
  loading: boolean = false;
  public arrFinalData = [];
  isEditing = false;
  isEditingSave = false;
  alert: AlertModel;
  roleName  = new FormControl('');
  private claimsNameObservable: Observable<Claim>;
  userClaims: any = [];
  userClaimsNew: any =[];
  indexValue: any;
  disableStatus = false;
  roleNameStore: any;
  pageDisable = false;
  rolesName: any[];
  displayRole: any ='';
  search = new FormControl('');
  id = new FormControl('');
  claimName = new FormControl('');
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  userClaimsRolesAPI: UserClaimsRolesAPI;
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {

    this.userClaimsNew = [];
    this.paginationCount = 0;
    this.userClaimsRolesAPI = new UserClaimsRolesAPI(dbService, (claims) => {
        this.userClaimsNew = claims;
        this.getPaginations();
        });
  }
  ngOnInit() {
    this.loadRoles();
  }


  async loadRoles() {
    this.loading = true;
    this.dbService.getRoles()
    .then(results => {
      this.rolesName = results;
      this.getRoles();
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  async getRoles() {
    this.loading = true;
    this.arrFinalData = this.rolesName;
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
    await this.loadUserRoles(this.arrFinalData[i], i);
    }
    this.loading = false;
  }

  async loadUserRoles(arrFinalData: any, iVal) {
    await this.dbService.getClaimSettingNew(this.arrFinalData[iVal].roleName)
    .then(results => {
      if(results.length > 0) {
        this.userClaims.push(results[0]);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'ACTIVE';
      case false: return 'INACTIVE';
      default: return '';
    }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
   rowData(value) {
    this.pageDisable = true;
    this.roleNameStore = value.roleName;
    this.displayRole = value.roleValue;
    this.userClaimsNew = [];
    this.pageCount = 'load';  this.paginationCount = 0;
    this.userClaimsRolesAPI.getClaimSettingSearch(this.roleNameStore, this.claimName.value, this.id.value,
    Number(this.selectedFilter), 'load');
  }
  getPaginations() {
    if (this.selectedFilter > this.userClaimsNew.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }
  applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.userClaimsRolesAPI.getClaimSettingSearch(this.roleNameStore, this.claimName.value,
      this.id.value, Number(this.selectedFilter), buttonAction);
  }
  filterpage(status: number) {
    this.selectedFilter = status;
  }
  async saveUserClaimRole() {
    this.disableStatus = true;
    this.loading = true;
    this.arrFinalData = this.userClaimsNew;
    await this.claimUploadUpdate();


  }

  async claimUploadUpdate() {
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      await this.saveClaimNameUpdate(this.arrFinalData[i], i);
    }
    this.loading = false;
    this.showAlert('success', 'User Claim Role Updated Successfully');
    this.backtoPage();
  }
  async saveClaimNameUpdate(arrFinalData: any, iVal) {
        await this.dbService.saveAdminClaimUpdate(arrFinalData, this.arrFinalData[iVal].id, this.arrFinalData[iVal].roleName, 
          this.arrFinalData[iVal].roleValue);
  }
  backtoPage() {
    this.pageDisable = false;
  }
}


