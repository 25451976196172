import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { ApiService } from 'src/app/services/api.service';
import { ImportDriverBag, AlertModel } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-driver-update-bags',
  templateUrl: './driver-update-bags.component.html',
  styleUrls: ['./driver-update-bags.component.css']
})
export class DriverUpdateBagsComponent implements OnInit {
	public arrFinalData = [];
	file_to_upload: File;
	importDriverBagsBulkForm: FormGroup;
	data: any;
	excelHeader: any;
	uploadedFileName: any;
	driverBags: any = [];
	isDisabledImport = false;
	isConfirm = false;
  isConfirmExport = false;
	isConfirmDisable = true;
	isLoad = false;
  DriverBags:any = [];
	alert: AlertModel;

  constructor(private fb: FormBuilder, 
  	public http: HttpClient, 
  	private apiService: ApiService) { }

  ngOnInit() {
  	this.createImportCSVForm();
  }
  private createImportCSVForm() {
    this.importDriverBagsBulkForm = this.fb.group({
      importdriverbags: [null, Validators.required]
    });
  }

  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.file_to_upload = target.files[0];
    this.uploadedFileName = target.files[0].name;
	  this.isDisabledImport = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);
  }

  importExcelData() {
    let services = ['jr_bag','hm_bag',undefined]
    var phoneno = /^\d{10}$/;
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];

    const jsonObjectKeys = ['phone_no', 'bag', 'bag2', 'result'];
    if (this.excelHeader.length === 3) {
      this.isConfirm = true
        for (let row = 1; row <= this.data.length - 1; row++) {
          const excelData = {};
          for (let col = 0; col < this.excelHeader.length + 1; col++) {  
            if(this.data[row][0] === undefined ) { // if empty cells
              this.data[row][3] = 'no mobile number';
              this.isConfirm = false
            } else if(this.data[row][1] === undefined && this.data[row][2] === undefined) { // no bag code
              this.data[row][3] = 'NULL value';
              this.isConfirm = false
            } else if (services.includes(this.data[row][1]) === false || services.includes(this.data[row][2]) === false) { // if bag code is invalid
              this.data[row][3] = 'bag invalid';
              this.isConfirm = false
            } else if (!this.data[row][0].toString().match(phoneno)) { // if mobile no is invalid
              this.data[row][3] = 'mobile number invalid';
              this.isConfirm = false
            } 
            excelData[jsonObjectKeys[col]] = this.data[row][col];
          }
          this.arrFinalData.push(excelData);
          this.driverBags = this.arrFinalData;
        }
    }

  }

  confirmData() {
  	this.isLoad = true;
    this.isConfirmExport = true;
    let DriverBags = []
  	this.apiService.processBulkDriverUpdateBags(this.file_to_upload).then(res => {
      if(res['isSuccess'] === true) {

      for(let x = 0; x<res['data'][0].adjusted.length; x++) {
        for (let y = 0; y<res['data'][0].adjusted[x]['success'].length; y++) {
           DriverBags.push(res['data'][0].adjusted[x]['success'][y])
          if(x == res['data'][0].adjusted.length-1 && y == res['data'][0].adjusted[x]['success'].length-1) {
            this.DriverBags = DriverBags;
            this.isConfirmExport = true;
            this.isConfirm = false;
            this.isLoad = false;
            this.showAlert('success', 'Import successfully processed');
            console.log(this.DriverBags)
          }
         
        }
      }
       
      } else {
        this.showAlert('danger', res['error']);
      }
      
    })
    .catch(err => { this.showAlert('danger', 'There is an error on processing import'); this.isLoad = false; });
  }


  confirmData2() {
    let res = {"isSuccess":true,"data":[{"adjusted":[{"success":[{"jr_bag":true,"hm_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"}],"error":[]},{"success":[{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"}],"error":[]},{"success":[{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"},{"jr_bag":true,"driverId":"zlnL6q9LOfUwckn7RyeBYjoGOlw1"}],"error":[{"driverId":"not-found","phone":"\n","message":"driver not found"}]}],"gs_url":"https:\/\/storage.googleapis.com\/download\/storage\/v1\/b\/joyride-dev-bb1bd.appspot.com\/o\/driver_bags_update%2F2021-02-10_09_38_47_Book1.csv?generation=1612949927858391&alt=media","data_length":25,"processed":25,"batchCtr":10}]}
    this.isLoad = true;
    this.isConfirmExport = true;
    let DriverBags = []

      for(let x = 0; x<res['data'][0].adjusted.length; x++) {
        for (let y = 0; y<res['data'][0].adjusted[x]['success'].length; y++) {
          DriverBags.push(res['data'][0].adjusted[x]['success'][y])
          if(x == res['data'][0].adjusted.length-1 && y == res['data'][0].adjusted[x]['success'].length-1) {
            this.DriverBags = DriverBags;
            this.isConfirmExport = true;
            this.isConfirm = false;
            this.showAlert('success', 'Import successfully processed');
            console.log(this.DriverBags)
          }
        }
      }
      this.isLoad = false;

  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  exportToCSV(){
    if(this.DriverBags) {
      this.isConfirmExport = false;
      const filename = () => 'DRIVER_BULK_UPDATE_BAGS' + (new Date()).toLocaleDateString();
      const exporter = new CSVExporter(filename(), ['MOBILE NUMBER','JR BAG','HM BAG','ERROR']);
      exporter.exportImportDriverBag(this.DriverBags as [ImportDriverBag]);
    }
  }


}
