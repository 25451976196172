import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { CashoutRequest } from '../interfaces/interfaces';
import { ApiService } from '../services/api.service';
import { WalletRequestFilter } from '../classes/FilterModels';
import { Subscription } from 'rxjs';
export class CashoutRequestsAPI extends BaseAPI {
    private handler: ([CashoutRequest]) => void;
    private unsubscribe: Subscription;
    constructor(dbService: DatabaseService, completion: ([CashoutRequest]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadCashoutRequests(filter: WalletRequestFilter) {
        this.state = APIState.loading;
        this.dbService.getAllCashoutRequests(filter)
            .then(requests => {
                this.state = (requests.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(requests as [CashoutRequest]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
    }
    exportCashout(from?: Date, to?: Date, gcash?: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allCashoutRequest(from, to, gcash).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [CashoutRequest]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
        this.unsubscribe = this.dbService.allCashoutRequest(from, to, gcash)
            .valueChanges().subscribe(values => {
                this.handler(values as [CashoutRequest]);
            }, err => {
                console.error(err);
            });
    }
}
/**
 * Update status of cashout requests
 */
export class CashoutStatusUpdateApi extends BaseAPI {
    private handler: (success: boolean, message: string) => void;

    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateCashoutStatus(statuses: any) {
        this.state = APIState.loading;
        this.apiService.processCashoutRequests(statuses)
        .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const successMessage = 'The status of the cashout requests were updated successfully.';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, successMessage);
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
                return true;
            })
            .catch(err => {
                const failureMessage = 'There was an error while updating the cashout requests. Please try again.';
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, failureMessage + err);
            });
    }
}
