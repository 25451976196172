import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { CustomerAPI } from 'src/app/api/customer.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { Customer } from 'src/app/interfaces/interfaces';
import { CustomerProfileComponent } from 'src/app/modals/customer/customer-profile/customer-profile.component';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';
@Component({
  selector: 'app-customer',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerSearchComponent implements OnInit {
  paginator: any;
  customerAPI: CustomerAPI;
  customer: any[] = [];
  customerCount: any;
  startDate: any;
  toDate: any;
  selectedFilter = 20;
  filter = new CustomerFilter();
  firstInResponse: any = [];
  lastInResponse: any = [];
  previousStart: any = [];
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
              public permissions: PermissionService, public userService: UserService) {
    this.customer = [];
    this.customerAPI = new CustomerAPI(dbService, (customer) => {
    });
  }
  ngOnInit() {
  }
  applyFilter() {
    this.customer = [];
    this.getCustomer(this.filter.from.selectedDate, this.filter.to.selectedDate);
  }
  isNull(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return ''; }
    return path;
  }
  reset() {
    this.filter.reset();
  }
  filterpage(status: number) {
    this.selectedFilter = status;
  }
  getCustomer(startDate?: Date, endDate?: Date) {
    this.disableLoad = true;
    this.disableInitial = true;
    this.customerAPI.getCustomer(startDate, endDate, '', '', '', this.selectedFilter, 'load').
      get()
      .subscribe(response => {
        if (!response.docs.length) {
          this.disableLoad = false;
          return false;
        }
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        this.customer = [];
        for (const item of response.docs) {
          this.customer.push(item.data());
        }
        if (this.selectedFilter > this.customer.length) {
          this.disableInitial = false;
        }
        this.previousStart = [];
        this.paginationCount = 0;
        this.disableLoad = false;
        this.disableNext = false;
        this.disablePrevious = false;
        this.pushPreviousStartAt(this.firstInResponse);
      }, error => {
      });
  }
  prevPage() {
    this.disablePrevious = true;
    this.disableInitial = true;
    const previousStart = this.getPrviousStartAt();
    this.customerAPI.getCustomer(this.filter.from.selectedDate, this.filter.to.selectedDate, '', previousStart, this.firstInResponse,
      this.selectedFilter, 'previous').
      get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        this.customer = [];
        for (const item of response.docs) {
          this.customer.push(item.data());
        }
        this.paginationCount--;
        this.popPreviousStartAt(this.firstInResponse);
        this.disablePrevious = false;
        this.disableNext = false;
      }, error => {
        this.disablePrevious = false;
      });
  }
nextPage() {
    this.disableNext = true;
    this.customerAPI.getCustomer(this.filter.from.selectedDate, this.filter.to.selectedDate, this.lastInResponse,
      '', '', this.selectedFilter, 'next').
      get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disableNext = false;
          this.disableInitial = false;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.customer = [];
        for (const item of response.docs) {
          this.customer.push(item.data());
        }

        if (this.selectedFilter > this.customer.length) {
          this.disableInitial = false;
        }

        this.paginationCount++;

        this.pushPreviousStartAt(this.firstInResponse);

        this.disableNext = false;
      }, error => {
        this.disableNext = false;
      });
  }
  pushPreviousStartAt(previousFirstDoc) {
    this.previousStart.push(previousFirstDoc);
  }
 popPreviousStartAt(previousFirstDoc) {
    this.previousStart.forEach(element => {
      if (previousFirstDoc.data().id === element.data().id) {
        element = null;
      }
    });
  }
  getPrviousStartAt() {
    if (this.previousStart.length > (this.paginationCount + 1)) {
      this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
    }
    return this.previousStart[this.paginationCount - 1];
  }
   openCustomer(customer: Customer) {
    const modalRef = this.modalService.open(CustomerProfileComponent, { size: 'lg' });
    modalRef.componentInstance.customer = customer;
  }
  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
}

/**
 * FILTER for Customers
 */
class CustomerFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
  }
  export() {
    
  }
}
