import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverTripSummaryReport } from 'src/app/interfaces/trip-interfaces';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { PermissionService } from 'src/app/services/permission.service';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { combineLatest, of } from 'rxjs';
@Component({
  selector: 'app-driver-trip',
  templateUrl: './driver-inactive-report.component.html',
  styleUrls: ['./driver-inactive-report.component.css']
})
export class DriverInactiveReportComponent implements OnInit {
  api: DriversListAPI;
  public startDate: string;
  public endDate: string;
  driverProfile: DriverProfile[];
  tripNotTakenDriver: DriverProfile[];
  dailyStatDate: Date = new Date();
  toDate: Date = new Date();
  isLoading = false;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  constructor(private dbService: DatabaseService, private db: AngularFirestore, public utils: UtilsService, public datepipe: DatePipe,public permissions: PermissionService
  ) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.tripNotTakenDriver = [];
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.tripNotTakenDriver = drivers;
    });
  }
  ngOnInit() {
  }
  get canSearch(): boolean {
    const allRequests = this.driverProfile;
    return (allRequests && allRequests.length > 0);
  }
  searchInactiveDrivers() {
    const fromDate = moment(this.dailyStatDate).format('YYYY-MM-DD');
    const toDate = moment(this.toDate).format('YYYY-MM-DD');
    this.isLoading = true;
    this.getTripNotTakenDriver(fromDate, toDate);
  }
  fillRange(startDate, endDate) {
    const result = [startDate];
    const tripStartDate = new Date(startDate);
    const tripEndDate = new Date(endDate);
    if (tripStartDate === tripEndDate) {
      result.push(tripStartDate.toISOString().substr(0, 10));
    } else {
      while (tripStartDate < tripEndDate) {
        tripStartDate.setUTCDate(tripStartDate.getUTCDate() + 1);
        result.push(tripStartDate.toISOString().substr(0, 10));
      }
    }
    return result;
  }
  getTripNotTakenDriver(fromDate?: string, toDate?: string) {
    this.tripNotTakenDriver = [];
    let queries;
    const queryFn: QueryFn = (aRef) => aRef.where('region', '==', this.region.value);
    const col = this.db.collection('reports').doc('tripsummary');
    const ids = this.fillRange(fromDate, toDate);
    let driverActiveProfiles: DriverTripSummaryReport[];
    let currentDriverProfiles: DriverTripSummaryReport[];
    let allDriverActiveProfiles: DriverTripSummaryReport[];
    let driverProfile: DriverProfile[];
    let inactiveDriverProfile: DriverProfile[];
    if (this.utils.checkRegionReleaseDatePrevious(this.dailyStatDate) === true) {
     queries = ids.map(el => col.collection(el, queryFn).valueChanges());
    } else {
      queries = ids.map(el => col.collection(el).valueChanges());
    }
    const combo = combineLatest(...queries)
      .subscribe(res => {
        this.dbService.getAllActivatedDriverRegistrationsRegion(1, 11, this.region.value).then(forms => {
          driverProfile = forms;
          driverActiveProfiles = res[0];
          if (fromDate === toDate) {
            allDriverActiveProfiles = driverActiveProfiles;
          } else {
            for (let i = 1; i <= res.length - 1; i++) {
              currentDriverProfiles = res[i];
              const idtrips = new Set(driverActiveProfiles.map(d => d.driverId));
              allDriverActiveProfiles = [...driverActiveProfiles, ...currentDriverProfiles.filter(d => !idtrips.has(d.driverId))];
            }
          }
          const tempDriver = [];
          inactiveDriverProfile = driverProfile;
          allDriverActiveProfiles = allDriverActiveProfiles.filter(d => d.numTripsCompleted >= 1);
          for (let i = 0; i <= allDriverActiveProfiles.length; i++) {
            for (let j = 0; j <= driverProfile.length; j++) {
              if ((driverProfile[j] !== undefined) && (allDriverActiveProfiles[i] !== undefined)) {
                if (allDriverActiveProfiles[i].driverId === driverProfile[j].uid) {
                  inactiveDriverProfile.splice(j, 1);
                  tempDriver.push(driverProfile[i]);
                }
              }
            }
          }
          inactiveDriverProfile = driverProfile.filter(e => !tempDriver.includes(e.uid));
          this.tripNotTakenDriver = inactiveDriverProfile.filter(e => e.form.activationDate <= toDate);
          this.isLoading = false;
        }).catch(err => {
          console.log(err);
        });
      });
  }
  get canExport(): boolean {
    const allRequests = this.tripNotTakenDriver;
    return (allRequests && allRequests.length > 0);
  }
  export() {
    const filename = () => 'Inactive Driver Summary ' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.inactiveDriverTitles());
    exporter.exportInactiveDriversSummary(this.tripNotTakenDriver as DriverProfile[]);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }

  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
}


