import { DatabaseService } from '../services/database.service';
import { ApiService } from '../services/api.service';

export enum APIState {
    none = 0,
    loading = 1,
    successWithData = 2,
    successWithoutData = 3,
    failed = 4
  }

export class BaseAPI {

    state: APIState;
    dbService: DatabaseService;
    apiService?: ApiService;

    constructor(dbService?: DatabaseService, apiService?: ApiService) {
        this.dbService = dbService;
        this.apiService = apiService;
        this.state = APIState.none;
    }

    get isReady(): boolean { return this.state === APIState.none; }
    get isLoading(): boolean { return this.state === APIState.loading; }
    get isFailed(): boolean { return this.state === APIState.failed; }
    get isSuccessWithData(): boolean { return this.state === APIState.successWithData; }
    get isSuccessWithoutData(): boolean { return this.state === APIState.successWithoutData; }
    get isSuccess(): boolean {
      return this.state === APIState.successWithData ||
             this.state === APIState.successWithoutData;
    }
}
