export enum WalletUpdateType {
    CREDIT = 0,
    DEBIT = 1
}

export enum WalletHistoryType {
    TOPUP_APPROVED = 0,
    CASHOUT_REQUESTED = 1,
    CASHOUT_REJECTED = 2,
    TRIP_COMMISSION_DEBITED = 3,
    TRIP_PROMO_CODE_CREDITS = 4,
    ADMIN_WALLET_ADJUSTMENTS = 5,
    ADMIN_EMI_GEAR = 6,
    TRANSACTION_FEES = 7,
    PAYMAYA_TOPUP = 8,
    METROBANK_TOPUP = 9,
    TRIP_PAYMENT_CUSTOMER = 10
}

export enum CustomerWalletType {
    PAYMAYA_TOPUP = 1,
    TRIP_PAYMENT_DRIVER = 2,
    ADMIN_WALLET_ADJUSTMENTS = 3,
    TRIP_PAYMENT_CUSTOMER = 10,
    REFERRAL_REWARD = 4,
    REFER_AND_EARN = 5

}

// WALLET HISTORY
export interface WalletHistory {
    id: string;
    walletId: string;
    name: string;
    phone: number;
    updateType: number;
    historyType: number;
    amount: number;
    finalBalance: number;
    updated_at: firebase.firestore.Timestamp;
    otherInfo: any;
    driverId: string;
    region: string;
}



// WALLET HISTORY
export interface WalletHistoryExport {
    id: string;
    walletId: string;
    name: string;
    phone: number;
    historyType: number;
    amount: number;
    finalBalance: number;
    updated_at: firebase.firestore.Timestamp;
    otherInfo: any;
}
