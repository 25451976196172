import { ApiService } from '../services/api.service';
import { AgmMarker, AgmInfoWindow } from '@agm/core';
import { DatabaseService } from '../services/database.service';
import { DriverLocationData, DriverProfile } from '../interfaces/interfaces';
import { localeData } from 'moment';
import { GetDriverAndTripAPI } from '../api/driverList.api';
import { Trip } from '../interfaces/trip-interfaces';

/**
 * Handles all interactions with AGM - Google Maps
 */
export class MarkerController {
    private dbService: DatabaseService;
    private lastOpenedInfoWindow?: AgmInfoWindow;
    public api: GetDriverAndTripAPI;
    public driverDetails: DriverProfile;
    public tripDetails: Trip;

    constructor(dbService: DatabaseService) {
        this.dbService = dbService;
        this.api = new GetDriverAndTripAPI(dbService, (success, msg, driver, trip) => {
            this.driverDetails = driver;
            this.tripDetails = trip;
        });
    }

    markerClicked(marker: AgmInfoWindow, locationData: DriverLocationData) {
        if (this.lastOpenedInfoWindow) { 
            this.lastOpenedInfoWindow.close();
            this.driverDetails = null;
            this.tripDetails = null;
        }
        this.lastOpenedInfoWindow = marker;
        this.loadMoreInfo(locationData.uid, locationData.tId);
    }

    loadMoreInfo(driverId: string, tripId: string) {
        this.api.loadDriverAndTripDetails(driverId, tripId);
    }

    reset() {
        this.lastOpenedInfoWindow = null;
        this.driverDetails = null;
        this.tripDetails = null;
    }
}