import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-driver-profile-filter',
  templateUrl: './driver-profile-filter.component.html',
  styleUrls: ['./driver-profile-filter.component.css']
})
export class DriverProfileFilterComponent implements OnInit {

  filterForm: FormGroup;

  @Input()
  settings: IDriverProfileFilterSettings;

  @Input()
  filter: IDriverProfileFilter;

  @Output()
  onApply: EventEmitter<IDriverProfileFilter> = new EventEmitter();

  constructor(public fb: FormBuilder) {
  }

  ngOnInit() {
    this.createFitlerForm();
  }

  private createFitlerForm() {
    this.filterForm = this.fb.group( {
      subStatus: [{['disabled']: !this.settings.showSubStatus, ['value']: this.filter.subStatus}, [Validators.required]],
      trainingDate: [{['disabled']: !this.settings.showTrainingDate, ['value']: this.filter.trainingDate}],
      registrationDate: [{['disabled']: !this.settings.showRegistrationDate, ['value']: this.filter.registrationDate}]
    });
  }

  apply() {
    this.onApply.emit(this.filterForm.value);
  }

  reset() {
    this.filterForm.reset();
    this.filter = {};
    this.onApply.emit(null);
  }
}

export interface IDriverProfileFilter {

  subStatus?: number;
  trainingDate?: string;
  registrationDate?: string;

}

export interface IDriverProfileFilterSettings {

  showSubStatus: boolean;
  showTrainingDate: boolean;
  showRegistrationDate: boolean;
  subStatuses: {value: number, name: string}[];
}
