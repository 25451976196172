import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DriversWorkingListAPI } from 'src/app/api/driverList.api';
import {  DriverWrokingHours } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
    selector: 'app-driver-workinghours',
    templateUrl: './driver-workinghours.component.html',
    styleUrls: ['./driver-workinghours.component.css']
})
export class DriverWorkingHoursComponent implements OnInit {
    dailyStatDate: Date = new Date();
    driverId = new FormControl('');
    driverphone = new FormControl('');
    api: DriversWorkingListAPI;
    driverWrokingHours: DriverWrokingHours[];
    driver = [];
    dayId: any;
    driverWrokingPrevious: boolean;
    constructor(private dbService: DatabaseService, public datepipe: DatePipe, private utils: UtilsService) {
    this.api = new DriversWorkingListAPI(dbService, (drivers) => {
        if (this.utils.checkDriverWrokingHoursPrevious( this.dayId) === true) {
        this.driverWrokingPrevious = true;
        this.driver = drivers.filter(val => val.numTripsCompleted >= 1 || val.numTripsCompleted_delivery >= 1
        || val.numTripsCompleted_pabili >= 1);
        this.driverWrokingHours = this.driver;
        } else {
            this.driverWrokingPrevious = false;
            this.driverWrokingHours = drivers;
        }
    });
    }
    ngOnInit() {
    }
    searchDriverWorkingHours() {
        this.dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
        this.api.loadDriverWorkingHours(this.driverphone.value, this.driverId.value,  this.dayId);
    }
    workingHours(val1: number) {
      return (val1 !== undefined ? (val1 / 60).toFixed(2) : '0');
    }
    additions(val1: number, val2: number, val3: number) {
        if (val1 === undefined) { val1 = 0; }
        if (val2 === undefined) { val2 = 0; }
        if (val3 === undefined) { val3 = 0; }
        const sum = val1 + val2 + val3;
        return (sum !== 0) ? sum : '';
      }

}
