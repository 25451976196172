import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { CarouselBanner, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import { UploadTask } from 'src/app/classes/uploadtask';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AngularFireStorage } from '@angular/fire/storage';
import { ImageResult, ResizeOptions } from 'ng2-imageupload';
import uniqBy from 'lodash/uniqBy';
import * as moment from 'moment';
@Component({
  selector: 'app-create-carousel-banner',
  templateUrl: './create-carousel-banner.component.html'
})
export class CreateCarouselBannerComponent implements OnInit {
  carouselBannerForm: FormGroup;
  carouselBanner: CarouselBanner;
  bannerUrl: string;
  alert: AlertModel;
  saving: boolean;
  appReferenceNumber: string;
  resizeOptions: ResizeOptions = {
      resizeMaxHeight: 500,
  };
  regions = OnboardingUtils.getRegions();

  uploadReferences: Map<FormControl, UploadTask>;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    private storage: AngularFireStorage
  ) {
    this.uploadReferences = new Map();
    this.appReferenceNumber = this.generateApplicationID();
  }
  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.carouselBannerForm = this.fb.group({
        id: '',
        bannerName: ['', [Validators.required]],
        ordering:  ['', Validators.required],
        publishDate: ['', [Validators.required]],
        expiryDate: ['', [Validators.required]],
        linkUrl: ['', [Validators.required]],
        region: ['', [Validators.required]],
        linkType: ['', [Validators.required]],
        bannerUrl: [null, [Validators.required]],
        description: ['', Validators.required],
        isActive: false
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveCarouselBanner(): boolean {
    return this.carouselBannerForm.valid && this.carouselBannerForm.dirty;
  }

  private generateApplicationID() {
    const timestamp = Math.round(new Date().getTime() / 1000.0);
    const random = Math.floor(1000000 + Math.random() * 9000000);
    return (timestamp + random) + ' ';
  }


  async createCarouselBanner() {

    this.carouselBannerForm.disable();
    this.saving = true;
    const values = this.carouselBannerForm.controls.bannerUrl.value;
    const bannerPhotoUploadTask = this.uploadReferences.get(this.carouselBannerForm.controls.bannerUrl as FormControl);
    const urls = async () => {
      if (!bannerPhotoUploadTask) {
        delete values.bannerUrl;
      } else {
        const bannerPhotoURL = await bannerPhotoUploadTask.urlObservable().toPromise();
        values.bannerUrl = bannerPhotoURL;
        this.carouselBannerForm.get('bannerUrl').setValue(bannerPhotoURL);
      }
    };
    await urls();

    this.carouselBanner = {
        id: '',
        bannerName: this.carouselBannerForm.controls.bannerName.value,
        ordering: this.carouselBannerForm.controls.ordering.value,
        publishDate: this.carouselBannerForm.controls.publishDate.value,
        expiryDate: this.carouselBannerForm.controls.expiryDate.value,
        linkUrl: this.carouselBannerForm.controls.linkUrl.value,
        linkType: this.carouselBannerForm.controls.linkType.value,
        region: this.carouselBannerForm.controls.region.value,
        bannerUrl: this.bannerUrl,
        description: this.carouselBannerForm.controls.description.value,
        isActive: this.carouselBannerForm.controls.isActive.value,
        createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        updatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
      };

    this.dbService.createCarouselBanner(this.carouselBanner)
    .then(result => {
    })
      .then(() => {
        this.showAlert('success', 'Carousel Banner created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the Carousel Banner - ' + e.message);
        this.carouselBannerForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  selected(imageResult: ImageResult) {
    this.bannerUrl = imageResult.resized.dataURL;
  }
}
