import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AdminUserListListener, AdminUser, CreateAdminUserAPI } from 'src/app/api/user.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditRolesComponent } from 'src/app/modals/users/edit-roles/edit-roles.component';
import { CreateUserComponent } from 'src/app/modals/users/create-user/create-user.component';
import { EditUserComponent } from 'src/app/modals/users/edit-user/edit-user.component';

import { AlertModel } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  // Data
  users: AdminUser[] = [];
  alert: AlertModel;
  email: any ;
  // APIs
  adminUsersListListener: AdminUserListListener;
  adminUserAPI: CreateAdminUserAPI;

  constructor(private apiService: ApiService, private dbService: DatabaseService, utils: UtilsService, private modalService: NgbModal,
              public authService: AuthService) { 
    this.adminUsersListListener = new AdminUserListListener(dbService, (users) => {
      this.users = users;
    });
    this.adminUserAPI = new CreateAdminUserAPI(apiService, (success, message) => {
      this.showAlert(success, message);
    });
  }

  ngOnInit() {
    this.adminUsersListListener.beginListening();
  }

  ngOnDestroy(): void {
    this.adminUsersListListener.endListening();
  }

  // Getter Methods

  getRoles(user: AdminUser): string {
    if (!user.roles) return "";
    return user.roles.join(', ');
  }

  // Actions

  createUser() {
    const modalRef = this.modalService.open(CreateUserComponent, { size: 'lg', scrollable: true });
  }

  editRoles(user: AdminUser) {
    const modalRef = this.modalService.open(EditRolesComponent, { size: 'sm', scrollable: true });
    modalRef.componentInstance.user = user;
  }
  editUsers(user: AdminUser) {
    const modalRef = this.modalService.open(EditUserComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.loadedData = user;
  }

  deleteUser(user: AdminUser) {
    this.adminUserAPI.deleteUser(user.uid);
  }

  // Alerts
  private showAlert(success: boolean, message: string) {
    const type = success ? 'success' : 'danger';
    this.alert = { type, message } as AlertModel
    setTimeout(() => { this.alert = null }, 3000);
  }

  resetPassword(value) {
    this.email = value;
    if (!this.email) {
      alert('Type in your email first');
    }
    this.authService.resetPasswordInit(this.email) .then(
      () =>  alert('A password reset link has been sent to your emailaddress'),
      (rejectionReason) => alert(rejectionReason))
    .catch(e => alert('An error occurred while attempting to resetyour password'));
  }
}

