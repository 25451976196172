import { BaseAPI, APIState } from './base.api';
import { Trip, UpdateTripStatusRequest, DriverTripSummaryReport, DriverTripId } from '../interfaces/trip-interfaces';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DriverLocationStat, DriverTripStatus } from 'src/app/interfaces/interfaces';
/**
 * Invokes the cloud function API to force update a trip status. Any response received
 * will be forwarded to the caller.
 */
export class UpdateTripStatusAPI extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateTripStatus(payload: UpdateTripStatusRequest) {
        this.state = APIState.loading;
        this.apiService.updateTripStatus(payload)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update the trip status ' + err.message);
            });
    }
    sendReceiptToCustomer(payload: string) {
        this.state = APIState.loading;
        this.apiService.adminSendTripReceipt(payload)
            .then(response => {
                console.log(response)
                this.state = APIState.successWithData;
                const isSuccess = response[this.success];
                this.handler(isSuccess, (isSuccess) ? response[this.data][this.message] : response[this.error][this.message]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to send trip receipt to customer ' + err.message);
            });
    }
    updateOrderType(payload: string) {
        this.state = APIState.loading;
        this.apiService.updateOrderType(payload)
            .then(response => {
                this.state = APIState.successWithData;
                const isSuccess = response['errorCode'];
                this.handler(isSuccess === 'DELIVERY_TYPE_UPDATED' ? true : false, response['message']);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while trying to update the trip status ' + err.message);
            });
    }
}
/**
 * Fetches a list of Trips matching the given filter properties (state, from date and to date)
 * Also this method registers a listener over the query and notifies when there are changes
 * to the results.
 */
export class TripListAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(dateFilter?: number, vehicleType?:string, region?: string, tripType?: string, status?: number, from?: Date, to?: Date, drivername?: string, customername?: string,
              driverphone?: string, customerphone?: string, promocode?: string, tripid?: string, driverId?: string, orderNumber?:string, merchantReference?:string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripsRef(dateFilter, vehicleType, region, tripType, status, from, to, drivername, customername, driverphone,
            customerphone, promocode, tripid, driverId, orderNumber, merchantReference).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripsRef(dateFilter,vehicleType, region, tripType, status, from, to, drivername, customername, driverphone,
            customerphone, promocode, tripid, driverId, orderNumber)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }

loadTripsPagingRegion(dateFilter?: number, vehicleType?:string, region?: string, driverId?: string, tripType?: any, status?: number, from?: Date, to?: Date,
                      drivername?: string, customername?: string, driverphone?: string, customerphone?: string, promocode?: string,
                      tripid?: string, limit?: number, statusValue?: string, orderNumber?:string, merchantReference?:string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.allTripsRefPage(dateFilter, vehicleType, region, driverId, tripType, status, from, to, drivername, customername, driverphone, customerphone,
            promocode, tripid, this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue, orderNumber, merchantReference).get().toPromise()
            .then(response => {
                this.firstInResponse = response.docs[0];
                this.lastInResponse = response.docs[response.docs.length - 1];
                this.previousStart.push(this.firstInResponse);
                if (statusValue === 'next') {
                    this.paginationCount++;
                }
                this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
                if (statusValue === 'previous') {
                    this.paginationCount--;
                    this.previousStart.forEach(element => {
                        if (this.firstInResponse.data().id === element.data().id) {
                            element = null;
                        }
                    });
                }
                this.handler(response.docs.map(c => c.data()) as [Trip]);
            }).catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
    loadTripsHours(status?: number, from?: Date, to?: Date) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.tripsHours(status, from, to).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        /* this.unsubscribe = this.dbService.tripsHours(status, from, to)
             .valueChanges().subscribe(values => {
                 this.handler(values as [Trip]);
             }, err => {
                 console.error(err);
             });*/
    }

    getDriverTrips(from?: Date, to?: Date, driverphone?: string, driverUid?: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.getAllDriverTrips(from, to, driverphone, driverUid).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.getAllDriverTrips(from, to, driverphone)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
    async allCustomerTripsRef(uid?: string, from?: Date, to?: Date) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        await this.dbService.allCustomerTripsRef(uid, from, to).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
}

export class TripListExportAllAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTripsExport(dateFilter?: number, vehicleType?: string, region?: string, driverId?: string, tripType?: string, status?: number, from?: Date, to?: Date,
                    drivername?: string, customername?: string, driverphone?: string, customerphone?: string, promocode?: string,
                    tripid?: string, orderNumber?: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripsRefPageExport(dateFilter, vehicleType, region, driverId, tripType, status, from, to,
            drivername, customername, driverphone, customerphone,
            promocode, tripid, orderNumber).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
}
export class TripListExportAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([DriverLocationStat]) => void;
    constructor(dbService: DatabaseService, completion: ([DriverLocationStat]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getDriverTripsLocations(locationsDate: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.getAllDriverTripsExport(locationsDate)
            .then(requests => {
                this.state = (requests.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(requests as [DriverLocationStat]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
    }
}


/**
 * Listens for updates on a given trip document and notifies the listener when
 * a callback is received from firestore.
 */
export class TripListener {

    private unsubscribe: Subscription;
    private dbService: DatabaseService;
    private handler: (trip: Trip) => void;

    constructor(dbService: DatabaseService, callback: (trip: Trip) => void) {
        this.dbService = dbService;
        this.handler = callback;
    }
    listenForTrip(tripId: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        if (!tripId) { return; }

        this.unsubscribe = this.dbService.tripRef(tripId).valueChanges()
            .subscribe(trip => {
                this.handler(trip as Trip);
            }, e => {
                console.error(e);
            });
    }
}
/**
 * Fetches a list of Trips matching the given filter properties (state, from date and to date)
 * Also this method registers a listener over the query and notifies when there are changes
 * to the results.
 */
export class DriverTripReportAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([DriverTripSummaryReport]) => void;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([DriverTripSummaryReport]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getDriverTripReports(from: string, region: string) {
        this.state = APIState.loading;
        this.dbService.getAllDriverTripReportsRegion(from, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [DriverTripSummaryReport]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
    getDriverTripReportsPaging(from: string, region: string, driverId: string, limit?: number, statusValue?: string, tripDate?: Date) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.getAllDriverTripReportsRegionPaging(from, region, driverId, this.lastInResponse,
            previousValue, this.firstInResponse, limit, statusValue, tripDate).get().toPromise()
            .then(response => {
                this.firstInResponse = response.docs[0];
                this.lastInResponse = response.docs[response.docs.length - 1];
                this.previousStart.push(this.firstInResponse);
                if (statusValue === 'next') {
                    this.paginationCount++;
                }
                this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
                if (statusValue === 'previous') {
                    this.paginationCount--;
                    this.previousStart.forEach(element => {
                        if (this.firstInResponse.data().id === element.data().id) {
                            element = null;
                        }
                    });
                }
                this.handler(response.docs.map(c => c.data()) as [DriverTripSummaryReport]);
            }).catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
}

export class DriverTripReportExportAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([DriverTripSummaryReport]) => void;

    constructor(dbService: DatabaseService, completion: ([DriverTripSummaryReport]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getDriverTripReportsExport(from: string, region: string, driverId: string) {
        this.state = APIState.loading;
        this.dbService.getAllActiveDriverTripsExport(from, region, driverId).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [DriverTripSummaryReport]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
}
export class TripLocationsDriverAPI extends BaseAPI {

    handler: (success: boolean, stat?: DriverTripId) => void;
    constructor(dbService: DatabaseService, completion: (success: boolean, stat?: DriverTripId) => void) {
        super(dbService);
        this.handler = completion;
    }
    fetchAllLocationsTripId(TripId: string) {
        this.state = APIState.loading;
        this.dbService.tripRef(TripId).get().toPromise()
            .then(snapshot => {
                if (snapshot.exists) {
                    this.state = APIState.successWithData;
                    this.handler(true, snapshot.data());
                } else {
                    this.state = APIState.successWithoutData;
                    this.handler(true, null);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, null);
            });
    }
}
export class TripListReportAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private tripsdata = [];
    private tripsdataempty = [];
    private handler: ([DriverTripStatus]) => void;
    constructor(dbService: DatabaseService, completion: ([DriverTripStatus]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getDriverTripsStatus() {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.locationTripOnline()
            .then(requests => {
                if (requests.length !== 0) {
                   this.tripsdata.push(requests); }
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
        this.dbService.locationTripOffline()
            .then(requests => {
                if (requests.length !== 0) {
                    this.tripsdata.push(requests); }
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
        this.dbService.locationTripOnlineFalse()
        .then(requests => {
            if (requests.length !== 0) {
               this.tripsdata.push(requests); }
        })
        .catch(err => {
            console.error(err);
            this.state = APIState.failed;
        });
        this.dbService.locationTripOnlineTrue()
        .then(requests => {
            if (requests.length !== 0) { this.tripsdata.push(requests); }
            this.state = (requests.length === 0) ? APIState.successWithoutData : APIState.successWithData;
            const combined1 = [].concat(this.tripsdata[0] === undefined ? this.tripsdataempty : this.tripsdata[0],
                this.tripsdata[1] === undefined ? this.tripsdataempty : this.tripsdata[1],
                this.tripsdata[2] === undefined ? this.tripsdataempty : this.tripsdata[2],
                this.tripsdata[3] === undefined ? this.tripsdataempty : this.tripsdata[3]);
            this.handler(combined1 as [DriverTripStatus]);
        })
        .catch(err => {
            console.error(err);
            this.state = APIState.failed;
        });
    }
}


