import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TripListAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import * as firebase from 'firebase';
@Component({
  selector: 'app-search-cretiriya',
  templateUrl: './search-cretiriya.component.html',
  styleUrls: ['./search-cretiriya.component.css']
})
export class SearchCretriyaComponent  implements OnInit {
  tripListAPI: TripListAPI;
  trips = [];
  filter = new TripHistoryFilter();
  drivername = new FormControl('');
  customername = new FormControl('');
  driverphone  = new FormControl('');
  customerphone  = new FormControl('');
  tripid  = new FormControl('');
  promocode  = new FormControl('');
  selectedFilter = 5000;
  firstInResponse: any = [];
  lastInResponse: any = [];
  previousStart: any = [];
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  statusvalue: any;
  tripType = new FormControl();
  defaultTripType: number;
  dayId: any;
  reportData: any;
  get selectedStatus(): string {
    return (this.filter.status) ? this.utils.tripStatusText(this.filter.status) : 'ALL TRIPS';
  }
  constructor(private dbService: DatabaseService, private db: AngularFirestore,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.trips = [];
    this.defaultTripType = this.utils.tripTypes[0];
    this.tripListAPI = new TripListAPI(dbService, (trips) => {
      this.trips = trips;
    });
}
ngOnInit() {
}
filterpage(status: number) {
    this.selectedFilter = status;
}
getreportdata() {
  this.trips = [];
  this.dayId = moment(this.filter.from.selectedDate).format('YYYY-MM-DD');
  return this.db.collection('reports').doc('driverworkinghours').collection(this.dayId).get()
  .subscribe(response => {
    console.log(response);
    for (const item of response.docs) {
      this.trips.push(item.data());
    }
    console.log( this.trips);
      }, error => {
    });
}
applyFilter() {
    this.trips = [];
    this.disableLoad = true;
    this.disableInitial = true;
    this.statusvalue = 'load';
    this.dayId = moment(this.filter.from.selectedDate).format('YYYY-MM-DD');
    const queryFn: QueryFn = (aRef) =>  aRef.orderBy('trip.tripDate', 'desc').where('trip.tripDate', '>=', this.filter.from.selectedDate).where('trip.tripDate', '<=', this.filter.to.selectedDate)
    .where('status', '==', 8);
    return this.db.collection<Trip>('trips', queryFn).
      get()
      .subscribe(response => {
        console.log(response);
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        for (const item of response.docs) {
         const name = item.data().driver.name;
         const phone = item.data().driver.phone;
         const driverid = item.data().driver.id;
         const region = item.data().region;
         const tripType = item.data().tripType;
         const distance = item.data().trip.distance.value;
         const workingminutes = this.utils.formatDatetogetHours(this.utils.dateFromTimestamp(item.data().timestamps.trip_complete_time), this.utils.dateFromTimestamp(item.data().timestamps.trip_start_time));
         console.log(name, phone, driverid, workingminutes, region);
        this.db.collection('reports').doc('driverworkinghours').collection(this.dayId).doc(driverid).set({'name': name,'phone': phone,'driverid': driverid,'region': region,'tripType': tripType,
        'distance': firebase.firestore.FieldValue.increment(Number(distance)),
        'workingminutes': firebase.firestore.FieldValue.increment(Number(workingminutes)) }, {merge: true });
         this.trips.push(item.data());
        }
        this.completedfunctionscall();
        if (this.selectedFilter > this.trips.length) {
          this.disableInitial = false;
        }
        this.previousStart = [];
        this.paginationCount = 0;
        this.disableLoad = false;
        this.disableNext = false;
        this.disablePrevious = false;
        this.pushPreviousStartAt(this.firstInResponse);
      }, error => {
      });
  }
  completedfunctionscall() {
    console.log('completed', this.trips.length);
  }
  applyFilterNext() {
    this.trips = [];
    this.disableNext = true;
    const queryFn: QueryFn = (aRef) => aRef.orderBy('trip.tripDate', 'desc').where('trip.tripDate', '>=', this.filter.from.selectedDate).where('trip.tripDate', '<=', this.filter.to.selectedDate)
    .where('region', '==', this.region.value).where('status', '==', 8).limit(this.selectedFilter).startAfter(this.lastInResponse);
    return this.db.collection<Trip>('trips', queryFn).
    get().subscribe(response => {
        if (!response.docs.length) {
          this.disableNext = false;
          this.disableInitial = false;
          return;
        }
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        this.trips = [];
        for (const item of response.docs) {
          const name = item.data().driver.name;
          const phone = item.data().driver.phone;
          const driverid = item.data().driver.id;
          const region = item.data().region;
          const workingminutes = this.utils.formatDatetogetHours(this.utils.dateFromTimestamp(item.data().timestamps.trip_complete_time), this.utils.dateFromTimestamp(item.data().timestamps.trip_start_time));
          console.log(name, phone, driverid, workingminutes);
          this.db.collection('reports').doc('driverworkinghours').collection(this.dayId).doc(driverid).set({'name': name,'phone': phone,'driverid': driverid,'region': region,
         'workingminutes': firebase.firestore.FieldValue.increment(Number(workingminutes)) }, {merge: true });
          this.trips.push(item.data());
      }
        this.completedfunctionscall();
        if (this.selectedFilter > this.trips.length) {
          this.disableInitial = false;
        }
        this.paginationCount++;
        this.pushPreviousStartAt(this.firstInResponse);
        this.disableNext = false;
      }, error => {
        this.disableNext = false;
      });
  }
pushPreviousStartAt(previousFirstDoc) {
    this.previousStart.push(previousFirstDoc);
  }
 popPreviousStartAt(previousFirstDoc) {
    this.previousStart.forEach(element => {
      if (previousFirstDoc.data().id === element.data().id) {
        element = null;
      }
    });
  }
  getPrviousStartAt() {
    if (this.previousStart.length > (this.paginationCount + 1)) {
      this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
    }
    return this.previousStart[this.paginationCount - 1];
  }
export() {
    const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
    exporter.exportTripManagement(this.trips as Trip[]);
}
openTripInfoModal(trip: Trip) {
      const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true});
      modalRef.componentInstance.trip = trip;
}
isNull(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return ''; }
    return path;
}
reset() {
    this.drivername.reset();
    this.customername.reset();
    this.driverphone.reset();
    this.customerphone.reset();
    this.filter.reset();
    this.promocode.reset();
    this.tripid.reset();
  }
}
class TripHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
    this.status = status;
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
