import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import {  AlertModel, SupportedRegions, UserClaim, UserClaimAdd } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-user-claim',
  templateUrl: './edit-user-claim.component.html'
})
export class EditUserClaimsComponent implements OnInit {
  userclaimForm: FormGroup;
  userClaim: UserClaimAdd;
  alert: AlertModel;
  saving: boolean;
  loadedData: any = [];
  updatedat: any;
  docID: any;
  dashboardedit: any;
  dashboardexport: any;
  dashboardsearch: any;
  claimName: any;
  isActive: any;
  description: any;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private fc: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.dashboardedit = this.loadedData.name;
    this.docID = this.loadedData.id;
    this.updatedat = this.loadedData.updated_at;
    this.isActive = this.loadedData.status;
    this.description = this.loadedData.description;
    this.createUserClaimForm();
  }

  private createUserClaimForm() {
    this.userclaimForm = this.fc.group({
      dashboard: '',
        dashboard_export: '',
        dashboard_search: '',
        isActive: false,
        description: ''
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveUserClaim(): boolean {
    return this.userclaimForm.valid && this.userclaimForm.dirty;
  }

  createUserClaim() {
    this.userClaim = {
      id: this.docID,
      status: this.userclaimForm.controls.isActive.value,
      name: this.userclaimForm.controls.dashboard.value,
      updated_at: new Date(),
      description: this.userclaimForm.controls.description.value
    };
    this.userclaimForm.disable();
    this.saving = true;
    this.dbService.editUserClaim(this.userClaim, this.docID)
      .then(() => {
        this.showAlert('success', 'User Claim  created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the user claim - ' + e.message);
        this.userclaimForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
