import { Component, OnInit } from '@angular/core';
import { Trip, TripStatus, TripType, UpdateTripStatusRequest } from 'src/app/interfaces/trip-interfaces';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { UpdateTripStatusAPI, TripListener } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { TripStopsComponent } from 'src/app/modals/trip-stops/trip-stops.component';
import { AssignDriverComponent } from 'src/app/modals/assign-driver/assign-driver.component';


@Component({
  selector: 'app-trip-status',
  templateUrl: './trip-status.component.html',
  styleUrls: ['./trip-status.component.css']
})
export class TripStatusComponent implements OnInit {

  trip: Trip; // Currently viewing trip details
  tripStatusForm: FormGroup; // Used to set status of the trip
  alert: AlertModel; // Show errors
  reason = null;
  enableSendReceiptBtn: boolean = true;
  enableUpdateOrderTypeBtn: boolean = true;
  enableSearchNearestBikerBtn: boolean = true;
  enableConvertBtn: boolean = true;
  enableCloseModal: boolean = true;
  requestingStatus = [0,1,13];
  // API
  updateTripStatusAPI: UpdateTripStatusAPI;
  tripListener: TripListener;

  // Data
  tripStatuses: TripStatus[] = [
    TripStatus.tripCancelledByDriver,
    TripStatus.tripCancelledByRider,
    TripStatus.tripCompleted,
    TripStatus.tripAdminCancelled
  ];

  // Custom Getters
  get enableUpdateBtn(): boolean {
    return this.tripStatusForm.valid && this.tripStatusForm.dirty;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public utils: UtilsService,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private apiService: ApiService,
    public permissions: PermissionService,
    private modalService: NgbModal) {

    this.updateTripStatusAPI = new UpdateTripStatusAPI(apiService, (success, message) => {
      this.showAlert(success, message);
      this.tripStatusForm.enable();
    });

    this.tripListener = new TripListener(dbService, (trip) => { this.trip = trip; });
  }

  ngOnInit() {
    this.createTripStatusForm();
    this.tripListener.listenForTrip(this.trip.tripId);
    this.validateCompleted();
  }

  showDropoff() {
    return this.trip.trip.tripType === 2 &&  this.trip.trip.stops.length > 0  ? this.trip.trip.stops[this.trip.trip.stops.length-1].stop : this.trip.trip.destination.title
  }

  private validateCompleted() {
    if(this.trip.status === 8) {
      this.tripStatuses = [
        TripStatus.tripCancelledByDriver,
        TripStatus.tripCancelledByRider,
        TripStatus.tripAdminCancelled
      ];
    }
  }

  isHappymove(tripType) {
    if(TripType.hmdelivery === tripType){
      return true;
    } else {
      return false;
    }
  }

  getVehicleName(status: string) {
    return OnboardingUtils.getVehiclyName(status);
  }

  private createTripStatusForm() {
    this.tripStatusForm = this.fb.group({
      tripId: [this.trip.tripId, Validators.required],
      reason: [null, [Validators.required, Validators.minLength(25), Validators.maxLength(200)]],
      status: [-1, [Validators.required, Validators.min(5), Validators.max(11)]]
    }, { validators: this.formValidator.bind(this) });
  }
  private formValidator(form: FormGroup): {[key: string]: boolean} {
    const selectedStatus = form.controls.status.value;
    if (this.tripStatuses.indexOf(selectedStatus) !== undefined) {
      return null;
    } else {
      return { ['invalidTripStatus']: true};
    }
  }

  private showAlert(success: boolean, message: string) {
    const type = success ? 'success' : 'danger';
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
    if(success) {
      setTimeout(() => { this.activeModal.dismiss('success');} , 2000);
    }
  }

  // MARK: Actions
  updateStatusBtnPressed() {
    const payload = this.tripStatusForm.value as UpdateTripStatusRequest;
    this.tripStatusForm.disable();
    this.updateTripStatusAPI.updateTripStatus(payload);
  }

  sendReceiptToCustomer() {
    const payload = this.tripStatusForm.value as UpdateTripStatusRequest;
    this.enableSendReceiptBtn = false;
    this.updateTripStatusAPI.sendReceiptToCustomer(payload.tripId);
  }

  updateOrderType() {
    const payload = this.tripStatusForm.value as UpdateTripStatusRequest;
    this.enableUpdateOrderTypeBtn = false;
    this.enableConvertBtn = false;
    this.enableCloseModal = false;
    this.updateTripStatusAPI.updateOrderType(payload.tripId);
  }

  openTripInfoModal(trip: Trip) {
     const modalRef = this.modalService.open(TripStopsComponent, {scrollable: true});
       modalRef.componentInstance.trip = trip;
  }

  assignToDriverModal(trip: Trip) {
    const modalRef = this.modalService.open(AssignDriverComponent, {size: 'sm', scrollable: true});
       modalRef.componentInstance.trip = trip;
  }
  
}
