import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  AlertModel } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
@Component({
  selector: 'app-driver-wallet-history',
  templateUrl: './driver-wallet-history.component.html',
  styleUrls: ['./driver-wallet-history.component.css']
})
export class DriverWalletHistoryComponent implements OnInit {
  walletHistory: WalletHistory[];
  filter = new WalletHistoryFilter();
  gcashNo = new FormControl('');
  walletId = new FormControl('');
  alert: AlertModel;
  walletHistoryListener: DriverWalletHistoryListener;
  wallethistoryLoading: boolean;
  constructor(private dbService: DatabaseService, private utils: UtilsService, public permissions: PermissionService) {
    this.wallethistoryLoading = false;
    this.walletHistory = [];
    this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
      if (success && history) {
          this.walletHistory = history;
          this.wallethistoryLoading = false;
      } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
    });
  }
  ngOnInit() {
  }
  getallWalletHistory() {
    this.wallethistoryLoading = true;
    this.walletHistory = [];
    this.walletHistoryListener.getallWalletHistorySearchByDriver(this.filter.from.selectedDate,
      this.filter.to.selectedDate, this.gcashNo.value, this.walletId.value);
  }
  export() {
    const filename = () => 'Biker Wallet History Summary ' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.DriverWalletHistory());
    exporter.exportDriverWalletHistory(this.walletHistory as WalletHistory[]);
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
class WalletHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
