import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { BaseAPI, APIState } from './base.api';
import { ApiService } from '../services/api.service';


export class NewsArticlesAPI extends BaseAPI {
    private handler: ([any]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([any]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getNewsArticleSearch(id: string, newsAuthor: string, newsType: string, newsUserType: string, 
                         newsStatus: string, newsRegion?: string, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
       
        this.dbService.getNewsArticleSearch(id, newsAuthor, newsType, newsUserType, newsStatus, newsRegion, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
}