import { Injectable } from '@angular/core';
import { Trip, TripStatus, TripType, TripTypeHM } from '../interfaces/trip-interfaces';
import { PromoDiscountType } from '../interfaces/promo.interface';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { WalletUpdateType, WalletHistoryType, CustomerWalletType } from '../interfaces/wallet.interfaces';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  get allTripStatuses(): number[] {
    return [
      TripStatus.rideRequested,
      TripStatus.searchingForDrivers,
      TripStatus.driverAssigned,
      TripStatus.driverArrived,
      TripStatus.tripStarted,
      TripStatus.tripCompleted,
      TripStatus.tripCancelledByRider,
      TripStatus.tripCancelledEarlyByRider,
      TripStatus.tripCancelledByDriver,
      TripStatus.tripAbortNoDriversFound,
      TripStatus.tripAdminCancelled,
      TripStatus.tripAbortServerError,
      TripStatus.requestingForBiker,
    ];
  }
  get tripStatusHours(): number[] {
    return [
      TripStatus.driverAssigned,
      TripStatus.driverArrived,
      TripStatus.tripStarted,
    ];
  }
  get tripTypes(): number[] {
    return [
      TripType.mcTaxi,
      TripType.delivery,
      TripType.pabili,
      TripType.tricycle,
      TripType.hmdelivery
    ];
  }
  get tripTypesHM(): string[] {
    return [
      TripTypeHM.scheduled,
      TripTypeHM.immediate
    ];
  }
  get tripTypesManagement(): number[] {
    return [
      TripType.all,
      TripType.mcTaxi,
      TripType.delivery,
      TripType.pabili,
      TripType.tricycle,
      TripType.hmdelivery
    ];
  }

  get status(): boolean[] {
    return [
      true,
      false
    ];
  }

  get promoDiscountTypes(): number[] {
    return [
      PromoDiscountType.FIXED,
      PromoDiscountType.PERCENTAGE,
    ];
  }

  get mainStatusForNewDrivers(): { value: number, name: string }[] {
    return [
      { value: 1, name: 'Activated' },
      { value: 2, name: 'Conditionally Activated' },
      { value: 4, name: 'Scheduled' },
      { value: 5, name: 'Pending for Gear Releasing' },
      { value: 6, name: 'Pending for Activation' },
      { value: 7, name: 'Rejected' },
      { value: 8, name: 'Pre Releasing Done' },
      { value: 3, name: 'Deactivated' }
    ];
  }

  get mainStatusForOnboardedDrivers(): { value: number, name: string }[] {
    return [
      { value: 1, name: 'Activated' },
      { value: 2, name: 'Conditionally Activated' },
      { value: 6, name: 'Pending for Activation' },
      { value: 3, name: 'Deactivated' }
    ];
  }

  get subStatusForNewDrivers(): { [key: number]: { value: number, name: string }[] } {
    return {
      1: [
        { value: 11, name: 'Active' },
        { value: 12, name: 'Probation' }
      ],
      2: [
        { value: 21, name: 'Active' },
        { value: 22, name: 'Probation' }
      ],
      4: [
        { value: 41, name: 'Unattended' },
        { value: 42, name: 'Attended' },
        { value: 43, name: 'Rejected' }
      ],
      5: [
        { value: 51, name: ' ' }
      ],
      6: [
        { value: 61, name: ' ' }
      ],
      7: [
        { value: 71, name: ' ' }
      ],
      3: [
        { value: 31, name: 'Suspended' },
        { value: 32, name: 'Banned' },
        { value: 33, name: 'Pulled out' }
      ]
    };
  }

  get subStatusForOnboardedDrivers(): { [key: number]: { value: number, name: string }[] } {
    return {
      1: [
        { value: 11, name: 'Active' },
        { value: 12, name: 'Probation' }
      ],
      2: [
        { value: 21, name: 'Active' },
        { value: 22, name: 'Probation' }
      ],
      4: [
        { value: 41, name: 'Unattended' },
        { value: 42, name: 'Attended' },
        { value: 43, name: 'Rejected' }
      ],
      3: [
        { value: 31, name: 'Suspended' },
        { value: 32, name: 'Banned' },
        { value: 33, name: 'Pulled out' }
      ],
      6: [
        { value: 61, name: ' ' }
      ],
    };
  }


  constructor() { }
  checkRegionReleaseDatePrevious(startDate: Date): boolean {
    const appRegionReleaseDate = new Date('2020-03-02');
    const tripStartDate = new Date(startDate);
    return (tripStartDate > appRegionReleaseDate);
  }
  checkTripTypeReleaseDatePrevious(startDate: Date): boolean {
    const appRegionReleaseDate = new Date('2020-03-19');
    const tripStartDate = new Date(startDate);
    return (tripStartDate > appRegionReleaseDate);
  }
  checkDriverWrokingHoursPrevious(startDate: string): boolean {
    const appRegionReleaseDate = new Date('2020-05-27');
    const tripStartDate = new Date(startDate);
    return (tripStartDate > appRegionReleaseDate);
  }
  optional(val?: any, defaultVal?: string) {
    const defaultValue = (defaultVal) ? defaultVal : 'Not Specified';
    return (val !== null && val !== undefined) ? val : defaultValue;
  }
  optionalNumber(val?: number): string {
    return (val == null || val === undefined) ? 'N/A' : String(val.toFixed(2));
  }

  getTotalBikerEarnings(trip: Trip): string {
    if (trip && trip.receipt) {
      return (trip.receipt.tripFare - trip.receipt.commissionAmt) + '';
    }
    return '';
  }

  getDriverRating(trip: Trip): string {
    if (trip && trip.driver && trip.driver.ratingByRider) {
      return trip.driver.ratingByRider.rating + '';
    }
    return '';
  }

  getRidersComments(trip: Trip): string {
    if (trip && trip.driver && trip.driver.ratingByRider && trip.driver.ratingByRider.comments) {
      return trip.driver.ratingByRider.comments;
    }
    return '';
  }

  getReceipt(trip: Trip): any {
    if (trip && trip.receipt) { return trip.receipt; }
    return {};
  }

  getDriver(trip: Trip): any {
    if (trip && trip.driver) { return trip.driver; }
    return {};
  }


  getRating(trip: Trip): any {
    if (trip && trip.driver && trip.driver.ratingByRider) { return trip.driver.ratingByRider; }
    return {};
  }
  dateFromTimestamp(ts: firebase.firestore.Timestamp) {
    if (!ts) { return null; }
    return ts.toDate();
  }
  formatDatetogetHours(tripCompletionDate: Date, tripstartDate: Date) {
    if (!tripCompletionDate && !tripstartDate) { return ''; }
    const tripStartTime = moment(tripstartDate, 'HH:mm:ss a');
    const tripEndTime = moment(tripCompletionDate, 'HH:mm:ss a');
    const tripDuration = moment.duration(tripEndTime.diff(tripStartTime));
    const hours = (tripDuration.asHours()).toFixed(1);
    const tripMintes = moment.duration(tripDuration);
    const tripDurationMinutes = tripMintes.minutes();
    return (tripDurationMinutes);
  }
  formatDistanceToSpeed(tripCompletionDate: Date, tripstartDate: Date, distanceMtr: number) {
    if (!tripCompletionDate && !tripstartDate) { return ''; }
    const tripStartTime = moment(tripstartDate, 'HH:mm:ss a');
    const tripEndTime = moment(tripCompletionDate, 'HH:mm:ss a');
    const tripDuration = moment.duration(tripEndTime.diff(tripStartTime));
    const tripMintes = moment.duration(tripDuration);
    const tripDurationMinutes = tripMintes.minutes();
    const tripDurationHours = (tripDurationMinutes / 60);
    const tripDistanceKM = (distanceMtr / 1000);
    const tripSpeed = (tripDistanceKM / tripDurationHours).toFixed(2);
    return (tripDurationMinutes === 0 ? 'Time cannot be 0' : tripSpeed + 'km/hour');
  }
  driverWorkingHours(workingMints: number) {
    if ( workingMints < 60) { return '0' + ' '  + 'hr' + ' ' + Number(workingMints) + ' ' + 'min'; }
    const workinghours = (workingMints / 60).toFixed(2);
    const hrs = parseInt((workinghours));
    const min = Math.round((Number(workinghours) - hrs) * 60);
    return (workinghours !== undefined ? hrs + ' ' + 'hr' +  ' ' + Number(min) + ' ' + 'min'  : '0');
  }
  driverDistance(distanceMeter: number) {
    const tripDistanceKM = (distanceMeter / 1000).toFixed(2);
    return  tripDistanceKM + ' ' + 'km/hour';
  }
  formatDate(date: Date) {
    if (!date) { return ''; }
    return moment(date).format('MMM DD, YYYY h:mm:a');
  }

  // Converts the date string to a new format
  customFormatDate(date: string, format: string = 'YYYY-MM-DD') {
    if (!date) { return ''; }
    return moment(date).format(format);
  }

  formatTripStatus(trip: Trip) {
    return this.tripStatusText(trip.status);
  }

  formatTripTypeHM(trip: Trip) {
    return this.tripTypeHMText(trip.trip.deliverySchedule.type);
  }

  covertDateFormat(transactionDate: string) {
      if (transactionDate === undefined) { return '--'; }
      return momenttz.tz(transactionDate, 'Asia/Manila').add(8,'hours').format('MMM DD, YYYY hh:mm:a')
  }

  covertDateWithTime(createdDate: string) {
      if (createdDate === undefined) { return '--'; }
      return moment(createdDate).format('YYYY-MM-DD hh:mm a')
  }

  isActiveText(status: boolean) {
    switch (status) {
      case true: return 'Active';
      case false: return 'Inactive';
      default: return '';
    }
  }

  convertStatusBoolean(status: string) {
    switch (status) {
      case 'true': return true;
      case 'false': return false;
      default: return '';
    }
  }

  tripStatusText(status: number) {
    switch (status) {
      case TripStatus.rideRequested: return 'REQUESTED';
      case TripStatus.searchingForDrivers: return 'SEARCHING BIKERS';
      case TripStatus.driverAssigned: return 'BIKER ASSIGNED';
      case TripStatus.driverArrived: return 'BIKER ARRIVED';
      case TripStatus.tripStarted: return 'TRIP IN PROGRESS';
      case TripStatus.tripCompleted: return 'TRIP COMPLETED';
      case TripStatus.tripCancelledByRider: return 'CUSTOMER CANCELLED';
      case TripStatus.tripCancelledEarlyByRider: return 'CUSTOMER CANCELLED';
      case TripStatus.tripCancelledByDriver: return 'BIKER CANCELLED';
      case TripStatus.tripAbortNoDriversFound: return 'NO BIKERS FOUND';
      case TripStatus.tripAbortServerError: return 'SERVER ERROR';
      case TripStatus.tripAdminCancelled: return 'ADMIN CANCELLED';
      case TripStatus.requestingForBiker: return 'REQUESTING';
      default: return '';
    }
  }

  tripTypeHMText(status: string) {
    switch (status) {
      case TripTypeHM.scheduled: return 'Scheduled Booking';
      case TripTypeHM.immediate: return 'Immediate';
      default: return '';
    }
  }

  classForTripTypeHM(status: string) {
    switch (status) {
      case TripTypeHM.scheduled: return { 'btn-primary': true };
      case TripTypeHM.immediate: return { 'btn-warning': true };
      default: return '';
    }
  }

  classForTripStatus(trip: Trip) {
    switch (trip.status) {
      case TripStatus.rideRequested: return { 'btn-primary': true };
      case TripStatus.searchingForDrivers: return { 'btn-primary': true };
      case TripStatus.driverAssigned: return { 'btn-danger': true };
      case TripStatus.driverArrived: return { 'btn-primary': true };
      case TripStatus.tripStarted: return { 'btn-warning': true };
      case TripStatus.tripCompleted: return { 'btn-success': true };
      case TripStatus.tripCancelledByRider: return { 'btn-danger': true };
      case TripStatus.tripCancelledEarlyByRider: return { 'btn-danger': true };
      case TripStatus.tripCancelledByDriver: return { 'btn-danger': true };
      case TripStatus.tripAbortNoDriversFound: return { 'btn-secondary': true };
      case TripStatus.tripAbortServerError: return { 'btn-dark': true };
      case TripStatus.tripAdminCancelled: return { 'btn-danger': true };
      case TripStatus.requestingForBiker: return { 'btn-primary': true };
      default: return '';
    }
  }

  updateTypeString(type: number): string {
    switch (type) {
      case WalletUpdateType.CREDIT: return 'CREDIT';
      case WalletUpdateType.DEBIT: return 'DEBIT';
      default: return 'UNKNOWN';
    }
  }

  historyTypeString(type: number): string {
    switch (type) {
      case WalletHistoryType.TOPUP_APPROVED: return 'TOPUP APPROVED';
      case WalletHistoryType.CASHOUT_REQUESTED: return 'CASHOUT REQUESTED';
      case WalletHistoryType.CASHOUT_REJECTED: return 'CASHOUT REJECTED';
      case WalletHistoryType.TRIP_COMMISSION_DEBITED: return 'COMMISSION';
      case WalletHistoryType.TRIP_PROMO_CODE_CREDITS: return 'PROMO CODE';
      case WalletHistoryType.ADMIN_WALLET_ADJUSTMENTS: return 'BY ADMIN';
      case WalletHistoryType.ADMIN_EMI_GEAR: return 'EMI BY ADMIN';
      case WalletHistoryType.TRANSACTION_FEES : return 'TRANSACTION FEES';
      case WalletHistoryType.PAYMAYA_TOPUP: return 'PAYMAYA TOPUP';
      case WalletHistoryType.METROBANK_TOPUP: return 'METROBANK TOPUP';
      case WalletHistoryType.TRIP_PAYMENT_CUSTOMER: return 'TRIP PAYMENT CUSTOMER';
      default: return 'UNKNOWN';
    }
  }
  historyCustomerTypeString(type: number): string {
    switch (type) {
      case CustomerWalletType.PAYMAYA_TOPUP: return 'TOP-UP BY PAYMAYA';
      case CustomerWalletType.TRIP_PAYMENT_DRIVER: return 'TRIP PAYMENT FOR BIKER';
      case CustomerWalletType.ADMIN_WALLET_ADJUSTMENTS: return 'ADMIN ADJUSTMENTS';
      case CustomerWalletType.TRIP_PAYMENT_CUSTOMER: return 'TRIP PAYMENT CUSTOMER';
      case CustomerWalletType.REFERRAL_REWARD: return 'REFERRAL REWARD';
      case CustomerWalletType.REFER_AND_EARN: return 'REFER AND EARN';
      default: return 'UNKNOWN';
    }
  }
  tripHoursStatus(status: number) {
    switch (status) {
      case TripStatus.driverAssigned: return 'BIKER ASSIGNED';
      case TripStatus.driverArrived: return 'BIKER ARRIVED';
      case TripStatus.tripStarted: return 'TRIP IN PROGRESS';
      default: return '';
    }
  }
  tripType(type: number) {
    switch (type) {
      case TripType.mcTaxi: return 'MC Taxi';
      case TripType.delivery: return 'Delivery';
      case TripType.pabili : return 'Pabili';
      case TripType.tricycle: return 'Taxicle';
      case TripType.hmdelivery: return 'Happy Move';
      default: return 'MC Taxi';
    }
  }
  tripTypeManagement(type: number) {
    switch (type) {
      case TripType.all: return 'All';
      case TripType.mcTaxi: return 'MC Taxi';
      case TripType.delivery: return 'Delivery';
      case TripType.pabili : return 'Pabili';
      case TripType.tricycle: return 'Taxicle';
      case TripType.hmdelivery: return 'Happy Move';
      default: return 'All';
    }
  }
  getRoles(role: string) {
    switch (role) {
      case 'super_admin': return 'SUPER ADMIN';
      case 'helpdesk_mnl': return 'HELPDESK - MNL';
      case 'helpdesk_ceb': return 'HELPDESK - CEB';
      case 'ops_senior': return 'OPS - SENIOR';
      case 'ops_qa': return 'OPS - QA';
      case 'onboarding_edit': return 'ONBOARDING - EDIT';
      case 'onboarding_upload': return 'ONBOARDING - UPLOAD';
      case 'admin_senior': return 'ADMIN - SENIOR';
      case 'bulk_topup': return 'BULK TOPUP';
      case 'topup_manual': return 'TOPUP MANUAL';
      case 'analytics_analytics': return 'ANALYTICS';
      default: return '';
    }
  }
  newsArticles(type: string) {
    switch (type) {
      case 'article': return 'Article';
      case 'promo': return 'Promo';
      case 'web': return 'Web';
      case 'internal': return 'Internal';
      case 'customerApp': return 'Customer App';
      case 'driverApp': return 'Driver App';
      default: return '';
    }
  }


}
