import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { GCashImport } from 'src/app/interfaces/interfaces';
import { DriversListAPI, DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import * as fs from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-import-deactivate',
  templateUrl: './driver-deactivation.component.html'
})
export class DriverDeactivationComponent implements OnInit {
  public arrFinalData = [];
  public arrFinalStatusWithData = [];
  importGCashForm: FormGroup;
  loopCount = 0;
  data: any;
  excelHeader: any;
  uploadedFileName: any;
  driverGcash: any = [];
  isDisabledImport = false;
  isConfirm = false;
  isConfirmDisable = true;
  isConfirmExport = false;
  isLoad = false;
  tempDriverUid: any;
  processStatus: any;
  processStatusText: any;
  driverListapi: DriversListAPI;
  personalInfo: DriverProfile[];
  driverProfileUpdateAPI: DriverProfileUpdateAPI;
  gcashNo: string;
  gCashImport: GCashImport;
  tempValue: any;
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService, private fb: FormBuilder) {
  }
  ngOnInit() {
    this.createImportGCashForm();
  }
  private createImportGCashForm() {
    this.importGCashForm = this.fb.group({
      importgcash: [null, Validators.required]
    });
  }

  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.uploadedFileName = target.files[0].name;
    if (target.files.length === 1 && (target.files[0].type === '.xlsx' ||
      target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      target.files[0].type === 'application/vnd.ms-excel')) {
      this.isDisabledImport = true;
      this.uploadedFileName = target.files[0].name;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
    }
  }
  importExcelData() {
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];
    const jsonObjectKeys = ['phone', 'remarks'];
    if (this.excelHeader.length === 2) {
      if (this.data.length <= 100) {
        for (let i = 1; i <= this.data.length - 1; i++) {
          const excelData = {};
          for (let j = 0; j < this.excelHeader.length; j++) {
            excelData[jsonObjectKeys[j]] = this.data[i][j];
          }
          this.isConfirm = true;
          this.arrFinalData.push(excelData);
          this.driverGcash = this.arrFinalData;
        }
      } else {
      }
    } else {
    }
  }

  async confirmData() {
    this.isConfirmDisable = false;
    this.isLoad = true;
    this.apiService.bulkDriverDeactivate(this.arrFinalData).then(response => {
      this.isLoad = false;
      this.isConfirmExport = true;
      this.tempValue = response;
      this.arrFinalStatusWithData = this.tempValue.data;
    })
      .catch(response => {
        console.log(response);
      }
      );
  }

  getTodayDate() {
    const d = new Date();
    return d.toISOString().split('T')[0];
  }

  downloadResultAsXLSX() {
    this.setExportExcelFileData(this.arrFinalStatusWithData);
  }
  setExportExcelFileData(excelExportData) {
    const excelHeader = ['Phone', 'Remarks', 'Process Status', 'Descriptions'];
    const exportExcelHeader = ['phone', 'remarks', 'status', 'reason'];
    const exportExcelData = [];
    let exportExcelHeaderData = {};
    let i = 0;
    let columnsKeyNames = [];
    for (i = 0; i < excelExportData.length; i++) {
      const singleRow = excelExportData[i];
      columnsKeyNames = Object.keys(singleRow);
      exportExcelHeaderData = {};
      for (let j = 0; j < columnsKeyNames.length; j++) {
        exportExcelHeaderData[excelHeader[j]] = singleRow[exportExcelHeader[j]];
      }
      exportExcelData.push(exportExcelHeaderData);
    }
    this.exportAsExcelFile(exportExcelData, 'DriverDeactivate');
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    fs.saveAs(data, fileName + '' + new Date().getTime() + EXCEL_EXTENSION);
  }

}