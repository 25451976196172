import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import * as moment from 'moment';
import { UtilsService } from 'src/app/services/utils.service';
import { DriversListAPI } from 'src/app/api/driverList.api';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  api: DriversListAPI;
  driverProfileManila = [];
  driverProileCebu  = [];
  metroManilaStats: any;
  metroCebuStats: any;
  manilaPercentMcTaxi: any;
  manilaPercentDelivery: any;
  manilaPercentPabili: any;
  manilaPercentTricycle: any;
  manilaPercentHmdelivery: any;
  cebuPercentMcTaxi: any;
  cebuPercentDelivery: any;
  cebuPercentPabili: any;
  cebuPercentTricycle: any;
  cebuPercentHmdelivery: any;
  participatedManila = [];
  participatedCebu = [];
  constructor(private dbService: DatabaseService, public utils: UtilsService) {
    this.metroCebuStats = 0;
    this.metroManilaStats = 0;
    const todayDate = new Date();
    const dayId = moment(todayDate).format('YYYY-MM-DD');
    this.dbService.allTimeDailyStats(dayId, 'Metro Manila').valueChanges().subscribe((data) => {
       if ( data !== undefined) {
        this.metroManilaStats = data;
        this.manilaPercentMcTaxi = this.optionalnumber(this.metroManilaStats.numTrips) / 100;
        this.manilaPercentDelivery = this.optionalnumber(this.metroManilaStats.numTrips_delivery) / 100;
        this.manilaPercentPabili = this.optionalnumber(this.metroManilaStats.numTrips_pabili) / 100;
        this.manilaPercentTricycle = this.optionalnumber(this.metroManilaStats.numTrips_tricycle) / 100;
        this.manilaPercentHmdelivery = this.optionalnumber(this.metroManilaStats.numTrips_hmdelivery) / 100;
      } else {this.metroManilaStats = 0 ; }
      }, err => { console.log(err); } );
    this.dbService.allTimeDailyStats(dayId, 'Metro Cebu').valueChanges().subscribe((data) => {
      if ( data !== undefined) {
        this.metroCebuStats = data;
        this.cebuPercentMcTaxi = this.optionalnumber(this.metroCebuStats.numTrips) / 100;
        this.cebuPercentDelivery =  this.optionalnumber(this.metroCebuStats.numTrips_delivery) / 100;
        this.cebuPercentPabili =  this.optionalnumber(this.metroCebuStats.numTrips_pabili) / 100;
        this. cebuPercentTricycle =  this.optionalnumber(this.metroCebuStats.numTrips_tricycle) / 100;
        this. cebuPercentHmdelivery =  this.optionalnumber(this.metroCebuStats.numTrips_hmdelivery) / 100;
      } else {this.metroCebuStats = 0; }
      }, err => { console.log(err); } );
    this.dbService.allTimeDailyActivateDriver(dayId).valueChanges().subscribe((data) => {
      if ( data !== undefined) {
      data = data.filter(val => val.numTripsCompleted >= 1 || val.numTripsCompleted_delivery >= 1 || val.numTripsCompleted_pabili >= 1 ||
        val.numTripsCompleted_tricycle >= 1);
      if ( data !== undefined) {
        this.participatedManila = data.filter(val => val.region === 'Metro Manila');
        this.participatedCebu = data.filter(val => val.region === 'Metro Cebu');
      } else { } }
    }, err => { console.log(err); } );
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.driverProfileManila = drivers.filter(val => val.personal.region === 'Metro Manila');
      this.driverProileCebu = drivers.filter(val => val.personal.region === 'Metro Cebu');
    });
  }
  ngOnInit() {
    this.api.loadActivatedDriverProfiles(1, 11);
  }
  optional(val: number, round: boolean = false, defaultVal: string = '0') {
    return (val !== undefined) ? (round ? val.toFixed(2) : val) : defaultVal;
  }
  optionalNumber(val?: number, defaultVal?: any): string | null {
    defaultVal = val ;
    return (val == null || val === undefined || defaultVal === '0' ) ? '' : String(val.toFixed(2));
  }
  optionalPercent(val1: number, val2: number) {
    return (val1 !== undefined && val2 !== 0) ? (val1 / val2).toFixed(2) : '0';
  }
  optionalnumber(val?: number, ) {
    return (val == null || val === undefined ) ? 0 : Number(val);
  }
  optionalAdding(val1: number, val2: number) {
    val1 = (+val1 || 0) + 0;
    val2 = (+val2 || 0) + 0;
    return  Number(val1 + val2);
  }
}
