import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFilesJrhmComponent } from '../upload-files/upload-files-jrhm.component';
import { UploadTask } from 'src/app/classes/uploadtask';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from './onboarding.utils';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from 'src/app/services/api.service';
import { SupportedRegions } from 'src/app/interfaces/interfaces';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
@Component({
  selector: 'app-driver-onboarding-jrhm',
  templateUrl: './driver-onboarding-jrhm.component.html',
  styleUrls: ['./driver-onboarding.component.css']
})
export class DriverOnboardingJrhmComponent implements OnInit {
  vehiclePermissionForm: FormGroup;
  personalInfoForm: FormGroup;
  driverIdentificationForm: FormGroup;
  vehicleDetailsForm: FormGroup;
  otherInfoForm: FormGroup;
  PermissionForm: FormGroup;
  submitted = false;
  emailExist = false;
  phoneExist = false;
  appReferenceNumber: string;
  uploadReferences: Map<FormControl, UploadTask>;
  ngOtherInfoFormReferredBy: any;
  isVisibleFinalErrorMsg: boolean;
  finalErrorMsg: any;
  myFiles = [];
  vehicleType: any;
  defaultVehicle: string = '';
  get showTin(): boolean {
    let val = this.personalInfoForm.controls.hasTin.value;
    val = (val) ? val : '';
    return val.toLocaleLowerCase() === 'yes';
  }
  get showSSS(): boolean {
    let val = this.personalInfoForm.controls.hasSSS.value;
    val = (val) ? val : '';
    return val.toLocaleLowerCase() === 'yes';
  }
  onboardingRegions = [];
  onboardingCityList = [];
  onboardingCity: SupportedRegions[];
  constructor(private modalService: NgbModal, private dbService: DatabaseService, public permissions: PermissionService,
              private apiService: ApiService, private fb: FormBuilder, private db: AngularFirestore, private storage: AngularFireStorage) {
    this.isVisibleFinalErrorMsg = false;
    this.uploadReferences = new Map();
    this.getRegionCityList();
    this.appReferenceNumber = this.generateApplicationID();
    this.vehicleType = OnboardingUtils.getFranchiseAreaVehicleType();
  }
  ngOnInit() {
    this.createDriverIdentificationForm();
    this.createVehicleDetailsForm();
    this.createOtherInfoForm();
    this.controlsInitialVisibility();
    this.createPersonalInfoForm();
    this.createPermissionForm();
    this.createVehiclePermissionForm();
  }
  private controlsInitialVisibility() {
    this.selectionChanged(this.otherInfoForm.controls.infoSourceOthers, false);
    this.selectionChanged(this.otherInfoForm.controls.whenAttendingTrainingComment, false);
    this.selectionChanged(this.otherInfoForm.controls.currentJobOthers, false);
    this.selectionChanged(this.otherInfoForm.controls.riderGroupOthers, false);
    this.selectionChangedBiker(this.otherInfoForm.controls.referredByOthers, false);
    this.ngOtherInfoFormReferredBy = 'N/A';
  }

  getVehicleName(status: string) {
    return OnboardingUtils.getVehiclyNameWithDescription(status);
  }

  getAreaName(area:string){
    return OnboardingUtils.getAreaName(area);
  }


  private generateApplicationID() {
    const timestamp = Math.round(new Date().getTime() / 1000.0);
    const random = Math.floor(1000000 + Math.random() * 9000000);
    return (timestamp + random) + ' ';
  }
  private resetForms() {
    this.appReferenceNumber = this.generateApplicationID();
    this.uploadReferences.clear();
    this.personalInfoForm.reset();
    this.driverIdentificationForm.reset();
    this.vehicleDetailsForm.reset();
    this.otherInfoForm.reset();
    this.PermissionForm.reset();
  }
  private createPersonalInfoForm() {
    this.personalInfoForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      middlename: [''],
      lastname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      address: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]],
      barangay: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      town: ['', Validators.required],
      region: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['Male', Validators.required],
      birthplace: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      phoneBrand: [''],
      imei: [''],
      imeiPhoto: [null],
      civilStatus: [''],
      hasTin: ['No', Validators.required],
      tin: ['', [Validators.minLength(9), Validators.maxLength(9)]],
      hasSSS: [''],
      sss: [''],
      mFirstname: [''],
      mMiddlename: [''],
      mLastname: [''],
      emergencyContactName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      emergencyContactPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      vehicle_type: ['', [Validators.required]],
    });
    this.personalInfoForm.controls.hasTin.valueChanges.subscribe(change => {
      if (change.toLocaleLowerCase() === 'yes') {
        this.personalInfoForm.controls.tin.setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9)]);
      } else {
        this.personalInfoForm.controls.tin.setValue(null);
        this.personalInfoForm.controls.tin.setValidators(null);
        this.personalInfoForm.controls.tin.markAsPristine();
      }
      this.personalInfoForm.controls.tin.updateValueAndValidity();
    });
    this.personalInfoForm.controls.hasSSS.valueChanges.subscribe(change => {
      if (change.toLocaleLowerCase() === 'yes') {
        this.personalInfoForm.controls.sss.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(12)]);
      } else {
        this.personalInfoForm.controls.sss.setValue(null);
        this.personalInfoForm.controls.sss.setValidators(null);
        this.personalInfoForm.controls.sss.markAsPristine();
      }
      this.personalInfoForm.controls.sss.updateValueAndValidity();
    });
  }
  private createDriverIdentificationForm() {
    this.driverIdentificationForm = this.fb.group({
      nbiClearance: [null, Validators.required],
      policeClearance: [null, Validators.required],
      drivingLicense: [null, [Validators.required]],
      officialReceipt: [null, Validators.required],
      registrationReceipt: [null, Validators.required],
      areYouTheOwner: ['Yes', Validators.required],
      authorizationLetter: this.fb.array([
        this.initCustomQuery()
      ]),
      govtIssuedID1: [null],
      govtIssuedID2: [null]
    });
  }
  initCustomQuery() {
    return this.fb.group({
      fileName: ['']
    });
  }
  private createVehicleDetailsForm() {
    this.vehicleDetailsForm = this.fb.group({
      vehicleBrand: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      vehicleModel: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      modelYear: [null, [Validators.required]],
      pistonDisplacement: [null],
      vehiclePlateNumber: [null, Validators.required],
      vehicleEngineNumber: [null, Validators.required],
      vehicleChasisNumber: [null, Validators.required],
      vehicleORExpiryDate: [null],
      motorCycleType: [''],
      driverLicenseType: ['Professional', Validators.required],
      driverLicenseNumber: [null, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      driverLicenseExpiryDate: [null],
      vehicle_type: [''],
    });
  }
  private createOtherInfoForm() {
    this.otherInfoForm = this.fb.group({
      currentJob: [''],
      currentJobOthers: [null],
      riderGroup: [''],
      riderGroupOthers: [null],
      referredBy: [null],
      referredByOthers: [null],
      gCashNumberReferrer: [null],
      availability: [''],
      whenAttendingTraining: [''],
      whenAttendingTrainingComment: [null],
      infoSource: [''],
      infoSourceOthers: [null],
      legalAccepted: [null]
    });
  }
  private createPermissionForm() {
    this.PermissionForm = this.fb.group({
    driverId: [null],
    phone: [null],
    mctaxi: false,
    delivery: false,
    pabili: false,
    tricycle: false,
    remarks: ''
  });
}

  private createVehiclePermissionForm(){
    this.vehiclePermissionForm = this.fb.group({
        driverId: [null],
        uid: [null],
        motorcycle: true,
        mv_small: false,
        mv_medium: false,
        mv_large: false,
        mpv: false,
        remarks: ['']
      })
  }
  checkemailavailablity(control: AbstractControl) {
    const c = control as FormControl;
    this.apiService.checkEmailavailability(c.value).toPromise()
      .then(response => {
        const isSuccess = 'isSuccess';
        if (response[isSuccess] === false) {
          this.emailExist = true;
          return false;
        } else {
          this.emailExist = false;
        }
      })
      .catch(
      );

    return;
  }
  checkphoneavailablity(control: AbstractControl) {
    const c = control as FormControl;
    this.apiService.checkPhoneavailability(c.value).toPromise()
      .then(response => {
        const isSuccess = 'isSuccess';
        if (response[isSuccess] === false) {
          this.phoneExist = true;
          return false;
        } else {
          this.phoneExist = false;
          return;
        }
      })
      .catch(
      );
  }
  fileNameFormArray(control: AbstractControl, i) {
    const c = control as FormControl;
    return c.value.fileName.name ? c.value.fileName.name : 'Choose File';
  }
  fileName(control: AbstractControl) {
    const c = control as FormControl;
    return c.value ? c.value.name : 'Choose File';
  }
  get f() { return this.personalInfoForm.controls; }

  get f1() { return this.driverIdentificationForm.controls; }

  get f2() { return this.vehicleDetailsForm.controls; }

  get f3() { return this.otherInfoForm.controls; }

  save() {
    this.submitted = true;
    const vehicleOwnerValidator = (form: FormGroup) => {
      const isOwner = form.controls.areYouTheOwner.value === 'Yes';
      if (isOwner) { return true; } else { return form.controls.authorizationLetter.value !== null; }
    };

    if (this.emailExist || this.phoneExist) {
      // tslint:disable-next-line: max-line-length
      // tslint:disable-next-line: prefer-const
      // tslint:disable-next-line: max-line-length
      // const alertmsg = '\'Oops! \n\nAng inyong mobile number o email address ay nakaregister na sa JoyRide. Kung may kailangan i-update sa naisumiteng form, maaaring mag-PM lamang sa JoyRide PH Kasundo Bikers Facebook Page.\n' +
      //   // tslint:disable-next-line: max-line-length
      //   'Kung first time lang ninyo mag-register at nakikita mo ang message na ito, subukan i-refresh ang page at sagutin ulit ang form. Maaari din gamitin ang Incognito Mode sa Google Chrome para sagutin ang form. \n\n';

      // alert(alertmsg);
      return;
    }
    if ((this.personalInfoForm.valid && this.personalInfoForm.dirty) &&
      (this.driverIdentificationForm.valid && this.driverIdentificationForm.dirty &&
        vehicleOwnerValidator(this.driverIdentificationForm)) &&
      (this.vehicleDetailsForm.valid && this.vehicleDetailsForm.dirty) &&
      (this.otherInfoForm.valid && this.otherInfoForm.dirty) &&
      (this.otherInfoForm.controls.legalAccepted.value === true)) {
      this.uploadFiles();
    } else {
      this.isVisibleFinalErrorMsg = true;
      // tslint:disable-next-line: max-line-length
      alert('Oops! \n\nPlease check your registration form and make sure that all red boxes have inputs. \n\n If you are having trouble uploading the photos, copy paste the registration link to a Google Chrome browser. ');
      return;
    }
  }
  canSave() {
    const vehicleOwnerValidator = (form: FormGroup) => {
      const isOwner = form.controls.areYouTheOwner.value === 'Yes';
      if (isOwner) { return true; } else { return form.controls.authorizationLetter.value !== null; }
    };

    return ((this.personalInfoForm.valid && this.personalInfoForm.dirty) &&
      // tslint:disable-next-line: max-line-length
      (this.driverIdentificationForm.valid && this.driverIdentificationForm.dirty && vehicleOwnerValidator(this.driverIdentificationForm)) &&
      (this.vehicleDetailsForm.valid && this.vehicleDetailsForm.dirty) &&
      (this.otherInfoForm.valid && this.otherInfoForm.dirty) &&
      (this.otherInfoForm.controls.legalAccepted.value === true));
  }

  private isDirty() {
    return this.personalInfoForm.dirty ||
      this.driverIdentificationForm.dirty ||
      this.vehicleDetailsForm.dirty ||
      this.otherInfoForm.dirty;
  }

  changeVehicle(){
    this.vehiclePermissionForm.value.motorcycle = this.defaultVehicle === 'motorcycle' ? true : false;
    this.vehiclePermissionForm.value.mv_small = this.defaultVehicle === 'mv_small' ? true : false;
    this.vehiclePermissionForm.value.mv_medium = this.defaultVehicle === 'mv_medium' ? true : false;
    this.vehiclePermissionForm.value.mv_large = this.defaultVehicle === 'mv_large' ? true : false;
  }

  private getPayload() {
    this.vehicleDetailsForm.value.vehicle_type = this.defaultVehicle;
    const val = (form: FormGroup) => {
      return OnboardingUtils.updateFileRefs(form, this.uploadReferences);
    };
    return {
      form: {
        id: this.appReferenceNumber,
        mainStatus: 0,
        appliedDate: new Date()
      },
      personal: val(this.personalInfoForm),
      identification: val(this.driverIdentificationForm),
      vehicle: val(this.vehicleDetailsForm),
      others: val(this.otherInfoForm),
      permission_services: val(this.PermissionForm),
      vehiclePermission: val(this.vehiclePermissionForm)
    };
  }
  addCustomQuery() {
    const control = <FormArray>this.driverIdentificationForm.controls['authorizationLetter'];
    control.push(this.initCustomQuery());
  }
  removeCustomQuery(i: number) {
    const control = <FormArray>this.driverIdentificationForm.controls['authorizationLetter'];
    control.removeAt(i);
    this.myFiles.splice(i, 1);
  }
  upload_SupportDoc(event, c: AbstractControl, indx) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const name = 'authorizationLetter';
    const path = OnboardingUtils.getUploadRef(this.appReferenceNumber, file, name + indx);
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
      this.myFiles.push({ 'fileName' : path });
    }
  }
  upload(event, c: AbstractControl) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const name = OnboardingUtils.getFileName(control);
    const path = OnboardingUtils.getUploadRef(this.appReferenceNumber, file, name);
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
    }
  }
  defaultExpiryDate() {
    const todayDate = new Date();
    const monthExpiry = new Date(todayDate.setMonth(todayDate.getMonth() + 1)); // 1 month to expire
    return monthExpiry.toISOString().split('T')[0];
  }
  maxDob() {
    const todayDate = new Date();
    const monthExpiry = new Date(todayDate.setFullYear(todayDate.getFullYear() - 18)); // 1 month to expire
    return monthExpiry.toISOString().split('T')[0];
  }
  selectionChangedBiker(c: AbstractControl, enabled: boolean) {
    const control = c as FormControl;
    if (this.otherInfoForm.controls.referredBy.value === 'OTHER JOYRIDE BIKER') {
      control.enable();
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      control.disable();
      control.setValidators([]);
      control.updateValueAndValidity();
    }
  }
  selectionChanged(c: AbstractControl, enabled: boolean) {
    const control = c as FormControl;
    enabled ? control.enable() : control.disable();
    if (enabled) {
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      control.setValidators([]);
      control.updateValueAndValidity();
    }
  }
  areYouTheOwnerSelectionChanged(c: AbstractControl, enabled: boolean) {
    const control = c as FormControl;
    if (control === this.driverIdentificationForm.controls.areYouTheOwner) {
      if (enabled) {
        this.driverIdentificationForm.controls.authorizationLetter.reset();
      }
    }
  }
  uploadFiles() {
    console.log('uploadFiles')

    try {
      // const control1 = <FormArray>this.driverIdentificationForm.controls['authorizationLetter'];
      // if (this.driverIdentificationForm.controls.areYouTheOwner.value === 'Yes') {
      //   // tslint:disable-next-line: max-line-length
      //   this.myFiles.push({ 'fileName': 'driverDetails/' + this.appReferenceNumber + '/' + this.appReferenceNumber + '-authorizationLetter0' });
      //   control1.setValue(this.myFiles);
      // } else {
      //   control1.setValue(this.myFiles);
      // }
      const ref = this.modalService.open(UploadFilesJrhmComponent);
      ref.componentInstance.uploadTasks = this.getUploadTasks();
      ref.componentInstance.appRef = this.appReferenceNumber;
      //ref.componentInstance.appRef = '12345';
      ref.componentInstance.formData = this.getPayload();
      ref.result.then((result) => {
        this.resetForms();
      }, (reason) => { });
    } catch(e) {
      console.log(e)
    }
    
  }
  getUploadTasks() {
    const tasks = Array<UploadTask>();
    this.uploadReferences.forEach((task, ctrl, map) => { tasks.push(task); });
    return tasks;
  }
  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');
    return of(confirmation);
  }
  canDeactivate(): Observable<boolean> | boolean {
     // tslint:disable-next-line: max-line-length
     return this.isDirty() ? this.confirm('Are you sure you want to exit this form? All information will be lost if you decide to proceed.') : true;
  }
  canShowAuthorizationLetterSection(): boolean {
    return this.driverIdentificationForm.controls.areYouTheOwner.value === 'No';
  }
  getCitiesbyRegion(region: string) {
    this.onboardingCityList = this.onboardingCity.filter(
      city => city.region === region);
    this.onboardingCityList = uniqBy(this.onboardingCityList, 'city');
    this.onboardingCityList = sortBy(this.onboardingCityList, 'city');
  }
  getRegionCityList() {
  this.dbService.getOnboardingRegionList().then(regions => {
  const region = 'regions';
  const data = Object(regions)[region];
  this.onboardingCity = data;
  this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }).catch(err => {
  console.log(err);
  }).finally(() => {
  });
  }
}
