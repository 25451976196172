import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DriverTripStat } from 'src/app/interfaces/interfaces';
@Component({
  selector: 'app-driver-stats-card',
  templateUrl: './driver-stats-card.component.html',
  styleUrls: ['./driver-stats-card.component.css']
  })
export class DriverStatsCardComponent implements OnInit {

  @Input() stat: DriverTripStat;
  get rating(): string {
    if (this.stat.rating === undefined || this.stat.numTripsCompleted === undefined) { return 'N/A'; }
    if (this.stat.numTripsCompleted === 0) { return 'N/A'; }
    return (this.stat.rating / this.stat.numTripsCompleted).toFixed(2) + '';
  }

  get tripsCancelled(): string {
    if (this.stat.numCancelledByDriver !== undefined && this.stat.numCancelledByCustomer !== undefined) {
      return  this.stat.numCancelledByDriver + ' Biker | ' + this.stat.numCancelledByCustomer + ' Rider';
    } else if (this.stat.numCancelledByDriver !== undefined) { return this.stat.numCancelledByDriver + ' Biker';
  } else if (this.stat.numCancelledByCustomer !== undefined) {
      return this.stat.numCancelledByCustomer + ' Rider'; } else {
      return 'N/A'; }
  }
  constructor() { }
  ngOnInit() {
  }
  ngOnChanges() {
    console.log('This stat = ' + this.stat);
  }

  optional(val: number, round: boolean = false, defaultVal: string = 'N/A') {
    return (val !== undefined) ? (round ? val.toFixed(2) : val) : defaultVal;
  }
}
