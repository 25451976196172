import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  //Export button
  get canShowExportManageTrips(): boolean { return this.user.canExport('managetrips_export'); }
  get canShowExportSearchTrips(): boolean { return this.user.canExport('searchtrips_export'); }
  get canShowExportManageTripHours(): boolean { return this.user.canExport('managetriphours_export'); }
  get canShowExportBikerTrips(): boolean { return this.user.canExport('bikertrips_export'); }
  get canShowExportTripSummaryReport(): boolean { return this.user.canExport('tripsummaryreport_export'); }
  get canShowExportActiveDriverReport(): boolean { return this.user.canExport('activedriverreport_export'); }
  get canShowExportInActiveDriverReport(): boolean { return this.user.canExport('inactivedriverreport_export'); }
  get canShowExportTripOnlineStatusReport(): boolean { return this.user.canExport('triponlinestatusreport_export'); }

  get canUpdateTripStatus(): boolean {
    return this.user.canDoAction('managetrips_edit');
  }

  get canUpdateTripAvailablityStatus(): boolean {
    return this.user.canDoAction('drivertripavailability_edit');
  }

  // get canExportDriverProfiles(): boolean {
  //   return this.user.isSuperAdmin();
  // }

  get canExportOnBoardedDriverProfiles(): boolean {
    return this.user.canExport('onboardeddrivers_export');
  }

  get canExportRegisteredDriverProfiles(): boolean {
    return this.user.canExport('newregistrations_export');
  }

  get canExportCustomerProfiles(): boolean {
    return this.user.isSuperAdmin();
  }

  get canExportCashoutRequests(): boolean {
    return this.user.canExport('cashoutrequests_export');
  }

  get canExportTopupRequests(): boolean {
    return this.user.canExport('topuprequests_export');
  }
  get canExportWalletRequests(): boolean {
    return this.user.isAdmin() || this.user.isSuperAdmin();
  }
  get canApproveCashoutRequests(): boolean {
    return this.user.canExport('cashoutrequests_edit');
  }
  get canApproveTopupRequests(): boolean {
    return this.user.canExport('topuprequests_edit');
  }
  get canApproveWalletRequests(): boolean {
    return this.user.isAdminL1() || this.user.isSuperAdmin();
  }
  get canSendDiverID(): boolean {
    return this.user.isSuperAdminUserRole() || this.user.isAdminL1UserRole();
  }
  get canAdjustTopupmanual(): boolean {
    return this.user.canExport('topupmanual_edit');
  }
  get canAdjustWallet(): boolean {
    return this.user.canExport('walletadjustments_edit');
  }
  get canExportAdjustWallet(): boolean {
    return this.user.canExport('exportwalletadjustment_export');
  }
  get canExportWalletBalance(): boolean {
    return this.user.canExport('exportwalletbalance_export');
  }
  get canExportDriverWalletHistory(): boolean {
    return this.user.canExport('exportdriverwallethistory_export');
  }
  get canExportWalletCommission(): boolean {
    return this.user.canExport('exportwalletcommission_export');
  }
  get canExportLowWalletBalance(): boolean {
    return this.user.canExport('lowwalletbalance_export');
  }
  get canExportEmiDriverHistory(): boolean {
    return this.user.canExport('driveremiwallethistory_export');
  }
  get canExportEmiPendingDriverHistory(): boolean {
    return this.user.canExport('driveremipendinghistory_export');
  }
  get canExportCustomerSearch(): boolean {
    return this.user.canExport('customersearch_export');
  }
  constructor(private user: UserService) { }

  // Profile Editing
  canEditProfile(onboarding: boolean): boolean {
    if (this.user.canDoAction('driverquicksearch_edit')) {
      return true;
  } else {
    return false;
  }
  }
  canCheckonBoardingProfile(): boolean {
    if (this.user.canDoAction('driverquicksearch_edit')) {
        return true;
    } else if (this.user.canDoAction('onboarding_uploading_edit')) {
        return true;
    } else {
      return false;
    }
  }
  canCheckonBoardingProfileEdit(): boolean {
    if (this.user.canDoAction('onboarding_editing_edit')) {
        return true;
    }  else {
      return false;
    }
  }
  canShowAdminApprovalTab(onboarding: boolean): boolean {
    // switch (onboarding) {
    //   case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
    //   case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
    //   default: return false;
    // }
    if (this.user.canDoAction('driverquicksearch_edit')) {
      return true;
  } else {
    return false;
  }
  }

  canCheckRegion(): boolean {
    if (this.user.canDoRegion('Metro Manila')) {
      return true;
  } else {
    return false;
  }
    }

  canCheckRegionCebu(): boolean {
      if (this.user.canDoRegionCebu('Metro Cebu')) {
        return true;
    } else {
      return false;
    }
      }
  
  canShowWalletSettingsTab(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }

  canShowTripsTab(onboarding: boolean): boolean {
    return !this.user.isOpsL1();
  }
  canShowDriverStatus(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }
  canshowPendingGearRelease(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }
  canshowAfterGearRelease(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }
  canshowmototcycleCheck(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }
  canshowSkills(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }
  canshowOrientations(onboarding: boolean): boolean {
    switch (onboarding) {
      case true: return this.user.isOpsL4() || this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      case false: return this.user.isAdminL2() || this.user.isAdminL3() || this.user.isSuperAdmin();
      default: return false;
    }
  }

}
