import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePromoCodeComponent } from 'src/app/modals/promo/create-promo-code/create-promo-code.component';
import { PromoCode, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { Paginator } from 'src/app/classes/paginator';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { FormControl } from '@angular/forms';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { PromoUtils, PromoDiscountType } from 'src/app/utils/promo.utils';
import * as moment from 'moment';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { EditPromoCodeComponent } from 'src/app/modals/promo/create-promo-code/edit-promo-code.component';
import { AddUserPromoCodeComponent } from 'src/app/modals/promo/create-promo-code/add-user-promo-code.component';
import { EditFranchiseAreaComponent } from 'src/app/modals/promo/create-promo-code/edit-franchise-area.component';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.css']
})
export class PromoComponent implements OnInit {
  paginator: Paginator<PromoCode> = null;
  loading: boolean = false;
  onboardingCity: SupportedRegions[];
  region = new FormControl();
  promocodestatus = new FormControl(0);
  promocodename = new FormControl();
  onboardingRegions = [];
  defaultRegion: any;
  promocodeValidFrom: Date = new Date();
  promocodeValidTill: Date = new Date();
  appEmptyResult: any;
  constructor(private dbService: DatabaseService, private modalService: NgbModal) {
    this.appEmptyResult = [];
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
  }
  ngOnInit() {
    this.getRegionCityList();
  }

  applyFilter() {
    this.loadPromoCodes();
  }
  get promoCodes(): PromoCode[] {
    return (this.paginator) ? this.paginator.getItems() : null;
  }
  async loadPromoCodes() {
    this.loading = true;
    const from = moment(this.promocodeValidFrom).format('YYYY-MM-DD');
    const to = moment(this.promocodeValidTill).format('YYYY-MM-DD');
    this.dbService.getPromoCodes(this.promocodename.value, this.region.value,
    Number(this.promocodestatus.value), from, to).then(codes => {
      this.appEmptyResult = codes;
      this.paginator = new Paginator(1, 10, codes);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }
  getStatusMessage(status: number) {
    switch (status) {
      case 0: return 'ACTIVE';
      case 1: return 'DISABLED';
      default: return '';
    }
  }
  getActionButtonTitle(status: number) {
    switch (status) {
      case 0: return 'DEACTIVATE';
      case 1: return 'DISABLED';
      default: return '';
    }
  }
  getPromoDiscountType(status: PromoDiscountType): string | null {
        return PromoUtils.getPromoDiscountType(status);
  }
  createNewPromoCode() {
    const modalRef =  this.modalService.open(CreatePromoCodeComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.result
    .then((result) => { this.loadPromoCodes(); }, (reason) => { });
  }
  deactivatePromoCode(code: PromoCode, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Deactivation Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to de-activate the code:' + code.code + '? \nOnce de-activated, the promo code cannot be enabled again.';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deactivatePromoCode(code.id);
      })
      .then(() => {
        const promoCode = code;
        const realIndex = this.paginator.itemNumberForIndex(index) - 1;
        promoCode.status = 1;
        this.paginator.updateItem(promoCode, realIndex);
      })
      .catch(e => {
        console.log(e);
      });
  }
  openModalAddUser(code, i) {
    const modalRef =  this.modalService.open(AddUserPromoCodeComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.promoCode = code;
    modalRef.result
    .then((result) => { this.loadPromoCodes(); }, (reason) => { });
  }
  openModalEditFranchiseArea(code, i) {
    const modalRef =  this.modalService.open(EditFranchiseAreaComponent, { size: 'sm', backdrop : 'static', keyboard : false });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.promoCode = code;
    modalRef.result
    .then((result) => { this.loadPromoCodes(); }, (reason) => { });
  }
  editPromoCode(code) {
    const modalRef =  this.modalService.open(EditPromoCodeComponent, { size: 'lg', backdrop : 'static', keyboard : false });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.promoCode = code;
    modalRef.result
    .then((result) => { this.loadPromoCodes(); }, (reason) => { });
  }
  getRegionCityList() {
    this.dbService.getOnboardingRegionList().then(regions => {
    const data = Object(regions)['regions'];
    this.onboardingCity = data;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
}


