import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { PaymayaReports, CustomerPaymayaReports } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { DatePickerModelWallet } from 'src/app/classes/DatePickerWallet';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { CustomerPayMayaTopupHistoryAPI } from 'src/app/api/customer.api';
import { PermissionService } from 'src/app/services/permission.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-customer-paymaya-topup',
  templateUrl: './customer-topup-history.component.html'
})
export class CustomerTopupHistoryComponent implements OnInit {
  customers: CustomerPaymayaReports[];
  filter = new DriverMetroFilter();
  uid = new FormControl('');
  driverphone = new FormControl('');
  payMayaAPI: CustomerPayMayaTopupHistoryAPI;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;

constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService, public userService: UserService) {
this.customers = [];
this.paginationCount = 0;
this.payMayaAPI = new CustomerPayMayaTopupHistoryAPI(dbService, (customers) => {
    this.customers = customers;
    this.getPaginations();
    });

}

ngOnInit() {
  this.pageCount = 'load';  this.paginationCount = 0;
  this.payMayaAPI.bindTopupHistory(this.uid.value, this.driverphone.value,
    this.filter.from.selectedDate, this.filter.to.selectedDate, Number(this.selectedFilter), 'load');

}
getPaginations() {
  if (this.selectedFilter > this.customers.length) {
    this.disableInitial = false;
  }
  if (this.pageCount === 'previous') {
    this.paginationCount--;
  } else if (this.pageCount === 'next') {
    this.paginationCount++;
  }
  this.disableLoad = false;
  this.disableNext = false;
  this.disablePrevious = false;
}
applyFilter(buttonAction: string) {
  this.disableLoad = true;
  this.disableInitial = true;
  if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
  if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
  if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
  this.payMayaAPI.bindTopupHistory(this.uid.value, this.driverphone.value,
    this.filter.from.selectedDate, this.filter.to.selectedDate, Number(this.selectedFilter), buttonAction);
}
filterpage(status: number) {
  this.selectedFilter = status;
}
exportPaymaya() {
  const filename = () => 'paymaya_topup_history' + (new Date()).toLocaleDateString();
  const exporter = new CSVExporter(filename(), CSVExporter.paymayaCustomerTopupHistory());
  exporter.exportPaymayaCustomerTopupHistory(this.customers as CustomerPaymayaReports[]);
}
get canExport(): boolean {
  if (!this.customers) { return false; }
  const allRequests = this.customers;
  return (allRequests && allRequests.length > 0);
}
}
/*** FILTER for Trips */
class DriverMetroFilter {
  from: DatePickerModelWallet = new DatePickerModelWallet(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
}
setStatus(status: number) {
}
reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
}
}
