import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { Customer } from 'src/app/interfaces/interfaces';
import { Paginator, DriverProfilePaginator } from 'src/app/classes/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { PermissionService } from 'src/app/services/permission.service';
import { CustomerAPI } from 'src/app/api/customer.api';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-poc-drivers',
  templateUrl: './registered-drivers-poc.component.html'
})
export class RegisteredDriversPOCComponent implements OnInit {
  paginator: Paginator<Customer> = null;
  driverpaginator: DriverProfilePaginator = null;
  loading: boolean;
  DriverExists: boolean;
  filter = new FormControl('');
  api: CustomerAPI;
  isSuccessDeleteData: boolean;
  phone = new FormControl('');
  result: any;
  constructor(private dbService: DatabaseService, private modalService: NgbModal, public utils: UtilsService,
              public permissions: PermissionService, private apiService: ApiService) {
    this.loading = false;
    this.DriverExists = false;
    this.isSuccessDeleteData = false;
    this.api = new CustomerAPI(dbService, (customer) => {
      this.paginator = new Paginator(1, 10, customer, this.filterBlock);
    });
  }
  get customers(): Customer[] {
    return (this.paginator) ? this.paginator.getItems(this.filter.value) : null;
  }
  get disablePagination(): boolean {
    return this.filter.value !== '';
  }
  filterBlock = (text: string, item: Customer) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.firstName, item.lastName].join(' ');
    return name.toLocaleLowerCase().includes(searchText)
      || item.phone.toLocaleLowerCase().includes(searchText);
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
  ngOnInit() {
  }
  async loadCustomers() {
    this.loading = false;
    this.isSuccessDeleteData = false;
    this.dbService.hasDriverPhoneNumber(this.phone.value)
      .then(result => {
        if (result) {
          this.DriverExists = true;
          return Promise.reject();
        } else {
          this.DriverExists = false;
          this.api.loadCustomerbyPhone(this.phone.value);
        }
      })
      .then(() => {
      })
      .finally(() => {
      });
  }
  deleteCustomer(customer: Customer) {
    if (confirm('Are you sure to fix the Biker Number ? ')) {
      this.apiService.deleteCustomerProfile(customer)
        .then(response => {
          this.loading = false;
          this.isSuccessDeleteData = true;
          const success = 'isSuccess';
          const data = 'date';
          const message = 'message';
          const error = 'error';
          if (response[success] === true) {
            this.result = response[data][message];
          } else {
            this.result = response[error][message];
          }
        })
        .catch(
        );
    }
  }
}
