import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { PaymayaReports } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PayMayaAPI } from 'src/app/api/paymaya.api';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-paymaya-walletlink',
  templateUrl: './paymaya-driver-walletlink.component.html'
})
export class PaymayaWalletLinkComponent implements OnInit {
  drivers: PaymayaReports[];
  filter = new DriverMetroFilter();
  driverphone = new FormControl('');
  dirverRefNo = new FormControl('');
  driverId = new FormControl('');
  linkId = new FormControl('');
  type = new FormControl('');
  payMayaAPI: PayMayaAPI;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService) {
this.paginationCount = 0;
this.drivers = [];
this.payMayaAPI = new PayMayaAPI(dbService, (drivers) => {
    this.drivers = drivers;
    this.getPaginations();
    });

}

ngOnInit() {
}
applyFilter(buttonAction: string) {
  this.disableLoad = true;
  this.disableInitial = true;
  if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
  if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
  if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
  this.payMayaAPI.bindWalletLink(this.driverphone.value, this.dirverRefNo.value,
    this.driverId.value, this.linkId.value, this.type.value,
    this.filter.from.selectedDate, this.filter.to.selectedDate, Number(this.selectedFilter), buttonAction);
}
getPaginations() {
  if (this.selectedFilter > this.drivers.length) {
    this.disableInitial = false;
  }
  if (this.pageCount === 'previous') {
    this.paginationCount--;
  } else if (this.pageCount === 'next') {
    this.paginationCount++;
  }
  this.disableLoad = false;
  this.disableNext = false;
  this.disablePrevious = false;
}
filterpage(status: number) {
  this.selectedFilter = status;
}
exportPaymaya() {
  const filename = () => 'paymaya_driver_walletlink' + (new Date()).toLocaleDateString();
  const exporter = new CSVExporter(filename(), CSVExporter.paymayaDriverWalletlink());
  exporter.exportPaymayawalletLink(this.drivers as PaymayaReports[]);
}
get canExport(): boolean {
  if (!this.drivers) { return false; }
  const allRequests = this.drivers;
  return (allRequests && allRequests.length > 0);
}

}
/*** FILTER for Trips */
class DriverMetroFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
}
setStatus(status: number) {
}
reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
}
}
