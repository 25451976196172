import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import {  AlertModel, SupportedRegions, UserClaim, Roles } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-user-roles',
  templateUrl: './edit-user-roles.component.html'
})
export class EditUserRolesComponent implements OnInit {
  userrolesForm: FormGroup;
  userRoles: Roles;
  alert: AlertModel;
  saving: boolean;
  roleName: any;
  roleValue: any;
  isActive: any;
  docID: any;
  loadedData: any = [];
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private fc: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.roleName = this.loadedData.roleName;
    this.roleValue = this.loadedData.roleValue;
    this.isActive = this.loadedData.status;
    this.docID = this.loadedData.id;
    this.createUserRolesForm();
  }

  private createUserRolesForm() {
    this.userrolesForm = this.fc.group({
        roleName: '',
        roleValue: '',
        isActive: false,
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveUserClaim(): boolean {
    return this.userrolesForm.valid && this.userrolesForm.dirty;
  }

  editUserRoles() {
    this.userRoles = {
      id: this.docID,
      status: this.userrolesForm.controls.isActive.value,
      roleName: this.userrolesForm.controls.roleName.value,
      roleValue: this.userrolesForm.controls.roleValue.value,
 
    };
    this.userrolesForm.disable();
    this.saving = true;
    this.dbService.editUserRoles(this.userRoles, this.docID)
      .then(() => {
        this.showAlert('success', 'User Role updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the user role - ' + e.message);
        this.userrolesForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
