import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
import { MetroBankBindAccountApi } from 'src/app/api/metrobank.api';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-metrobank',
    templateUrl: './metrobank-topupinquiry.component.html'
})

export class MetroBankTopUpInquiryComponent implements OnInit {

filter = new BindAccHistroyFilter();
driverphone = new FormControl('');
referenceNo = new FormControl('');
transactioncode = new FormControl('');
metroBankAPI: MetroBankBindAccountApi;
bindAccInquiryHisstory: any;
constructor(private dbService: DatabaseService, private apiService: ApiService,
            public permissions: PermissionService, private utils: UtilsService) {
  this.metroBankAPI = new MetroBankBindAccountApi(apiService, (success, history) => {
     this.bindAccInquiryHisstory = history;
  });
}
ngOnInit() {
}
getAllWalletHistory() {
  this.metroBankAPI.metroBanTopUpInquiry(this.driverphone.value, this.referenceNo.value);
}
canFillterBikers(): boolean {
  return this.driverphone.dirty;
}
canFillterBikersId(): boolean {
 return this.referenceNo.dirty;
}
}
class BindAccHistroyFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
}
