import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import {  AlertModel } from 'src/app/interfaces/interfaces';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-driver-individual-activation',
  templateUrl: './driver-individual-activation.component.html',
  styleUrls: ['./driver-individual-activation.component.css']
})
export class DriverIndividualActivationComponent implements OnInit {
  public arrFinalData = [];
  public arrFinalStatusWithData = [];
  individualDriverGCashForm: FormGroup;
  tempDriverUid: any;
  saving: boolean;
  gcashnoExist = false;
  alert: AlertModel;
  submitted: false;
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService, private fb: FormBuilder) {
  }
  ngOnInit() {
    this.createIndividualDriverGCashForm();
  }
  createIndividualDriverGCashForm() {
    this.individualDriverGCashForm = this.fb.group({
      phone: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      gcashnumber: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      bikerId: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.untouched || c.valid;
  }
  checkgcashnoavailablity(control: AbstractControl) {
    const c = control as FormControl;
    const checkGCashNo = true;
    if (checkGCashNo) {
      this.dbService.hasGCashNumber(c.value)
        .then(result => {
          if (result) {
            this.gcashnoExist = true;
            return Promise.reject();
          } else {
            this.gcashnoExist = false;
          }
        });
    } else { }
    return;
  }
  updataGcashData() {
    const values = this.individualDriverGCashForm.value;
    this.saving = true;
    // tslint:disable-next-line: max-line-length
    const driverPhotoURL = 'https://firebasestorage.googleapis.com/v0/b/joyride-prod.appspot.com/o/driverDetails%2F1579236169%2F1579236169-vehicle-photo?alt=media&token=bffb4d35-c6d9-4177-a870-3d9b7d20a144';
    // tslint:disable-next-line: max-line-length
    this.dbService.getProfileInfo(this.individualDriverGCashForm.value.phone.toString(), this.individualDriverGCashForm.value.gcashnumber.toString()
    ).then(forms => {
      if (this.gcashnoExist) {
        alert('The entered GCash Number already exists in the database!');
        this.saving = false;
      } else {
        this.tempDriverUid = (forms[0] === undefined || forms[0] === null) ? '' : forms[0].uid;
        this.apiService.updateImportDriverProfile(this.tempDriverUid, this.individualDriverGCashForm.value.phone.toString(),
          this.individualDriverGCashForm.value.gcashnumber.toString(),
          this.individualDriverGCashForm.value.bikerId.toString(), driverPhotoURL, this.today()).then(response => {
            if (response) {
              const alertType = response ? 'isSuccess' : 'danger';
              const message = 'Saved Sucessfully';
              this.saving = false;
              this.showAlert(alertType, message);
            }
          })
          .catch(response => {
          }
          );
      }
    }).catch(err => {
      console.log(err);
      });
  }
  today() {
    const d = new Date();
    return d.toISOString().split('T')[0];
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  canSaveGcashData(): boolean {
    return this.individualDriverGCashForm.valid;
  }
}
