import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { CustomerAPPServices, AlertModel } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'app-edit-customerapp-services',
  templateUrl: './edit-customerapp-services.component.html'
})
export class EditCustomerAppServicesComponent implements OnInit {
  CustomerAPPServicesForm: FormGroup;
  CustomerAPPServicesModel: CustomerAPPServices;
  alert: AlertModel;
  saving: boolean;
  loadedData: any = [];
  type: any;
  tripType: any;
  updatedby: any;
  description: any;
  metromanila: any;
  metrocebu: any;
  baguio: any;
  isactive: any;
  isactiveYes: any;
  isactiveNo: any;
  updatedon: any;
  docID: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService
  ) {
  }
  ngOnInit() {
    this.type = this.loadedData.type;
    this.tripType = this.loadedData.tripType;
    this.description = this.loadedData.description;
    this.isactive = this.loadedData.isactive;
    this.metrocebu = this.loadedData.metrocebu;
    this.metromanila = this.loadedData.metromanila;
    this.baguio = this.loadedData.baguio? this.loadedData.baguio: false;
    this.updatedby = this.loadedData.updated_by;
    this.updatedon = this.loadedData.updated_on;
    this.docID = this.loadedData.type;

    if (this.loadedData.isactive === true) {
        this.isactiveYes = 'true';
      } else {
        this.isactiveNo = 'false';
      }
    this.createForm();
  }
  private createForm() {
    this.CustomerAPPServicesForm = this.fb.group({
      type: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      description: ['', Validators.maxLength(500)],
      isactive: ['', Validators.maxLength(100)],
      metromanila: false,
      metrocebu: false,
      baguio: false
    });
  }

  c(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }

  canSaveCustomerAPPServices(): boolean {
    return this.CustomerAPPServicesForm.valid && this.CustomerAPPServicesForm.dirty;
  }

  updateCustomerAPPServices() {
     const locisactive = (this.CustomerAPPServicesForm.controls.isactive.value === 'true' ? true : false);
     
     this.CustomerAPPServicesModel = {
      id: this.docID,
      type: this.CustomerAPPServicesForm.controls.type.value,
      tripType: this.tripType,
      description: this.CustomerAPPServicesForm.controls.description.value,
      metrocebu: this.CustomerAPPServicesForm.controls.metrocebu.value,
      metromanila: this.CustomerAPPServicesForm.controls.metromanila.value,
      baguio: this.CustomerAPPServicesForm.controls.baguio.value,
      updated_by: this.updatedby,
      updated_on: this.updatedon
    };
     this.CustomerAPPServicesForm.disable();
     this.saving = true;
     this.dbService.editCustomerAPPServices(this.CustomerAPPServicesModel, this.docID)
      .then(() => {
        this.showAlert('success', 'Services updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while update the services - ' + e.message);
        this.CustomerAPPServicesForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}





