import { Component, OnInit } from '@angular/core';
import { TripDailyStatsAPI } from 'src/app/api/driver-stats.api';
import { DatabaseService } from 'src/app/services/database.service';
import { AllTripStat } from 'src/app/interfaces/interfaces';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-trip-summary',
  templateUrl: './trip-management-report.component.html',
  styleUrls: [ './trip-management-report.component.css']
})
export class TripMgmtReportComponent implements OnInit {
  api: DriversListAPI;
  tripdailyStatsAPI: TripDailyStatsAPI;
  dailyStat: AllTripStat = {};
  dailyStatDate: Date = new Date();
  dailyDriverAvailableCount: any;
  noofTrips: any;
  totalCentPercent: any;
  tripCompPercent: any;
  tripDriverCancelPercent: any;
  tripCustomerCancelPercent: any;
  tripAdminCancelPercent: any;
  tripNoBikerPercent: any;
  tripServerErrorPercent: any;
  dailyDriverAvailablePercent: any;
  activedDriverCount: number;
  activatedDriverPercent: any;
  driverProfile: DriverProfile[];
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  totalCenPercentDelivery: any;
  totalCenPercentPabili: any;
  totalCenPercentTricycle: any;
  totalCenPercentHmdelivery: any;
  tripCompPercentDelivery: any;
  driverCancelDelivery: any;
  customerCancelDelivery: any;
  adminCancelDelivery: any;
  noBikerPercentDelivery: any;
  serverErrorPercentDelivery: any;
  daliyDeliverycount: any;
  dailyDeliveryPercent: any;
  dailyPabiliCount: any;
  dailyPabiliPercent: any;
  dailyTricycleCount: any;
  dailyTricyclePercent: any;
  dailyHmdeliveryCount: any;
  dailyHmdeliveryPercent: any;
  constructor(private dbService: DatabaseService, public utils: UtilsService) {
    this.driverProfile = [];
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.tripdailyStatsAPI = new TripDailyStatsAPI(dbService, (success, stat) => {
      this.dailyStat = (success && stat) ? stat : {};
      this.totalCentPercent = this.totalPercent(this.dailyStat.numTrips);
      this.totalCenPercentDelivery =  this.totalPercent(this.dailyStat.numTrips_delivery);
      this.totalCenPercentPabili =  this.totalPercent(this.dailyStat.numTrips_pabili);
      this.totalCenPercentTricycle =  this.totalPercent(this.dailyStat.numTrips_tricycle);
      this.totalCenPercentHmdelivery =  this.totalPercent(this.dailyStat.numTrips_hmdelivery);
      this.tripCompPercent = this.optionalPercent(this.dailyStat.numTripsCompleted, this.totalCentPercent);
      this.tripCompPercentDelivery = this.optionalPercent(this.dailyStat.numTripsCompleted_delivery , this.totalCenPercentDelivery);
      this.tripDriverCancelPercent = this.optionalPercent(this.dailyStat.numTripsCancelledByDriver, this.totalCentPercent);
      this.driverCancelDelivery = this.optionalPercent(this.dailyStat.numTripsCancelledByDriver_delivery, this.totalCenPercentDelivery);
      this.tripCustomerCancelPercent = this.optionalPercent(this.dailyStat.numTripsCancelledByCustomer, this.totalCentPercent);
      this.customerCancelDelivery = this.optionalPercent(this.dailyStat.numTripsCancelledByCustomer_delivery, this.totalCenPercentDelivery);
      this.tripAdminCancelPercent = this.optionalPercent(this.dailyStat.numTripsAdminCancelled, this.totalCentPercent);
      this.adminCancelDelivery = this.optionalPercent(this.dailyStat.numTripsAdminCancelled_delivery, this.totalCenPercentDelivery);
      this.tripNoBikerPercent = this.optionalPercent(this.dailyStat.numTripsNoBikerFound, this.totalCentPercent);
      this.noBikerPercentDelivery = this.optionalPercent(this.dailyStat.numTripsNoBikerFound_delivery, this.totalCenPercentDelivery);
      this.tripServerErrorPercent = this.optionalPercent(this.dailyStat.numTripsServerError, this.totalCentPercent);
      this.serverErrorPercentDelivery = this.optionalPercent(this.dailyStat.numTripsServerError_delivery, this.totalCenPercentDelivery);
      });
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.driverProfile = drivers;
    });
  }
  optionalNumber(val?: number, defaultVal?: any): string | null | 'NaN' {
    defaultVal = val ;
    return (val == null || val === undefined || defaultVal === '0' ) ? ' ' : String(val.toFixed(2));
  }
  optionalPercent(val1: number, val2: number) {
    return (val1 !== undefined && val2 !== 0) ? val1 / val2 : '0';
  }
  totalPercent( val1: number) {
    return (val1 !== undefined) ? val1 / 100 : 0;
  }
  ngOnInit() {
    this.loadDriverApplications();
  }
  fetchDailyStat() {
    this.dailyDriverAvailableCount = '';
    const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
    if (this.utils.checkRegionReleaseDatePrevious(this.dailyStatDate) === true) {
        this.tripdailyStatsAPI.fetchStatsOnDate(dayId, this.region.value);
        this.getDailyDriverCountRegion(dayId, this.region.value);
     } else {
       this.tripdailyStatsAPI.fetchStatsOnDate(dayId,  'stats');
       this.getDailyDriverCount(dayId);
     }
  }
  getDailyDriverCountRegion(dayId, region) {
    this.dbService.getAllDriverTripReportsRegion(dayId, region).get().toPromise()
      .then(snapshots => snapshots.docs.map(c => c.data()))
      .then(response => {
        this.dailyDriverAvailableCount = response.filter(val => val.numTripsCompleted >= 1).length;
        this.daliyDeliverycount = response.filter(val => val.numTripsCompleted_delivery >= 1).length;
        this.dailyPabiliCount = response.filter(val => val.numTripsCompleted_pabili >= 1).length;
        this.dailyTricycleCount = response.filter(val => val.numTripsCompleted_Tricycle >= 1).length;
        this.dailyHmdeliveryCount = response.filter(val => val.numTripsCompleted_Hmdelivery >= 1).length;
        this.driverProfile = this.driverProfile.filter(val => val.personal.region = this.region.value);
        this.activedDriverCount = this.driverProfile.length;
        this.activatedDriverPercent = this.activedDriverCount / 100;
        this.dailyDriverAvailablePercent = this.optionalPercent(this.dailyDriverAvailableCount, this.activatedDriverPercent);
        this.dailyDeliveryPercent = this.optionalPercent(this.daliyDeliverycount, this.activatedDriverPercent);
        this.dailyPabiliPercent = this.optionalPercent(this.dailyPabiliCount, this.activatedDriverPercent);
        this.dailyTricyclePercent = this.optionalPercent(this.dailyTricycleCount, this.activatedDriverPercent);
        this.dailyHmdeliveryPercent = this.optionalPercent(this.dailyHmdeliveryCount, this.activatedDriverPercent);
      })
      .catch(e => {
        console.error(e);
      });
  }
  getDailyDriverCount(dayId) {
    this.dbService.getAllDriverTripReports(dayId).get().toPromise()
      .then(snapshots => snapshots.docs.map(c => c.data()))
      .then(response => {
        this.dailyDriverAvailableCount = response.filter(val => val.numTripsCompleted >= 1).length;
        this.daliyDeliverycount = response.filter(val => val.numTripsCompleted_delivery >= 1).length;
        this.activedDriverCount = this.driverProfile.length;
        this.activatedDriverPercent = this.activedDriverCount / 100;
        this.dailyDriverAvailablePercent = this.optionalPercent(this.dailyDriverAvailableCount, this.activatedDriverPercent);
        this.dailyDeliveryPercent = this.optionalPercent(this.daliyDeliverycount, this.activatedDriverPercent);
      })
      .catch(e => {
        console.error(e);
      });
  }
  private async loadDriverApplications() {
    this.api.loadActivatedDriverProfiles(1, 11);
  }
  optional(val: number, round: boolean = false, defaultVal: string = '0') {
    return (val !== undefined) ? (round ? val.toFixed(2) : val) : defaultVal;
  }
}
