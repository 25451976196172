import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { Customer, AlertModel } from 'src/app/interfaces/interfaces';
import { Paginator } from 'src/app/classes/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerProfileComponent } from 'src/app/modals/customer/customer-profile/customer-profile.component';
import { UtilsService } from 'src/app/services/utils.service';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { CustomerAPI, CustomerProfileAPI } from 'src/app/api/customer.api';
import { CustomerWalletHistoryListener } from 'src/app/api/customer-wallet.api';
import { CustomerWalletAdjustmentAPI } from 'src/app/api/fareSettings.api';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-customer-wallet',
  templateUrl: './wallet-adjustments.component.html'
})
export class CustomerWalletAdjustmentsComponent implements OnInit {
  paginator: Paginator<Customer> = null;
  loading: boolean = false;
  filter = new FormControl('');
  walletHistory: WalletHistory[];
  customer: Customer;
  customerphone  = new FormControl('');
  firstName  = new FormControl('');
  emailId  = new FormControl('');
  alert: AlertModel;
  walletHistoryListener: CustomerWalletHistoryListener;
  walletAdjustmentAPI: CustomerWalletAdjustmentAPI;
  api: CustomerAPI;
  walletAdjustmentForm: FormGroup;
  customerProfileAPI: CustomerProfileAPI;
  isVisiableWalletAdj = false;
  filterBlock = (text: string, item: Customer) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.firstName, item.lastName].join(' ');
    return name.toLocaleLowerCase().includes(searchText)
       || item.phone.toLocaleLowerCase().includes(searchText);
  }
  constructor(private dbService: DatabaseService, private modalService: NgbModal, private apiService: ApiService,
              public utils: UtilsService, public permissions: PermissionService, private fb: FormBuilder) {
                this.api = new CustomerAPI(dbService, (customer) => {
                  this.paginator = new Paginator(1, 5, customer, this.filterBlock);
                });
                this.walletAdjustmentAPI = new CustomerWalletAdjustmentAPI(apiService, (success, message) => {
                  const alertType = success ? 'success' : 'danger';
                  this.showAlert(alertType, message);
                  this.walletAdjustmentForm.enable();
                  if (success) {
                    this.walletAdjustmentForm.reset();
                    this.walletAdjustmentForm.markAsPristine();
                  }
                });
                this.customerProfileAPI = new CustomerProfileAPI(this.dbService, true, (success, profile) => {
                    if (success && profile) {
                      this.customer = profile;
                      this.walletAdjustmentForm.controls.customerId.setValue(this.customer.uid);
                      this.walletHistoryListener.beginListening(profile.uid);
                    // tslint:disable-next-line: max-line-length
                    } else if (success) { this.showAlert('danger', 'No Customer was found with the searched ID'); } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Customer profile'); }
                  });
                this.walletHistoryListener = new CustomerWalletHistoryListener(this.dbService, (success, history) => {
                    // tslint:disable-next-line: max-line-length
                    if (success && history) { this.walletHistory = history; } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
                  });
   }
  get customers(): Customer[] {
    return (this.paginator) ? this.paginator.getItems(this.filter.value) : null;
  }
   get disablePagination(): boolean {
    return this.filter.value !== '';
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
  ngOnInit() {
      this.createWalletAdjustmentForm();
  }
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy(): void {
      this.customerProfileAPI.endListening();
      this.walletHistoryListener.endListening();
   }
  loadCustomers() {
      this.loading = true;
      this.api.loadCustomerSearch(this.customerphone.value, this.firstName.value, this.emailId.value);
  }
  openCustomer(customer: Customer) {
    const modalRef = this.modalService.open(CustomerProfileComponent, { size: 'lg' });
    modalRef.componentInstance.customer = customer;
  }
  private createWalletAdjustmentForm() {
    this.walletAdjustmentForm = this.fb.group({
      customerId: ['', Validators.required],
      amount: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(7)]],
      reason: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]]
    });
  }
  export() {
    const customers =   (this.filter.value) ? this.paginator.getItems(this.filter.value) : this.paginator.collection;
    const filename = () => 'registered_customers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.customerProfileTitles());
    exporter.exportCustomers(customers as Customer[]);
  }
  get canExport(): boolean {
    if (!this.paginator) { return false; }
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  getWalletAssessmentInformation(customerid) {
    this.isVisiableWalletAdj = true;
    this.customerProfileAPI.fetchCustomerProfile(customerid);
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.untouched || c.disabled || c.valid;
  }
  canSaveWalletAdjustments(): boolean {
    return this.walletAdjustmentForm.valid && this.walletAdjustmentForm.dirty;
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  saveWalletAdjustments(customerid: string) {
    this.walletAdjustmentForm.disable();
    // tslint:disable-next-line: radix
    this.walletAdjustmentForm.controls.amount.setValue(parseInt(this.walletAdjustmentForm.controls.amount.value));
    this.walletAdjustmentAPI.adjustWalletAmount(customerid, this.walletAdjustmentForm.value);
  }
  cancelWalletAdjustments() {
    this.customerProfileAPI.endListening();
    this.walletHistoryListener.endListening();
    this.isVisiableWalletAdj = false;
  }
}
