import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { EditPromoCode, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { PromoUtils, PromoDiscountType } from 'src/app/utils/promo.utils';
import { UtilsService } from 'src/app/services/utils.service';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-create-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.css']
})
export class EditPromoCodeComponent implements OnInit {
  promoCodeForm: FormGroup;
  promoCodeModel: EditPromoCode;
  promoCode: any;
  defaultRegion: any;
  discount_type: number;
  promocodeval: any;
  description: any;
  numTrips: any;
  globalCount:any;
  discount: any;
  from: any;
  to: any;
  start_time:any;
  end_time:any;
  docID: any;
  status: any;
  mctaxi: any;
  delivery: any;
  pabili: any;
  tricycle: any;
  hmdelivery: any;
  franchise_area: any;
  alert: AlertModel;
  saving: boolean;
  onboardingRegions = [];
  onboardingCity: SupportedRegions[];

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    public utils: UtilsService,
  ) {
  }
  ngOnInit() {
    this.defaultRegion = this.promoCode.region;
    this.discount_type = this.promoCode.discount_type == undefined ? 0 : this.promoCode.discount_type;
    this.promocodeval = this.promoCode.code;
    this.description = this.promoCode.description;
    this.numTrips = this.promoCode.numTrips;
    this.globalCount = this.promoCode.globalCount;
    this.discount = this.promoCode.discount;
    this.from = this.promoCode.validity.from;
    this.to = this.promoCode.validity.to;
    this.start_time = this.promoCode.validity.start_time;
    this.end_time = this.promoCode.validity.end_time;
    this.docID = this.promoCode.id;
    this.status = this.promoCode.status;
    this.mctaxi = this.promoCode.mctaxi === undefined ? false : this.promoCode.mctaxi;
    this.delivery = this.promoCode.delivery === undefined ? false : this.promoCode.delivery;
    this.pabili = this.promoCode.pabili === undefined ? false : this.promoCode.pabili;
    this.tricycle = this.promoCode.tricycle === undefined ? false : this.promoCode.tricycle;
    this.hmdelivery = this.promoCode.hmdelivery === undefined ? false : this.promoCode.hmdelivery;
    this.franchise_area = this.promoCode.franchise_area === undefined ? '' : this.promoCode.franchise_area;
    this.getRegions();
    this.createForm();
  }
  getPromoDiscountType(status: PromoDiscountType): string | null {
        return PromoUtils.getPromoDiscountType(status);
  }

  private createForm() {
    this.promoCodeForm = this.fb.group({
      type: [0, Validators.required],
      code: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25), ValidatorService.promoCode]],
      numTrips: [0, [Validators.required, Validators.min(1)]],
      globalCount: [null,[Validators.min(1)]],
      discount: [0.0, [Validators.required, Validators.min(0), Validators.max(100)]],
      validFrom: [null],
      validTo: [null],
      start_time: [null],
      end_time: [null],
      description: ['', Validators.maxLength(100)],
      region: ['', Validators.required],
      discount_type: ['', Validators.required],
      mctaxi: false,
      delivery: false,
      pabili: false,
      tricycle: false,
      hmdelivery: false,
    }, { validators: this.formValidator });
  }

  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }

  private formValidator(form: FormGroup): { [key: string]: boolean } {
    const fromDate = form.controls.validFrom.value;
    const toDate = form.controls.validTo.value;
    const start_time = form.controls.start_time.value;
    const end_time = form.controls.end_time.value;
    if (!fromDate && !toDate) { return null; }
    if (fromDate && toDate) {
    if (start_time || end_time) {
      return start_time <= end_time && fromDate <= toDate && start_time && end_time  ? null : {['invalidDateRange']: true };
    }  else {  return fromDate <= toDate ? null : {['invalidDateRange']: true }; }
    } else {
      return { ['invalidDateRange']: true };
    }
    
  }


  private validity(form: FormGroup) {
    const fromDate = form.controls.validFrom.value;
    const toDate = form.controls.validTo.value;
    const start_time = form.controls.start_time.value;
    const end_time = form.controls.end_time.value;
    if (!fromDate && !toDate) { return null;
    } else { return { from: fromDate, to: toDate, start_time: start_time, end_time: end_time }; }
  }

  createNewCode() {
    let pcode = this.promoCodeForm.controls.code.value;
    this.promoCodeModel = {
      type: this.promoCodeForm.controls.type.value,
      code: pcode.toUpperCase(),
      description: this.promoCodeForm.controls.description.value,
      numTrips: this.promoCodeForm.controls.numTrips.value,
      globalCount: this.promoCodeForm.controls.globalCount.value,
      discount: this.promoCodeForm.controls.discount.value,
      discount_type: parseInt(this.promoCodeForm.controls.discount_type.value),
      validity: this.validity(this.promoCodeForm),
      status: this.status,
      region: this.promoCodeForm.controls.region.value,
      id: this.docID,
      mctaxi: this.promoCodeForm.controls.mctaxi.value,
      delivery: this.promoCodeForm.controls.delivery.value,
      pabili: this.promoCodeForm.controls.pabili.value,
      tricycle: this.promoCodeForm.controls.tricycle.value,
      hmdelivery: this.promoCodeForm.controls.hmdelivery.value,
      franchise_area: this.franchise_area
    };
    this.promoCodeForm.disable();
    this.saving = true;

    this.dbService.editPromoCode(this.promoCodeModel, this.docID)
      .then(() => {
        this.showAlert('success', 'Promo-code updated successfully');
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the promo code - ' + e.message);
        this.promoCodeForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  getRegions() {
    this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }

  toInt(index: number): any {
    return index;
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
