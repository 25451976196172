import { Component, OnInit } from '@angular/core';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingApplication, DriverProfile, GCashCity, SupportedRegions } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverProfileComponent } from 'src/app/modals/driver/driver-profile/driver-profile.component';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-quicksearch-drivers',
  templateUrl: './quicksearch-drivers.component.html',
  styleUrls: ['./quicksearch-drivers.component.css']
})
export class QuicksearchDriversComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  api: DriversListAPI;
  firstname = new FormControl('');
  lastname = new FormControl('');
  phone = new FormControl('');
  bikerid = new FormControl('');
  GCashRegions: GCashCity[];
  onboardingCity: SupportedRegions[];
  constructor(private dbService: DatabaseService, private modalService: NgbModal, private utils: UtilsService,
              public permissions: PermissionService) {
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.paginator = new DriverProfilePaginator(1, 10, drivers);
    });
  }
  ngOnInit() {
    this.getGcashRegionList();
    this.getRegionCityList();
  }
  private async loadDriverApplications(firstname?: string, lastname?: string, phone?: string, bikerid?: string) {
    this.api.loadDriverProfilesbyQuickSearch(firstname, lastname, phone, bikerid);
  }
  get applications(): OnboardingApplication[] {
    if (this.paginator) {
      return this.paginator.getItemsWithFilter('');
    } else {
      return null;
    }
  }
  getsearchresult() {
    this.loadDriverApplications(this.firstname.value, this.lastname.value, this.phone.value, this.bikerid.value);
  }
  getGcashRegionList() {
    this.dbService.getGcashRegionList().then(regions => {
      this.GCashRegions = regions;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  getRegionCityList() {
    this.dbService.getOnboardingRegionList().then(regions => {
    const datafilter = 'regions';
    const data = Object(regions)[datafilter];
    this.onboardingCity = data;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  getVehicleName(status: string) {
    return OnboardingUtils.getVehiclyName(status);
  }
  openDriverProfile(driver: DriverProfile) {
    const modalRef = this.modalService.open(DriverProfileComponent, { size: 'xl', scrollable: true, backdrop : 'static', keyboard : false });
    modalRef.componentInstance.isOnboardingProfile = false;
    modalRef.componentInstance.driverProfile = driver;
    modalRef.componentInstance.status = this.utils.mainStatusForNewDrivers;
    modalRef.componentInstance.subStatus = this.utils.subStatusForNewDrivers;
    modalRef.componentInstance.GCashCities = this.GCashRegions;
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.result
    .then((result) => { }, (res) => { this.getsearchresult();});
  }
  export() {
    const drivers = this.paginator.collection;
    const filename = () => 'quick_search drivers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.driverProfileTitles());
    exporter.exportDrivers(drivers as DriverProfile[]);
  }
}
