import { Component, OnInit } from '@angular/core';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingApplication, DriverProfile, GCashCity } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { UtilsService } from 'src/app/services/utils.service';
import { IDriverProfileFilter } from 'src/app/controls/driver-profile-filter/driver-profile-filter.component';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-export-wallet-balance',
  templateUrl: './export-walletbalance.component.html',
  styleUrls: ['./export-walletbalance.component.css']
})
export class ExportWalletBalanceComponent implements OnInit {
  drivers: DriverProfile[];
  paginator: DriverProfilePaginator = null;
  api: DriversListAPI;
  currentFilter: IDriverProfileFilter = {};
  GCashRegions: GCashCity[];
  onboardingRegions = [];
  region = new FormControl('Metro Manila');
  defaultRegion: any;
  constructor(private dbService: DatabaseService, private utils: UtilsService, public permissions: PermissionService) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.drivers = [];
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.drivers = drivers;
    });
  }
  ngOnInit() {
    this.loadDriverApplications(this.region.value);
  }
  get canExport(): boolean {
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
  export() {
    const filename = () => 'Wallet Balance' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.walletBalance());
    exporter.exportWalletBalance(this.drivers as DriverProfile[]);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  get applications(): OnboardingApplication[] {
    if (this.paginator) {
      return this.paginator.getItemsWithFilter();
    } else {
      return null;
    }
  }
  private async loadDriverApplications(region: string) {
    this.api.loadDriverWalletBalance(region);
  }
  regionFilter() {
    this.loadDriverApplications(this.region.value);
  }
}
