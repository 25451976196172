import { Component, OnInit } from '@angular/core';
import { WalletCashoutStatus, WalletUtils } from 'src/app/utils/wallet.utils';
import { CashoutRequestsAPI, CashoutStatusUpdateApi } from 'src/app/api/cashouts.api';
import { DatabaseService } from 'src/app/services/database.service';
import { CashoutRequest, WalletSettings } from 'src/app/interfaces/interfaces';
import { WalletSettingsAPI } from 'src/app/api/topup.api';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { FormControl } from '@angular/forms';
import { Paginator } from 'src/app/classes/paginator';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';


@Component({
    selector: 'app-export-cashout-request',
    templateUrl: './export-cashout.component.html',
    styleUrls: ['./export-cashout.component.css']
  })

export class ExportCashoutComponent implements OnInit {

tableheadingShow: any;
filterForm: FormGroup;
// filterModel: ExportWalletRequestFilter;
searchDriverCashoutRequest = new FormControl('');
paginator: Paginator<CashoutRequest> = null;
// APIS
cashoutListApi: CashoutRequestsAPI;
walletSettingsAPI: WalletSettingsAPI;
updateStatusAPI: CashoutStatusUpdateApi;
exportcashout: CashoutRequest[];
// Data
approvalStatus: any = {};
settings: WalletSettings;
filter = new CahoutFilter();
status: number;
gcashNo = new FormControl('');
constructor(
  dbService: DatabaseService) {
   this.cashoutListApi = new CashoutRequestsAPI(dbService, (requests) => {
    this.exportcashout = requests;
   // console.log('cashout', this.exportcashout);
   });
 }

ngOnInit() {
  // this.createFilterForm();
}
applyFilter() {
  // tslint:disable-next-line: max-line-length
 // console.log(this.gcashNo.value);
  this.cashoutListApi.exportCashout(this.filter.from.selectedDate, this.filter.to.selectedDate, this.gcashNo.value);
  this.tableheadingShow = true;
}


getCashoutStatusName(status: WalletCashoutStatus): string | null {
  return WalletUtils.getCashoutStatusName(status);
}
formatDate(date) {
  if (date === undefined ) { return ''; }
  return WalletUtils.formatDate(date.seconds);
}


exportAsCSV() {

  if (this.exportcashout === undefined) { return; }
  const filename = () => 'Export Cashout Report' + (new Date()).toLocaleDateString();
  const exporter = new CSVExporter(filename(), CSVExporter.CashoutExport());
  exporter.exportCashoutRequest(this.exportcashout as [CashoutRequest]);
}
}
class CahoutFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }

  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
 }



