import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { NewsArticle, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import { UploadTask } from 'src/app/classes/uploadtask';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AngularFireStorage } from '@angular/fire/storage';
import { ImageResult, ResizeOptions } from 'ng2-imageupload';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-create-news-article',
  templateUrl: './news-articles-create.component.html'
})
export class CreateNewsArticleComponent implements OnInit {
  newsArticleForm: FormGroup;
  newsArticle: NewsArticle;
  alert: AlertModel;
  bannerUrl: string;
  saving: boolean;
  regions = OnboardingUtils.getRegions();
  appReferenceNumber: string;
  uploadReferences: Map<FormControl, UploadTask>;
  resizeOptions: ResizeOptions = {
      resizeMaxHeight: 500,
  };
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    private storage: AngularFireStorage
  ) {
    this.uploadReferences = new Map();
    this.appReferenceNumber = this.generateApplicationID();
  }
  ngOnInit() {
    this.createForm();
  }
  private createForm() {
    this.newsArticleForm = this.fb.group({
        id: '',
        title: ['', [Validators.required]],
        shortDescription: ['', Validators.required],
        fullContent: ['', Validators.required],
        newsAuthor: ['', [Validators.required]],
        publishDate: ['', [Validators.required]],
        expiryDate: ['', [Validators.required]],
        newsType: ['', [Validators.required]],
        region: ['', [Validators.required]],
        linkUrl: ['', [Validators.required]],
        linkType: ['', [Validators.required]],
        bannerUrl: [null, [Validators.required]],
        newsUserType: ['', [Validators.required]],
        isActive: false,
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveNewsArticles(): boolean {
    return this.newsArticleForm.valid && this.newsArticleForm.dirty;
  }

  private generateApplicationID() {
    const timestamp = Math.round(new Date().getTime() / 1000.0);
    const random = Math.floor(1000000 + Math.random() * 9000000);
    return (timestamp + random) + ' ';
  }


  async createNewsArticles() {

    this.newsArticleForm.disable();
    this.saving = true;
    const values = this.newsArticleForm.controls.bannerUrl.value;
    const bannerPhotoUploadTask = this.uploadReferences.get(this.newsArticleForm.controls.bannerUrl as FormControl);
    const urls = async () => {
      if (!bannerPhotoUploadTask) {
        delete values.bannerUrl;
      } else {
        const bannerPhotoURL = await bannerPhotoUploadTask.urlObservable().toPromise();
        values.bannerUrl = bannerPhotoURL;
        this.newsArticleForm.get('bannerUrl').setValue(bannerPhotoURL);
      }
    };
    await urls();

    this.newsArticle = {
        id: '',
        title: this.newsArticleForm.controls.title.value,
        shortDescription: this.newsArticleForm.controls.shortDescription.value,
        fullContent: this.newsArticleForm.controls.fullContent.value,
        newsAuthor: this.newsArticleForm.controls.newsAuthor.value,
        publishDate: this.newsArticleForm.controls.publishDate.value,
        expiryDate: this.newsArticleForm.controls.expiryDate.value,
        newsType: this.newsArticleForm.controls.newsType.value,
        linkUrl: this.newsArticleForm.controls.linkUrl.value,
        linkType: this.newsArticleForm.controls.linkType.value,
        region: this.newsArticleForm.controls.region.value,
        bannerUrl: this.bannerUrl,
        newsUserType: this.newsArticleForm.controls.newsUserType.value,
        createDate: new Date(),
        updateDate: new Date(),
        isActive: this.newsArticleForm.controls.isActive.value,
      };

    this.dbService.createNewsArticles(this.newsArticle)
    .then(result => {
    })
      .then(() => {
        this.showAlert('success', 'News and Article created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the News and Article - ' + e.message);
        this.newsArticleForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  upload(event, c: AbstractControl) {
    const control = c as FormControl;
    const file = event.target.files[0];
    const name = OnboardingUtils.getFileName(control);
    const path = OnboardingUtils.getUploadRefNews(this.appReferenceNumber, file, name);
    if (path) {
      const uploadTask = new UploadTask(file, path, this.storage);
      this.uploadReferences.set(control, uploadTask);
      uploadTask.beginUpload();
    }
  }
  uploadProgress(c: AbstractControl) {
    const control = c as FormControl;
    const uploadTask = this.uploadReferences.get(control);
    if (uploadTask) { return uploadTask.uploadProgress; }
    return null;
  }
  selected(imageResult: ImageResult) {
    this.bannerUrl = imageResult.resized.dataURL;
  }
}
