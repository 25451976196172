import { DatePickerModel } from './DatePickerModel';
import { QueryFn } from '@angular/fire/firestore';
import { isNumber } from 'util';
import * as moment from 'moment';

/**
 * This file contains all the modal classes used for Filtering in the 
 * admin panel
 */

// FILTER: WALLET
export class WalletRequestFilter {
    status: number;
    requestedRange: DateRangeFilter;
    actedRange: DateRangeFilter;

    constructor() {
        this.status = 0;
        this.requestedRange = new DateRangeFilter(false);
        this.actedRange = new DateRangeFilter(false);
    }

    reset() {
        this.status = 0;
        this.requestedRange = new DateRangeFilter(false);
        this.actedRange = new DateRangeFilter(false);
    }

    query(): QueryFn {
        if (!isNumber(this.status)) return undefined;

        return (aRef) => {
            var ref = aRef.where("status", "==", this.status);
            if (this.requestedRange.enabled) {
                ref = ref.where('created_at', '>=', this.requestedRange.from.selectedDate)
                    .where('created_at', '<=', this.requestedRange.to.selectedDate)
                    .orderBy('created_at', 'desc');
            } else if (this.actedRange.enabled) {
                ref = ref.where('acted_on', '>=', this.actedRange.from.selectedDate)
                    .where('acted_on', '<=', this.actedRange.to.selectedDate)
                    .orderBy('acted_on', 'desc');
            }  else {
                ref = ref.orderBy('created_at', 'desc');
            }
            return ref;
        }
    }
}

/**
 * Defines a Date Range to be used in a filter. The "enabled" boolean defines
 * whether this filter needs to be considered in the parent filter
 */
export class DateRangeFilter {
    from: DatePickerModel;
    to: DatePickerModel;
    enabled: boolean;

    constructor(enabled: boolean) {
        this.from = new DatePickerModel(moment().startOf('day').toDate());
        this.to = new DatePickerModel(moment().endOf('day').toDate());
        this.enabled = enabled;
    }
}