import { Component, OnInit } from '@angular/core';
import { WalletTopupStatus, WalletUtils } from 'src/app/utils/wallet.utils';
import { DatabaseService } from 'src/app/services/database.service';
import { TopupRequest, WalletSettings } from 'src/app/interfaces/interfaces';
import { TopupRequestsAPI, WalletSettingsAPI, TopupStatusUpdateApi } from 'src/app/api/topup.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Paginator } from 'src/app/classes/paginator';
import { WalletRequestFilter } from 'src/app/classes/FilterModels';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import * as moment from 'moment';


@Component({
    selector: 'app-export-topup-request',
    templateUrl: './export-topup.component.html',
    styleUrls: ['./export-topup.component.css']
  })

  export class ExportTopupComponent implements OnInit {

    // Form Controls - Search
    search = new FormControl('');
    paginator: Paginator<TopupRequest> = null;

    filter = new TouUpFilter();
    gcashNo = new FormControl('');

    exporttopupRequest: TopupRequest[];

    // APIs
    topupListApi: TopupRequestsAPI;
    get topupRequests(): TopupRequest[] {
      return (this.paginator) ? this.paginator.getItems(this.search.value) : null;
    }
     /*** Returns a boolean indicating whether the data can be exported or not  */
    get canExport(): boolean {
      const allRequests = this.topupRequests;
      return (allRequests && allRequests.length > 0);
    }
    /*** to show dropdown and button */
    get canShowButton(): boolean {
      const allRequests = this.topupRequests;
      return (allRequests && allRequests.length > 0);
    }
    constructor(
      private dbService: DatabaseService,
      private apiService: ApiService,
      private modalService: NgbModal,
      private permissions: PermissionService) {
         this.topupListApi = new TopupRequestsAPI(dbService, (requests) => {
          this.exporttopupRequest = requests;
        this.paginator = new Paginator(1, requests.length, requests);
      });
    }
    ngOnInit() {
      //this.filterApplied();
    }
      // MARK: Helpers
      /**  Maps an integer indicating a topup status to a human readable string */
    getTopupStatusName(status: WalletTopupStatus): string | null {
      return WalletUtils.getTopupStatusName(status);
    }
    /*** Formats the timestamp in seconds to a readable date string * @param timestamp
     */
    formatDate(date) {
      if (date === undefined ) { return ''; }
      return WalletUtils.formatDate(date.seconds);
    }
     retry() {
      this.filterApplied();
    }
    filterApplied() {
      this.topupListApi.exportTopupRequest(this.filter.from.selectedDate, this.filter.to.selectedDate, this.gcashNo.value);
    }
    /**
     * Export content as CSV
     */
    exportAsCSV() {
      if (this.exporttopupRequest === undefined) { return; }
      const filename = () => { return 'Export Topup Report' + (new Date()).toLocaleDateString(); };
      const exporter = new CSVExporter(filename(), CSVExporter.TopupExport());
      exporter.exportTopupRequest(this.exporttopupRequest as [TopupRequest]);
    }
  }
class TouUpFilter {
    status: number;
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
      this.status = undefined;
    }
}