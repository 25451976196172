import { BaseAPI, APIState } from './base.api';
import { ApiService } from '../services/api.service';
import {
    Motocyclecheck, SkillsAssessment, Orientation, UpdateGearReleaseRequestPayload, UpdateAfterGearReleaseRequestPayload,
    UpdateTraningRequestPayload,
    UpdateAdminApprovalRequestPayload, ServiceSettings,  DeliverybagSettings
} from '../interfaces/interfaces';
export class OnboardingAdminApprovalAPI extends BaseAPI {
    callback: (isSuccess: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
    constructor(apiService: ApiService, callback: (isSuccess: boolean, message: string) => void) {
        super(null, apiService);
        this.callback = callback;
    }
    saveAdminApprovalData(driverId: string, phone: string, payload: UpdateAdminApprovalRequestPayload) {
        this.state = APIState.loading;
        this.apiService.updateAdminApprovalData(driverId, phone, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Onboarding information updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }

    saveMotorCycleCheck(driverId: string, payload: Motocyclecheck) {
        this.state = APIState.loading;
        this.apiService.updateDriverMototCyclecCheck(driverId, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveSkillsAssessment(driverId: string, payload: SkillsAssessment) {
        this.state = APIState.loading;
        this.apiService.updateSkillAssessment(driverId, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveOrientations(driverId: string, payload: Orientation) {
        this.state = APIState.loading;
        this.apiService.updateOrientation(driverId, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveGearReleaseForm(driverId: string, mainStatus: number, payload: UpdateGearReleaseRequestPayload) {
        this.state = APIState.loading;
        this.apiService.updateGearRelaseForm(driverId, mainStatus, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Status Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveGearActivationForm(driverId: string, mainStatus: number, payload: UpdateAfterGearReleaseRequestPayload) {
        this.state = APIState.loading;
        this.apiService.updateGearActivationForm(driverId, mainStatus, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Status Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveTraningData(driverId: string, payload: UpdateTraningRequestPayload) {
        this.state = APIState.loading;
        this.apiService.updateTraningData(driverId, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Onboarding information updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the cashout requests. Please try again. ' + err);
            });
    }
    saveServiceSettingsForm(driverId: string, phone: string, payload: ServiceSettings) {
        this.state = APIState.loading;
        this.apiService.updateServiceSettings(driverId, phone, payload)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the service settings. Please try again. ' + err);
            });
    }
    saveDeliverybagSettingsForm(driverId: string, payload: DeliverybagSettings) {
        this.state = APIState.loading;
        this.apiService.updateDeliverybagSettings(driverId, payload )
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.callback(true, 'Updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.callback(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.callback(false, 'There was an error while updating the service settings. Please try again. ' + err);
            });
    }
}
