import { OnInit, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { UploadTask } from 'src/app/classes/uploadtask';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';

enum UploadUIState {
  None = 0,
  UploadingFiles,
  SavingData,
  Error,
  Success
}

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})

export class UploadFilesComponent implements OnInit {

  uploadTasks: Array<UploadTask>;
  appRef: string;
  formData: any;

  errMsg?: string;
  canRetry: boolean = true;
  defaultErrMsg: string = 'There was an error submitting your form. Please try again';

  private state = UploadUIState.None;

  constructor(public activeModal: NgbActiveModal,
    private httpClient: ApiService, 
    private dbService: DatabaseService) { }

  ngOnInit() {
    this.beginUpload();
  }

  canShowSpinner() { return this.state == UploadUIState.UploadingFiles || this.state == UploadUIState.SavingData }
  canShowError() { return this.state == UploadUIState.Error; }
  canShowSuccess() { return this.state == UploadUIState.Success; }

  beginUpload() {
    this.uploadTasks.forEach((task) => { task.beginUpload() });
    this.state = UploadUIState.UploadingFiles;
    Promise.all(this.uploadTasks.map<AngularFireUploadTask>((task) => task.uploadTask))
        .then(values => {
          this.state = UploadUIState.SavingData;
          return this.httpClient.saveOnboardingForm(this.formData).toPromise();
        })
        .then(response => {
          this.state = (response["isSuccess"]) ? UploadUIState.Success : UploadUIState.Error;
          this.errMsg = this.getError(response);
          this.canRetry = this.canRetryOnError(response);
          return true;
        })
        .catch(e => this.state = UploadUIState.Error );
  }

  getError(response: any) {
    const isSuccess = response['isSuccess'];
    if (isSuccess) return null;
    const errorMessage = response['error']['message'];
    return (errorMessage) ? errorMessage : this.defaultErrMsg;
  }

  canRetryOnError(response: any) { 
    const isSuccess = response['isSuccess'];
    if (isSuccess) return false;
    const code = response['error']['code'];
    if (code && code === 'DUPLICATE_FORM_REQUEST') return false
    else return true;
  }

}
