import { Component, OnInit } from '@angular/core';
import {  TripListExportAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverLocationStat } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
    selector: 'app-driver-trip',
    templateUrl: './driver-location-report.component.html',
    styleUrls: ['./driver-inactive-report.component.css']
    })
  export class DriverTripLocationCountComponent implements OnInit {
    tripListExportAPI: TripListExportAPI;
    dailyStatDate: Date = new Date();
    trips: DriverLocationStat[];
   constructor(private dbService: DatabaseService, public utils: UtilsService,               private modalService: NgbModal,
               public permissions: PermissionService) {
      this.trips = [];
      this.tripListExportAPI = new TripListExportAPI(dbService, (trips) => {
        this.trips = [];
        this.trips = trips;
     });
     }
  ngOnInit() {

    }
     applyFilter() {
      const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
      this.tripListExportAPI.getDriverTripsLocations(dayId);
     }
  }

