import { Component, OnInit } from '@angular/core';
import { TripListAPI } from 'src/app/api/trip.api';
import { DriverAllTimeStatsAPI, DriverDailyStatsAPI} from 'src/app/api/driver-stats.api';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverTripStat } from 'src/app/interfaces/interfaces';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-driver-trips',
  templateUrl: './trip-management-driver-trips.component.html',
  styleUrls: ['./trip-management-report.component.css' ]
})
export class DriverTripComponent implements OnInit {
  tripListAPI: TripListAPI;
  allTimeStatsAPI: DriverAllTimeStatsAPI;
  dailyStatsAPI: DriverDailyStatsAPI;
  trips: Trip[];
  allTimeStat: DriverTripStat = {};
  dailyStat: DriverTripStat = {};
  filter = new DriverTripFilter();
  driverphone = new FormControl('');
  dirverUid = new FormControl('');
  driverId: any;
  tripDate: any;
  canShowDailySummary: boolean;
  dailyStatDate: Date = new Date();
constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService) {
    this.trips = [];
    this.allTimeStat = {};
    this.canShowDailySummary = true;
    this.tripListAPI = new TripListAPI(dbService, (trips) => {
      this.trips = trips;
      this.getDriverOverAllSummary();
    });
    this.allTimeStatsAPI = new DriverAllTimeStatsAPI(dbService, (success, stat) => {
      this.allTimeStat = (success && stat) ? stat : {};
      this.canShowDailySummary = true;
     });
    this.dailyStatsAPI = new DriverDailyStatsAPI(dbService, (success, stat) => {
      this.allTimeStat = (success && stat) ? stat : {};
      this.canShowDailySummary = false;
    });
}
ngOnInit() {
}
applyFilter() {
  this.tripListAPI.getDriverTrips(this.filter.from.selectedDate, this.filter.to.selectedDate, this.driverphone.value, this.dirverUid.value);
}
 getDriverOverAllSummary() {
  if (this.trips[0] === undefined) { return; }
  this.driverId =  this.trips[0].driver.id;
  this.allTimeStatsAPI.fetchAllTimeStats(this.driverId);
}
getDriverDailySummary() {
  this.tripDate = moment(this.dailyStatDate).format('MM-DD-YYYY');
  const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
  this.dailyStatsAPI.fetchStatsOnDate(this.driverId, dayId);
}
export() {
    const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
    exporter.exportTripManagement(this.trips as Trip[]);
}
get canExport(): boolean {
   return (this.trips && this.trips.length > 0);
}
openTripInfoModal(trip: Trip) {
    const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true });
    modalRef.componentInstance.trip = trip;
}
optional(val: number, round: boolean = false, defaultVal: string = '0') {
  return (val !== undefined) ? (round ? val.toFixed(2) : val) : defaultVal;
}
optionalDistanceInMeter(val: number, round: boolean = false, defaultVal: string = '0') {
  return (val !== undefined) ? (round ? (val / 1000).toFixed(2) : val) : defaultVal;
}
canFillterBikers(): boolean {
   return this.driverphone.dirty;
}
canFillterBikersId(): boolean {
  return this.dirverUid.dirty;
}
}
/*** FILTER for Trips */
class DriverTripFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
}
setStatus(status: number) {
}
reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
}
}
