import { Component, OnInit, OnDestroy } from '@angular/core';
import { DriverLocationListener } from 'src/app/api/driverList.api';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverLocation, DriverLocationData } from 'src/app/interfaces/interfaces';
import { MarkerController } from 'src/app/classes/MarkerController';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
enum LocationStatus {
  AVAILABLE = 0,
  TRIP_REQUESTED = 1,
  IN_TRIP = 2,
}
@Component({
  selector: 'app-online-drivers',
  templateUrl: './online-drivers.component.html',
  styleUrls: ['./online-drivers.component.css']
})
export class OnlineDriversComponent implements OnInit, OnDestroy {

  locationListener: DriverLocationListener;
  locations: DriverLocation[];
  stats: DriverLocationStats;
  markerController: MarkerController;
  onboardingRegions = [];
   constructor(dbService: DatabaseService, apiService: ApiService, public utils: UtilsService, private modalService: NgbModal) { 
    this.locations = [];
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.stats = new DriverLocationStats([]);
    this.markerController = new MarkerController(dbService);
    this.locationListener = new DriverLocationListener(dbService, (locations) => {
      this.markerController.reset();
      this.stats = new DriverLocationStats(locations);
    });
  }

  ngOnInit() {
    this.locationListener.beginListening();
  }

  ngOnDestroy(): void {
    this.locationListener.endListening();
  }

  markerIconForLocation(loc: DriverLocation): string {
    if (loc.d.online && loc.d.status !== undefined) {
      switch (loc.d.status) {
        case LocationStatus.AVAILABLE: return 'assets/marker/marker-yellow.png';
        case LocationStatus.IN_TRIP: return 'assets/marker/marker-green.png';
        case LocationStatus.TRIP_REQUESTED: return 'assets/marker/marker-blue.png';
        default: return null;
      }
    } else {
       return 'assets/marker/marker-red.png';
    }
  }

  statusString(status: number): string {
    if (status === undefined || status === null) { return ''; }
    switch (status) {
      case LocationStatus.AVAILABLE: return 'AVAILABLE';
      case LocationStatus.TRIP_REQUESTED: return 'TRIP REQUESTED';
      case LocationStatus.IN_TRIP: return 'IN TRIP';
      default: return '';
    } 
  }

  openTripInfoModal(trip: Trip) {
    const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true});
    modalRef.componentInstance.trip = trip;
  }

  lastUpdatedDate(locData: DriverLocationData): string {
    if (locData.updated_at) { return this.utils.formatDate(locData.updated_at.toDate()); } else { return 'N/A'; }
  }
}

class DriverLocationStats {

  onlineWithTrip = 0;
  onlineTripRequested = 0;
  onlineWithoutTrip = 0;
  offline = 0;
  onlineWithTripCebu = 0;
  onlineTripRequestedCebu = 0;
  onlineWithoutTripCebu = 0;
  offlineCebu = 0;
  metroManila: any;
  metrocebu: any;
  constructor(locations: DriverLocation[]) {
    locations.forEach((loc) => {
      if (loc.d.online && loc.d.status !== undefined && loc.d.region === 'Metro Manila' ) { 
        switch (loc.d.status) {
          case LocationStatus.AVAILABLE:
            this.onlineWithoutTrip += 1;
            break;
          case LocationStatus.IN_TRIP:
            this.onlineWithTrip += 1;
            break;
          case LocationStatus.TRIP_REQUESTED:
            this.onlineTripRequested += 1;
            break;
          default: 
         // this.offline += 1;
          break;
        } 
      } else if(!loc.d.online && loc.d.status !== undefined && loc.d.region === 'Metro Manila'){
        this.offline += 1;
      } else if(loc.d.online && loc.d.status !== undefined && loc.d.region === 'Metro Cebu'){
        switch (loc.d.status) {
          case LocationStatus.AVAILABLE:
            this.onlineWithoutTripCebu += 1;
            break;
          case LocationStatus.IN_TRIP:
            this.onlineWithTripCebu += 1;
            break;
          case LocationStatus.TRIP_REQUESTED:
            this.onlineTripRequestedCebu += 1;
            break;
          default: 
          break;
        }
      } else if(!loc.d.online && loc.d.status !== undefined && loc.d.region === 'Metro Cebu'){
        this.offlineCebu += 1;
      } 
    });
  }
}
