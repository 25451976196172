import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { FormControl } from '@angular/forms';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-emi-search',
  templateUrl: './emi-search.component.html',
  styleUrls: ['./emi-search.component.css']
})
export class DriverEmiSearchComponent implements OnInit {
  emivalue: any[];
  api: DriversListAPI;
  disable = false;
  filter = new DriverEmiFilter();
  driverId = new FormControl('');
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  constructor(private dbService: DatabaseService, private utils: UtilsService,
              public permissions: PermissionService, public datepipe: DatePipe) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.emivalue = [];
    this.api = new DriversListAPI(dbService, (emi) => {
      if (this.region.value === 'Metro Manila') {
        this.emivalue = emi.filter(regionFilter => (regionFilter.region === undefined) ||
        (regionFilter.region === this.region.value));
        } else {
          this.emivalue = emi.filter(regionFilter => regionFilter.region === this.region.value);
        }
     });
}
ngOnInit() {
}
async applyFilter() {
  this.emivalue = [];
  await this.api.searchDriverEMI(this.datepipe.transform(this.filter.from.selectedDate, 'yyyy-MM-dd'), this.driverId.value);
}
}
/*** FILTER for Driver EMI*/
class DriverEmiFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
  }
}
