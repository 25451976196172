import { Component, OnInit } from '@angular/core';
import { DriverAllTimeStatsAPI, DriverDailyStatsAPI, DriverWeeklyStatsAPI, DriverMonthlyStatsAPI } from 'src/app/api/driver-stats.api';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverTripStat, DriverProfile, AlertModel } from 'src/app/interfaces/interfaces';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI, DriverProfileAPI } from 'src/app/api/driverList.api';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
@Component({
  selector: 'app-driver-stats',
  templateUrl: './driver-stats.component.html',
  styleUrls: ['./driver-stats.component.css']
})
export class DriverStatsComponent implements OnInit {
  driverId: string;
  allTimeStatsAPI: DriverAllTimeStatsAPI;
  dailyStatsAPI: DriverDailyStatsAPI;
  weeklyStatsAPI: DriverWeeklyStatsAPI;
  monthlyStatsAPI: DriverMonthlyStatsAPI;
  allTimeStat: DriverTripStat = {};
  dailyStat: DriverTripStat = {};
  weeklyStat: DriverTripStat = {};
  monthlyStat: DriverTripStat = {};
  dailyStatDate: Date = new Date();
  weeklyStatDate: Date = new Date();
  monthlyStatDate: Date = new Date();
  paginator: DriverProfilePaginator = null;
  phone = new FormControl('');
  bikerid = new FormControl('');
  api: DriversListAPI;
  driverProfileAPI: DriverProfileAPI;
  driverProfile: DriverProfile;
  isDriverChange: boolean;
  isStatisticsfound: boolean;
  alert: AlertModel;
  diverId: any;
   constructor( private dbService: DatabaseService) {
    this.isDriverChange = false;
    this.isStatisticsfound = false;
    this.api = new DriversListAPI(dbService, (drivers) => {
      if (drivers[0] !==  undefined ) {
        if (drivers[0].uid === undefined) { return '' };
        this.diverId = drivers[0].uid;
        this.getDriverProfileInformation(this.diverId);
        } else {drivers[0] = 0;
                this.showAlert('danger', 'No Biker was found with the searched ID');}
      });
    this.driverProfileAPI = new DriverProfileAPI(this.dbService, true, (success, profile) => {
      if (success && profile) {
        this.driverProfile = profile;
        } else if (success) {
        this.showAlert('danger', 'No Biker was found with the searched ID');
      } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Biker profile'); }
    });
    this.allTimeStatsAPI = new DriverAllTimeStatsAPI(dbService, (success, stat) => {
      this.allTimeStat = (success && stat) ? stat : {};
    });
    this.dailyStatsAPI = new DriverDailyStatsAPI(dbService, (success, stat) => {
      this.dailyStat = (success && stat) ? stat : {};
    });
    this.weeklyStatsAPI = new DriverWeeklyStatsAPI(dbService, (success, stat) => {
      this.weeklyStat = (success && stat) ? stat : {};
    });
    this.monthlyStatsAPI = new DriverMonthlyStatsAPI(dbService, (success, stat) => {
      this.monthlyStat = (success && stat) ? stat : {};
    });
  }
  ngOnInit() {
  }
  fetchAllTimeStats(UID) {
    this.isStatisticsfound = true;
    this.driverId = UID;
    if (this.driverId === undefined || this.driverId.trim().length === 0) { return; }
    this.allTimeStatsAPI.fetchAllTimeStats(this.driverId);
  }
  fetchDailyStat() {
    if (this.driverId === undefined || this.driverId.trim().length === 0) { return; }
    const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
    this.isDriverChange = true;
    this.dailyStatsAPI.fetchStatsOnDate(this.driverId, dayId);
  }
  fetchWeeklyStat() {
    if (this.driverId === undefined || this.driverId.trim().length === 0) { return; }
    const weekId = moment(this.weeklyStatDate).format('YYYY-WW');
    this.isDriverChange = true;
    this.weeklyStatsAPI.fetchStatsOnDate(this.driverId, weekId);
  }
  fetchMonthlyStat() {
    if (this.driverId === undefined || this.driverId.trim().length === 0) { return; }
    const monthId = moment(this.monthlyStatDate).format('YYYY-MM');
    this.isDriverChange = true;
    this.monthlyStatsAPI.fetchStatsOnDate(this.driverId, monthId);
  }
  private async loadDriverApplications(phone?: string, bikerid?: string) {
    this.api.loadDriverTripAvailability(phone, bikerid);
  }
  getDriverProfileInformation(driverId) {
    this.driverProfileAPI.fetchDriverProfile(driverId);
    this.fetchAllTimeStats(driverId);
  }
  getDriverSearch() {
    this.isDriverChange = false;
    this.isStatisticsfound = false;
    this.dailyStat = {};
    this.weeklyStat = {};
    this.monthlyStat = {};
    this.loadDriverApplications(this.phone.value, this.bikerid.value);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  optional(val: number, round: boolean = false, defaultVal: string = '0') {
    return (val !== undefined) ? (round ? val.toFixed(2) : val) : defaultVal;
  }
  optionalDistanceInMeter(val: number, round: boolean = false, defaultVal: string = '0') {
    return (val !== undefined) ? (round ? (val / 1000).toFixed(2) : val) : defaultVal;
  }
  optionalrating(rating: number, numTripsCompleted: number ) {
    if (rating === undefined || numTripsCompleted === undefined) { return '0'; }
    if (numTripsCompleted === 0) { return '0'; }
    return (rating / numTripsCompleted).toFixed(2) + '';
  }
}
