import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  OnboardingApplication, WalletAdjustment, OnboardingPersonalDetails,
  UpdatePersonalDetailsRequestPayload, UpdateVehicleDetailsRequestPayload, vehiclePermission,
  UpdateGCashDetailsRequestPayload, UpdateDriverPhotosRequestPayload,
  UpdateVehiclePhotosRequestPayload, Motocyclecheck, UpdateGearReleaseRequestPayload,
  UpdateAfterGearReleaseRequestPayload, UpdateTraningRequestPayload,
  SkillsAssessment, Orientation, UpdateAdminApprovalRequestPayload, Customer,
  TopupManual, UpdateDriverStatusPayload, EMIAdjustment, ServiceSettings, DeliverybagSettings, EMIBulkAdjustment, FareSettingsFranchiseArea, AssignDriver
} from '../interfaces/interfaces';
import { environment } from '../../environments/environment';
import * as firebase from 'firebase/app';
import { UpdateTripStatusRequest } from '../interfaces/trip-interfaces';
import { CreateAdminUserRequest } from '../api/user.api';
import { now } from 'moment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  private url(path: string): string {
    return `${environment.endpoint}/${path}`;
  }

  private happymoveurl(path: string): string {
    return `${environment.happymove}/${path}`;
  }

  private wemoveurl(path: string): string {
    return `${environment.wemove}/${path}`;
  }

  sendDriverId(bikerId: string) {
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = this.happymoveurl('delivery/driver/generateid?bikerId='+bikerId);
    return this.httpClient.get(url, { headers: httpHeader }).toPromise();
  } 

  async adminSendTripReceipt(tripId: string) {
    const url = this.url('admin/adminSendTripReceipt');
    const token = await firebase.auth().currentUser.getIdToken();
    const body1 = { ['tripId']: tripId }
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token,
      'source': 'customer'
    });
    return this.httpClient.post(url, body1, { headers: httpHeader }).toPromise();
  }

  updateFareSettingsValues(newFares: any, tripArea: string, tripType: string) {
    const body1 = { ['fares']: newFares, ['uid']: firebase.auth().currentUser.uid, ['location']: tripArea, ['service']: tripType  }
    const url = this.url('admin/franchise/fares');
    return this.httpClient.post(url, body1).toPromise();
  }

  updateFareSettingsEnable(enable: boolean, tripArea: string, tripType: string) {
    const body1 = { ['enable']: enable ? 0:1, ['uid']: firebase.auth().currentUser.uid, ['location']: tripArea, ['service']: tripType  }
    const url = this.url('admin/franchise/service');
    return this.httpClient.post(url, body1).toPromise();
  }

  saveOnboardingForm(form: OnboardingApplication) {
    return this.httpClient.post(this.url('submitOnboardingForm'), form);
  }

  checkEmailavailability(email: string) {
    const body1 = { ['email']: email };
    return this.httpClient.post(this.url('validation/checkemailavailability'), body1);
  }

  checkPhoneavailability(phone: string) {
    const body1 = { ['phone']: phone };
    return this.httpClient.post(this.url('validation/checkphoneavailability'), body1);
  }

  async processBulkEmiUpdate(file_to_upload: File, remarks: string) {
    let formData:FormData = new FormData();
    formData.append('remarks', remarks);
    formData.append('csv_sheet', file_to_upload);
    formData.append('uid', await firebase.auth().currentUser.uid);
    const url = this.url('admin/gear/balance-csv');
    return this.httpClient.post(url, formData).toPromise();
  }

  async assignDriver(payload: AssignDriver) {
    const url = this.url('admin/driver/assign-driver');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }

  async setUserPassword(payload) {
    const url = this.wemoveurl('auth/admin-set-password');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }

  async getCustomerLoadTransaction(limit: number, from: string, to:string) {
    const url = this.wemoveurl('jr-load/admin/orders?_order=DESC&_end='+limit+'&_sort=order_id&_start=0&createdAt='+from+','+to+'&user_uid_type=customer');
    const token = await firebase.auth().currentUser.getIdToken();
    const uid = firebase.auth().currentUser.uid;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token,
      'xuid_type': 'admin',
      'xcustomeruid': uid
    });
    return this.httpClient.get(url, { headers: httpHeader }).toPromise();
  }

  async getDriverLoadTransaction(limit: number, from: string, to:string) {
    const url = this.wemoveurl('jr-load/admin/orders?_order=DESC&_end='+limit+'&_sort=order_id&_start=0&createdAt='+from+','+to+'&user_uid_type=driver');
    const token = await firebase.auth().currentUser.getIdToken();
    const uid = firebase.auth().currentUser.uid;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token,
      'xuid_type': 'admin',
      'xcustomeruid': uid
    });
    return this.httpClient.get(url, { headers: httpHeader }).toPromise();
  }

  async processBulkDriverUpdateServices(file_to_upload: File) {
    let formData:FormData = new FormData();
    formData.append('csv_sheet', file_to_upload);
    formData.append('uid', await firebase.auth().currentUser.uid);
    const url = this.url('admin/driver/bulk-update-services');
    return this.httpClient.post(url, formData).toPromise();
  }

  async processBulkDriverUpdateBags(file_to_upload: File) {
    let formData:FormData = new FormData();
    formData.append('csv_sheet', file_to_upload);
    formData.append('uid', await firebase.auth().currentUser.uid);
    const url = this.url('admin/driver/bulk-availableBags');
    return this.httpClient.post(url, formData).toPromise();
  }

  async processGoogleDistAPI(url: any) {
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.get(url, { headers: httpHeader }).toPromise().then(snapshot => {
      return snapshot;
    });
  }
  async processTopupRequests(statuses: any) {
    const url = this.url('admin/approveTopupRequests');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, statuses, { headers: httpHeader }).toPromise();
  }
  async processCashoutRequests(statuses: any) {
    const url = this.url('admin/approveCashoutRequests');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, statuses, { headers: httpHeader }).toPromise();
  }
  async adjustWallet(adjustment: WalletAdjustment) {
    const url = this.url('admin/walletAdjustment');
    const token = await firebase.auth().currentUser.getIdToken();
    const adminName = await firebase.auth().currentUser.displayName;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    adjustment.displayName = adminName;
    return this.httpClient.post(url, adjustment, { headers: httpHeader }).toPromise();
  }
  async topupManualWallet(topupManual: TopupManual) {
    const url = this.url('admin/topupManual');
    const token = await firebase.auth().currentUser.getIdToken();
    const adminName = await firebase.auth().currentUser.displayName;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    topupManual.displayName = adminName;
    return this.httpClient.post(url, topupManual, { headers: httpHeader }).toPromise();
  }
  async updateTripStatus(payload: UpdateTripStatusRequest) {
    const url = this.url('admin/updateTripStatus');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverLocationStatus(driverId: string, payload: UpdateDriverStatusPayload) {
    const url = this.url('admin/updateDriverLocationStatus');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.online = (payload.online.toString() === 'true' ? true : false);
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateAdminApprovalData(driverId: string, phone: string, payload: UpdateAdminApprovalRequestPayload) {
    const url = this.url('admin/updateFormStatus');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.phone = phone;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverPersonalData(driverId: string, payload: UpdatePersonalDetailsRequestPayload) {
    const url = this.url('admin/updateDriverPersonalInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverVehicleData(driverId: string, payload: UpdateVehicleDetailsRequestPayload) {
    const url = this.url('admin/updateDriverVehicleInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverVehiclePermission(payload: vehiclePermission) {
    const url = this.url('admin/updateDriverVehiclePermission');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.uid = firebase.auth().currentUser.uid;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverGCashData(driverId: string, phone: string, payload: UpdateGCashDetailsRequestPayload) {
    const url = this.url('admin/updateDriverGCashInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.phone = phone;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDriverPhotoData(driverId: string, payload: UpdateDriverPhotosRequestPayload) {
    const url = this.url('admin/updateDriverPhotoInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateVehiclePhotoData(driverId: string, payload: UpdateVehiclePhotosRequestPayload) {
    const url = this.url('admin/updateVehiclePhotoInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async deleteCustomerProfile(customer: Customer) {
    const url = this.url('admin/deleteCustomerProfile');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, customer, { headers: httpHeader }).toPromise();
  }
  async updateDriverPersonalGcash(driverId: string, payload: OnboardingPersonalDetails) {
    const url = this.url('admin/updateDriverPersonalInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
       ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async createAdminUser(userInfo: CreateAdminUserRequest) {
    const url = this.url('admin/createUser');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, userInfo, { headers: httpHeader }).toPromise();
  }
  async deleteUser(uid: string) {
    const url = this.url('admin/deleteUser');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, { ['uid']: uid }, { headers: httpHeader }).toPromise();
  }
  async updateRoles(uid: string, roles: string[]) {
    const url = this.url('admin/updateRoles');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, { ['uid']: uid, ['roles']: roles }, { headers: httpHeader }).toPromise();
  }

  async updateAdminUser(uid: string, userInfo: CreateAdminUserRequest) {
    const url = this.url('admin/updateUser');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, { ['uid']: uid, ['userInfo']: userInfo }, { headers: httpHeader }).toPromise();
  }
  async updateDriverMototCyclecCheck(driverId: string, payload: Motocyclecheck) {
    const url = this.url('admin/updateMotorCycleCheck');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateSkillAssessment(driverId: string, payload: SkillsAssessment) {
    const url = this.url('admin/updateSkillAssessment');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateOrientation(driverId: string, payload: Orientation) {
    const url = this.url('admin/updateOrientation');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateGearRelaseForm(driverId: string, mainStatus: number, payload: UpdateGearReleaseRequestPayload) {
    const url = this.url('admin/updateGearRelease');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.mainStatus = mainStatus;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateGearActivationForm(driverId: string, mainStatus: number, payload: UpdateAfterGearReleaseRequestPayload) {
    const url = this.url('admin/updateGearActivation');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.mainStatus = mainStatus;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateTraningData(driverId: string, payload: UpdateTraningRequestPayload) {
    const url = this.url('admin/updateTrainingData');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async  updateImportDriverProfile(uid: string, phone: string, gcashnumber: string, bikerId: string,
                                   driverPhoto: string, activationDate: string) {
    const objImportDriverProfile = {
      ['uid']: uid, ['phone']: phone, ['gcashnumber']: gcashnumber, ['bikerId']: bikerId,
      ['driverPhoto']: driverPhoto, ['activationDate']: activationDate
    };
    const url = this.url('admin/updateDriverBulkInfo');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, objImportDriverProfile, { headers: httpHeader }).toPromise();
  }
  async updateEMI(selectedDate: string) {
    const url = this.url('admin/updateDriverEMI');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    const objemiDate = { ['emiDate']: selectedDate };
    return this.httpClient.post(url, objemiDate, { headers: httpHeader }).toPromise();
  }
  async bulkDriverTopup(driverId: string, gCashRefId: string, reason: string, amount: number, paymentType: string, paymayaRefNo: string) {
    const objTopupImport = { ['driverId']: driverId, ['gCashRefId']: gCashRefId, ['reason']: reason, ['amount']: amount,
    ['displayName']: '', ['paymentType']: paymentType, ['paymayaRefNo']: paymayaRefNo};
    const url = this.url('admin/topupManual');
    const token = await firebase.auth().currentUser.getIdToken();
    const adminName = await firebase.auth().currentUser.displayName;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    objTopupImport.displayName = adminName;
    return this.httpClient.post(url, objTopupImport, { headers: httpHeader }).toPromise();
  }
  async emiAdjustment(adjustment: EMIAdjustment) {
    const url = this.url('admin/DriverEMIManual');
    const token = await firebase.auth().currentUser.getIdToken();
    const adminName = await firebase.auth().currentUser.displayName;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    adjustment.displayName = adminName;
    return this.httpClient.post(url, adjustment, { headers: httpHeader }).toPromise();
  }
  async bulkDriverDeactivate(data: any) {
    const url = this.url('admin/bulkdriverdeactivate');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, data, { headers: httpHeader }).toPromise();
  }
  async bindAccInquiry(phone: string, referenceNumber: string) {
    const data = {  ['externalUserId'] : phone, ['referenceNo'] : referenceNumber };
    const url = 'https://dev.payment.joyride.com.ph/metrobank/bindInquiry';
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, data, { headers: httpHeader }).toPromise();
  }
  async topUpInquiry(phone: string, referenceNumber: string) {
    const data = {  ['phone'] : phone, ['referenceNo'] : referenceNumber };
    const url = 'https://dev.payment.joyride.com.ph/metrobank/admintopupInquiry';
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, data, { headers: httpHeader }).toPromise();
  }
  async paymayaTopupInquiry(referenceNumber: string) {
    const data = {  ['rrn'] : referenceNumber };
    const url = 'http://paymaya-dev.wemove.com.ph/paymaya/checkrecurringpayment';
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });

    return this.httpClient.post(url, data, { headers: httpHeader }).toPromise();
  }
  async updateServiceSettings(driverId: string, phone: string, payload: ServiceSettings) {
    const url = this.url('admin/updateDriverPermission');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    payload.driverId = driverId;
    payload.phone = phone;
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async updateDeliverybagSettings(driverId: string, payload: DeliverybagSettings) {
    const body1 = { ['driverId']: driverId, ['bags']: payload, ['uid']: firebase.auth().currentUser.uid }
    const url = this.url('admin/driver/updateAvailableBags');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, body1, { headers: httpHeader }).toPromise();
  }
  async updateTopupWebhook(payload: any) {
    const url = this.url('admin/adminDriverwebhookcallback');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
     'Content-Type': 'application/json',
     'source': 'driver',
     ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.post(url, payload, { headers: httpHeader }).toPromise();
  }
  async adjustWalletCustomer(adjustment: WalletAdjustment) {
    const url = this.url('admin/customerwalletAdjustment');
    const token = await firebase.auth().currentUser.getIdToken();
    const adminName = await firebase.auth().currentUser.displayName;
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    adjustment.displayName = adminName;
    return this.httpClient.post(url, adjustment, { headers: httpHeader }).toPromise();
  }
  async updateOrderType(tripId: string) {
    const payload = { ['tripId']: tripId};
    const url = this.wemoveurl('happymove-delivery/admin/update/order-type');
    const token = await firebase.auth().currentUser.getIdToken();
    const httpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      ['Authorization']: 'Bearer ' + token
    });
    return this.httpClient.put(url, payload, { headers: httpHeader }).toPromise();
  }
}
