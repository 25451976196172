import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTricycleDropoffComponent } from 'src/app/modals/tricycle/create-tricycle-dropoff.component';
import { EditTricycleDropoffComponent } from 'src/app/modals/tricycle/edit-tricycle-dropoff.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import {  AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-tricycle-dropoff',
  templateUrl: './tricycle-dropoff-cities.component.html'
})
export class TricycleDropoffComponent implements OnInit {
  tricycleDropoff: any = [];
  loading: boolean = false;
  onboardingRegions = [];
  defaultRegion: any;
  tricyclePickup: any[];
  region = new FormControl();
  onboardingCity: SupportedRegions[];
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
  }
  ngOnInit() {
    this.getRegionCityList();
    this.loadTricycledropoffCities();
  }

  createTricycledropoff() {
    const modalRef =  this.modalService.open(CreateTricycleDropoffComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.result
    .then((result) => { this.loadTricycledropoffCities();  }, (reason) => { });
  }
  editTricycledropoff(data) {
    this.loadTricyclePickupCities();
    const modalRef =  this.modalService.open(EditTricycleDropoffComponent, { size: 'lg' });
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.loadedData = data;
    modalRef.componentInstance.tricyclePickup = this.tricyclePickup;
    modalRef.result
    .then((result) => { this.loadTricycledropoffCities(); }, (reason) => { });
  }
  async loadTricycledropoffCities() {
    this.loading = true;
    this.dbService.getTricycleDropoffCities(this.defaultRegion)
    .then(results => {
      this.tricycleDropoff = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }
  async loadTricyclePickupCities() {
    this.dbService.getTricyclePickupCities(this.defaultRegion)
    .then(results => {
      this.tricyclePickup = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'DEACTIVATE';
      case false: return 'DISABLED';
      default: return '';
    }
  }
  deactivateTricycledropoff(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Deactivation Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to de-activate this  taxicle dropoff city:' + data.pickup_city +
    '? \nOnce de-activated, the taxicle dropoff city fee cannot be enabled again.';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deactivateTricycleDropoffCities(data.id, this.region.value);
      })
      .then(() => {
        this.loadTricycledropoffCities();
      })
      .catch(e => {
        console.log(e);
      });
  }
  deleteTricycledropoff(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete the taxicle dropoff city';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteTricycleDropoffCities(data.id, this.region.value);
      })
      .then(() => {
        this.loadTricycledropoffCities();
      })
      .catch(e => {
        console.log(e);
      });
  }
  getRegionCityList() {
    this.dbService.getOnboardingRegionList().then(regions => {
    const data = Object(regions)['regions'];
    this.onboardingCity = data;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }

}


