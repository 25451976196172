import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { PayMayaNumberUpdation, AlertModel } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-paymaya-number-updation',
  templateUrl: './paymayanumber-updation.component.html'
})
export class PaymayaNumberUpdationComponent implements OnInit {
  PayMayaNumberUpdationForm: FormGroup;
  PayMayaNumberUpdationModel: PayMayaNumberUpdation;
  alert: AlertModel;
  saving: boolean;
  loadedData: any = [];
  updatedby: string;
  description: any;
  canUpdate: any;
  isactiveYes: any;
  isactiveNo: any;
  updatedon: any;
  docID: any;

  constructor(
    private fb: FormBuilder,
    private dbService: DatabaseService,
    public utils: UtilsService
  ) {
   
  }
  ngOnInit() {
    this.getPaymayaNumberUpdation();
    this.createForm();
  }

  async getPaymayaNumberUpdation() {
    this.dbService.getPaymayaNumberUpdationStatus()
    .then(results => {
    this.loadedData = results;
    
    this.canUpdate = this.loadedData.canUpdate;
    this.updatedby = this.loadedData.updated_by;
    this.updatedon = this.loadedData.updated_on;
    this.docID = this.loadedData.id;
    if (this.loadedData.canUpdate === true) {
        this.isactiveYes = 'true';
      } else {
        this.isactiveNo = 'false';
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  private createForm() {
    this.PayMayaNumberUpdationForm = this.fb.group({
        canUpdate: ['', Validators.maxLength(100)]
    });
  }
  c(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveCustomerAPPServices(): boolean {
    return this.PayMayaNumberUpdationForm.valid && this.PayMayaNumberUpdationForm.dirty;
  }

  updateCustomerAPPServices() {
     const locisactive = (this.PayMayaNumberUpdationForm.controls.canUpdate.value === 'true' ? true : false);

     this.PayMayaNumberUpdationModel = {
      id: this.docID,
      canUpdate: locisactive,
      updated_by: this.updatedby,
      updated_on: this.updatedon
    };
     this.PayMayaNumberUpdationForm.disable();
     this.saving = true;
     this.dbService.updatePaymayaNumberUpdationStatus(this.PayMayaNumberUpdationModel, this.docID)
      .then(() => {
        this.showAlert('success', 'PayMaya Number Status updated successfully');
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while update the PayMaya Number Status - ' + e.message);
        this.PayMayaNumberUpdationForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
