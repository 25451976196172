import { Component, OnInit } from '@angular/core';
import { Customer, LoadTransactionList } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'moment';
import { CustomerLoadTransactionListListener } from 'src/app/api/merchant.api';
import { ApiService } from 'src/app/services/api.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
@Component({
  selector: 'app-customer-load-transaction',
  templateUrl: './customer-load-transaction.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerLoadTransactionComponent implements OnInit {
  loading: boolean = false;
  customerFormFilter: FormGroup;
  api: CustomerLoadTransactionListListener;
  CustomerLoadTransactionList: LoadTransactionList;
  date:any;
  selectedFilter = 20;
  filter = new CustomerFilter();

  constructor(private apiService: ApiService, private modalService: NgbModal, private fb: FormBuilder, public utils: UtilsService) {
                this.api = new CustomerLoadTransactionListListener(apiService, (success, list) => {
                  this.CustomerLoadTransactionList = list;
                });
   }

  ngOnInit() {
    this.createCustomerFormFilter();
  }
  filterpage(status: number) {
    this.selectedFilter = status;
    this.applyFilter();
  }

  createCustomerFormFilter() {
    const date: Date = new Date();
    this.applyFilter();
  }

  applyFilter() {
      this.loading = true;
      const from = moment(this.filter.from.date).format('YYYY-MM-DD')
      const to = moment(this.filter.to.date).format('YYYY-MM-DD')
      this.api.loadCustomerLoadTransactionList(this.selectedFilter,from,to);
  }

  covertDateFormat(data: string){
    return moment(data).format('MMM DD, YYYY hh:ss a')
  }

  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
}

class CustomerFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    setStatus(status: number) {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
  }


