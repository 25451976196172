import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { BaseAPI, APIState } from './base.api';
import { ApiService } from '../services/api.service';
export interface AdminUser {
    displayName: string;
    email: string;
    uid: string;
    region: string;
    roles?: string[];
}
export interface CreateAdminUserRequest {
    name: string;
    email: string;
    password: string;
    region: string;
}
export interface RoleUser {
    id: string;
    roleName: string;
    roleValue: string;
    status: boolean;
    roles?: string[];
}
export class AdminUserListListener {
    private unsubscribe: Subscription;
    private dbService: DatabaseService;
    private handler: (users: AdminUser[]) => void;
    constructor(dbService: DatabaseService, completion: (users: AdminUser[]) => void) {
        this.handler = completion;
        this.dbService = dbService;
    }
    beginListening() {
        this.endListening();
        this.unsubscribe = this.dbService.allAdminUsersSubscription().subscribe(values => {
            this.handler(values);
        }, error => {
            console.error(error);
        });
    }
    endListening() {
        if (this.unsubscribe) {
            this.unsubscribe.unsubscribe();
        }
    }
}
/**
 * Create User API
 */
export class CreateAdminUserAPI extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    setUserPassword(data) {
        this.state = APIState.loading;
        this.apiService.setUserPassword(data)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'New password has been successfully saved.');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while updating the password. Please try again. ' + e);
            });
    }
    createUser(user: CreateAdminUserRequest) {
        this.state = APIState.loading;
        this.apiService.createAdminUser(user)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'User was created successfully. Please set roles for the newly created user.');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while creating the user. Please try again. ' + e);
            });
    }
    deleteUser(uid: string) {
        this.state = APIState.loading;
        this.apiService.deleteUser(uid)
            .then((response) => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'User was deleted successfully.');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while deleting the user. Please try again. ' + e);
            });
    }
    updateRoles(uid: string, roles: string[]) {
        this.state = APIState.loading;
        this.apiService.updateRoles(uid, roles)
            .then((response) => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'User\'s roles were updated successfully.');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while updating roles for the user. Please try again. ' + e);
            });
    }

    updateUser(uid: string, user: CreateAdminUserRequest) {
        this.state = APIState.loading;
        this.apiService.updateAdminUser(uid, user)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'User was updated successfully. ');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while updating the user. Please try again. ' + e);
            });
    }
}
/**
 * Create User Claims and Roles
 */
export class UserClaimsRolesAPI extends BaseAPI {
    private handler: ([any]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([any]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getUserClaimsSearch(name: string, Id: string, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.getUserClaimsSearch(name, Id, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
getClaimSettingSearch(role: string, name: string, Id: string, limit?: number, statusValue?: string) {
    this.state = APIState.loading;
    let previousValue;
    if (statusValue === 'previous') {
        if (this.previousStart.length > (this.paginationCount + 1)) {
            previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
        }
        previousValue = this.previousStart[this.paginationCount - 1];
    }
    if (statusValue === 'load') {
        this.firstInResponse = [];
        this.lastInResponse = [];
        this.previousStart = [];
    }
    this.dbService.getClaimSettingSearch(role, name, Id, this.lastInResponse, previousValue,
    this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        this.previousStart.push(this.firstInResponse);
        if (statusValue === 'next') {
            this.paginationCount++;
        }
        this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
        if (statusValue === 'previous') {
            this.paginationCount--;
            this.previousStart.forEach(element => {
                if (this.firstInResponse.data().id === element.data().id) {
                    element = null;
                }
            });
        }
        this.handler(response.docs.map(c => c.data()) as [any]);
    }).catch(e => {
        console.error(e);
        this.state = APIState.failed;
    });
}
getRolesSearch(roleName: string, roleValue: string, Id: string, limit?: number, statusValue?: string) {
    this.state = APIState.loading;
    let previousValue;
    if (statusValue === 'previous') {
        if (this.previousStart.length > (this.paginationCount + 1)) {
            previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
        }
        previousValue = this.previousStart[this.paginationCount - 1];
    }
    if (statusValue === 'load') {
        this.firstInResponse = [];
        this.lastInResponse = [];
        this.previousStart = [];
    }
    this.dbService.getRolesSearch(roleName, roleValue, Id, this.lastInResponse, previousValue,
    this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];
        this.previousStart.push(this.firstInResponse);
        if (statusValue === 'next') {
            this.paginationCount++;
        }
        this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
        if (statusValue === 'previous') {
            this.paginationCount--;
            this.previousStart.forEach(element => {
                if (this.firstInResponse.data().id === element.data().id) {
                    element = null;
                }
            });
        }
        this.handler(response.docs.map(c => c.data()) as [any]);
    }).catch(e => {
        console.error(e);
        this.state = APIState.failed;
    });
}
}


