import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { DriverProfile, AlertModel } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { ApiService } from 'src/app/services/api.service';
import { DriverProfileAPI, DriversWalletListAPI } from 'src/app/api/driverList.api';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
@Component({
  selector: 'app-wallet-commission',
  templateUrl: './export-wallet-commission.component.html',
  styleUrls: ['./export-wallet-commission.component.css']
})
export class ExportWalletCommissionComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  driverProfile: DriverProfile;
  walletHistory: WalletHistory[];
  filter = new WalletHistoryFilter();
  gcashNo = new FormControl('');
  alert: AlertModel;
  searchConditionsForm: FormGroup;
  walletHistoryListener: DriverWalletHistoryListener;
  driverProfileAPI: DriverProfileAPI;
  driversListAPI: DriversWalletListAPI;
  wallethistoryLoading: boolean;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  constructor(private fb: FormBuilder, private apiService: ApiService, private dbService: DatabaseService, private utils: UtilsService,
              public permissions: PermissionService) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.wallethistoryLoading = false;
    this.walletHistory = [];
    this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
      if (success && history) {
      this.walletHistory = history;
      this.wallethistoryLoading = false;
      } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
    });
    this.driversListAPI = new DriversWalletListAPI(dbService, (drivers) => {
      this.paginator = new DriverProfilePaginator(1, 5, drivers);
    });
}
ngOnInit() {
    this.searchConditionsForm = this.fb.group({
      fullName: [],
      phone: []
    });
}
  getAllWalletHistory() {
    this.wallethistoryLoading = true;
    this.walletHistory = [];
    if (this.utils.checkRegionReleaseDatePrevious(this.filter.from.selectedDate) === true) {
    this.walletHistoryListener.getallWalletCommissionHistorysearchRegion(this.filter.from.selectedDate, this.filter.to.selectedDate,
    this.region.value);
    } else {
      this.walletHistoryListener.getallWalletCommissionHistorysearch(this.filter.from.selectedDate, this.filter.to.selectedDate);
    }
  }
  export() {
    const filename = () => 'Wallet Commission Details -' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.walletCommissionHistory());
    exporter.exportWalletCommission(this.walletHistory as WalletHistory[]);
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
class WalletHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
