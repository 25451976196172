import { Component, OnInit, Output, Input } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-empty-results',
  template: `
    <div class="empty-results-container mt-5">
      <div class="placeholder text-center">
        <i class={{iconClass()}}></i>
        <p class="title text-muted">{{title}}</p>
        <p>{{message}}</p>
      </div>
    </div>
  `,
  styleUrls: ['./emptyresults.component.css']
})
export class EmptyResultsComponent implements OnInit {

  @Input() iconName: string;
  @Input() title: string;
  @Input() message: string;

  constructor() { }

  ngOnInit() { }

  iconClass() { return this.iconName + ' text-muted'; }

}

@Component({
  selector: 'app-api-failed',
  template: `
    <div class="empty-results-container mt-5">
      <div class="placeholder text-center">
        <i class="fas fa-biking text-danger"></i>
        <p class="title text-danger">Oops! A Glitch!</p>
        <p>
          There was an error while executing your request<br>
          <button class="btn btn-danger mt-2" (click)="tryAgain()">Try Again</button>
        </p>
      </div>
    </div>
  `,
  styleUrls: ['./emptyresults.component.css']
})
export class APIErrorComponent {

  @Output()
  retry: EventEmitter = new EventEmitter();

  constructor() {
  }

  tryAgain() {
    this.retry.emit('RetryAction');
  }
}
