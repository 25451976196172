import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WalletRequestFilter } from 'src/app/classes/FilterModels';

@Component({
  selector: 'app-wallet-request-filter',
  templateUrl: './wallet-request-filter.component.html',
  styleUrls: ['./wallet-request-filter.component.css']
})
export class WalletRequestFilterComponent implements OnInit {

  @Output() 
  onApply: EventEmitter<WalletRequestFilter> = new EventEmitter();

  // Form - Filter
  filterForm: FormGroup;

  // Model
  filterModel: WalletRequestFilter;

  constructor(private fb: FormBuilder) {
    this.filterModel = new WalletRequestFilter();
  }

  ngOnInit() {
    this.createFilterForm();
  }

  private createFilterForm() {
    this.filterForm = this.fb.group({
      status: [this.filterModel.status, Validators.required],
      reqOnFrom: [this.filterModel.requestedRange.from.date],
      reqOnTo: [this.filterModel.requestedRange.to.date],
      reqActFrom: [this.filterModel.actedRange.from.date],
      reqActTo: [this.filterModel.actedRange.to.date]
    });
  }

  applyFilter() {
    const status = (<HTMLSelectElement>document.getElementById('filterselect')).value;
    if (status === '1') {
      this.filterModel.status = 1;
    } else if (status === '2') {
      this.filterModel.status = 2;
    } else {
      this.filterModel.status = 0;
    }
    this.onApply.emit(this.filterModel);
  }

  resetFilter() {
    this.filterModel.reset();
    this.onApply.emit(this.filterModel);
  }

  enableReqOnDateFilter(enable: boolean) {
    this.filterModel.requestedRange.enabled = enable;
    if (enable) { this.filterModel.actedRange.enabled = false; }
  }

  enableReqActDateFilter(enable: boolean) {
    this.filterModel.actedRange.enabled = enable;
    if (enable) { this.filterModel.requestedRange.enabled = false; }
  }
}
