import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AlertModel, FareSettings, FareSlab, SupportedRegions } from 'src/app/interfaces/interfaces';
import { UpdateFareSettingsAPI } from 'src/app/api/fareSettings.api';

@Component({
  selector: 'app-fare',
  templateUrl: './fare.component.html',
  styleUrls: ['./fare.component.css']
})
export class FareComponent implements OnInit {
  private fareSettingsObservable: Observable<FareSettings>;
  private updateFareSettingsAPI: UpdateFareSettingsAPI;
  isEditing = false;
  isFareTableValid = true;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  canShowregion: boolean;
  get disableField(): boolean {
    return this.updateFareSettingsAPI.isLoading;
  }
  get fareTable(): FareSlab[] {
    if (this.region.value === this.onboardingRegions[0]) {
    return this.fareSettings.fareTable;
    } else {
      return this.fareSettings.fareTableCebu;
    }
  }
  alert: AlertModel;
  fareSettings: FareSettings;
  fareSettingsCopy: FareSettings;
  constructor(private modalService: NgbModal, private dbService: DatabaseService) {
    this.canShowregion = false;
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.updateFareSettingsAPI = new UpdateFareSettingsAPI(dbService, (success) => {
      this.isEditing = !success;
      const alertType = (success) ? 'success' : 'danger';
      const alertMsg = (success) ? 'Fare settings updated successfully' : 'Updating fare settings failed';
      this.showAlert(alertType, alertMsg);
    });
    this.initializeSubscribers();
  }
  ngOnInit() {
 }
 initializeSubscribers() {
    this.canShowregion = true;
    this.fareSettingsObservable = this.dbService.globalFareSettings().valueChanges();
    this.fareSettingsObservable.subscribe((data) => {
      this.fareSettings = data;
      this.fareSettingsCopy = this.clone(data);
    },
      (err) => {
        console.log(err);
      });
  }
  editFare() {
    this.isEditing = true;
  }
  resetFare() {
    this.fareSettings = this.clone(this.fareSettingsCopy);
    this.isEditing = false;
  }
  saveFare() {
    this.isFareTableValid = this.validateFare();
    if (this.isFareTableValid) {
     this.updateFareSettingsAPI.updateFareSettings(this.fareSettings);
    }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  clone(obj: any): any {
    return (JSON.parse(JSON.stringify(obj)));
  }
  validateFare() {
    const count = this.fareTable.length;
    if (count === 0) { return false; }
    const isFirst = (index) => {
      return index === 0; };
    const isLast = (index) => {
      return index === this.fareTable.length - 1; };
    for (let index = 0; index < count; index++) {
      if (isFirst(index) && this.fareTable[index].from !== 0) {
        this.showAlert('danger', 'The "from" value for first entry must be zero.');
        return false;
      }
      if (isLast(index) && this.fareTable[index].to !== 999) {
        this.showAlert('danger', 'The "to" value for last entry must be 999.');
        return false;
      }
      if (this.fareTable[index].base === null || this.fareTable[index].perKm === null ||
        this.fareTable[index].perMin === null) {
        this.showAlert('danger', 'Empty values are not accepted. Please enter zero instead');
        return false;
      }
      if (this.fareTable[index].from >= this.fareTable[index].to) {
        this.showAlert('danger', '"From value" cannot be greater than or equal to "To value"');
        return false;
      }
      if (this.fareTable[index].base !== 0 && this.fareTable[index].perKm !== 0) {
        this.showAlert('danger', 'Cannot define both base fare and price per Km.');
        return false;
      }
      if (!isFirst(index)) {
        const prevRow = this.fareTable[index - 1];
        if (prevRow.to !== this.fareTable[index].from) {
          this.showAlert('danger', 'The "from" value must be equal to "to" value of previous row');
          return false;
        }
      }
    }
    return true;
  }
}
