import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { WalletHistory } from '../interfaces/wallet.interfaces';
import { BaseAPI, APIState } from './base.api';
export class CustomerWalletHistoryListener {
    state: APIState = APIState.none;
    private dbService: DatabaseService;
    private handler: (success: boolean, history?: WalletHistory[]) => void;
    private subscription: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, handler: (success: boolean, history: WalletHistory[]) => void ) {
        this.dbService = dbService;
        this.handler = handler;
    }
    beginListening(walletId: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getCustomerWalletHistory(walletId).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistory(from?: Date, to?: Date) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistory(from, to).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistorysearchPage(from?: Date, to?: Date, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.getAllCustomerWalletHistorySearchPage(from, to, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(true, response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
}
    getAllCustomerWalletHistoryByCustomerPage(from?: Date, to?: Date, GCashNo?: string, walletId?: string,
                                              limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.getAllCustomerWalletHistoryByCustomerPage(from, to, GCashNo, walletId, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(true, response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
}
    endListening() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

