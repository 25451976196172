import { Component, OnInit } from '@angular/core';
import { Trip, TripStatus, UpdateTripStatusRequest } from 'src/app/interfaces/trip-interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateAdminUserAPI } from 'src/app/api/user.api';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.css']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm: FormGroup;
  UserAPI: CreateAdminUserAPI;
  uid: string; // Currently viewing trip details
  isLoading: boolean = false;
  api: CreateAdminUserAPI;
  alert: AlertModel;
  reenterpassword: string;
  isValid: boolean = false;

  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder) {

    this.api = new CreateAdminUserAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.setPasswordForm.markAsPristine();
        this.setPasswordForm.disable();
      } else {
        this.setPasswordForm.enable();
      }
      this.showAlert(alertType, message);
    });
  }

  get password() { return this.setPasswordForm.get('password'); }

  get checkPasswords() { 
    const password = this.setPasswordForm.get('password').value;
    return password !== this.reenterpassword ? false : true;    
  }

  ngOnInit() {
    this.createSetPasswordForm()
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  } 

  createSetPasswordForm() {
    this.setPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]],
      uid: [this.uid],
    });
  }

  processSetPassword() {
    this.isValid = true;
    if(this.password.valid && this.checkPasswords) {
      const data = this.setPasswordForm.value;
      console.log(data)
      this.api.setUserPassword(data);
      this.isValid = false;
    }
  }

}
