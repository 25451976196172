import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewsArticleComponent } from 'src/app/modals/news/news-articles-create.component';
import { EditNewsArticleComponent } from 'src/app/modals/news/news-articles-edit.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { NewsArticlesAPI } from 'src/app/api/news.api';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { NewsArticle} from 'src/app/interfaces/interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-articles.component.html'
})
export class NewsArticlesComponent implements OnInit {
  newsArticleData: any = [];
  loading: boolean = false;
  newsArticlesAPI: NewsArticlesAPI;

  Id = new FormControl('');
  newsAuthor = new FormControl('');
  publishDate: Date = new Date();
  expiryDate: Date = new Date();
  newsType = new FormControl('');
  newsUserType = new FormControl('');
  newsRegion = new FormControl('');
  newsStatus = new FormControl(0);
  regions = OnboardingUtils.getRegions();
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;

  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {
    this.newsArticleData = [];
    this.paginationCount = 0;

    this.newsArticlesAPI = new NewsArticlesAPI(dbService, (news) => {
      this.newsArticleData = news;
     // console.log("Data", this.newsArticleData);
      this.getPaginations();
      });
    this.loadnewsArticle();
  }
  ngOnInit() {
  }

  getPaginations() {
    if (this.selectedFilter > this.newsArticleData.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }

  applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    const publishDate = moment(this.publishDate).format('YYYY-MM-DD');
    const expiryDate = moment(this.expiryDate).format('YYYY-MM-DD');
    
    this.newsArticlesAPI.getNewsArticleSearch(this.Id.value, this.newsAuthor.value,
      this.newsType.value, this.newsUserType.value, this.newsStatus.value, this.newsRegion.value, Number(this.selectedFilter), buttonAction);
  }

  getStatusMessage(status: boolean) {
    switch (status) {
      case true: return 'Active';
      case false: return 'In Active';
      default: return '';
    }
  }

  filterpage(status: number) {
    this.selectedFilter = status;
  }
  createNewsArticle() {
    const modalRef =  this.modalService.open(CreateNewsArticleComponent, { size: 'xl' });
    modalRef.result
    .then((result) => { this.loadnewsArticle();  }, (reason) => { });
  }
  editNewsArticle(data) {
    const modalRef =  this.modalService.open(EditNewsArticleComponent, { size: 'xl' });
    modalRef.componentInstance.loadedData = data;
    modalRef.result
    .then((result) => { this.loadnewsArticle(); }, (reason) => { });
  }
  async loadnewsArticle() {

    const publishDate = moment(this.publishDate).format('YYYY-MM-DD');
    const expiryDate = moment(this.expiryDate).format('YYYY-MM-DD');
    this.pageCount = 'load';  this.paginationCount = 0;
    this.Id.reset();
    this.newsAuthor.reset();
    this.newsType.reset();
    this.newsUserType.reset();
    this.newsRegion.reset();
    this.newsStatus.setValue('all');
    // console.log('First', this.newsStatus.value);
    this.newsArticlesAPI.getNewsArticleSearch(this.Id.value, this.newsAuthor.value, this.newsType.value, this.newsUserType.value,
      this.newsStatus.value, this.newsRegion.value, Number(this.selectedFilter), 'load');
  }

  deleteNewsArticles(data : NewsArticle, index: number) {
    console.log(data.id);
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete the news and article';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteNewsArticles(data.id);
      })
      .then(() => {
        this.loadnewsArticle();
      })
      .catch(e => {
        console.log(e);
      });
  }



}


