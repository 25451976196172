import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaymayaTopupInquiry } from 'src/app/api/paymaya.api';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ApiService } from 'src/app/services/api.service';
@Component({
    selector: 'app-paymaya-topupinquiry',
    templateUrl: './topup-inquiry.component.html'
  })
export class TopupInquiryComponent implements OnInit {
referenceNo = new FormControl('');
paymayaTopupInquiryAPI: PaymayaTopupInquiry;
bindAccInquiryHisstory: any;
constructor(private dbService: DatabaseService, private apiService: ApiService,
            public permissions: PermissionService) {
  this.paymayaTopupInquiryAPI = new PaymayaTopupInquiry(apiService, (success, history) => {
  this.bindAccInquiryHisstory = history;
  });
}
ngOnInit() {
}
submitTopupInquiry() {
  this.paymayaTopupInquiryAPI.payamayaTopupInquiry(this.referenceNo.value);
}
canFillterReferenceNo(): boolean {
 return this.referenceNo.dirty;
}
}

