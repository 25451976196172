import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DriverProfile, AlertModel, OnboardingApplication } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DriverTopupManualAPI } from 'src/app/api/fareSettings.api';
import { ApiService } from 'src/app/services/api.service';
import { DriverProfileAPI, DriversWalletListAPI } from 'src/app/api/driverList.api';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-top-manual',
  templateUrl: './topup-manual.component.html',
  styleUrls: ['./topup-manual.component.css']
})
export class TopupManualComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  driverProfile: DriverProfile;
  walletHistory: WalletHistory[];
  topupManualForm: FormGroup;
  firstname = new FormControl('');
  uid = new FormControl('');
  phone = new FormControl('');
  alert: AlertModel;
  isVisiableWalletAdjustment = false;
  isGcashReferenceNoExist = false;
  driverTopupManualAPI: DriverTopupManualAPI;
  walletHistoryListener: DriverWalletHistoryListener;
  driverProfileAPI: DriverProfileAPI;
  driversListAPI: DriversWalletListAPI;
  diverId: any;
  filterBlock = (text: string, item: OnboardingApplication) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.personal.firstname, item.personal.lastname].join(' ');
    return item.form.id.toLocaleLowerCase().includes(searchText) ||
      item.personal.phone.toLocaleLowerCase().includes(searchText) ||
      name.toLocaleLowerCase().includes(searchText);
  }
  constructor(private fb: FormBuilder, private apiService: ApiService, private dbService: DatabaseService, private utils: UtilsService,
              public permissions: PermissionService) {
    this.driverTopupManualAPI = new DriverTopupManualAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      this.showAlert(alertType, message);
      this.topupManualForm.enable();
      if (success) {
        this.createWalletAdjustmentForm();
        // this.topupManualForm.markAsPristine();
      }
    });
    this.driverProfileAPI = new DriverProfileAPI(this.dbService, true, (success, profile) => {
      if (success && profile) {
        this.driverProfile = profile;
        this.topupManualForm.controls.driverId.setValue(this.driverProfile.uid);
        this.walletHistoryListener.beginListening(profile.uid);
      } else if (success) {
        this.showAlert('danger', 'No Biker was found with the searched ID');
      } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Biker profile'); }
    });
    this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
      // tslint:disable-next-line: max-line-length
      if (success && history) { this.walletHistory = history; } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
    });
    this.driversListAPI = new DriversWalletListAPI(dbService, (drivers) => {
     if (drivers[0].uid !==  undefined ) {
     this.diverId = drivers[0].uid;
     this.getWalletAssessmentInformation(this.diverId);
     } else {drivers[0].uid = 0; }
    });
  }
  ngOnInit() {
    this.createWalletAdjustmentForm();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    this.driverProfileAPI.endListening();
    this.walletHistoryListener.endListening();
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  private async loadDriverApplications(uid?: string, firstname?: string, phone?: string) {
    this.driversListAPI.loadDriverWalletDetails(uid, firstname, phone);
  }
  search() {
    this.isVisiableWalletAdjustment = false;
    this.loadDriverApplications(this.uid.value, this.firstname.value, this.phone.value);
  }
  getWalletAssessmentInformation(driverId) {
    this.driverProfileAPI.fetchDriverProfile(driverId);
    this.isVisiableWalletAdjustment = true;
  }
  private createWalletAdjustmentForm() {
    this.topupManualForm = this.fb.group({
      driverId: ['', Validators.required],
      amount: [0, [Validators.required, Validators.minLength(2), Validators.maxLength(4)]],
      paymentType: ['cash' , Validators.required],
      gCashRefId: [''],
      paymayaRefNo: [''],
      reason: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.untouched || c.disabled || c.valid;
  }
  canSaveTopupManualAdjustments(): boolean {
   // console.log(this.canCheckGcashRefId(), this.canCheckPaymayarefNo(), this.topupManualForm.controls.paymayaRefNo.value);
    return this.topupManualForm.valid && this.topupManualForm.dirty && !this.isGcashReferenceNoExist;
  }
  canCheckGcashRefId(): boolean {
    return (this.topupManualForm.controls.paymentType.value !== 'paymaya' && this.topupManualForm.controls.gCashRefId.value !== '');
  }
  canCheckPaymayarefNo(): boolean {
   return (this.topupManualForm.controls.paymentType.value === 'paymaya' && this.topupManualForm.controls.paymayaRefNo.value !== '');
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  saveTopupManualAdjustments(driverid: string) {
    this.topupManualForm.disable();
    // tslint:disable-next-line: radix
    if (this.topupManualForm.value.paymentType !== 'paymaya') { this.topupManualForm.value.paymayaRefNo = ''; }
    if (this.topupManualForm.value.paymentType === 'paymaya') { this.topupManualForm.value.gCashRefId = ''; }
    this.topupManualForm.controls.amount.setValue(parseInt(this.topupManualForm.controls.amount.value));
    this.driverTopupManualAPI.topupManualWalletAmount(driverid, this.topupManualForm.value);
  }
  cancelTopupManualAdjustments() {
    this.driverProfileAPI.endListening();
    this.walletHistoryListener.endListening();
    this.isVisiableWalletAdjustment = false;
    this.createWalletAdjustmentForm();
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  checkGCashAvailablity(control: AbstractControl) {
    const c = control as FormControl;
    if (c.value !== '') {
      this.dbService.hasGCashRefNumber(c.value)
        .then(result => {
          if (result) {
            this.isGcashReferenceNoExist = true;
            return Promise.reject();
          } else {
            this.isGcashReferenceNoExist = false;
          }
        })
        .then(() => {
        })
        .finally(() => {

        });
    } else { }
  }
  checkPaymayaNoAvailablity(control: AbstractControl) {
    const c = control as FormControl;
    if (c.value !== '') {
      this.dbService.hasPaymayaRefNumber(c.value)
        .then(result => {
          if (result) {
            this.isGcashReferenceNoExist = true;
            return Promise.reject();
          } else {
            this.isGcashReferenceNoExist = false;
          }
        })
        .then(() => {
        })
        .finally(() => {

        });
    } else { }
  }

}
