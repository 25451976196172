import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TripListAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-trip-management',
  templateUrl: './trip-management.component.html',
  styleUrls: ['./trip-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TripManagementComponent implements OnInit {
  tripListAPI: TripListAPI;
  trips: Trip[];
  // tslint:disable-next-line: no-use-before-declare
  filter = new TripHistoryFilter();
  drivername = new FormControl('');
  customername = new FormControl('');
  driverphone  = new FormControl('');
  customerphone  = new FormControl('');
  tripid  = new FormControl('');
  promocode  = new FormControl('');
  driverId = new FormControl('');
  tripType = new FormControl();
  dateFrom: any = '';
  dateTo: any = '';
  vehicleType = new FormControl();
  orderNumber = new FormControl();
  merchantReference = new FormControl();
  dateFilterType = new FormControl('');
  onboardingRegions = [];
  vehicleTypecombo = [];
  defaultVehicleType:string;
  franchise_area = new FormControl('Metro Manila');
  defaultRegion: any = 'Metro Manila';
  defaultRegionCebu = 'Metro Cebu';
  isOnboardingProfile = false;
  dateFilter: string = 'Date Booked';
  dateFilterIndex: number = 0;
  areas: any = ["Metro Manila","Metro Cebu"];
  get selectedStatus(): string {
    return (this.filter.status) ? this.utils.tripStatusText(this.filter.status) : 'ALL TRIPS';
  }
  get fromDate(): any {
    return (this.dateFrom) ? this.filter.from.selectedDate : ''
  }
  get toDate(): any {
    return (this.dateTo) ? this.filter.to.selectedDate : ''
  }
  defaultTripType: number;
  constructor(private dbService: DatabaseService,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.defaultTripType = this.utils.tripTypesManagement[0];
    this.vehicleTypecombo = OnboardingUtils.getHMVehicleType();
    this.defaultVehicleType = this.vehicleTypecombo[0];
    this.trips = [];
    this.tripListAPI = new TripListAPI(dbService, (trips) => {
      this.trips = trips;
    });
    this.loadFranchiseArea();
    
  }
ngOnInit() {
  
}
changeDateFrom(){
  this.dateFrom = moment(this.filter.from.date).format('DD-MM-YYYY hh:mm A')
}
changeDateTo(){
  this.dateTo = moment(this.filter.to.date).format('DD-MM-YYYY hh:mm A')
}
applyFilter() {
  this.tripListAPI.loadTrips(this.dateFilterIndex, this.vehicleType.value, this.franchise_area.value, this.tripType.value, this.filter.status, this.fromDate, this.toDate, this.drivername.value, this.customername.value, this.driverphone.value,
                                this.customerphone.value, this.promocode.value, this.tripid.value, this.driverId.value, this.orderNumber.value, this.merchantReference.value);
}
loadFranchiseArea() {
    this.dbService.getFranchiseArea().then(results => {
      this.areas = results
      this.defaultRegion = this.areas[4]
    }).catch(err => {
      console.log(err);
    })
 }
filterdatetype(dateTypeIndex: number, dateType: string) {
    this.dateFilter = dateType;
    this.dateFilterIndex = dateTypeIndex;
}
getDriverVehicleName(status: string): string | null {
  return OnboardingUtils.getVehiclyName(status);
}
export() {
    const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
    exporter.exportTripManagement(this.trips as Trip[]);
}
openTripInfoModal(trip: Trip) {
      const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true, backdrop : 'static', keyboard : false});
      modalRef.componentInstance.trip = trip;
      modalRef.result
      .then((result) => { }, (res) => { 
        if(res === 'success') {this.applyFilter()} 

      });
}
isNull(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return ''; }
    return path;
}
reset() {
    this.drivername.reset();
    this.customername.reset();
    this.driverphone.reset();
    this.customerphone.reset();
    this.filter.reset();
    this.promocode.reset();
    this.tripid.reset();
    this.orderNumber.reset();
    this.merchantReference.reset()
    this.dateFrom = '';
    this.dateTo = '';
  }
}
/** * FILTER for Trips */
class TripHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
    this.status = status;
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
