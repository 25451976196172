import { Component, OnInit } from '@angular/core';
import { WalletTopupStatus, WalletUtils } from 'src/app/utils/wallet.utils';
import { DatabaseService } from 'src/app/services/database.service';
import { TopupRequest, WalletSettings } from 'src/app/interfaces/interfaces';
import { TopupRequestsAPI, WalletSettingsAPI, TopupStatusUpdateApi } from 'src/app/api/topup.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Paginator } from 'src/app/classes/paginator';
import { WalletRequestFilter } from 'src/app/classes/FilterModels';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-topup-request',
  templateUrl: './topup-request.component.html',
  styleUrls: ['./topup-request.component.css']
})
export class TopupRequestComponent implements OnInit {
  search = new FormControl('');
  paginator: Paginator<TopupRequest> = null;
  // APIs
  topupListApi: TopupRequestsAPI;
  walletSettingsAPI: WalletSettingsAPI;
  updateStatusAPI: TopupStatusUpdateApi;
  // Data
  approvalStatus: any = {};
  settings: WalletSettings;
  filter: WalletRequestFilter;
  bulkUpdateList: any = [];
  status: number;
  canShowbulkUpdate: string;
  // Search Filter
  filterBlock = (text: string, item: TopupRequest) => {
    const searchText = text.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().includes(searchText) ||
      item.gCashRefNumber.toLocaleLowerCase().includes(searchText);
  }
  /**
   * Returns the cashout requests to be displayed in the page
   */
  get topupRequests(): TopupRequest[] {
    return (this.paginator) ? this.paginator.getItems(this.search.value) : null;
  }
  /**
   * Returns a boolean indicating whether the data can be exported or not
   */
  get canExport(): boolean {
    const allRequests = this.topupRequests;
    return (allRequests && allRequests.length > 0);
  }
  /**
   * to show dropdown and button 
   */
  get canShowButton(): boolean {
    const allRequests = this.topupRequests;
    return (allRequests && allRequests.length > 0);
  }
  /**
   * The idea here is that the action column will be visible only when the admin locks
   * the wallet update using the switch. (This will prevent any drivers from submitting a request
   * and causing race conditions)
   */
  get canShowActionColumn(): boolean {
    return this.permissions.canApproveTopupRequests &&
      this.filter.status === WalletTopupStatus.pending &&
      this.settings && this.settings.topupsEnabled === false;
  }
  /**
   * The save button can be displayed only when the action column is displayed.
   * Additionally, user should have modified a request status.
   */
  get canShowSaveButton(): boolean {
    return this.permissions.canApproveTopupRequests &&
      this.canShowActionColumn &&
      Object.keys(this.approvalStatus).length !== 0;
  }
  // MARK: Constructors and Initializers
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private permissions: PermissionService) {
    this.filter = new WalletRequestFilter();
    this.topupListApi = new TopupRequestsAPI(dbService, (requests) => {
      this.paginator = new Paginator(1, requests.length, requests, this.filterBlock);
      this.approvalStatus = {};
    });
    this.walletSettingsAPI = new WalletSettingsAPI(dbService, (settings) => {
      this.settings = settings;
    });
    this.updateStatusAPI = new TopupStatusUpdateApi(apiService, (success, message) => {
      this.showAlert(success, message);
    });
  }
  ngOnInit() {
    this.filterApplied(this.filter);
    this.walletSettingsAPI.getWalletConfig();
  }
  // MARK: Helpers
  /**
   * Maps an integer indicating a topup status to a human readable string
   */
  getTopupStatusName(status: WalletTopupStatus): string | null {
    return WalletUtils.getTopupStatusName(status);
  }
  /**
   * Formats the timestamp in seconds to a readable date string
   * @param timestamp
   */
  formatDate(date) {
    if (date === undefined) { return ''; }
    return WalletUtils.formatDate(date.seconds);
  }
  retry() {
    this.filterApplied(this.filter);
  }
  filterApplied(data: WalletRequestFilter) {
    this.filter = data;
    this.topupListApi.loadTopupRequests(data);
  }
  // MARK: Events
  /**
   * Invoked when the switch is turned on or off. If turned ON, no drivers will be allowed to
   * submit requests
   */
  toggleTopupSettings(event) {
    this.walletSettingsAPI.updateWalletConfig(!event.target.checked, !event.target.checked);
  }
  /** * Select/Deselect All Check Box  */
  selectAllRequest() {
    if (this.canShowbulkUpdate === 'show') {
      this.canShowbulkUpdate = 'hide';
    } else {
      this.canShowbulkUpdate = 'show';
    }
  }
  /**
   * Invoked when the admin selects an approval / rejection status for a given topup request.
   * The admin inputs are saved in a Dictionary and finally merged with the topup request when
   * save button is pressed
   */
  statusChanged(request: TopupRequest, event) {
    const value = event.target.value;
    if (value !== undefined && (value === WalletTopupStatus.approved || value === WalletTopupStatus.declined)) {
      this.approvalStatus[request.receiptId] = value;
    } else {
      delete this.approvalStatus[request.receiptId];
    }
  }
  /**
   * Performs the actual API call to update the status of each topup request and update
   * the wallet accordingly.
   */
  onSaveWalletStatus() {
    const status = (<HTMLSelectElement>document.getElementById('status')).value;
    if (status === '1') {
      this.status = 1;
    } else if (status === '2') {
      this.status = 2;
    }
    if (this.status !== undefined && (this.status === WalletTopupStatus.approved || this.status === WalletTopupStatus.declined)) {
      const items = document.getElementsByName('bulkupdate');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < items.length; i++) {
        if ((items[i] as HTMLInputElement).checked === true) {
          this.approvalStatus[items[i].id] = this.status;
        }
      }
    }
    this.canShowbulkUpdate = 'hide';
    this.updateStatusAPI.updateTopupStatus(this.approvalStatus);
  }
  // MARK: Alerts

  /**
   * Shows the success / failure reason after executing the transaction to update the wallet.
   * This method is invoked on both success and failure of the transaction.
   */
  showAlert(isSuccess: boolean, message: string) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = isSuccess ? 'Success!' : 'Error';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmActionTitle = 'OK';
    modalRef.result
      .then((result) => {
        this.approvalStatus = {};
        return this.filterApplied(this.filter);
      })
      .catch(e => { console.error(e); });
  }
  /*** Export content as CSV */
  exportAsCSV() {
    if (this.topupRequests === undefined) { return; }
    const filename = () => 'Topup_Request' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), ['UID', 'GCash Mobile Number', 'Name', 'Amount']);
    exporter.exportCashouts(this.topupRequests as [TopupRequest]);
  }
}
