import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { TopupRequest, WalletSettings } from '../interfaces/interfaces';
import { ApiService } from '../services/api.service';
import { WalletRequestFilter } from '../classes/FilterModels';
import { Subscription } from 'rxjs';
export class TopupRequestsAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([TopupRequest]) => void;
    constructor(dbService: DatabaseService, completion: ([TopupRequest]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTopupRequests(filter: WalletRequestFilter) {
        this.state = APIState.loading;
        this.dbService.getAllTopupRequests(filter)
            .then(requests => {
                this.state = (requests.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(requests as [TopupRequest]);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
    }
    exportTopupRequest(from?: Date, to?: Date, gcash?: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.exportTopupRequest(from, to, gcash).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [TopupRequest]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.exportTopupRequest(from, to, gcash)
            .valueChanges().subscribe(values => {
                this.handler(values as [TopupRequest]);
            }, err => {
                console.error(err);
            });
    }
}
/**
 * Request class to enable / disable driver topup requests. When this flag is disabled (false),
 * all topup requests will return an appropriate error.
 */
export class WalletSettingsAPI extends BaseAPI {
    private handler: (settings: WalletSettings) => void;
    constructor(dbService: DatabaseService, completion: (settings: WalletSettings) => void) {
        super(dbService);
        this.handler = completion;
    }
    getWalletConfig() {
        this.state = APIState.loading;
        this.dbService.getWalletConfig()
            .then(config => {
                this.state = APIState.successWithData;
                this.handler(config as WalletSettings);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
    }
    updateWalletConfig(cashoutEnabled: boolean, topupEnabled: boolean) {
        const payload = { cashoutsEnabled: cashoutEnabled, topupsEnabled: topupEnabled };
        this.state = APIState.loading;
        this.dbService.setWalletConfig(payload)
            .then(result => {
                this.state = APIState.successWithData;
                this.handler(payload as WalletSettings);
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
            });
    }
}

/**
 * Update status of the requests
 */
export class TopupStatusUpdateApi extends BaseAPI {
    private handler: (success: boolean, message: string) => void;
    success = 'isSuccess';
    data = 'data';
    message = 'message';
    error = 'error';
    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updateTopupStatus(statuses: any) {
        this.state = APIState.loading;
        this.apiService.processTopupRequests(statuses)
            .then(response => {
                const isSuccess = response[this.success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'Top up values updated successfully for the approved drivers');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[this.error][this.message]);
                }
                return true;
            })
            .catch(err => {
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while updating the topup requests. Please try again. ' + err);
            });
    }
}
