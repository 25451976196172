import { Component, OnInit } from '@angular/core';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { Customer } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { UtilsService } from 'src/app/services/utils.service';
import { IDriverProfileFilter } from 'src/app/controls/driver-profile-filter/driver-profile-filter.component';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { FormControl } from '@angular/forms';
import { CustomerAPI, CustomerProfileAPI } from 'src/app/api/customer.api';
import { CustomerWalletHistoryListener } from 'src/app/api/customer-wallet.api';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
@Component({
  selector: 'app-customer-wallet-balance',
  templateUrl: './customer-export-balance.component.html'
})
export class CustomerWalletBalanceComponent implements OnInit {
  customers: Customer[];
  paginator: DriverProfilePaginator = null;
  api: CustomerAPI;
  currentFilter: IDriverProfileFilter = {};
  region = new FormControl('Metro Manila');
  defaultRegion: any;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  constructor(private dbService: DatabaseService, private utils: UtilsService, public permissions: PermissionService) {
    this.customers = [];
    this.api = new CustomerAPI(dbService, (customers) => {
      this.customers = customers;
    });
  }
  ngOnInit() {
    this.loadCustomerApplications();
  }
  get canExport(): boolean {
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
  export() {
    const filename = () => 'Wallet Balance' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.customerwalletBalance());
    exporter.exportCustomerWalletBalance(this.customers as Customer[]);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  private async loadCustomerApplications() {
    this.api.loadCustomerWalletBalance();
  }
  regionFilter() {
    this.loadCustomerApplications();
  }
}
