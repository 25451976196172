import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import {  TripSummaryRegion } from 'src/app/api/driver-stats.api';
import { DriverTripStat } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector:  'app-trip-summary-search',
  templateUrl: './trip-summary-search.component.html',
  styleUrls: ['./trip-summary-search.component.css']
})
export class TripSummarySearchComponent implements OnInit {
    api: TripSummaryRegion;
    driverTripStat: DriverTripStat[];
    dateValues: any;
    // tslint:disable-next-line: no-use-before-declare
    filter = new TripSummaryFilter();
    loadingTrips: boolean;
    onboardingRegions = [];
    tripTypes: any;
    region = new FormControl();
    tripType = new FormControl();
    defaultRegion: any;
    defaultTripType: number;
    tripTypeValue: number;
    defaultRegionCebu = 'Metro Cebu';
    isOnboardingProfile = false;
constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService) {
              this.onboardingRegions = OnboardingUtils.getRegions();
              this.tripTypes = utils.tripType(1);
              this.defaultRegion = this.onboardingRegions[0];
              this.defaultTripType = this.utils.tripTypes[0];
              this.tripTypeValue = this.defaultTripType;
              this.loadingTrips = false;
              this.driverTripStat = [];
              this.api = new TripSummaryRegion(dbService, (trips) => {
                this.driverTripStat = trips;
                this.applyfillterDateRage();
              });
}
ngOnInit() {
}
applyFilter() {
      this.driverTripStat = [];
      this.loadingTrips = true;
      const multiDate = this.fillterDateRange(this.filter.from.selectedDate, this.filter.to.selectedDate);
      this.dateValues = multiDate;
      if (this.utils.checkRegionReleaseDatePrevious(this.filter.from.selectedDate) === true) {
      this.api.fetchTripSummaryRegion(this.dateValues, this.region.value);
      } else {
        this.api.fetchTripSummaryRegion(this.dateValues, 'stats');
      }
}
fillterDateRange(startDate, endDate) {
        const result = [];
        const tripStartDate = new Date(startDate);
        const tripEndDate = new Date(endDate);
        if (tripStartDate === tripEndDate ) {
          result.push(tripStartDate.toISOString().substr(0, 10));
        } else {
          while (tripStartDate < tripEndDate) {
            tripStartDate.setUTCDate(tripStartDate.getUTCDate() + 1);
            result.push(tripStartDate.toISOString().substr(0, 10));
          }
        }
        return result;
}
applyfillterDateRage() {
  const keys = Object.keys(this.dateValues);
  for ( let i = 0; keys.length > i; i++) {
      for ( let i = 0; this.driverTripStat.length > i; i++) {
          const dateValue = 'dateValue';
          if (this.driverTripStat[i] !== undefined) {
          this.driverTripStat[i][dateValue] = (this.dateValues[i]);
          }
          this.loadingTrips = false;
      }
  }
  if (Number(this.tripType.value) === 1) {
    this.driverTripStat = this.driverTripStat.filter(d => d !== undefined && d.numTrips >= 1);
   } else if (Number(this.tripType.value) === 2) {
    this.driverTripStat = this.driverTripStat.filter(d => d !== undefined && d.numTrips_delivery >= 1);
   } else if (Number(this.tripType.value) === 3) {
    this.driverTripStat = this.driverTripStat.filter(d => d !== undefined && d.numTrips_pabili >= 1);
   } else if (Number(this.tripType.value) === 4) {
    this.driverTripStat = this.driverTripStat.filter(d => d !== undefined && d.numTrips_tricycle >= 1);
   }
  this.tripTypeValue = Number(this.tripType.value);
  this.loadingTrips = false;
}
export() {
         if (this.tripTypeValue === this.utils.tripTypes[0]) {
        const filename = () => 'Trip_Summary_MCTaxi' + (new Date()).toLocaleDateString();
        const exporter = new CSVExporter(filename(), CSVExporter.tripSummary());
        exporter.exportTripSummary(this.driverTripStat as DriverTripStat[]);
        } else if (this.tripTypeValue === this.utils.tripTypes[1]) {
          const filename = () => 'Trip_Summary_Delivery' + (new Date()).toLocaleDateString();
          const exporter = new CSVExporter(filename(), CSVExporter.tripSummary());
          exporter.exportTripSummaryDelivery(this.driverTripStat as DriverTripStat[]);
        }  else if (this.tripTypeValue === this.utils.tripTypes[2]) {
          const filename = () => 'Trip_Summary_Pabili' + (new Date()).toLocaleDateString();
          const exporter = new CSVExporter(filename(), CSVExporter.tripSummary());
          exporter.exportTripSummaryPabili(this.driverTripStat as DriverTripStat[]);
        }
}
isNull(path?: string) {
     if (path === null || path === undefined || path.length === 0) { return ''; }
     return path;
    }
 reset() {
      this.filter.reset();
    }
}
class TripSummaryFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
     }
   setStatus(status: number) {
    }
   reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
}
