import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { TricyclePickup, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-create-tricycle-pickup',
  templateUrl: './create-tricycle-pickup.component.html'
})
export class CreateTricyclePickupComponent implements OnInit {
  tricyclePickupForm: FormGroup;
  tricyclePickupModel: TricyclePickup;
  alert: AlertModel;
  saving: boolean;
  onboardingRegions = [];
  onboardingCity: SupportedRegions[];
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.getRegions();
    this.createForm();
  }
  private createForm() {
    this.tricyclePickupForm = this.fb.group({
        pickup_city_code: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        pickup_city: ['', Validators.required],
        region: ['', Validators.required],
        latlong: ['', [Validators.required, ValidatorService.cannotContainSpace]]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveTricyclePickup(): boolean {
    return this.tricyclePickupForm.valid && this.tricyclePickupForm.dirty;
  }


  createTricyclePickupCity() {
    this.tricyclePickupModel = {
      id: '',
      pickup_city_code: this.tricyclePickupForm.controls.pickup_city_code.value,
      pickup_city: this.tricyclePickupForm.controls.pickup_city.value,
      region: this.tricyclePickupForm.controls.region.value,
      latlong: this.tricyclePickupForm.controls.latlong.value,
      is_active: true,
      updated_at: new Date()
    };
    this.tricyclePickupForm.disable();
    this.saving = true;
    this.dbService.hasPickupCity(this.tricyclePickupForm.controls.region.value,
      this.tricyclePickupForm.controls.pickup_city.value)
    .then(result => {
      if (result) {
        this.showAlert('danger', 'The entered Pickup City already exists in the database');
        this.tricyclePickupForm.enable(); // Enable editing
        return Promise.reject();
      } else {
        return this.dbService.hasPickupCityCode(this.tricyclePickupForm.controls.region.value,
          this.tricyclePickupForm.controls.pickup_city_code.value)
        .then(resultval => {
          if (resultval) {
            this.showAlert('danger', 'The entered Pickup City Code already exists in the database');
            this.tricyclePickupForm.enable(); // Enable editing
            return Promise.reject();
          } else {
            return this.dbService.createTricyclePickupCities(this.tricyclePickupModel, this.tricyclePickupForm.controls.region.value);
          }
      });
    }
    })
      .then(() => {
        this.showAlert('success', 'Taxicle Pickup City created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the Taxicle Pickup City - ' + e.message);
        this.tricyclePickupForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  getRegions() {
    this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }
}
