import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
//import { FareUtils } from 'src/app/pages/onboarding/driver/fare.utils';
import { AlertModel, FareSettings, FareSlab, FareSettingsFranchiseArea } from 'src/app/interfaces/interfaces';
import { UpdateFareSettingsAPI } from 'src/app/api/fareSettings.api';

@Component({
  selector: 'app-otp-provider',
  templateUrl: './otp-provider.component.html',
  styleUrls: ['./otp-provider.component.css']
})
export class OtpProviderComponent implements OnInit {
  serviceEnable: any;
  isSaving = true;
  isEditing = false;
  isLoading = false;
  isFareTableValid = true;
  provider = new FormControl();
  onboardingUtils:any;
  defaultProvider: string;
  otpProvider = [];
  alert: AlertModel;
  constructor(private modalService: NgbModal, private dbService: DatabaseService, private apiService: ApiService,  public utils: UtilsService) {
    this.onboardingUtils = OnboardingUtils;

    this.defaultProvider = '';
    this.otpProvider = OnboardingUtils.getOtppProvider();
  }
 ngOnInit() {
   this.loadOtpProvider();
 }

 loadOtpProvider() {
    this.dbService.getOtpSettings().then(results => {
      this.defaultProvider = results.data().otp_provider;
    }).catch(err => {
      console.log(err);
    })
 }

 async saveotpprovider() {
    this.dbService.saveOtpSettings(this.provider.value)
    .then(results => {
      this.showAlert('success', 'OTP provider has been successfully updated!');
    }).catch(err => {
      this.showAlert('danger', err);
    })
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  clone(obj: any): any {
    return (JSON.parse(JSON.stringify(obj)));
  }
}
