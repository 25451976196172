import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { WalletHistory } from '../interfaces/wallet.interfaces';
import { BaseAPI, APIState } from './base.api';
import { EMIWallet } from '../interfaces/interfaces';
export class DriverWalletHistoryListener {
    state: APIState = APIState.none;
    private dbService: DatabaseService;
    private handler: (success: boolean, history?: WalletHistory[]) => void;
    private subscription: Subscription;
    constructor(dbService: DatabaseService, handler: (success: boolean, history: WalletHistory[]) => void ) {
        this.dbService = dbService;
        this.handler = handler;
    }
    beginListening(walletId: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getWalletHistory(walletId).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistory(from?: Date, to?: Date) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistory(from, to).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistorysearchRegion(from?: Date, to?: Date, region?: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistorySearchRegion(from, to, region).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistorysearch(from?: Date, to?: Date) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistorySearch(from, to).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletCommissionHistorysearchRegion(from?: Date, to?: Date, region?: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletCommissionSearchRegion(from, to, region).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletCommissionHistorysearch(from?: Date, to?: Date) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletCommissionSearch(from, to).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getallWalletHistorySearchByDriver(from?: Date, to?: Date, GCashNo?: string, walletId?: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistoryByDriver(from, to, GCashNo, walletId).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    getAllWalletHistoryByEmi(from?: Date, to?: Date, phone?: string, driverId?: string) {
        if (this.subscription) { this.subscription.unsubscribe(); }
        this.subscription = this.dbService.getAllWalletHistoryByEmi(from, to, phone, driverId).valueChanges().subscribe(values => {
            this.state = (values.length > 0) ? APIState.successWithData : APIState.successWithoutData;
            this.handler(true, values);
        }, e => {
            console.error(e);
            this.state = APIState.failed;
            this.handler(false);
        });
    }
    endListening() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
export class EMIHistoryAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([EMIWallet]) => void;

    constructor(dbService: DatabaseService, completion: ([EMIWallet]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getAllEMIHistory(date: []) {
        this.state = APIState.loading;
        this.dbService.driverEMIHistrory(date).then(forms => {
            this.handler(forms as [EMIWallet]);
        }).catch(err => {
            console.log(err);
            this.state = APIState.failed;
        });
     }
}
