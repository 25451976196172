import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer, CustomerSpecialRate, AlertModel } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { CustomerRateAPI } from 'src/app/api/customer.api';
import { SetPasswordComponent } from 'src/app/modals/customer/customer-profile/set-password.component';
import { CustomerRatesAddComponent } from 'src/app/modals/users/customer-rates/customer-rates-add.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { RouterPermissionService } from 'src/app/services/router-permission.service';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {
  @Input() customer: Customer;
  profileForm: FormGroup;
  saving: boolean;
  alert: AlertModel;
  btnBlock: string;
  api: CustomerRateAPI;
  customerRateList:CustomerSpecialRate;
  constructor(public activeModal: NgbActiveModal, private userService: UserService, private fb: FormBuilder,
              private dbService: DatabaseService, private modalService: NgbModal, public rp: RouterPermissionService) {
              this.api = new CustomerRateAPI(dbService, (customerRate) => {
                this.customerRateList = customerRate;
              });
  }
  ngOnInit() {
    this.createForm();
    this.loadCustomerRates();
  }
  loadCustomerRates() {
      this.api.loadCustomerSpecialRate(this.customer.uid);
  }
  createForm() {
    this.profileForm = this.fb.group({
      uid: [ this.customer.uid, Validators.required ],
      firstName: [ this.customer.firstName, Validators.required ],
      lastName: [ this.customer.lastName, Validators.required ],
      email: [ this.customer.email, [ Validators.required, Validators.email ] ],
      phone: [ this.customer.phone, [
                Validators.required,
                Validators.maxLength(14),
                Validators.minLength(10)
              ] ],
      blocked: [ this.customer.blocked ],
      updated_by: [ this.customer.updated_by, Validators.required, ]
    });

    this.userService.hasEditPermission().then(result => {
      result ? this.profileForm.enable() : this.profileForm.disable();
    });

    this.userService.getCurrentUser().then(result => {
      this.profileForm.value.updated_by = result['email']
    });

    this.btnBlock = this.profileForm.value.blocked == 1 ? 'Unblock' : 'Block'
  }

  saveProfile() {
    if (this.profileForm.dirty && this.profileForm.valid) {
      this.saving = true;
      this.profileForm.disable();
      this.dbService.saveCustomerProfile(this.profileForm.value)
        .then(() => {
          this.customer = { ...this.customer, ...this.profileForm.value };
          this.profileForm.markAsPristine();
          this.showAlert('success', 'Profile updated successfully');
        })
        .catch(err => this.showAlert('danger', err.message))
        .finally(() => {
          this.saving = false;
          this.profileForm.enable();
        });
    }
  }

  blockCustomer() {
    this.profileForm.value.blocked = this.profileForm.value.blocked == 1 ? 0 : 1;
    this.saving = true;
    this.dbService.saveCustomerProfile(this.profileForm.value)
        .then(() => {
          this.customer = { ...this.customer, ...this.profileForm.value };
          this.profileForm.markAsPristine();
          this.showAlert('success', 'Profile updated successfully');
        })
        .catch(err => this.showAlert('danger', err.message))
        .finally(() => {
          this.saving = false;
          this.btnBlock = this.profileForm.value.blocked == 1 ? 'Unblock' : 'Block'
        });
  }

  createCustomerRatesModal() {
    const modalRef = this.modalService.open(CustomerRatesAddComponent, {backdrop : 'static', keyboard : false });
    modalRef.componentInstance.uid = this.customer.uid;
    modalRef.result
    .then((result) => { }, (res) => { this.loadCustomerRates() });
  }

  deleteCustomerRate(data : CustomerSpecialRate, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete '+ data.location +' . '+ data.service +' . ' + data.rateType +' . ' + data.value +' ?';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteCustomerRate(this.customer.uid, data.rateId);
      })
      .then(() => {
        this.loadCustomerRates();
      })
      .catch(e => {
        console.log(e);
      });
  }

  setPasswordModal() {
    const modalRef = this.modalService.open(SetPasswordComponent, {size: 'sm', scrollable: false});
       modalRef.componentInstance.uid = this.customer.uid;
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
}
