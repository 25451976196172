import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  AlertModel } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
@Component({
  selector: 'app-emi-wallet-history',
  templateUrl: './emi-wallet-history.component.html',
  styleUrls: ['./emi-wallet-history.component.css']
})
export class EmiWalletHistoryComponent implements OnInit {
  walletHistory: WalletHistory[];
  filter = new WalletHistoryFilter();
  phone = new FormControl('');
  driverId = new FormControl('');
  alert: AlertModel;
  walletHistoryListener: DriverWalletHistoryListener;
  emiLoading: boolean;
 constructor(private dbService: DatabaseService, private utils: UtilsService, public permissions: PermissionService) {
    this.walletHistory = [];
    this.emiLoading = false;
    this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
      if (success && history) {
          this.walletHistory = history;
          this.emiLoading = false;
      } else {
        this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.');
      }
    });
  }
  ngOnInit() {
  }
  getAllWalletHistory() {
    this.walletHistory = [];
    this.emiLoading = true;
    this.walletHistoryListener.getAllWalletHistoryByEmi(this.filter.from.selectedDate,
      this.filter.to.selectedDate, this.phone.value, this.driverId.value);
  }
  export() {
    const filename = () => 'Biker_Wallet_History_Summary ' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.DriverWalletHistory());
    exporter.exportDriverWalletHistory(this.walletHistory as WalletHistory[]);
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
 }
class WalletHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
