import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RouterPermissionService {
  constructor(public user: UserService) { }
  // Returns whether the User Management can be displayed
  get canShowDashBoardMenu(): boolean { return this.user.canDoAction('dashboard_search'); }
  get canShowUserMainMenu(): boolean { return this.user.canDoAction('users_search'); }
  // Returns whether Driver Menu item can be displayed
  get canShowDriverMainMenu(): boolean { return true; }
  get canShowNewRegistrationsSubMenu(): boolean { return this.user.canDoAction('newregistrations_search'); }
  get canShowQuickRegistrationsSubMenu(): boolean { return this.user.canDoAction('driverquicksearch_search'); }
  get canShowOnboardingSubMenu(): boolean { return this.user.canDoAction('onboarding_search'); }
  get canShowOnboardedDriversSubMenu(): boolean { return this.user.canDoAction('onboardeddrivers_search'); }
  get canShowOnlineDriversSubMenu(): boolean { return this.user.canDoAction('onlinedrivers_search'); }
  get canShowDriverStatsSubMenu(): boolean { return this.user.canDoAction('driverstats_search'); }
  get canShowDriverGCashSubMenu(): boolean { return this.user.canDoAction('exportgcash_search'); }
  get canShowBulkActivationSubMenu(): boolean { return this.user.canDoAction('bulkactivation_search'); }
  get canShowDriverTripAvailabiltySubMenu(): boolean { return this.user.canDoAction('drivertripavailability_search'); }
  // Returns whether Trip Management Menu item can be displayed
  get canShowManageTripsSubMenu(): boolean { return this.user.canDoAction('managetrips_search'); }
  get canShowSearchTripsSubMenu(): boolean { return this.user.canDoAction('searchtrips_search'); }
  get canShowTripMgmtBasedonHoursSubMenu(): boolean { return this.user.canDoAction('tripmanagementbasedonhours_search'); }
  get canShowBikerTripMgmtSubMenu(): boolean { return this.user.canDoAction('bikertripmanagement_search'); }
  get canShowTripSummarySubMenu(): boolean { return this.user.canDoAction('tripsummary_search'); }
  get canShowTripSummaryReportSubMenu(): boolean { return this.user.canDoAction('tripsummaryreport_search'); }
  get canShowActiveDriverReportSubMenu(): boolean { return this.user.canDoAction('activedriverreport_search'); }
  get canShowInactiveDriverReportSubMenu(): boolean { return this.user.canDoAction('inactivedriverreport_search'); }
  get canShowTripLocationTrackSubMenu(): boolean { return this.user.canDoAction('triplocationtrack_search'); }
  get canShowSearchTripsQA(): boolean { return this.user.canDoAction('tripqa_search'); }

  get canShowCashoutReqSubMenu(): boolean { return this.user.canDoAction('cashoutrequests_search'); }
  get canShowTopupReqSubMenu(): boolean { return this.user.canDoAction('topuprequests_search'); }
  get canShowWalletAdjustmentSubMenu(): boolean { return this.user.canDoAction('walletadjustments_search'); }
  get canShowTopupManualSubMenu(): boolean { return this.user.canDoAction('topupmanual_search'); }
  get canShowBulkTopupSubMenu(): boolean { return this.user.canDoAction('bulktopup_search'); }
  get canShowexportWalletAdjustmentSubMenu(): boolean { return this.user.canDoAction('exportwalletadjustment_search'); }
  get canShowexportDriverWalletHistorySubMenu(): boolean { return this.user.canDoAction('exportdriverwallethistory_search'); }
  get canShowexportWalletCommissionSubMenu(): boolean { return this.user.canDoAction('exportwalletcommission_search'); }
  get canShowlowWalletBalanceSubMenu(): boolean { return this.user.canDoAction('lowwalletbalance_search'); }
  get canShowexportWalletBalanceSubMenu(): boolean { return this.user.canDoAction('exportwalletbalance_search'); }

  get canShowemiHistorySearchSubMenu(): boolean { return this.user.canDoAction('emihistory_search'); }
  get canShowDriveremiWalletHistorySubMenu(): boolean { return this.user.canDoAction('driveremiwallethistory_search'); }
  get canShowDriveremiPendingWalletHistorySubMenu(): boolean { return this.user.canDoAction('driveremipendinghistory_search'); }
  get canShowCustomersSubMenu(): boolean { return this.user.canDoAction('customers_search'); }
  get canShowCustomersSearchSubMenu(): boolean { return this.user.canDoAction('customersearch_search'); }
  get canShowCustomerLoadTransactionSubMenu(): boolean { return this.user.canDoAction('customerloadtransaction_search'); }
  get canShowCustomerRateBulkUpdateSubMenu(): boolean { return this.user.canDoAction('customerratebulkupdate_search'); }
  get canShowDriverLoadTransactionSubMenu(): boolean { return this.user.canDoAction('driverloadtransaction_search'); }
  get canShowFixBikerNumberSearchSubMenu(): boolean { return this.user.canDoAction('fixbikernumber_search'); }
  get canShowPromoCodeSubMenu(): boolean { return this.user.canDoAction('promocodes_search'); }
  get canShowFareConfigSubMenu(): boolean { return this.user.canDoAction('fareconfiguration_search'); }
    // get canShowTripMenuSuperAdmin(): boolean { return this.user.isSuperAdmin(); }
  // get canShowTripsMainMenu(): boolean { return !this.user.isOpsL1(); }
  // get canShowManageTripsSubMenu(): boolean { return !this.user.isOpsL1(); }
  // get canShowManageTripSummarySubMenu(): boolean { return this.user.isAdmin() || this.user.isSuperAdmin(); }
  // // Returns whether Wallet Menu item can be displayed
  // get canShowWalletMainMenu(): boolean { return this.user.isAdminL1() || this.user.isSuperAdmin(); }
  //   // Returns whether Promo code menu item can be displayed
  // get canShowDriverSearchcretiriyaSubMenu(): boolean { return  this.user.isSuperAdmin(); }
  // // Returns whether Settings menu item can be displayed
  // get canShowSettingsMainMenu(): boolean { return this.user.isSuperAdmin(); }
  // get canShowCustomerDeleteMenu(): boolean { return this.user.isAdminL3() || this.user.isSuperAdmin(); }
  // // return Metrobank SubMenu
  // get canShowMetroBankSuperAdmin(): boolean { return this.user.isSuperAdmin(); }
  // // return Paymay SubMenu
  get canShowSettingsMainMenu(): boolean { return this.user.canDoAction('users_search'); }
  get canShowPaymayaSuperAdmin(): boolean { return this.user.canDoAction('users_search'); }
  get canShowHolidaySurchargeSuperAdmin(): boolean { return this.user.canDoAction('users_search'); }
  get canShowNewsArticlesMainMenu(): boolean { return this.user.canDoAction('newsarticles_search'); }
  get canShowCarouselBannerMainMenu(): boolean { return this.user.canDoAction('carouselbanner_search'); }
  get canShowCustomerSpecialRate(): boolean { return this.user.canDoAction('customerspecialrate_search'); }


  //customer wallet
  get canShowCustomerWalletMenu(): boolean { return this.user.canDoAction('customerwalletmenu_search'); }
  get canShowCustomerWalletAdjustmentSubMenu(): boolean { return this.user.canDoAction('customerwalletadjustments_search'); }
  get canShowCustomerExportWalletAdjustmentSubMenu(): boolean { return this.user.canDoAction('customerexportwalletadjustments_search'); }
  get canShowCustomerPaymayaTopupHistorySubMenu(): boolean { return this.user.canDoAction('customerpaymayatopuphistory_search'); }
  get canShowCustomerLowWalletBalanceSubMenu(): boolean { return this.user.canDoAction('customerlowwalletbalance_search'); }
  get canShowCustomerExportWalletHistorySubMenu(): boolean { return this.user.canDoAction('customerexportwallethistory_search'); }
  get canShowCustomerExportWalletBalanceSubMenu(): boolean { return this.user.canDoAction('customerexportwalletbalance_search'); }

  get canShowEmiBulkUpdateSubMenu(): boolean { return this.user.canDoAction('emibulkupdate_search'); }

  get canShowOtpProvider(): boolean {return this.user.canDoAction('otpprovider_search');}


}
