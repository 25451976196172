import { FareSettings } from './interfaces';

export enum TripStatus {
    rideRequested = 0,
    searchingForDrivers = 1,
    driverAssigned = 2,
    driverArrived = 3,
    tripStarted = 4,
    tripCancelledByRider = 5,
    tripCancelledEarlyByRider = 6,
    tripCancelledByDriver = 7,
    tripCompleted = 8,
    tripAbortNoDriversFound = 9,
    tripAbortServerError = 10,
    tripAdminCancelled = 11,
    requestingForBiker = 13
}
export enum TripType {
   all = 0,
   mcTaxi = 1,
   delivery = 2,
   pabili = 3,
   tricycle = 4,
   hmdelivery = 5
}

export enum TripTypeHM {
   scheduled = '1',
   immediate = '2'
}



/**
 * TRIP
 * Defines the keys in a trip object
 */
export interface Trip {
    tripId: string;
    status: number;
    isFavorite: boolean;
    reason?: string;
    rider: TripRiderInfo; // There cannot be a trip without a rider
    driver?: TripDriverInfo; // Driver is optional as this object will not be populated when no drivers are found.
    receipt?: TripReceipt; // Receipt will be available only for completed trips
    trip: TripDetails;
    bag?: BagDetails;
    timestamps?: Timestamps;
    region: string;
    paymentMode: string;
    tripType: number;
    tripDetails?: any;
    vehicle?: string;
    total_eta?:string;
}

export interface Timestamps {
    driver_assigned_time?: any;
    trip_start_time?: any; 
    trip_complete_time?:any;
    driver_arrived_time?:any;
}

/// Defines the rider information in the trip object
export interface TripRiderInfo {
    id: string;
    name: string;
    notes?: string;
    phone: string;
    email: string;
    created_at?: firebase.firestore.Timestamp;
}

/// Defines the joyride biker's information in the trip object
export interface TripDriverInfo {
    id: string;
    name: string;
    email: string;
    gender: string;
    phone: string;
    gCashPhone: string;
    driverPhotoURL: string;
    imei: string;
    licenseNumber: string;
    rating: string;
    commission: number;
    vehicleBrand: string;
    vehicleModel: string;
    vehiclePlateNumber: string;
    vehiclePhotoURL: string;
    ratingByRider?: DriverRatingByRider;
}

/// Defines rating of the biker by the customer after trip completion
export interface DriverRatingByRider {
    rating: number;
    comments?: string;
}

/// Defines the receipt object in the trip
export interface TripReceipt {
    commission: number;
    commissionAmt: number;
    creditToWallet: number;
    finalTripFare: number;
    netFare: number;
    promoCodeDiscount: number;
    tripFare: number;
}

/// Defines the trip details such as source, destination, promo codes, etc
export interface TripDetails {
    source: TripLocation;
    destination: TripLocation;
    settings: FareSettings;
    distance: TripDistance;
    promoCode?: string;
    fare: number;
    discountedFare?: number;
    otherExpenses?: [TripExpense];
    tripDate: firebase.firestore.Timestamp;
    item: string;
    tripFare: number;
    serviceCharge: number;
    holidayFee: number;
    deliverySchedule?: any;
    afterHourSurcharge?:any;
    additionalStopsCharge?:any;
    addOns?:any; 
    priorityFee?:any;
    tripType?:number;
    stops?:any;
    surCharge?:number;
    happyMoveExtraChargeData?:any;
}

export interface BagDetails {
    code?: string;
    description?: string; 
}

/// Defines the structure for source and destination objects in a trip
export interface TripLocation {
    latitude: number;
    longitude: number;
    title: string;
    fullAddress: string;
    contactPhoneNo: string;
    contactName: string;
    stop: string;
}

/// Defines the structure for distance object in the trip
export interface TripDistance {
    text: string;
    value: number;
}

/// Defines the structure for each object in the otherExpenses array
export interface TripExpense {
    amount: number;
    type: string;
}

/**
 * REQUESTS
 */
export interface UpdateTripStatusRequest {
    tripId: string;
    status: number;
    reason: string;
}


/**
 * TRIP
 * Defines the keys in a trip object
 */
export interface DriverTripSummaryReport {
        driverId: string;
        name: string;
        phone: string;
        numAccepted: number;
        numCancelledByCustomer: number;
        numCancelledByDriver: number;
        numTripsServerError: number;
        numTripsAdminCancelled: number;
        numRejected: number;
        numRequested: number;
        numTripsCompleted: number;
        rating: number;
        ratedTripsCount: number;
        totalCommission: number;
        totalFare: number;
        totalPromoCredits: number;
        totalDistanceServed: number;
        updateDate: firebase.firestore.Timestamp;
        region: string;
        numAccepted_delivery: number;
        numCancelledByCustomer_delivery: number;
        numCancelledByDriver_delivery: number;
        numTripsServerError_delivery: number;
        numTripsAdminCancelled_delivery: number;
        numRejected_delivery: number;
        numRequested_delivery: number;
        numTripsCompleted_delivery: number;
        rating_delivery: number;
        ratedTripsCount_delivery: number;
        totalCommission_delivery: number;
        totalFare_delivery: number;
        totalPromoCredits_delivery: number;
        totalDistanceServed_delivery: number;
        numRequested_pabili: number;
        numAccepted_pabili: number;
        numTripsCompleted_pabili: number;
        numRejected_pabili: number;
        numCancelledByCustomer_pabili: number;
        numCancelledByDriver_pabili: number;
        numTripsServerError_pabili: number;
        numTripsAdminCancelled_pabili: number;
        totalFare_pabili: number;
        totalCommission_pabili: number;
        numRequested_tricycle: number;
        numAccepted_tricycle: number;
        numTripsCompleted_tricycle: number;
        numRejected_tricycle: number;
        numCancelledByCustomer_tricycle: number;
        numCancelledByDriver_tricycle: number;
        numTripsServerError_tricycle: number;
        numTripsAdminCancelled_tricycle: number;
        totalFare_tricycle: number;
        totalCommission_tricycle: number;

}
export interface DriverTripId  {
    tripId?: string;
}
export interface DriverTripLocations  {
   d?: DriverLocationData;
}
export interface DriverLocationData {
    coordinates: firebase.firestore.GeoPoint;
    updated_at?: firebase.firestore.Timestamp;
}
