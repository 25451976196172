import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { BaseAPI, APIState } from './base.api';
import { ApiService } from '../services/api.service';


export class CarouselBannerAPI extends BaseAPI {
    private handler: ([any]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([any]) => void) {
        super(dbService);
        this.handler = completion;
    }
    getCarouselBannerSearch(status?: string, bannerName?:string, region?: string) {
        this.state = APIState.loading;
        this.dbService.getCarouselBannerSearch(status, bannerName, region).get().toPromise().then(response => {
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
    }
}