import { Component, OnInit } from '@angular/core';
import { Trip, TripStatus, UpdateTripStatusRequest } from 'src/app/interfaces/trip-interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import { AlertModel,DriverProfile, AssignDriver } from 'src/app/interfaces/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DriversListAPI , DriverProfileUpdateAPI} from 'src/app/api/driverList.api';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.css']
})
export class AssignDriverComponent implements OnInit {
  assignDriverForm: FormGroup;
  driverList: DriverProfile[];
  assignDriverAPI: DriverProfileUpdateAPI;
  trip: Trip; // Currently viewing trip details
  isLoading: boolean = false;
  canEdit: boolean = true;
  api: DriversListAPI;
  driverPhone: string;
  data: AssignDriver;
  alert: AlertModel;
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder) {

    this.api = new DriversListAPI(dbService, (drivers) => {
      this.driverList = drivers as DriverProfile[];
      this.isLoading = false;
      this.canEdit = true;
    });

    this.assignDriverAPI = new DriverProfileUpdateAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      if (success) {
        this.assignDriverForm.markAsPristine();
        this.assignDriverForm.disable();
      } else {
        this.assignDriverForm.enable();
      }
      this.showAlert(alertType, message);
    });

  }

  ngOnInit() {
    this.createPersonalInfoForm()
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  private createPersonalInfoForm() {
    this.assignDriverForm = this.fb.group({
      tripId: [this.trip.tripId],
      driverId: []
    })
  }

  searchDriverMobile(event: any) {
    this.driverList = [];
    if(event.target.selectionEnd === 10 || event.target.selectionStart === 10) {
      const mobile = event.target.value
      this.loadDrivers(mobile)
      this.isLoading = true;
      this.canEdit = false;
    }
  }

  private async loadDrivers(mobile) {
    this.api.loadDriverByPhone(mobile);
  }

  reassignBiker() {
    const data = this.assignDriverForm.value;
    this.assignDriverAPI.assignDriver(data as AssignDriver);
  }

}
