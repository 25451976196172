import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { GCashImport } from 'src/app/interfaces/interfaces';
import { DriversListAPI, DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { DriverProfile, TopupManual } from 'src/app/interfaces/interfaces';
import * as fs from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-topup-manual',
  templateUrl: './import-topup.component.html',
  styleUrls: ['./topup-manual.component.css']
})
export class ImportTopupManualComponent implements OnInit {
  public arrFinalData = [];
  public arrFinalStatusWithData = [];
  importGCashForm: FormGroup;
  data: any;
  excelHeader: any;
  uploadedFileName: any;
  driverGcash: any = [];
  isDisabledImport = false;
  isConfirm = false;
  isConfirmDisable = true;
  isConfirmExport = false;
  isLoad = false;
  tempDriverUid: any;
  processStatus: any;
  processStatusText: any;
  driverListapi: DriversListAPI;
  personalInfo: DriverProfile[];
  driverProfileUpdateAPI: DriverProfileUpdateAPI;
  gcashNo: string;
  gCashImport: GCashImport;
  refIdresponse: any;
  gcashResponseMessage: any;
  topupManual: TopupManual;
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService, private fb: FormBuilder) {
  }
  ngOnInit() {
    this.createimportGCashForm();
  }
  private createimportGCashForm() {
    this.importGCashForm = this.fb.group({
      importgcash: [null, Validators.required]
    });
  }
  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.uploadedFileName = target.files[0].name;
    if (target.files.length === 1 && (target.files[0].type === '.xlsx' ||
      target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      target.files[0].type === 'application/vnd.ms-excel')) {
      this.isDisabledImport = true;
      this.uploadedFileName = target.files[0].name;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);
    } else {

    }
  }
  importExcelData() {
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];
    const jsonObjectKeys = ['gcashno', 'paymentType', 'gcashrefno', 'paymayaRefNo', 'amount',  'remarks'];
    if (this.excelHeader.length === 6) {
      for (let i = 1; i <= this.data.length - 1; i++) {
        const excelObject = {};
        for (let j = 0; j < this.excelHeader.length; j++) {
          excelObject[jsonObjectKeys[j]] = this.data[i][j];
        }
        this.isConfirm = true;
        this.arrFinalData.push(excelObject);
        this.driverGcash = this.arrFinalData;
      }
    } else {
    }
  }
  async confirmData() {
    this.isConfirmDisable = false;
    this.isLoad = true;
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      if (this.arrFinalData[i].gcashrefno === undefined) {
        this.arrFinalData[i].gcashrefno = '';
      } else if (this.arrFinalData[i].paymayaRefNo === undefined) {
        this.arrFinalData[i].paymayaRefNo = '';
      }
      await this.importDriverProfileData(this.arrFinalData[i], i);
    }
    this.isConfirmExport = true;
    this.isLoad = false;
  }
  async importDriverProfileData(arrFinalData: any, iVal) {
    if (this.arrFinalData[iVal].gcashno !== undefined) {
      await this.dbService.getProfileInfotopup(this.arrFinalData[iVal].gcashno.toString()).then(forms => {
        this.tempDriverUid = (forms[0] === undefined || forms[0] === null) ? '' : forms[0].uid;
        this.apiService.bulkDriverTopup(this.tempDriverUid, this.arrFinalData[iVal].gcashrefno.toString(),
          this.arrFinalData[iVal].remarks.toString(), parseInt(this.arrFinalData[iVal].amount) ,
          this.arrFinalData[iVal].paymentType.toString(), this.arrFinalData[iVal].paymayaRefNo.toString()).then(response => {
            this.tempDriverUid = '';
            this.updateDriverCreatingSuccessStatus(this.arrFinalData[iVal], response);
          })
          .catch(response => {
            this.updateDriverCreatingFailureStatus(this.arrFinalData[iVal], response);
          }
          );
      }).catch(err => {
        console.log(err);
      });
    }
    this.refIdresponse = false;
    this.gcashResponseMessage = false;
  }
getTodayDate() {
    const todayDate = new Date();
    return todayDate.toISOString().split('T')[0];
  }
updateDriverCreatingSuccessStatus(currentRow: any, responseStatus: any) {
    const exportSuccessData = {};
    for (let j = 0; j < this.excelHeader.length; j++) {
      if (j === this.excelHeader.length - 1) {
        exportSuccessData['gcashno'] = currentRow.gcashno;
        exportSuccessData['paymentType'] = currentRow.paymentType;
        exportSuccessData['gcashrefno'] = currentRow.gcashrefno;
        exportSuccessData['paymayaRefNo'] = currentRow.paymayaRefNo;
        exportSuccessData['amount'] = currentRow.amount;
        exportSuccessData['remarks'] = currentRow.remarks;
        if (responseStatus['isSuccess'] === true) {
          exportSuccessData['processStatus'] = responseStatus['data']['code'];
          exportSuccessData['processStatusText'] = responseStatus['data']['message'];
        } else {
          exportSuccessData['processStatus'] = 'Failure';
          exportSuccessData['processStatusText'] = responseStatus['error']['message'];
        }
      }
    }
    this.arrFinalStatusWithData.push(exportSuccessData);
}
updateDriverCreatingFailureStatus(currentRow: any, responseStatus: any) {
    const exportFailureData = {};
    for (let j = 0; j < this.excelHeader.length; j++) {
      if (j === this.excelHeader.length - 1) {
        exportFailureData['gcashno'] = currentRow.gcashno;
        exportFailureData['paymentType'] = currentRow.paymentType;
        exportFailureData['gcashrefno'] = currentRow.gcashrefno;
        exportFailureData['paymayaRefNo'] = currentRow.paymayaRefNo;
        exportFailureData['amount'] = currentRow.amount;
        exportFailureData['remarks'] = currentRow.remarks;
        if (responseStatus['isSuccess'] === true) {
          exportFailureData['processStatus'] = responseStatus['data']['code'];
          exportFailureData['processStatusText'] = responseStatus['data']['message'];
        } else if (responseStatus === true) {
          exportFailureData['processStatus'] = 'Failure';
          if (this.refIdresponse === true) {
            exportFailureData['processStatusText'] = 'GCash Ref Number is Already Exits ';
          } else if (this.gcashResponseMessage === true) {
            exportFailureData['processStatusText'] = 'GCash number is Not Found';
          }
        } else {
          exportFailureData['processStatus'] = 'Failure';
          exportFailureData['processStatusText'] = (responseStatus['error']['message'] === null ||
            responseStatus['error']['message'] === undefined ?
            'GCash number is undefined' : responseStatus['error']['message']);
        }
      }
    }
    this.arrFinalStatusWithData.push(exportFailureData);
    this.refIdresponse = ' ';
    this.gcashResponseMessage = '';
}
downloadResultAsXLSX() {
    this.setExportExcelFileData(this.arrFinalStatusWithData);
}
setExportExcelFileData(excelExportData) {
    const excelHeader = ['Gcash Number', 'Payment Type', 'Gcash Ref Number',
    'Paymaya Reference No', 'Amount',  'Remarks', 'Process Status', 'Descriptions'];
    const exportExcelHeader = ['gcashno', 'paymentType', 'gcashrefno', 'paymayaRefNo',
    'amount',  'remarks', 'processStatus', 'processStatusText'];
    const finalExcelData = [];
    let objecttemp = {};
    let i = 0;
    let columnsKeyNames = [];
    for (i = 0; i < excelExportData.length; i++) {
      const singleRecord = excelExportData[i];
      columnsKeyNames = Object.keys(singleRecord);
      objecttemp = {};
      for (let j = 0; j < columnsKeyNames.length; j++) {
        objecttemp[excelHeader[j]] = singleRecord[exportExcelHeader[j]];
      }
      finalExcelData.push(objecttemp);
    }
    this.exportAsExcelFile(finalExcelData, 'TopUp');
}
exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
}
saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    fs.saveAs(data, fileName + '' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
