import { BaseAPI, APIState } from './base.api';
import { Trip, UpdateTripStatusRequest, DriverTripSummaryReport, DriverTripId } from '../interfaces/trip-interfaces';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DriverLocationStat, DriverTripStatus } from 'src/app/interfaces/interfaces';
import { Observable } from 'rxjs';
/**
 * Invokes the cloud function API to force update a trip status. Any response received
 * will be forwarded to the caller.
 */
/**
 * Fetches a list of Trips matching the given filter properties (state, from date and to date)
 * Also this method registers a listener over the query and notifies when there are changes
 * to the results.
 */
export class RequestingTripAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripsRequestingToday(todayDate, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripsRequestingToday(todayDate, region)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
}

export class OngoingTripAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripsOngoingToday(todayDate, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripsOngoingToday(todayDate, region)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
}

export class LatePickupTripImmediateAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripLatePickupTodayImmediate(todayDate, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripLatePickupTodayImmediate(todayDate, region)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
}

export class LatePickupTripScheduledAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripLatePickupTodayScheduled(todayDate, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripLatePickupTodayScheduled(todayDate, region)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
}


export class LatePickupForCancellationTripAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripLatePickupForCancellation(todayDate, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.allTripLatePickupForCancellation(todayDate, region)
            .valueChanges().subscribe(values => {
                this.handler(values as [Trip]);
            }, err => {
                console.error(err);
            });
    }
}

export class LateDropoffTripAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([Trip]) => void;
    constructor(dbService: DatabaseService, completion: ([Trip]) => void) {
        super(dbService);
        this.handler = completion;
    }
    loadTrips(todayDate, region) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }
        this.state = APIState.loading;
        this.dbService.allTripLateDropoff(todayDate, region)
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [Trip]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }

}


