import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public afAuth: AngularFireAuth) { }
  login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
              .then((res: any) => { resolve(res); },
                    (err: any) => { reject(err); });
    });
  }
  logout() {
    return new Promise<any>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }
  resetPasswordInit(email: string) {
    const urlvalue =  environment.resetlink;
    return this.afAuth.auth.sendPasswordResetEmail(
      email,
      { url: urlvalue });
    }
    getAuth() {
      return this.afAuth.auth;
    }

}
