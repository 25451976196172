import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverGCashAPI } from 'src/app/api/driver-gcash.api';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { OnboardingApplication, DriverProfile } from 'src/app/interfaces/interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { UserService } from 'src/app/services/user.service';

import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-driver-gcash',
  templateUrl: './driver-gcash.component.html',
  styleUrls: ['./driver-gcash.component.css'],
})
export class DriverGCashComponent implements OnInit {
  drivergcash: OnboardingApplication[];
  driverGCashAPI: DriverGCashAPI;
  searchGCash = new DriverGCashFilter();
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  constructor(private dbService: DatabaseService, public userService: UserService,
              public utils: UtilsService, public datepipe: DatePipe,
              private modalService: NgbModal) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.drivergcash = [];
    this.driverGCashAPI = new DriverGCashAPI(dbService, (drivergcash) => {
      this.drivergcash = drivergcash;
    });
  }
  ngOnInit() {
  }
  get canExport(): boolean {
    if (!this.drivergcash) { return false; }
    const allRequests = this.drivergcash;
    return (allRequests && allRequests.length > 0);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  searchDriverGCash() {
   this.driverGCashAPI.loadDriverGCash(this.searchGCash.from.selectedDate, this.searchGCash.to.selectedDate, this.region.value);
  }
  exportAllDriverGCash() {
    const drivers = this.drivergcash;
    const filename = () => 'driver_gcash' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.driverGCash());
    exporter.exportDriversGCash(drivers as DriverProfile[]);
  }
}
/** * FILTER for Trips */
class DriverGCashFilter {

  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());

  }
}
