import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import * as moment from 'moment';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { DriverLogSearch } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
    selector: 'app-driver-log',
    templateUrl: './driver-log.component.html'
})
export class DriverLogComponent implements OnInit {
    public startDate: string;
    public endDate: string;
    driverLogSearch = new DriverLogFilter();
    driverLog: DriverLogSearch[];
    api: DriversListAPI;
    changes: any;
constructor(private utils: UtilsService, private dbService: DatabaseService, private modalService: NgbModal) {
    this.api = new DriversListAPI(dbService, (drivers) => {
        this.driverLog = drivers;
        this.getTabChanges();
    });
}
ngOnInit() {
}
searchDriverLog() {
    this.api.driverLogSearch(this.driverLogSearch.from.selectedDate, this.driverLogSearch.to.selectedDate);
}
getTabChanges() {
    this.changes = [];
    for (let i = 0 ; i <= this.driverLog.length - 1 ; i++) {
    if (this.driverLog[i].change !== "") {
    const changesString = JSON.parse(this.driverLog[i].change);
    changesString.update_at = this.driverLog[i].updated_at;
    changesString.email = this.driverLog[i].email;
    changesString.user_id = this.driverLog[i].user_id;
    if (changesString.driverId !== undefined) {
    this.dbService.driverProfileSubscription(changesString.driverId).subscribe((profile) => {
        changesString.driver_Phone = profile.personal.phone;
     }, (error) => {
    console.error(error);
     });
    }
    this.changes.push(changesString);
}
}
}
}
class DriverLogFilter {
    status: number;
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    reset() {
        this.from = new DatePickerModel(moment().startOf('day').toDate());
        this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
}
