import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestingTripAPI, OngoingTripAPI, LatePickupTripImmediateAPI, LatePickupTripScheduledAPI, LatePickupForCancellationTripAPI, LateDropoffTripAPI } from 'src/app/api/tripqa.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import { TripQaDetailsComponent } from 'src/app/modals/trip-qa-details/trip-qa-details.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-trip-qa',
  templateUrl: './trip-qa.component.html',
  styleUrls: ['./trip-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TripQaComponent implements OnInit {
  RequestingTripAPI: RequestingTripAPI;
  OngoingTripAPI: OngoingTripAPI;
  LatePickupTripImmediateAPI: LatePickupTripImmediateAPI;
  LatePickupTripScheduledAPI: LatePickupTripScheduledAPI;
  LatePickupForCancellationTripAPI: LatePickupForCancellationTripAPI;
  LateDropoffTripAPI: LateDropoffTripAPI;
  requesting_trips: Trip[];
  ongoing_trips: Trip[];
  latepickup_immediate_trips: Trip[];
  latepickup_scheduled_trips: Trip[];
  latepickup_forcancellation_trips: Trip[];
  latedropoff_trips: Trip[];
  trips: Trip[];fformatTripStatus
  tripAPI: any;
  report: string = '0';
  interval: any;
  dropdown = ["Requesting Booking Today","Ongoing Booking Today","Late Pickup Today (Immediate)","Late Pickup Today (Scheduled)","Late Pickup Today (For Cancellation)","Late Dropoff"]
  constructor(private dbService: DatabaseService,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.trips = [];
    this.requesting_trips = [];
    this.ongoing_trips = [];
    this.latepickup_immediate_trips = [];
    this.latepickup_scheduled_trips = [];
    this.latepickup_forcancellation_trips = [];
    this.latedropoff_trips = [];
    this.RequestingTripAPI = new RequestingTripAPI(dbService, (trips) => {this.requesting_trips = trips; this.selectReport();});
    this.OngoingTripAPI = new OngoingTripAPI(dbService, (trips) => {this.ongoing_trips = trips;this.selectReport();});
    this.LatePickupTripImmediateAPI = new LatePickupTripImmediateAPI(dbService, (trips) => {this.latepickup_immediate_trips = trips;this.selectReport();});
    this.LatePickupTripScheduledAPI = new LatePickupTripScheduledAPI(dbService, (trips) => {this.latepickup_scheduled_trips = trips;this.selectReport();});
    this.LatePickupForCancellationTripAPI = new LatePickupForCancellationTripAPI(dbService, (trips) => {this.latepickup_forcancellation_trips = trips;this.selectReport();});
    this.LateDropoffTripAPI = new LateDropoffTripAPI(dbService, (trips) => {this.latedropoff_trips = trips;this.selectReport();});
    this.tripAPI = this.RequestingTripAPI;
    this.trips = this.requesting_trips;
  }

ngOnInit() {
  const todayDate = moment().toDate();
  this.RequestingTripAPI.loadTrips(todayDate, this.checkRegion());
  this.OngoingTripAPI.loadTrips(todayDate, this.checkRegion());
  this.LatePickupTripImmediateAPI.loadTrips(todayDate, this.checkRegion());
  this.LatePickupTripScheduledAPI.loadTrips(todayDate, this.checkRegion());
  this.LatePickupForCancellationTripAPI.loadTrips(todayDate, this.checkRegion());
  this.LateDropoffTripAPI.loadTrips(todayDate, this.checkRegion());

  clearInterval(this.interval);
  this.interval = setInterval(() => {
      this.applyFilter()
   }, 60000);
}

checkRegion() {
  if (this.permissions.canCheckRegionCebu()) {
      return 'Metro Cebu';
  } else {
    return '';
  }
}

applyFilter() {
    const todayDate = moment().toDate();
    this.LatePickupTripImmediateAPI.loadTrips(todayDate, this.checkRegion());
    this.LatePickupTripScheduledAPI.loadTrips(todayDate, this.checkRegion());
    this.LatePickupForCancellationTripAPI.loadTrips(todayDate, this.checkRegion());
    this.LateDropoffTripAPI.loadTrips(todayDate, this.checkRegion());
    this.selectReport();
}


selectReport() {
  if(this.report === '0') {
    this.tripAPI = this.RequestingTripAPI;
    this.trips = this.requesting_trips
  } else if(this.report === '1') {
    this.tripAPI = this.OngoingTripAPI;
    this.trips = this.ongoing_trips
  } else if(this.report === '2') {
    this.tripAPI = this.LatePickupTripImmediateAPI;
    this.trips = this.latepickup_immediate_trips
  } else if(this.report === '3') {
    this.tripAPI = this.LatePickupTripScheduledAPI;
    this.trips = this.latepickup_scheduled_trips
  } else if(this.report === '4') {
    this.tripAPI = this.LatePickupForCancellationTripAPI;
    this.trips = this.latepickup_forcancellation_trips
  } else if(this.report === '5') {
    this.tripAPI = this.LateDropoffTripAPI;
    this.trips = this.latedropoff_trips;
    
  }
}
openTripInfoModal(trip: Trip) {
      const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true});
      modalRef.componentInstance.trip = trip;
}
getDifference(startTripTime, ETA) {
  const todayDate = moment().toDate();
  const start_time = this.utils.dateFromTimestamp(startTripTime);
  const eta = ETA;
  const time_difference = Math.abs(todayDate.getTime() - start_time.getTime());
  var diffMinutes = Math.ceil(time_difference / (1000 * 60)); // get minutes
  if(eta < diffMinutes) { //if dropoff time exceeds
    return false;
  } else { //if dropoff time is on time
    return true;
  }
}

getDriverVehicleName(status: string): string | null {
    return OnboardingUtils.getVehiclyName(status);
  }

}
