import { ExportToCsv } from 'export-to-csv';
import { CashoutRequest, TopupRequest, DriverProfile, Customer, DriverTripStat,
          PaymayaReports, LocationDriver, CustomerPaymayaReports, EMIdetails, ErrorImportDriverService, ImportDriverBag } from '../interfaces/interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { Trip, TripStatus, DriverTripSummaryReport, TripType } from '../interfaces/trip-interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { WalletUpdateType, WalletHistoryType, CustomerWalletType } from '../interfaces/wallet.interfaces';
import { WalletCashoutStatus, DriverStatus, WalletUtils, WalletTopupStatus } from 'src/app/utils/wallet.utils';
import * as moment from 'moment';
export class CSVExporter {
    constructor(fileName: string, headers: string[], public utilService?: UtilsService) {
        this.fileName = fileName;
        const options = this.getOptions(fileName, headers);
        this.exporter = new ExportToCsv(options);
    }

    fileName: string;
    idcount = 0;
    private exporter: ExportToCsv;


    static DriverWalletHistory() {
        return [
            'ID', 'Region', 'Driver Id',  'Adj Date', 'Payment Type', 'Reference No' , 'Trip Id',
            'Apporved By', 'Opening Balance', 'Credit', 'Debit', 'Closing Balance', 'Update Type', 'Remarks'
        ];
    }
    static CashoutExport() {
        return [
            'ID', 'Name', 'Phone Number', 'Gcash No', 'Status', 'Date Of Request', 'Date Of Accepted', 'Amount', 'Receipt Id'
        ];
    }
    static TopupExport() {
        return [
            'ID', 'Name', 'Phone Number', 'Gcash No', 'Status', 'Date Of Request', 'Date Of TopUp',
            'Date Of Accepted', 'Amount', 'Receipt Id'
        ];
    }

    static customerProfileTitles() {
        return [
            'Reg ID', 'UID', 'Name', 'Phone', 'Email', 'Registration Date'
        ];
    }
    static driverProfileTitles() {
        return [
            'Reg ID', 'Biker ID', 'UID', 'Registration Date', 'Training Date', 'Status', 'Substatus', 'Remarks',
            'Firstname', 'Middlename', 'Lastname', 'Address', 'Barangay', 'Region', 'City', 'Date of Birth', 'Sex',
            'Birthplace', 'Email', 'Phone', 'GCash Number', 'Phone Brand & Model', 'IMEI1', 'Civil Status', 'TIN', 'SSS',
            'Mother\'s Maiden Firstname', 'Mother\'s Maiden Middlename', 'Mother\'s Maiden Lastname', 'Emergency Contact Name',
            'Emergency Contact Phone', 'Motorcycle Brand', 'Motorcycle Model', 'Model Year', 'Piston Displacement',
            'Motorcycle Plate Number', 'Motorcycle Engine Number', 'Motorcycle Chassis Number', 'Motorcycle OR Expiry',
            'Motorcycle Type', 'Driver\'s License Type', 'Driver\'s License Number', 'Driver\'s License Expiry',
            'What is your current job', 'Are you currently enrolled in other service providers?', 'Who referred you to JoyRide?',
            'How many hours per day can you commit to JoyRide?', 'When can you attend training of Joyride?',
            'How did you hear about JoyRide?', 'Activation Date', 'Driver Photo', 'Vehicle Photo', 'Operator Name', 'Operator Phone Number',
            'Reference Number', 'Vehicle Type'
        ];
    }

    static inactiveDriverTitles() {
        return [
            'ID', 'UID', 'Name', 'Phone', 'Region', 'Status', 'Sub Status', 'Registration Date', 'Activation Date'
        ];
    }

    static driverGCash() {
        return [
            'id', 'uid', 'msisdn', 'middleName', 'lastName', 'mothersMaidenName', 'firstName', 'emailAddress', 'gender', 'Region', 'suffix',
            'nationality', 'maritalStatus', 'birthDate', 'birthPlace', 'contactNumber', 'occupation', 'idType', 'idNumber', 'idRemarks',
            'caStreet', 'caBrgytown', 'caProvinceCity', 'caRegion', 'caCountry', 'caZipcode',
            'paStreet', 'paBrgytown', 'paProvinceCity', 'paRegion', 'paZipcode', 'paCountry', 'classification'
        ];
    }
    static tripManagement() {
        return [
            'Trip ID', 'Trip Date', 'Region', 'Trip Type', 'Item', 'Biker Name', 'Biker Phone', 'Biker Email', 'Biker UID',
            'Customer Name', 'Customer Phone', 'Customer Id', 'Pick up', 'Source', 'Drop Off',
            'destination', 'Distance', 'Notes', 'Trip Requested',
            'Trip Accepted', 'Biker Arrived', 'Trip Started', 'Trip Completed',
            'Biker Rating', 'Fare', 'Discounted Fare', 'Commission Amount', 'Bikers Credit', 'Promo Code', 'Status',
            'Reason for Cancellation'
        ];
    }

    static drivertripReport() {
        return [
            'ID', 'UID', 'Name', 'Phone', 'Region', 'No.of Requested', 'No.of Accepted', 'No.of Completed', 'No.of Rejected',
            'No.of Cancelled by Customer', 'No.of Cancelled by Driver', 'No.of Server Error', 'No.of Admin Cancelled',
            'Total Fare', 'Total Commission'
        ];
    }
    static walletBalance() {
        return [
            'UID', 'Name', 'Mobile Number', 'Region', 'Closing Balance'
        ];
    }
    static walletHistory() {
        return [
            'S.No', 'Biker UID', 'Name', 'Phone', 'Region', 'Adjustment Date', 'Payment Type', 'Reference No',
            'Apporved By', 'Amount', 'Closing Balance', 'Account Type', 'Updated Type', 'Remarks'
        ];
    }

    static walletCommissionHistory() {
        return [
            'S.No', 'Driver Id', 'Name', 'Phone', 'Region', 'Adjustment Date', 'Trip Id', 'Amount',
            'Closing Balance', 'Account Type', 'Updated Type'
        ];
    }

    static tripSummary() {
        return [
            'Date', 'No. of Trips', 'Trips Completed', 'No. of Trips Can. Customers', 'No. of Trips Can. Drivers',
            'No. of Trips Can. Admin', 'No Bikers Found'
        ];
    }
    static paymayaTopupHistory() {
        return [ 'S.No', 'Phone', 'DriverId', 'Request Ref Number', 'Receipt Number', 'Amount', 'Type', 'Status', 'Created Date'
        ];
    }
    static paymayaDriverWalletlink() {
        return [ 'S.No', 'Phone', 'DriverId', 'Request Ref Number',  'Type', 'LinkId', 'Status', 'Created Date'
        ];
    }
    static paymayaWalletlinkHistory() {
        return [ 'S.No', 'Phone', 'DriverId', 'Type', 'LinkId', 'Status', 'Updated Date'
        ];
    }
    static paymayaTopupInquiry() {
        return [ 'S.No', 'Phone', 'DriverId', 'Request Ref Number', 'Receipt Number', 'Amount', 'Type', 'Status', 'Created Date' ,
        'Updated Date'
    ];
    }
    static paymayaTransactionsFees() {
        return [ 'S.No', 'Phone', 'DriverId', 'Request Ref Number', 'Receipt Number', 'Amount', 'Reason',
        'Updated Date'
        ];
    }
    static customerwalletHistory() {
        return [
            'S.No', 'Customer UID', 'Name', 'Phone', 'Adjustment Date', 'Payment Type', 'Reference No',
            'Apporved By', 'Amount', 'Closing Balance', 'Account Type', 'Card Type', 'History Type', 'Remarks'
        ];
    }
    static CustomerWalletHistory() {
        return [
            'ID', 'Customer Id',  'Adj Date', 'Name', 'Phone', 'Payment Type', 'Reference No' , 'Trip Id',
            'Apporved By', 'Opening Balance', 'Credit', 'Debit', 'Closing Balance', 'Card Type', 'History Type', 'Remarks'
        ];
    }
    static customerwalletBalance() {
        return [
            'UID', 'Name', 'Mobile Number', 'Email', 'Closing Balance'
        ];
    }
    static paymayaCustomerTopupHistory() {
        return [ 'S.No', 'Phone', 'CustomerId', 'Request Ref Number', 'Transaction. Ref Number', 'Card Type',
                 'Amount', 'Type',  'Created Date'
        ];
    }
    static driverOnlineStuckReport() {
        return [
            'UID', 'Name', 'Mobile Number', 'Online Status', 'Trip Status', 'Region'
        ];
    }
    getOptions(fName: string, fHeaders: string[]) {
        return {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            filename: fName,
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: fHeaders
        };
    }


    exportCashouts(requests: [CashoutRequest]) {
    const data = requests.map(r => {
            return { biker_phone: r.paymayaNumber, amount: r.amount, drivername: r.name };
        });
        this.exporter.generateCsv(data);
    }

    exportEmiDetails(requests: [EMIdetails]) {
    const data = requests.map(r => {
            return { 
                date_created: r.date_created, 
                driverId: r.driverId,
                newBalance: r.newBalance,
                phone: r.phone, 
                previousBalance: r.previousBalance 
            };
        });
        this.exporter.generateCsv(data);
    }

    exportImportDriverBag(requests: [ImportDriverBag]) {
    const data = requests.map(r => {
            return { 
                mobilenumber: r.phone, 
                jr_bag: r.jr_bag == undefined ? '' : r.jr_bag,
                hm_bag: r.hm_bag == undefined ? '' : r.hm_bag,
                message: r.message == undefined ? '' : r.message
            };
        });
        this.exporter.generateCsv(data);
    }

    exportErrorImportDriverService(requests: [ErrorImportDriverService]) {
    const data = requests.map(r => {
            return { 
                phone: r.phone, 
                error: r.error,
                service: r.service
            };
        });
        this.exporter.generateCsv(data);
    }

    exportCashoutRequest(cashoutRequest: CashoutRequest[]) {
        const data = cashoutRequest.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                name: d.name,
                phone: d.phone,
                gCashPhone: d.gCashPhone,
                status: this.getCashoutStatusName(d.status),
                created_at: this.createdDate(d.created_at),
                updated_at: this.createdDate(d.updated_at),
                amount: d.amount,
                receiptId: d.receiptId
            };
        });
        this.exporter.generateCsv(data);
    }
    exportTopupRequest(topupRequest: TopupRequest[]) {
        const data = topupRequest.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                name: d.name,
                phone: d.phone,
                gCashPhone: d.gCashPhone,
                status: this.getTopupStatusName(d.status),
                created_at: this.createdDate(d.created_at),
                dateOfTopUp: d.dateOfTopUp,
                acted_on: this.createdDate(d.acted_on),
                amount: d.amount,
                receiptId: d.receiptId
            };
        });
        this.exporter.generateCsv(data);
    }

    exportWalletAdjustmentandTopupManual(walletHistory: WalletHistory[]) {
        const data = walletHistory.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.walletId === undefined ? ' ' : d.walletId,
                name: d.name,
                phone: d.phone,
                region: d.region === undefined ? 'Metro Manila' : d.region,
                updated_at: this.createdDate(d.updated_at),
                paymentType: d.otherInfo.gCashRefId !== undefined ? 'Cash' :
                d.otherInfo.requestReferenceNumber !== undefined ? 'PayMaya' : 'ByAdmin',
                referenceNo: d.otherInfo.gCashRefId !== undefined ? d.otherInfo.gCashRefId :
                d.otherInfo.requestReferenceNumber !== undefined ? d.otherInfo.requestReferenceNumber : '',
                approverName: d.otherInfo.approverName === undefined ? '' : d.otherInfo.approverName,
                amount: d.amount.toFixed(2),
                finalBalance: d.finalBalance.toFixed(2),
                updateType: this.updateTypeString(d.updateType),
                historyType: this.historyTypeString(d.historyType),
                remarks: d.otherInfo.reason
            };
        });
        this.exporter.generateCsv(data);
    }
    exportWalletCommission(walletHistory: WalletHistory[]) {
        const data = walletHistory.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.walletId === undefined ? ' ' : d.walletId,
                name: d.name,
                phone: d.phone,
                region: d.region === undefined ? 'Metro Manila' : d.region,
                updated_at: this.createdDate(d.updated_at),
                tripId: d.otherInfo.tripId,
                amount: d.amount.toFixed(2),
                finalBalance: d.finalBalance.toFixed(2),
                updateType: this.updateTypeString(d.updateType),
                historyType: this.historyTypeString(d.historyType)
            };

        });
        this.exporter.generateCsv(data);
    }

    exportTopup(requests: [TopupRequest]) {
        const data = requests.map(r => {
            return { phone: r.gCashPhone, name: r.name, ref: r.gCashRefNumber, amount: r.amount };
        });
        this.exporter.generateCsv(data);
    }


    exportCustomers(customers: Customer[]) {
        const data = customers.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                uid: d.uid,
                customername: d.firstName + ' ' + d.lastName,
                phone: d.phone,
                email: d.email,
                created_at: this.createdDate(d.created_at)

            };
        });
        this.exporter.generateCsv(data);
    }

    exportDrivers(drivers: DriverProfile[]) {
        const data = drivers.map(d => {
            return {
                formId: d.form.id === undefined ? 'Not Specified' : d.form.id,
                bikerId: d.form.bikerId === undefined ? 'Not Specified' : d.form.bikerId,
                uid: d.uid === undefined ? 'Not Specified' : d.uid,
                regDate: d.form.appliedDate === undefined ? 'Not Specified' : d.form.appliedDate,
                trainDate: d.form.trainingDate === undefined ? 'Not Specified' : d.form.trainingDate,
                status: d.form.mainStatus === undefined ? 'Not Specified' : this.getStatusName(d.form.mainStatus),
                substatus: d.form.subStatus === undefined ? 'Not Specified' : this.getSubStatusName(d.form.subStatus),
                remarks: d.form.remarks === undefined ? 'Not Specified' :
                d.form.remarks === null ? 'Not Specified' : d.form.remarks.toString(),
                firstname: d.personal.firstname === undefined ? 'Not Specified' : d.personal.firstname,
                middlename: d.personal.middlename === undefined ? 'Not Specified' : d.personal.middlename,
                lastname: d.personal.lastname === undefined ? 'Not Specified' : d.personal.lastname,
                address: d.personal.address === undefined ? 'Not Specified' : d.personal.address,
                barangay: d.personal.barangay === undefined ? 'Not Specified' : d.personal.barangay,
                region: d.personal.region === undefined ? 'Not Specified' : d.personal.region,
                city: d.personal.town === undefined ? 'Not Specified' : d.personal.town,
                dob: d.personal.dob === undefined ? 'Not Specified' : d.personal.dob,
                sex: d.personal.gender === undefined ? 'Not Specified' : d.personal.gender,
                birthplace: d.personal.birthplace === undefined ? 'Not Specified' : d.personal.birthplace,
                email: d.personal.email === undefined ? 'Not Specified' : d.personal.email,
                phone: '0' + d.personal.phone === undefined ? 'Not Specified' : '0' + d.personal.phone,
                gCashNumber: d.wallet.gCashPhone === undefined ? 'Not Specified' : d.wallet.gCashPhone,
                phoneBrand: d.personal.phoneBrand === undefined ? 'Not Specified' : d.personal.phoneBrand,
                imei: d.personal.imei === undefined ? 'Not Specified' : d.personal.imei,
                civilStatus: d.personal.civilStatus === undefined ? 'Not Specified' : d.personal.civilStatus,
                tin: d.personal.tin === undefined ? 'Not Specified' : d.personal.tin,
                sss: d.personal.sss === undefined ? 'Not Specified' : d.personal.sss,
                mFirstname: d.personal.mFirstname === undefined ? 'Not Specified' : d.personal.mFirstname,
                mMiddlename: d.personal.mMiddlename === undefined ? 'Not Specified' : d.personal.mMiddlename,
                mLastname: d.personal.mLastname === undefined ? 'Not Specified' : d.personal.mLastname,
                eContactName: d.personal.emergencyContactName === undefined ? 'Not Specified' : d.personal.emergencyContactName,
                eContactPhone: '0' + d.personal.emergencyContactPhone === undefined ? 'Not Specified' :
                '0' + d.personal.emergencyContactPhone,
                vBrand: d.vehicle.vehicleBrand === undefined ? 'Not Specified' : d.vehicle.vehicleBrand,
                vModel: d.vehicle.vehicleModel === undefined ? 'Not Specified' : d.vehicle.vehicleModel,
                vYear: d.vehicle.modelYear === undefined ? 'Not Specified' : d.vehicle.modelYear,
                vPiston: d.vehicle.pistonDisplacement === undefined ? 'Not Specified' : d.vehicle.pistonDisplacement,
                vPlateNum: d.vehicle.vehiclePlateNumber === undefined ? 'Not Specified' : d.vehicle.vehiclePlateNumber,
                vEngNum: d.vehicle.vehicleEngineNumber === undefined ? 'Not Specified' : d.vehicle.vehicleEngineNumber,
                vChassisNum: d.vehicle.vehicleChasisNumber === undefined ? 'Not Specified' : d.vehicle.vehicleChasisNumber,
                vORExpiry: d.vehicle.vehicleORExpiryDate === undefined ? 'Not Specified' : d.vehicle.vehicleORExpiryDate,
                vType: d.vehicle.motorCycleType === undefined ? 'Not Specified' : d.vehicle.motorCycleType,
                dLicenseType: d.vehicle.driverLicenseType === undefined ? 'Not Specified' : d.vehicle.driverLicenseType,
                dLicenseNum: d.vehicle.driverLicenseNumber === undefined ? 'Not Specified' : d.vehicle.driverLicenseNumber,
                dLicenseExpiry: d.vehicle.driverLicenseExpiryDate === undefined ? 'Not Specified' : d.vehicle.driverLicenseExpiryDate,
                currentJob: d.others.currentJob === undefined ? 'Not Specified' : d.others.currentJob,
                // + ' (' + d.others.currentJobOthers === undefined ? 'Not Specified' : d.others.currentJobOthers  + ')',
                enrolled: d.others.riderGroup === undefined ? 'Not Specified' : d.others.riderGroup,
                // + ' (' + d.others.riderGroupOthers === undefined ? 'Not Specified' : d.others.riderGroupOthers + ')',
                referredBy: d.others.referredBy === undefined ? 'Not Specified' : d.others.referredBy,
                //  + ' (' + d.others.referredByOthers === undefined ? 'Not Specified' : d.others.referredByOthers  + ')',
                hours: d.others.availability === undefined ? 'Not Specified' : d.others.availability,
                training: d.others.whenAttendingTraining === undefined ? 'Not Specified' : d.others.whenAttendingTraining,
                // tslint:disable-next-line: max-line-length
                //  + ' ('+                 d.others.whenAttendingTrainingComment  === undefined ? 'Not Specified' : d.others.whenAttendingTrainingComment  + ')',
                source: d.others.infoSource === undefined ? 'Not Specified' : d.others.infoSource,
                //  + ' ('  + d.others.infoSourceOthers  === undefined ? 'Not Specified' : d.others.infoSourceOthers  + ')',
                activationDate: d.form.activationDate === undefined ? 'Not Specified' : d.form.activationDate,
                driverPhoto: d.form.driverPhoto === undefined ? 'No' : 'Yes',
                vehiclePhoto: d.form.vehiclePhoto === undefined ? 'No' : this.vehiclePhoto(d.form.vehiclePhoto),
                operatorName: d.personal.operatorName === undefined ? 'Not Specified' : d.personal.operatorName,
                operatorPhoneNumber: d.personal.operatorPhoneNumber === undefined ? 'Not Specified' : d.personal.operatorPhoneNumber,
                referencenumber: d.personal.referencenumber === undefined ? 'Not Specified' : d.personal.referencenumber,
                vehicle_type: d.personal.vehicle_type === undefined ? 'Not Specified' : d.personal.vehicle_type,

            };
        });
        this.exporter.generateCsv(data);
    }
    exportDriversGCash(drivers: DriverProfile[]) {
        const data = drivers.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                uid: d.uid,
                phone: '0' + d.gCash.gcashnumber,
                middlename: d.gCash.middlename,
                lastname: d.gCash.lastname,
                mFirstname: d.personal.mFirstname + ' ' + d.personal.mMiddlename + ' ' + d.personal.mLastname,
                firstname: d.gCash.firstname,
                email: d.gCash.email,
                sex: d.gCash.gender,
                region: d.personal.region,
                suffix: d.gCash.suffix,
                nationality: d.gCash.nationalty,
                maritialStatus: d.gCash.maritalstatus,
                dob: d.gCash.dob,
                birthplace: d.gCash.birthplace,
                contactNumber: d.gCash.contactnumber,
                occupation: d.gCash.occupation,
                idType: d.gCash.idtype,
                idNumber: d.gCash.idnumber,
                idRemarks: d.gCash.idremarks,
                caStreet: d.gCash.curaddressstreet,
                caBrgytown: d.gCash.curaddresstown,
                caProvinceCity: d.gCash.curaddresscity,
                caRegion: d.gCash.curaddressregion,
                caCountry: d.gCash.curaddresscountry,
                caZipcode: d.gCash.curaddresszipcode,
                paStreet: d.gCash.peraddressstreet,
                paBrgytown: d.gCash.peraddresstown,
                paProvinceCity: d.gCash.peraddresscity,
                paRegion: d.gCash.peraddressregion,
                paZipcode: d.gCash.peraddresszipcode,
                paCountry: d.gCash.peraddresscountry,
                classification: d.gCash.classification,
            };
        });
        this.exporter.generateCsv(data);
    }
exportDriverWalletHistory(walletHistory: WalletHistory[]) {
        const data = walletHistory.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                region: d.region === undefined ? 'Metro Manila' : d.region,
                walletId: d.walletId === undefined ? '' :  d.walletId,
                updated_at: this.createdDate(d.updated_at),
                paymentType: d.otherInfo.gCashRefId !== undefined ? 'Cash' :
                d.otherInfo.requestReferenceNumber !== undefined ? 'PayMaya' : 'ByAdmin',
                referenceNo: d.otherInfo.gCashRefId !== undefined ? d.otherInfo.gCashRefId :
                d.otherInfo.requestReferenceNumber !== undefined ? d.otherInfo.requestReferenceNumber : '',
                tripId: (d.otherInfo.tripId === undefined ? '' : d.otherInfo.tripId),
                approverName: (d.otherInfo.approverName === undefined ? '' : d.otherInfo.approverName),
                openingBalance: d.finalBalance - d.amount,
                credit: (d.updateType === 0 ? d.amount.toFixed(2) : ''),
                debit: (d.updateType === 1 ? d.amount.toFixed(2) : ''),
                finalBalance: d.finalBalance.toFixed(2),
                historyType: this.historyTypeString(d.historyType),
                remarks: (d.otherInfo.reason === undefined ? '' : d.otherInfo.reason)
            };

        });
        this.exporter.generateCsv(data);
    }


    exportTripManagement(trip: Trip[]) {
        const data = trip.map(d => {
            return {
                tripIdtripData: d.tripId,
                tripDate: this.createdDate(d.trip.tripDate),
                region: d.region === undefined ? 'Not Specified' : d.region,
                tripType: d.tripType === undefined ? 'MC Taxi' : this.getTripTypeName( d.tripType),
                item: d.trip.item === undefined ? 'Not Specified' : d.trip.item,
                drivername: d.driver === undefined ? 'Not Specified' : d.driver.name,
                phone: d.driver === undefined ? 'Not Specified' : d.driver.phone,
                email: d.driver === undefined ? 'Not Specified' : d.driver.email,
                driverid: d.driver === undefined ? 'Not Specified' : d.driver.id,
                ridername: d.rider.name,
                riderphone: d.rider.phone,
                id: d.rider.id,
                bikeraddress: d.trip.source.fullAddress,
                source: d.trip.source.latitude + ',' + d.trip.source.longitude,
                customeraddress: d.trip.destination.fullAddress,
                destination: d.trip.destination.latitude + ',' + d.trip.destination.longitude,
                distance: d.trip.distance.text,
                notes: d.rider.notes === undefined ? 'Not Specified' : d.rider.notes,
                triprequesteddate: this.createdDate(d.trip.tripDate),
                tripaccepteddate: d.timestamps.driver_assigned_time === undefined ? 'Not Specified' :
                    this.createdDate(d.timestamps.driver_assigned_time),
                bikerarrived: d.timestamps.driver_arrived_time === undefined ? 'Not Specified' :
                    this.createdDate(d.timestamps.driver_arrived_time),
                tripstarted: d.timestamps.trip_start_time === undefined ? 'Not Specified' :
                    this.createdDate(d.timestamps.trip_start_time),
                tripcompleted: d.timestamps.trip_complete_time === undefined ? 'Not Specified' :
                    this.createdDate(d.timestamps.trip_complete_time),
                bikerrating: d.driver === undefined ? 'Not Specified' : d.driver.rating,
                fare: d.trip.fare,
                discountfare: d.trip.discountedFare === undefined ? 'Not Specified' : d.trip.discountedFare,
                commissionamount: d.receipt === undefined ? 'Not Specified' : d.receipt.commissionAmt,
                bikercredit: d.receipt === undefined ? 'Not Specified' : d.receipt.creditToWallet,
                promoCode: d.trip.promoCode === undefined ? 'Not Specified' : d.trip.promoCode,
                status: this.getTripStatusName(d.status),
                reason: d.reason === undefined ? 'Not Specified' : d.reason
            };

        });
        this.exporter.generateCsv(data);
    }


    exportWalletBalance(driverBalance: DriverProfile[]) {
        const data = driverBalance.map(d => {
            return {
                uid: d.uid,
                firstname: d.personal.firstname + '' + d.personal.lastname,
                phone: '0' + d.personal.phone,
                region: d.personal.region,
                amount: d.wallet.amount
            };
        });
        this.exporter.generateCsv(data);
    }

    exportTripSummary(driverTripStat: DriverTripStat[]) {
        const data = driverTripStat.map(d => {
              return {
                date: d === undefined ? '' : d.dateValue,
                numTrips: d.numTrips === undefined ? '' : d.numTrips,
                numTripsCompleted: d.numTripsCompleted === undefined ? '' : d.numTripsCompleted,
                numTripsCancelledByCustomer: d.numTripsCancelledByCustomer === undefined ? '' : d.numTripsCancelledByCustomer,
                numTripsCancelledByDriver: d.numTripsCancelledByDriver === undefined ? '' : d.numTripsCancelledByDriver,
                numTripsAdminCancelled: d.numTripsAdminCancelled === undefined ? '' : d.numTripsAdminCancelled,
                numTripsNoBikerFound: d.numTripsNoBikerFound === undefined ? '' : d.numTripsNoBikerFound,
            };
      });
        this.exporter.generateCsv(data);
    }
    exportTripSummaryDelivery(driverTripStat: DriverTripStat[]) {
        const data = driverTripStat.map(d => {
            return {
                date: d === undefined ? '' : d.dateValue,
                numTrips: d.numTrips_delivery === undefined ? '' : d.numTrips_delivery,
                numTripsCompleted: d.numTripsCompleted_delivery === undefined ? '' : d.numTripsCompleted_delivery,
                numTripsCancelledByCustomer: d.numTripsCancelledByCustomer_delivery === undefined ? '' :
                                            d.numTripsCancelledByCustomer_delivery,
                numTripsCancelledByDriver: d.numTripsCancelledByDriver_delivery === undefined ? '' : d.numTripsCancelledByDriver_delivery,
                numTripsAdminCancelled: d.numTripsAdminCancelled_delivery === undefined ? '' : d.numTripsAdminCancelled_delivery,
                numTripsNoBikerFound: d.numTripsNoBikerFound_delivery === undefined ? '' : d.numTripsNoBikerFound_delivery,
            };
        });
        this.exporter.generateCsv(data);
    }
    exportTripSummaryPabili(driverTripStat: DriverTripStat[]) {
        const data = driverTripStat.map(d => {
            return {
                date: d === undefined ? '' : d.dateValue,
                numTrips: d.numTrips_pabili === undefined ? '' : d.numTrips_pabili,
                numTripsCompleted: d.numTripsCompleted_pabili === undefined ? '' : d.numTripsCompleted_pabili,
                numTripsCancelledByCustomer: d.numTripsCancelledByCustomer_pabili === undefined ? '' :
                                            d.numTripsCancelledByCustomer_pabili,
                numTripsCancelledByDriver: d.numTripsCancelledByDriver_pabili === undefined ? '' : d.numTripsCancelledByDriver_pabili,
                numTripsAdminCancelled: d.numTripsAdminCancelled_pabili === undefined ? '' : d.numTripsAdminCancelled_pabili,
                numTripsNoBikerFound: d.numTripsNoBikerFound_pabili === undefined ? '' : d.numTripsNoBikerFound_pabili,
            };
        });
        this.exporter.generateCsv(data);
    }

    exportActiveDriverSummary(trip: DriverTripSummaryReport[]) {
        const data = trip.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.driverId === undefined ? ' ' : d.driverId,
                drivername: d.name === undefined ? ' ' : d.name,
                phone: d.phone === undefined ? ' ' : d.phone,
                region: d.region === undefined ? ' ' : d.region,
                numRequested: this.additions(d.numRequested, d.numRequested_delivery, d.numRequested_pabili),
                numAccepted: this.additions(d.numAccepted , d.numAccepted_delivery, d.numAccepted_pabili),
                numTripsCompleted: this.additions(d.numTripsCompleted, d.numTripsCompleted_delivery, d.numTripsCompleted_pabili),
                numRejected: this.additions(d.numRejected, d.numRejected_delivery, d.numRejected_pabili),
                numCancelledByCustomer: this.additions(d.numCancelledByCustomer, d.numCancelledByCustomer_delivery,
                d.numCancelledByCustomer_pabili),
                numCancelledByDriver: this.additions(d.numCancelledByDriver, d.numCancelledByDriver_delivery,
                d.numCancelledByDriver_pabili),
                numTripsServerError: this.additions(d.numTripsServerError, d.numTripsServerError_delivery, d.numTripsServerError_pabili),
                numTripsAdminCancelled: this.additions(d.numTripsAdminCancelled, d.numTripsAdminCancelled_delivery,
                d.numTripsAdminCancelled_pabili),
                totalFare: this.additions(d.totalFare, d.totalFare_delivery, d.totalFare_pabili),
                totalCommission: this.additions(d.totalCommission, d.totalCommission_delivery, d.totalCommission_pabili)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportActiveDriverSummaryDelivery(trip: DriverTripSummaryReport[]) {

        const data = trip.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.driverId === undefined ? ' ' : d.driverId,
                drivername: d.name === undefined ? ' ' : d.name,
                phone: d.phone === undefined ? ' ' : d.phone,
                region: d.region === undefined ? ' ' : d.region,
                numRequested: d.numRequested_delivery === undefined ? ' ' : d.numRequested_delivery,
                numAccepted: d.numAccepted_delivery === undefined ? ' ' : d.numAccepted_delivery,
                numTripsCompleted: d.numTripsCompleted_delivery === undefined ? ' ' : d.numTripsCompleted_delivery,
                numRejected: d.numRejected_delivery === undefined ? ' ' : d.numRejected_delivery,
                numCancelledByCustomer: d.numCancelledByCustomer_delivery === undefined ? ' ' : d.numCancelledByCustomer_delivery,
                numCancelledByDriver: d.numCancelledByDriver_delivery === undefined ? ' ' : d.numCancelledByDriver_delivery,
                numTripsServerError: d.numTripsServerError_delivery === undefined ? ' ' : d.numTripsServerError_delivery,
                numTripsAdminCancelled: d.numTripsAdminCancelled_delivery === undefined ? ' ' : d.numTripsAdminCancelled_delivery,
                totalFare: d.totalFare_delivery === undefined ? ' ' : d.totalFare_delivery.toFixed(2),
                totalCommission: d.totalCommission_delivery === undefined ? ' ' : d.totalCommission_delivery.toFixed(2)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportActiveDriverSummaryPabili(trip: DriverTripSummaryReport[]) {

        const data = trip.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.driverId === undefined ? ' ' : d.driverId,
                drivername: d.name === undefined ? ' ' : d.name,
                phone: d.phone === undefined ? ' ' : d.phone,
                region: d.region === undefined ? ' ' : d.region,
                numRequested: d.numRequested_pabili === undefined ? ' ' : d.numRequested_pabili,
                numAccepted: d.numAccepted_pabili === undefined ? ' ' : d.numAccepted_pabili,
                numTripsCompleted: d.numTripsCompleted_pabili === undefined ? ' ' : d.numTripsCompleted_pabili,
                numRejected: d.numRejected_pabili === undefined ? ' ' : d.numRejected_pabili,
                numCancelledByCustomer: d.numCancelledByCustomer_pabili === undefined ? ' ' : d.numCancelledByCustomer_pabili,
                numCancelledByDriver: d.numCancelledByDriver_pabili === undefined ? ' ' : d.numCancelledByDriver_pabili,
                numTripsServerError: d.numTripsServerError_pabili === undefined ? ' ' : d.numTripsServerError_pabili,
                numTripsAdminCancelled: d.numTripsAdminCancelled_pabili === undefined ? ' ' : d.numTripsAdminCancelled_pabili,
                totalFare: d.totalFare_pabili === undefined ? ' ' : d.totalFare_pabili.toFixed(2),
                totalCommission: d.totalCommission_pabili === undefined ? ' ' : d.totalCommission_pabili.toFixed(2)
            };
        });
        this.exporter.generateCsv(data);
    }

    exportInactiveDriversSummary(drivers: DriverProfile[]) {
        const data = drivers.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                uid: d.uid,
                name: d.personal.firstname + ' ' + d.personal.lastname,
                phone: d.personal.phone,
                region: d.personal.region,
                status: this.getStatusName(d.form.mainStatus),
                substatus: this.getSubStatusName(d.form.subStatus),
                regDate: this.customFormatDate(d.form.appliedDate),
                activationDate: this.customFormatDate(d.form.activationDate)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayaTopupHistory(driver: PaymayaReports[]) {
        const data = driver.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone,
                uid: d.uid,
                requestReferenceNumber: d.paymaya === undefined ? d.requestReferenceNumber : d.paymaya.requestReferenceNumber,
                receiptNumber: d.paymaya === undefined ? '' : d.paymaya.receiptNumber,
                amount: d.paymaya === undefined ? d.amount : d.paymaya.amount,
                type: d.Type,
                status: d.paymaya === undefined ? '' : d.paymaya.status,
                createdDate: this.createdDate(d.createDate)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayawalletLink(driver: PaymayaReports[]) {
        const data = driver.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone,
                driverId: d.driverId,
                referenceNo: d.referenceNo,
                type: d.Type,
                linkId: d.linkId  === undefined ? '' : d.linkId,
                state: d.paymaya === undefined ? '' : d.paymaya.card === undefined ? '' : d.paymaya.card.state,
                created_at: this.createdDate(d.created_at)
           };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayawalletLinkHistory(driver: PaymayaReports[]) {
        const data = driver.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone,
                driverId: d.driverId,
                type: d.Type,
                linkId: d.linkId  === undefined ? '' : d.linkId,
                state: d.paymaya === undefined ? '' : d.paymaya.card === undefined ? '' : d.paymaya.card.state,
                update_at: this.createdDate(d.update_at)
           };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayaTopupInquiry(driver: PaymayaReports[]) {
        const data = driver.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone === undefined ? '' : d.phone,
                uid: d.uid,
                requestReferenceNumber:  d.requestReferenceNumber,
                receiptNumber: d[0] === undefined ? '' : d[0].receiptNumber,
                amount:  d[0] === undefined ? '' : d[0].amount,
                type: d.Type,
                status: d[0] === undefined ? '' : d[0].status,
                createdDate: this.createdDate(d.createDate),
                updatedAt: d[0] === undefined ? '' : d[0].updatedAt
            };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayaTrnsactionsFees(driver: PaymayaReports[]) {
        const data = driver.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone,
                walletId: d.walletId,
                requestReferenceNumber: d.otherInfo === undefined ? '' : d.otherInfo.requestReferenceNumber === undefined ? '' :
                d.otherInfo.requestReferenceNumber,
                receiptNumber: d.otherInfo === undefined ? '' : d.otherInfo.paymayaReceiptnumber === undefined ? '' :
                d.otherInfo.paymayaReceiptnumber,
                amount: d.amount,
                reason: d.otherInfo === undefined ? '' : d.otherInfo.reason,
                updated_at: this.createdDate(d.updated_at)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportCustomerWalletAdjustmentandTopupManual(walletHistory: WalletHistory[]) {
        const data = walletHistory.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                driverId: d.walletId === undefined ? ' ' : d.walletId,
                name: d.name,
                phone: d.phone,
                updated_at: this.createdDate(d.updated_at),
                paymentType: d.otherInfo.gCashRefId !== undefined ? 'Cash' :
                d.otherInfo.requestReferenceNumber !== undefined ? 'PayMaya' : 'ByAdmin',
                referenceNo: d.otherInfo.gCashRefId !== undefined ? d.otherInfo.gCashRefId :
                d.otherInfo.requestReferenceNumber !== undefined ? d.otherInfo.requestReferenceNumber : '',
                approverName: d.otherInfo.approverName === undefined ? '' : d.otherInfo.approverName,
                amount: d.amount.toFixed(2),
                finalBalance: d.finalBalance.toFixed(2) || 0,
                updateType: this.updateTypeString(d.updateType),
                cardType:  d.otherInfo.cardType !== undefined ? d.otherInfo.cardType : '',
                historyType: this.historyCustomerTypeString(d.historyType),
                remarks: d.otherInfo.reason !== undefined ? d.otherInfo.reason : ''
            };
        });
        this.exporter.generateCsv(data);
    }
    exportCustomerWalletHistory(walletHistory: WalletHistory[]) {
        const data = walletHistory.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id: this.idcount,
                walletId: d.walletId === undefined ? '' :  d.walletId,
                updated_at: this.createdDate(d.updated_at),
                name: d.name === undefined ? '' :  d.name,
                phone: d.phone === undefined ? '' :  d.phone,
                paymentType: d.otherInfo.gCashRefId !== undefined ? 'Cash' :
                d.otherInfo.requestReferenceNumber !== undefined ? 'PayMaya' : 'ByAdmin',
                referenceNo: d.otherInfo.gCashRefId !== undefined ? d.otherInfo.gCashRefId :
                d.otherInfo.requestReferenceNumber !== undefined ? d.otherInfo.requestReferenceNumber : '',
                tripId: (d.otherInfo.tripId === undefined ? '' : d.otherInfo.tripId),
                approverName: (d.otherInfo.approverName === undefined ? '' : d.otherInfo.approverName),
                openingBalance: d.finalBalance - d.amount,
                credit: (d.updateType === 0 ? d.amount.toFixed(2) : ''),
                debit: (d.updateType === 1 ? d.amount.toFixed(2) : ''),
                finalBalance: d.finalBalance.toFixed(2),
                cardType: (d.otherInfo.cardType === undefined ? '' : d.otherInfo.cardType),
                historyType: this.historyCustomerTypeString(d.historyType),
                remarks: (d.otherInfo.reason === undefined ? '' : d.otherInfo.reason)
            };

        });
        this.exporter.generateCsv(data);
    }
    exportCustomerWalletBalance(customerBalance: Customer[]) {
        const data = customerBalance.map(d => {
            return {
                uid: d.uid,
                firstname: d.firstName + '' + d.lastName,
                phone: '0' + d.phone,
                email: d.email,
                amount: d.amount
            };
        });
        this.exporter.generateCsv(data);
    }
    exportPaymayaCustomerTopupHistory(customer: CustomerPaymayaReports[]) {
        const data = customer.map(d => {
            this.idcount = this.idcount + 1;
            return {
                id : this.idcount,
                phone: d.phone,
                uid: d.uid,
                requestReferenceNumber: d.requestReferenceNumber === undefined ? d.requestReferenceNumber : d.requestReferenceNumber,
                transactionReferenceNumber: d.transactionReferenceNumber === undefined ?
                d.transactionReferenceNumber : d.transactionReferenceNumber,
                paymayaScheme : (d.paymentScheme !== undefined ? d.paymentScheme : 'N/A') ,
                amount: d.totalAmount === undefined ? d.amount : d.totalAmount.value,
                type: d.Type,
                createdDate: this.createdDate(d.createDate)
            };
        });
        this.exporter.generateCsv(data);
    }
    exportDriverOnlineStuckReport(locations: LocationDriver[]) {
        const data = locations.map(c => {
            return {
                uid: c.d.uid,
                firstname: c.d.firstName + '' + c.d.lastName,
                phone: '0' + c.d.phone,
                online: c.d.online,
                status: this.getTripStatusName(c.d.status),
                region: c.d.region
            };
        });
        this.exporter.generateCsv(data);
    }
    getTripStatusName(status: number): string | null {
        return this.tripStatusText(status);
    }
    getTripTypeName(type: number): string | null {
        switch (type) {
            case TripType.mcTaxi : return 'MC Taxi';
            case TripType.delivery: return 'Delivery';
            case TripType.pabili: return 'Pabili';
        }
    }
    tripStatusText(status: number) {
        switch (status) {
            case TripStatus.rideRequested: return 'REQUESTED';
            case TripStatus.searchingForDrivers: return 'SEARCHING BIKERS';
            case TripStatus.driverAssigned: return 'BIKER ASSIGNED';
            case TripStatus.driverArrived: return 'BIKER ARRIVED';
            case TripStatus.tripStarted: return 'TRIP IN PROGRESS';
            case TripStatus.tripCompleted: return 'TRIP COMPLETED';
            case TripStatus.tripCancelledByRider: return 'CUSTOMER CANCELLED';
            case TripStatus.tripCancelledEarlyByRider: return 'CUSTOMER CANCELLED';
            case TripStatus.tripCancelledByDriver: return 'BIKER CANCELLED';
            case TripStatus.tripAbortNoDriversFound: return 'NO BIKERS FOUND';
            case TripStatus.tripAbortServerError: return 'SERVER ERROR';
            case TripStatus.tripAdminCancelled: return 'ADMIN CANCELLED';
            default: return '';
        }
    }

    customFormatDate(date: string, format: string = 'YYYY-MM-DD') {
        if (!date) { return ''; }
        return moment(date).format(format);
    }
    updateTypeString(type: number): string {
        switch (type) {
            case WalletUpdateType.CREDIT: return 'CREDIT';
            case WalletUpdateType.DEBIT: return 'DEBIT';
            default: return 'UNKNOWN';
        }
    }

    historyTypeString(type: number): string {
        switch (type) {
            case WalletHistoryType.TOPUP_APPROVED: return 'TOPUP APPROVED';
            case WalletHistoryType.CASHOUT_REQUESTED: return 'CASHOUT REQUESTED';
            case WalletHistoryType.CASHOUT_REJECTED: return 'CASHOUT REJECTED';
            case WalletHistoryType.TRIP_COMMISSION_DEBITED: return 'COMMISSION';
            case WalletHistoryType.TRIP_PROMO_CODE_CREDITS: return 'PROMO CODE';
            case WalletHistoryType.ADMIN_WALLET_ADJUSTMENTS: return 'BY ADMIN';
            case WalletHistoryType.TRANSACTION_FEES : return 'TRANSACTION FEES';
            case WalletHistoryType.PAYMAYA_TOPUP: return 'PAYMAYA TOPUP';
            case WalletHistoryType.METROBANK_TOPUP: return 'METROBANK TOPUP';
            default: return 'UNKNOWN';
        }
    }

    historyCustomerTypeString(type: number): string {
        switch (type) {
          case CustomerWalletType.PAYMAYA_TOPUP: return 'TOP-UP BY PAYMAYA';
          case CustomerWalletType.TRIP_PAYMENT_DRIVER: return 'TRIP PAYMENT FOR BIKER';
          case CustomerWalletType.ADMIN_WALLET_ADJUSTMENTS: return 'ADMIN ADJUSTMENTS';
          case CustomerWalletType.TRIP_PAYMENT_CUSTOMER: return 'TRIP PAYMENT CUSTOMER';
          case CustomerWalletType.REFER_AND_EARN: return 'REFER AND EARN';
          case CustomerWalletType.REFERRAL_REWARD: return 'REFERRAL REWARD';
          default: return 'UNKNOWN';
        }
      }

    getStatusName(status: number): string | null {
        return OnboardingUtils.getStatusName(status);
    }

    getDriverStatusName(status: DriverStatus): string | null {
        return WalletUtils.getDriverStatusName(status);
    }

    getSubStatusName(status: number): string | null {
        return OnboardingUtils.getSubStatusName(status);
    }


    createdDate(createdat?: firebase.firestore.Timestamp): string {
        if (createdat) { return this.formatDate(createdat.toDate()); } else { return ''; }
    }

    getCashoutStatusName(status: WalletCashoutStatus): string | null {
        return WalletUtils.getCashoutStatusName(status);
    }
    getTopupStatusName(status: WalletTopupStatus): string | null {
        return WalletUtils.getTopupStatusName(status);
    }
    additions(val1: number, val2: number, val3: number) {
        if (val1 === undefined) { val1 = 0; }
        if (val2 === undefined) { val2 = 0; }
        if (val3 === undefined) { val3 = 0; }
        const sum = val1 + val2 + val3;
        return (sum !== 0) ? sum : '';
      }
      optionalNumber(val?: number) {
        return (val == null || val === undefined) ? '' : Number(val.toFixed(2));
      }

    formatDate(date: Date) {
        if (!date) { return ''; }
        return moment(date).format('MMM DD, YYYY h:mm:a');
    }
    vehiclePhoto(vehiclePhoto: string) {
        // tslint:disable-next-line: max-line-length
        return (vehiclePhoto === 'https://firebasestorage.googleapis.com/v0/b/joyride-prod.appspot.com/o/driverDetails%2F1579236169%2F1579236169-vehicle-photo?alt=media&token=bffb4d35-c6d9-4177-a870-3d9b7d20a144' ? 'No' : 'Yes');
    }
}
