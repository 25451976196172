import { Component, OnInit } from '@angular/core';
import { TripDailyStatsAPI } from 'src/app/api/driver-stats.api';
import { DatabaseService } from 'src/app/services/database.service';
import { AllTripStat } from 'src/app/interfaces/interfaces';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-driver-log',
  templateUrl: './driver-online-log.component.html'
})
export class DriverOnlineReportComponent implements OnInit {
    dailyFromDate: Date = new Date();
    dailyToDate: Date = new Date();

  ngOnInit() {
  }

}
