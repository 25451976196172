// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  happymove: "https://api-gateway.happymove.com.ph",
  wemove: "https://api.wemove.com.ph",
  endpoint: 'https://us-central1-joyride-prod.cloudfunctions.net',
  resetlink: 'https://admin.joyride.com.ph/#/resetpassword',
firebase: {
    apiKey: 'AIzaSyBH2TnQp8TnoC20zjba0A8V9zzV0zYqXB4',
    authDomain: 'joyride-prod.firebaseapp.com',
    databaseURL: 'https://joyride-prod.firebaseio.com',
    projectId: 'joyride-prod',
    storageBucket: 'joyride-prod.appspot.com',
    messagingSenderId: '147519689926',
    appId: '1:147519689926:web:7588f92cd35f006847f3c7'
  }
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
