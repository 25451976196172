import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MetroBankBindAccountApi } from 'src/app/api/metrobank.api';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-metrobank',
    templateUrl: './bindacc-inquiry.component.html'
})

export class BindAccInquiryComponent implements OnInit {
driverphone = new FormControl('');
referenceNo = new FormControl('');
transactioncode = new FormControl('');
metroBankAPI: MetroBankBindAccountApi;
bindAccInquiryHisstory: any;
constructor(private dbService: DatabaseService, private apiService: ApiService,
            public permissions: PermissionService) {
  this.metroBankAPI = new MetroBankBindAccountApi(apiService, (success, history) => {
    this.bindAccInquiryHisstory = history;
  });
}
ngOnInit() {
}
getAllWalletHistory() {
     this.metroBankAPI.metroBankBindInquiry(this.driverphone.value, this.referenceNo.value);
}
canFillterBikers(): boolean {
  return this.driverphone.dirty;
}
canFillterBikersId(): boolean {
 return this.referenceNo.dirty;
}
}
