import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = '';
  email: any ;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { 
    this.createForm();
  }

  ngOnInit() {
  }
  
  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }

  tryLogin(value){
    this.authService.login(value.email, value.password)
    .then(res => {
      this.router.navigate(['/home']);
    }, err => {
      console.log(err);
      this.errorMessage = err.message;
    })
  }

  resetPassword(value) {
    this.email = value;
    if (!this.email) {
      alert('Type in your email first');
    }
    this.authService.resetPasswordInit(this.email) .then(
      () =>  alert('A password reset link has been sent to your emailaddress'),
      (rejectionReason) => alert(rejectionReason))
    .catch(e => alert('An error occurred while attempting to resetyour password'));
  }
}
