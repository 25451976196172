import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAdminUserAPI, CreateAdminUserRequest } from 'src/app/api/user.api';
import { ApiService } from 'src/app/services/api.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  newUserForm: FormGroup;
  alert: AlertModel;
  defaultRegion: any;
  onboardingRegions = [];
  loadedData: any = [];
  displayName: any;
  email: any;
  password: any;
  region: any;
  docID: any;
  // APIs
  createUserAPI: CreateAdminUserAPI;

  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal) {
      this.createUserAPI = new CreateAdminUserAPI(apiService, (success, message) => {
        this.showAlert(success, message);
        this.newUserForm.enable();
      });
      this.onboardingRegions = OnboardingUtils.getRegions();
     // this.defaultRegion = this.onboardingRegions[0];
  }

  ngOnInit() {
    this.displayName = this.loadedData.displayName;
    this.email = this.loadedData.email;
   
    this.defaultRegion = this.loadedData.region;
    this.docID = this.loadedData.uid;
    this.createNewUserForm();
  }

  // Private

  canSave(): boolean {
    return this.newUserForm.valid && this.newUserForm.dirty;
  }

  createNewUserForm() {
    this.newUserForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email]],
    
      region: ['']
    });
  }

  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid || !c.touched;
  }

  private showAlert(success: boolean, message: string) {
    const type = success ? 'success' : 'danger';
    this.alert = { type, message } as AlertModel;
    setTimeout(() => {
      this.alert = null;
      this.activeModal.close('success');
    }, 3000);
  }

  // Actions

  updateUser() {
    const payload = this.newUserForm.value;
    this.newUserForm.disable();
    this.createUserAPI.updateUser(this.docID, payload as CreateAdminUserRequest);
  }
}
