import * as moment from 'moment';

export enum DriverStatus {
    activated = 1,
    conditionally_activated = 2,
    pending_for_gear_releasing = 3,
    pending_for_activation = 4,
    rejected = 5,
    pre_releasing_done = 6,
    deactivated = 7
}

export enum WalletCashoutStatus {
    pending = 0,
    approved = 1,
    declined = 2
}

export enum WalletTopupStatus {
    pending = 0,
    approved = 1,
    declined = 2
}

export enum EmiTransactionType {
    no_emi_transaction_type = undefined,
    automatic = 0,
    manual = 1
}

export class WalletUtils {

    /**
     * Retuns a human understandable string for the given delivery status
     * @param status;
     */
    static getDriverStatusName(status: number): string | null {
        switch (status) {
            case DriverStatus.activated: return 'Activated';
            case DriverStatus.conditionally_activated: return 'Cnditionally Activated';
            case DriverStatus.pending_for_gear_releasing: return 'Pending for Gear Releasing';
            case DriverStatus.pending_for_activation: return 'Pending for Activation';
            case DriverStatus.rejected: return 'Rejected';
            case DriverStatus.pre_releasing_done: return 'Pre Releasing Done';
            case DriverStatus.deactivated: return 'Deactivated';
            default: return '';
        }
    }

    /**
     * Retuns a human understandable string for the given delivery status
     * @param status;
     */
    static getEmiTransactionType(type: number): string | null {
        switch (type) {
            case EmiTransactionType.automatic: return 'Automatic';
            case EmiTransactionType.manual: return 'Manual';
            case EmiTransactionType.no_emi_transaction_type: return 'Automatic';
            default: return '';
        }
    }

    /**
     * Retuns a human understandable string for the given cashout status
     * @param status;
     */
    static getCashoutStatusName(status: number): string | null {
        switch (status) {
            case WalletCashoutStatus.pending: return 'Pending';
            case WalletCashoutStatus.approved: return 'Approved';
            case WalletCashoutStatus.declined: return 'Declined';
            default: return '';
        }
    }

    /**
     * Retuns a human understandable string for the given cashout status
     * @param status;
     */
    static getTopupStatusName(status: number): string | null {
        switch (status) {
            case WalletTopupStatus.pending: return 'Pending';
            case WalletTopupStatus.approved: return 'Approved';
            case WalletTopupStatus.declined: return 'Declined';
            default: return '';
        }
    }
    /**
     * Formats the given timestamp in milliseconds for display in cashout / topup requests page
     * @param timestamp;
     */
    static formatDate(seconds: number) {
        if (seconds === undefined) { return '--'; }
        const ts = new Date(seconds * 1000);
        return moment(ts).format('MMM DD, YYYY h:mm:a');
    }
}
