import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { ApiService } from 'src/app/services/api.service';
import { DatabaseService } from 'src/app/services/database.service';
import { ErrorImportDriverService, AlertModel } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-customer-rate-bulk-update',
  templateUrl: './customer-rate-bulk-update.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerRateBulkUpdateComponent implements OnInit {
	public arrFinalData = [];
	file_to_upload: File;
	importForm: FormGroup;
	data: any;
	excelHeader: any;
	uploadedFileName: any;
	rates: any = [];
	isDisabledImport = false;
	isConfirm = false;
  isConfirmExport = false;
	isConfirmDisable = true;
	isLoad = false;
  Rates:any = [];
	alert: AlertModel;

  constructor(private fb: FormBuilder, 
  	public http: HttpClient, 
  	private apiService: ApiService,
    private dbService: DatabaseService) { }

  ngOnInit() {
  	this.createImportCSVForm();
  }
  private createImportCSVForm() {
    this.importForm = this.fb.group({
      import: [null, Validators.required]
    });
  }

  onfilechange(evt: any) {
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1 && evt.target.accept !== '.xlsx') { throw new Error('Cannot use multiple files'); }
    this.file_to_upload = target.files[0];
    this.uploadedFileName = target.files[0].name;
	  this.isDisabledImport = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      };
      reader.readAsBinaryString(target.files[0]);

  }

  importExcelData() {
    let location = ['Laguna','Metro Manila','Metro Cebu']
    let rateType = ['baseFare','perKm','per_stops','night_surcharge','holiday_surcharge','cash_remittance','s1.driver_help','s2.driver_help','s3.driver_help'
    ,'s1.extra_help','s2.extra_help','s3.extra_help','s1.extra_help_2','s2.extra_help_2','s3.extra_help_2'
    ,'s1.base','s1.perKm','s2.perKm','s3.perKm','serviceFees']
    let service = ['MCTAXI','DELIVERY','PABILI','TRICYCLE','HMDELIVERY']
    let vehicleType = ['motorcycle','mv_small','mv_medium','mv_large']
    var phoneno = /^\d{10}$/;
    this.isDisabledImport = false;
    this.excelHeader = this.data[0];

    const jsonObjectKeys = ['location', 'rateType', 'service', 'status','value','vehicle','phone_no', 'result'];
    if (this.excelHeader.length === 7) {
      this.isConfirm = true
        for (let row = 1; row <= this.data.length - 1; row++) {
          if(this.data[row][0] !== undefined && this.data[row][1] !== undefined
              && this.data[row][2] !== undefined
              && this.data[row][3] !== undefined
              && this.data[row][4] !== undefined
              && this.data[row][5] !== undefined
              && this.data[row][6] !== undefined){
            const excelData = {};
            for (let col = 0; col < this.excelHeader.length + 1; col++) {  
              if(this.data[row][0] === undefined || this.data[row][1] === undefined
                || this.data[row][2] === undefined
                || this.data[row][3] === undefined
                || this.data[row][4] === undefined
                || this.data[row][5] === undefined
                || this.data[row][6] === undefined) { // if empty cells
                this.data[row][7] = 'NULL value';
                this.isConfirm = false
              } else if (location.includes(this.data[row][0]) === false) { // if location is invalid
                this.data[row][7] = 'location invalid';
                this.isConfirm = false
              } else if (rateType.includes(this.data[row][1]) === false) { // if rateType is invalid
                this.data[row][7] = 'rate type invalid';
                this.isConfirm = false
              } else if (service.includes(this.data[row][2]) === false) { // if service is invalid
                this.data[row][7] = 'service invalid';
                this.isConfirm = false
              } else if (this.data[row][3] !== 1) { // if invalid status
                this.data[row][7] = 'Invalid status';
                this.isConfirm = false
              } else if (isNaN(this.data[row][4]) === true) { // if value is not number
                this.data[row][7] = 'Invalid value';
                this.isConfirm = false
              } else if (vehicleType.includes(this.data[row][5]) === false) { // if vehicleType is invalid
                this.data[row][7] = 'vehicle type invalid';
                this.isConfirm = false
              } else if (!this.data[row][6].toString().match(phoneno)) { // if mobile no is invalid
                this.data[row][7] = 'mobile number invalid';
                this.isConfirm = false
              } 
            excelData[jsonObjectKeys[col]] = this.data[row][col];
          }
          this.arrFinalData.push(excelData);
          this.rates = this.arrFinalData;
        }
      }
    }

  }

  confirmData() {
    this.isConfirmDisable = false;
    this.isConfirm = false;
    this.dbService.updateBulkCustomerSpecialRate(this.rates)
    .catch(err => { this.showAlert('danger', 'There is an error on processing import'); });
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

}
