import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { RouterPermissionService } from 'src/app/services/router-permission.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private auth: AuthService,
              private router: Router,
              public rp: RouterPermissionService) { }

  ngOnInit() {
  }

  signout() {
    this.auth.logout().then( res => {
      this.router.navigate(['/login']);
    })
  }
}
