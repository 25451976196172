import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserClaimsComponent } from 'src/app/modals/user-claims/create-user-claim.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { EditUserClaimsComponent } from 'src/app/modals/user-claims/edit-user-claim.component';
import {Claim, ClaimsValue } from 'src/app/interfaces/interfaces';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UserClaimsRolesAPI } from 'src/app/api/user.api';
@Component({
  selector: 'app-user-claims',
  templateUrl: './user-claims.component.html'
})
export class UserClaimsComponent implements OnInit {
  loadedData: any = [];
  userClaimsId: any = [];
  loading: boolean = false;
  isEditing = false;
  private claimsNameObservable: Observable<Claim>;
  userClaims: any = [];
  indexValue: any;
  roleName  = new FormControl('');
  claimName = new FormControl('');
  id = new FormControl('');
  userClaimsRolesAPI: UserClaimsRolesAPI;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {
    this.userClaims = [];
    this.paginationCount = 0;
    this.userClaimsRolesAPI = new UserClaimsRolesAPI(dbService, (claims) => {
        this.userClaims = claims;
        this.getPaginations();
        });
  }
  ngOnInit() {
    this.loadUserClaims();
  }
  getPaginations() {
    if (this.selectedFilter > this.userClaims.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }
  applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.userClaimsRolesAPI.getUserClaimsSearch(this.claimName.value, this.id.value, Number(this.selectedFilter), buttonAction);
  }
  filterpage(status: number) {
    this.selectedFilter = status;
  }

  createUserClaim() {
    const modalRef =  this.modalService.open(CreateUserClaimsComponent, { size: 'lg' });
    modalRef.result
    .then((result) => { this.loadUserClaims();  }, (reason) => { });
  }
  editUserClaim(data, i) {
    this.indexValue = i;
    const modalRef =  this.modalService.open(EditUserClaimsComponent, { size: 'lg' });
    modalRef.componentInstance.loadedData = data;
    modalRef.result
    .then((result) => { this.loadUserClaims(); }, (reason) => { });
  }
  async loadUserClaims() {
    this.pageCount = 'load';  this.paginationCount = 0;
    this.userClaimsRolesAPI.getUserClaimsSearch(this.claimName.value, this.id.value,
    Number(this.selectedFilter), 'load');
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'ACTIVE';
      case false: return 'INACTIVE';
      default: return '';
    }
  }
  deactivateHolidayStatus(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Deactivation Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to de-activate this Holiday:' + data.holiday_name +
    '? \nOnce de-activated, the holiday fee cannot be enabled again.';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deactivateHolidayStatus(data.id);
      })
      .then(() => {
        this.loadUserClaims();
      })
      .catch(e => {
        console.log(e);
      });
  }
  deleteUserClaim(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete the user claims';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteUserClaims(data.id);
      })
      .then(() => {
        this.loadUserClaims();
      })
      .catch(e => {
        console.log(e);
      });
  }

}


