import { Component, OnInit } from '@angular/core';
import { Trip, TripStatus, UpdateTripStatusRequest } from 'src/app/interfaces/trip-interfaces';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-customer-rates-add',
  templateUrl: './customer-rates-add.component.html',
  styleUrls: ['./customer-rates.component.css']
})
export class CustomerRatesAddComponent implements OnInit {

  trip: Trip; // Currently viewing trip details
  customerRateForm:FormGroup;
  isValid: boolean = false;
  alert: AlertModel;
  uid: string;
  dropdown: any;
  serviceType: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiService: ApiService,
    private dbService: DatabaseService) {

    this.dbService.getMerchantRates().then(values => {
      this.dropdown = values.data()
    }, error => {
        console.log(error);
    });
  }

  get location() { return this.customerRateForm.get('location'); }
  get service() { return this.customerRateForm.get('service'); }
  get vehicle() { return this.customerRateForm.get('vehicle'); }
  get rateType() { return this.customerRateForm.get('rateType'); }
  get value() { return this.customerRateForm.get('value'); }
 
  ngOnInit() {
    this.createCustomerRateForm();
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

  get getRateType() {
    switch(this.serviceType) {
      case 'HMDELIVERY':
        return this.dropdown.rateType_hm
        break;
      case 'PABILI':
        return this.dropdown.rateType_pabili
        break;
      default:
        return this.dropdown.rateType_jr
    }
  }

  get getVehicleType() {
    return this.serviceType === 'HMDELIVERY' ? this.dropdown.vehicleType : ['motorcycle'];
  }

  createCustomerRateForm() {
    this.customerRateForm = this.fb.group({
      location: [ '',Validators.required ],
      service: [ '',Validators.required ],
      vehicle: [ '',Validators.required ],
      rateType: [ '',Validators.required ],
      value: [ '',Validators.required ]
    });
  }

  saveCustomerRates() {
    this.isValid = true;
    if(this.customerRateForm.status === 'VALID') {
      const data = this.customerRateForm.value;
      this.dbService.createNewCustomerRate(data, this.uid)
      .then(values => {
        if(values) {
          this.showAlert('danger', 'Existing fare. Please try again.');
        } else {
          this.showAlert('success', 'Successfully added!');
        }
      }, error => {
          this.showAlert('danger', 'There was an error on saving');
      });
      this.isValid = false;
    }
  }
  
}