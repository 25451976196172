import { IDriverProfileFilter } from '../controls/driver-profile-filter/driver-profile-filter.component';
import * as I from '../interfaces/interfaces';
import * as moment from 'moment';

export class Paginator<T> {
    page = 1;
    pageSize = 10;
    collectionSize = 0;
    collection: T[];
   // For searching and filtering
    filter?: (text: string, item: T) => unknown;

    constructor(page: number, itemsPerPage: number, collection: T[], 
        filter?: (text: string, item: T) => unknown) {
        this.page = page;
        this.pageSize = itemsPerPage;
        this.collection = collection;
        this.collectionSize = collection.length;
        this.filter = filter;
    }

    searchIn(collection: T[], text?: string): T[] {
        if (!text) { return collection; }
        return collection.filter((item) => { return this.filter(text, item) });
    }

    getItems(searchText?: string): T[] {
        if (!this.collection) { return null; }
        if (searchText && searchText !== '') {
            return this.searchIn(this.collection.map((item, i) => ({ row: i + 1, ...item })), searchText);
        } else {
        return this.collection
            .map((item, i) => ({ row: i + 1, ...item }))
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        }
    }

    itemNumberForIndex(index: number): number {
        return ((this.page - 1) * this.pageSize) + (index + 1)
    }

    updateItem(item: T, index: number) {
        if (index < this.collection.length) {
            this.collection[index] = item;
        }
    }
}

/**
 * Custom Paginator for Driver Profile
 */

export class DriverProfilePaginator extends Paginator<I.OnboardingApplication> {

    moreFilters?: IDriverProfileFilter;
    filteredCollection: I.OnboardingApplication[];

    get isMoreFilterApplied(): boolean {
        return this.moreFilters !== null && this.moreFilters !== undefined;
    }

    constructor(page: number, itemsPerPage: number, collection: I.OnboardingApplication[], 
        filter?: (text: string, item: I.OnboardingApplication) => unknown) {
        super(page, itemsPerPage, collection, filter)
        this.filteredCollection = collection;
    }

    setMoreFilter(mFilters?: IDriverProfileFilter) {
        this.moreFilters = mFilters;
        this.filteredCollection = this.applyFilter(mFilters);
    }

    private applyFilter(mFilters?: IDriverProfileFilter) {
        if (!mFilters) { return this.collection; }
        return this.collection.filter((item) => {
            const matchesSubstatus = (mFilters.subStatus) ? item.form.subStatus === mFilters.subStatus : true;
            var matchesRegDate = true;
            var matchesTrainingDate = true;

            if (mFilters.trainingDate) {
              const trainingDate = moment(new Date(item.form.trainingDate)).format('YYYY-MM-DD');
              const filterDate =  moment(new Date(mFilters.trainingDate)).format('YYYY-MM-DD');
              matchesTrainingDate = trainingDate === filterDate;
            }
            if (mFilters.registrationDate) {
              const appliedDate = moment(new Date(item.form.appliedDate)).format('YYYY-MM-DD');
              const filterDate = moment(new Date(mFilters.registrationDate)).format('YYYY-MM-DD');
              matchesRegDate = appliedDate === filterDate;
            }
            return matchesSubstatus && matchesTrainingDate && matchesRegDate;
        }).map((item, i) => ({ row: i + 1, ...item }));
    }
    getItemsWithFilter(searchText?: string, moreFilters?: IDriverProfileFilter) {
        if (!this.filteredCollection) { return null; }
        // Apply search text on the filtered collection
        if (searchText && searchText !== '') {
            return this.searchIn(this.filteredCollection, searchText);
        }  else if (this.isMoreFilterApplied) {
            return this.filteredCollection;
        }  else {
            return this.collection.map((item, i) => ({ row: i + 1, ...item }))
                .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
 }
    }

    collectionToExport(searchText?: string, moreFilters?: IDriverProfileFilter) {
        if (!this.filteredCollection) { return []; }
        // Apply search text on the filtered collection
        if (searchText && searchText !== '') {
            return this.searchIn(this.filteredCollection, searchText);
        } else {
            return this.filteredCollection; // Return the filtered collection
        }
    }
}