import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { PaymayaReports } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModel } from 'src/app/interfaces/interfaces';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PayMayaTopupHistoryAPI, PaymayaWebhookUpdateAPI } from 'src/app/api/paymaya.api';
import { PermissionService } from 'src/app/services/permission.service';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-paymaya-topupwebhook',
  templateUrl: './paymaya-topup-webhook-reports.component.html'
})
export class PaymayaTopupWebhookComponent implements OnInit {
  drivers: any[];
  filter = new DriverMetroFilter();
  driverphone = new FormControl('');
  type = new FormControl('');
  driverId = new FormControl('');
  linkId = new FormControl('');
  payMayaAPI: PayMayaTopupHistoryAPI;
  paymayaWebhookUpdateAPI: PaymayaWebhookUpdateAPI;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  isLoad = false;
  alert: AlertModel; // Show errors
constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
            public permissions: PermissionService, public apiService: ApiService) {
this.paginationCount = 0;
this.drivers = [];
this.payMayaAPI = new PayMayaTopupHistoryAPI(dbService, (drivers) => {
    this.drivers = drivers;
    });

}

ngOnInit() {
    this.applyFilter();
}
applyFilter() {
  this.payMayaAPI.bindTopupWebhook() ;
}
async applyFilterProcess() {
    this.isLoad = true;
    for (let i = 0; i <= this.drivers.length - 1; i++) {
        await this.updateWebhookData(this.drivers[i], i);
      }
    this.isLoad = false;
    this.showAlert('Paymaya Webhook Process Updated Successfully');
}
async updateWebhookData(webhookData: any, iVal) {
    await this.apiService.updateTopupWebhook(webhookData);

}
exportPaymaya() {
  const filename = () => 'paymaya_walletlink_history' + (new Date()).toLocaleDateString();
  const exporter = new CSVExporter(filename(), CSVExporter.paymayaWalletlinkHistory());
  exporter.exportPaymayawalletLinkHistory(this.drivers as PaymayaReports[]);
}
get canExport(): boolean {
  if (!this.drivers) { return false; }
  const allRequests = this.drivers;
  return (allRequests && allRequests.length > 0);
}
private showAlert( message: string) {
  const type = 'success' ;
  this.alert = { type, message } as AlertModel;
  setTimeout(() => this.alert = null, 3000);
}

}

/*** FILTER for Trips */
class DriverMetroFilter {
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
}
setStatus(status: number) {
}
reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
}
}
