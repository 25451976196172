import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { ClaimsValue } from 'src/app/interfaces/interfaces';
import { AdminUser } from '../api/user.api';
import { DatabaseService } from './database.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: firebase.User;
  userRoles: ClaimsValue[];
  userData: any;
  roles: string[];
  currentUserRoleValue: string;
  constructor(public db: AngularFirestore,
              public afAuth: AngularFireAuth,
              public dbService: DatabaseService) { }
  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.user = user;
          this.roles = await this.getRoles();
          this.userRoles = await this.getRolesNew();
         // this.userData = await this.getRolesNewRegion();
          resolve(user);
        } else {
          this.user = null;
          this.roles = [];
          reject('No user logged in');
        }
      }).bind(this);
    });
  }

  hasEditPermission(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      firebase.auth().currentUser.getIdTokenResult()
      .then(tokenResult => {
        resolve(!!tokenResult.claims.superAdmin);
      })
      .catch(e => reject(false));
    });
  }

  getRoles(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      firebase.auth().currentUser.getIdTokenResult()
        .then(tokenResult => {
          const roles = (tokenResult.claims.roles) ? tokenResult.claims.roles : [];
          resolve(roles);
        })
        .catch(e => reject([]));
    });
  }

  async getRolesNew() {
    const currentUserId = firebase.auth().currentUser.uid;
    const adminref = this.db.collection<AdminUser>('admin_users').doc(currentUserId);
    const adminData = await adminref.get().toPromise().then(data => {
      return data;
    });
    const returnData = adminData.data();
    this.userData = returnData;
   // console.log(returnData);
    const ref = await this.db.collection<ClaimsValue>('user_role_settings')
                    .doc(returnData.roles[0]).collection('claims');
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as ClaimsValue[];
    });
    return applications;
 }
 async getRolesNewRegion() {
  const currentUserId = firebase.auth().currentUser.uid;
  const adminref = this.db.collection<AdminUser>('admin_users').doc(currentUserId);
  const adminData = await adminref.get().toPromise().then(data => {
    return data;
  });
  return adminData.data();
}

  // Role Check Methods
  isSuperAdmin(): boolean {
    return (this.roles.indexOf('SUPERADMIN') !== -1 || 
      this.roles.indexOf('super_admin') !== -1)
    ;
  }

  isSuperAdminUserRole(): boolean {
    return (this.userData.roles.indexOf('SUPERADMIN') !== -1 || 
      this.userData.roles.indexOf('super_admin') !== -1)
    ;
  }

  canDoAction(claimName): boolean {
    return (this.userRoles.some(x => x.name === claimName && x.status === true));
}
  canExport(claimName): boolean {
    return (this.userRoles.some(x => x.name === claimName && x.status === true));

  }
  canDoRegion(regionName): boolean {
    if (this.userData.region === regionName && this.userData.roles[0] === 'helpdesk_mnl') {
      return true;
    } else {
      return false;
    }
}
  canDoRegionCebu(regionName): boolean {
  if (this.userData.region === regionName && this.userData.roles[0] === 'helpdesk_ceb') {
    return true;
  } else {
    return false;
  }
}

  isAdmin(): boolean {
    return (this.roles.indexOf('ADMIN-L1') !== -1 ||
            this.roles.indexOf('ADMIN-L2') !== -1 ||
            this.roles.indexOf('ADMIN-L3') !== -1);
  }

  isAdminL1(): boolean {
    return this.roles.indexOf('ADMIN-L1') !== -1;
  }

  isAdminL1UserRole(): boolean {
    return this.userData.roles.indexOf('ADMIN-L1') !== -1;
  }


  isAdminL2(): boolean {
    return this.roles.indexOf('ADMIN-L2') !== -1;
  }

  isAdminL3(): boolean {
    return this.roles.indexOf('ADMIN-L3') !== -1;
  }

  isOps(): boolean {
    return (this.roles.indexOf('OPS-L1') !== -1 ||
            this.roles.indexOf('OPS-L2') !== -1 ||
            this.roles.indexOf('OPS-L3') !== -1);
  }

  isOpsL1(): boolean {
    return this.roles.indexOf('OPS-L1') !== -1;
  }

  isOpsL2(): boolean {
    return this.roles.indexOf('OPS-L2') !== -1;
  }

  isOpsL3(): boolean {
    return this.roles.indexOf('OPS-L3') !== -1;
  }

  isOpsL4(): boolean {
    return this.roles.indexOf('OPS-L4') !== -1;
  }
}

