import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
import { MetroBankAPI } from 'src/app/api/metrobank.api';
import { DatabaseService } from 'src/app/services/database.service';
import { PermissionService } from 'src/app/services/permission.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
    selector: 'app-metrobank',
    templateUrl: './bindacc-inquiry-history.component.html',
    styleUrls: ['./bindacc-history.component.css']
  })
export class BindAccInquiryHistoryComponent implements OnInit {

filter = new BindAccHistroyFilter();
driverphone = new FormControl('');
referenceNo = new FormControl('');
transactioncode = new FormControl('');
metroBankAPI: MetroBankAPI;
bindAccInquiryHisstory = [];
selectedFilter = 50;
paginationCount = 0;
disableNext = false;
disablePrevious = false;
disableLoad = false;
disableInitial = true;
pageCount: any;
constructor(private dbService: DatabaseService, public permissions: PermissionService, private utils: UtilsService) {
  this.paginationCount = 0;
  this.bindAccInquiryHisstory = [];
  this.metroBankAPI = new MetroBankAPI(dbService, (histroy) => {
    this.bindAccInquiryHisstory = histroy;
    this.getPaginations();
  });
}

ngOnInit() {
}
getPaginations() {
  if (this.selectedFilter > this.bindAccInquiryHisstory.length) {
    this.disableInitial = false;
  }
  if (this.pageCount === 'previous') {
    this.paginationCount--;
  } else if (this.pageCount === 'next') {
    this.paginationCount++;
  }
  this.disableLoad = false;
  this.disableNext = false;
  this.disablePrevious = false;
}
getAllWalletHistory(buttonAction: string) {
  this.disableLoad = true;
  this.disableInitial = true;
  if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
  if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
  if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
  this.metroBankAPI.metroBankBindInquiryHistory(this.driverphone.value, this.referenceNo.value,
  this.transactioncode.value, this.filter.from.selectedDate, this.filter.to.selectedDate, Number(this.selectedFilter), buttonAction);
}
filterpage(status: number) {
  this.selectedFilter = status;
}
}
class BindAccHistroyFilter {
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
    constructor() {
    }
    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());
    }
}
