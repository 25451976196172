import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {  TripListener } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip, DriverTripLocations } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  DriverLocationProfile} from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import {  DriverLocationAPI} from 'src/app/api/driverList.api';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
    selector: 'app-driver-trip',
    templateUrl: './driver-trip-locationstrack.component.html',
    styleUrls: ['./driver-active-report-component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class TripLocationsDriverComponent implements OnInit {
    tripid  = new FormControl('');
    triplocations: any;
    tripLocationdata: DriverTripLocations[];
    driverLocationAPI: DriverLocationAPI;
    driverLocationdata: DriverLocationProfile;
    ShowTripIdSearch: boolean;
    tripListenerAPI: TripListener;
    tripDriverinfo: Trip;
    driverName: any;
    driverSource: any;
    driverDestination: any;
    tripShow = false;
    origin: any;
    destination: any;
  constructor(private dbService: DatabaseService, private apiService: ApiService,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.tripLocationdata = [];
    this.ShowTripIdSearch = false;
    this.tripListenerAPI = new TripListener(dbService, (trips) => {
        this.tripDriverinfo =  trips;
        this.getTripLocationsData();
   });
    this.driverLocationAPI = new DriverLocationAPI(this.dbService, true, (success, profile) => {
        if (success && profile) {
        this.driverLocationdata = profile;
       } else if (success) {
        } else {  }
    });
   }

ngOnInit() {
}
applyFilter() {
     this.tripListenerAPI.listenForTrip(this.tripid.value);
    }
getTripLocationsData() {
      if (this.tripDriverinfo !== undefined) {
          this.dbService.allTimelocations(this.tripDriverinfo.driver.id, this.tripid.value).then(regions => {
          this.tripLocationdata = regions;
          this.origin =  {lat: `${this.tripDriverinfo.trip.source.latitude}`, lng: `${this.tripDriverinfo.trip.source.longitude}` };
          this.destination =  {lat: `${this.tripDriverinfo.trip.destination.latitude}`,
                            lng: `${this.tripDriverinfo.trip.destination.longitude}`};
          this.driverName = this.tripDriverinfo.driver.name;
          this.driverSource = this.tripDriverinfo.trip.source.fullAddress;
          this.driverDestination = this.tripDriverinfo.trip.destination.fullAddress;
          this.tripShow = true;
          this.ShowTripIdSearch = true;
        }).catch(err => {
          console.log(err);
        }).finally(() => {
        });
      } else {
        this.tripShow = false;
      }

    }
}
