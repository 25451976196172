import { BaseAPI, APIState } from './base.api';
import { DriverProfile} from '../interfaces/interfaces';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
export class DriverGCashAPI extends BaseAPI {
    private unsubscribe: Subscription;
    private handler: ([DriverProfile]) => void;

    constructor(dbService: DatabaseService, completion: ([DriverProfile]) => void) {
        super(dbService);
        this.handler = completion;
    }

    loadDriverGCash(from?: Date, to?: Date, region?: string) {
        if (this.unsubscribe) { this.unsubscribe.unsubscribe(); }

        this.state = APIState.loading;
        this.dbService.searchDriverGCash(from, to, region).get().toPromise()
            .then(snapshots => snapshots.docs.map(c => c.data()))
            .then(response => {
                this.state = (response.length === 0) ? APIState.successWithoutData : APIState.successWithData;
                this.handler(response as [DriverProfile]);
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });

        this.unsubscribe = this.dbService.searchDriverGCash(from, to, region)
        .valueChanges().subscribe(values => {
            this.handler(values as [DriverProfile]);
        }, err => {
            console.error(err);
        });
    }
}
