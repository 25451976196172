import { Component, OnInit } from '@angular/core';
import { DriverStatus, WalletCashoutStatus, WalletUtils } from 'src/app/utils/wallet.utils';
import { CashoutRequestsAPI, CashoutStatusUpdateApi } from 'src/app/api/cashouts.api';
import { DatabaseService } from 'src/app/services/database.service';
import { CashoutRequest, WalletSettings } from 'src/app/interfaces/interfaces';
import { WalletSettingsAPI } from 'src/app/api/topup.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Paginator } from 'src/app/classes/paginator';
import { WalletRequestFilter } from 'src/app/classes/FilterModels';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-cashout-request',
  templateUrl: './cashout-request.component.html',
  styleUrls: ['./cashout-request.component.css']
})
export class CashoutRequestComponent implements OnInit {
  search = new FormControl('');
  paginator: Paginator<CashoutRequest> = null;
  cashoutListApi: CashoutRequestsAPI;
  walletSettingsAPI: WalletSettingsAPI;
  updateStatusAPI: CashoutStatusUpdateApi;
  approvalStatus: any = {};
  settings: WalletSettings;
  filter: WalletRequestFilter;
  status: number;
  canShowSelectAll: string;
  total_cashout_request_amount: number = 0;
  total_cashout_request_count: number = 0;
  filterBlock = (text: string, item: CashoutRequest) => {
    const searchText = text.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().includes(searchText);
  }
  // MARK: Computed Properties
  /**
   * Returns the cashout requests to be displayed in the page
   */
  get cashoutRequests(): CashoutRequest[] {
    return (this.paginator) ? this.paginator.getItems(this.search.value) : null;
  }
  /**
   * Returns a boolean indicating whether the data can be exported or not
   */
  get canExport(): boolean {
    const allRequests = this.cashoutRequests;
    return (allRequests && allRequests.length > 0);
  }
  /**
   * to show dropdown and button
   */
  get canShowButton(): boolean {
    const allRequests = this.cashoutRequests;
    return (allRequests && allRequests.length > 0);
  }
  /**
   * The idea here is that the action column will be visible only when the admin locks
   * the wallet update using the switch. (This will prevent any drivers from submitting a request
   * and causing race conditions)
   */
  get canShowActionColumn(): boolean {
    return this.permissions.canApproveCashoutRequests &&
      this.filter.status === WalletCashoutStatus.pending &&
      this.settings && this.settings.cashoutsEnabled === false;
  }
  /*** The save button can be displayed only when the action column is displayed.
   * Additionally, user should have modified a request status.
   */
  get canShowSaveButton(): boolean {
    return this.permissions.canApproveCashoutRequests &&
      this.canShowActionColumn &&
      Object.keys(this.approvalStatus).length !== 0;
  }
  // MARK: Constructors and Initializers
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private permissions: PermissionService) {

    this.filter = new WalletRequestFilter();
    this.cashoutListApi = new CashoutRequestsAPI(dbService, (requests) => {
      this.paginator = new Paginator(1, requests.length, requests, this.filterBlock);
      this.approvalStatus = {};
    });
    this.walletSettingsAPI = new WalletSettingsAPI(dbService, (settings) => {
      this.settings = settings;
    });
    this.updateStatusAPI = new CashoutStatusUpdateApi(apiService, (success, message) => {
      this.showAlert(success, message);
    });
  }
  ngOnInit() {
    this.filterApplied(this.filter);
    this.walletSettingsAPI.getWalletConfig();
  }
  // MARK: Helpers

  /**
   * Maps an integer indicating a Cashout status to a human readable string
   */
  getCashoutStatusName(status: WalletCashoutStatus): string | null {
    return WalletUtils.getCashoutStatusName(status);
  }

  getDriverStatusName(status: DriverStatus): string | null {
        return WalletUtils.getDriverStatusName(status);
   }
  /**
   * Formats the timestamp in seconds to a readable date string
   */
  formatDate(date) {
    if (date === undefined) { return ''; }
    return WalletUtils.formatDate(date.seconds);
  }
  retry() {
    this.filterApplied(this.filter);
  }
  filterApplied(data: WalletRequestFilter) {
    this.filter = data;
    this.cashoutListApi.loadCashoutRequests(data);
  }
  // MARK: Events
  /**
   * Invoked when the switch is turned on or off. If turned ON, no drivers will be allowed to
   * submit requests
   */
  toggleCashoutSettings(event) {
     this.walletSettingsAPI.updateWalletConfig(!event.target.checked, !event.target.checked);
  }
  /**
   * Select/Deselect All Check Box
   */
  selectAllRequest() {
    if (this.canShowSelectAll === 'show') {
      this.canShowSelectAll = 'hide';
    } else {
      this.canShowSelectAll = 'show';
    }
  }
  /**
   * Invoked when the admin selects an approval / rejection status for a given topup request.
   * The admin inputs are saved in a Dictionary and finally merged with the topup request when
   * save button is pressed
   */
  statusChanged(request: CashoutRequest, event) {
    const value = event.target.value;
    if (value !== undefined && (value === WalletCashoutStatus.approved || value === WalletCashoutStatus.declined)) {
      this.approvalStatus[request.receiptId] = value;
    } else {
      delete this.approvalStatus[request.receiptId];
    }
  }
  /**
   * Get Total amount and total count from selected rows
   *
   */
   getRecordOnUpdateWalletStatus() {
    const ids = []
    let total_amount = 0;
    const status = (<HTMLSelectElement>document.getElementById('status')).value;

    this.status = status === '1' ? this.status = 1 : this.status = 2;
    if (this.status !== undefined && (this.status === WalletCashoutStatus.approved || this.status === WalletCashoutStatus.declined)) {
      const items = document.getElementsByName('bulkupdate');
      for (var i = 0; i < items.length; i++) {
        if ((items[i] as HTMLInputElement).checked === true) {
          this.approvalStatus[items[i].id] = this.status;
          ids.push(items[i].id)
        }
      }
    }

    this.cashoutRequests.forEach(d => {
      if(ids.includes(d.receiptId) == true) {
        total_amount+=d.amount
      }
    })

    this.total_cashout_request_amount = total_amount;
    this.total_cashout_request_count = ids.length;
    this.showConfirm(this.total_cashout_request_count, this.total_cashout_request_amount, this.status)
   }
  /**
   * Performs the actual API call to update the status of each topup request and update
   * the wallet accordingly.
   */
  onUpdateWalletStatus() {
    const status = (<HTMLSelectElement>document.getElementById('status')).value;
    if (status === '1') {
      this.status = 1;
    } else if (status === '2') {
      this.status = 2;
    }
    if (this.status !== undefined && (this.status === WalletCashoutStatus.approved || this.status === WalletCashoutStatus.declined)) {
      const items = document.getElementsByName('bulkupdate');
      for (let i = 0; i < items.length; i++) {
        if ((items[i] as HTMLInputElement).checked === true) {
          this.approvalStatus[items[i].id] = this.status;
        }
      }
    }
    this.canShowSelectAll = 'hide';
    this.updateStatusAPI.updateCashoutStatus(this.approvalStatus);
  }
  // MARK: Alerts
  /**
   * Shows the success / failure reason after executing the transaction to update the wallet.
   * This method is invoked on both success and failure of the transaction.
   */
  showConfirm(count: number, amount:number, status:number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Are you sure you want to proceed? '
    modalRef.componentInstance.message = `Number of Rows Selected:  ${count}`;
    modalRef.componentInstance.message1 = `Total Amount: ${amount}`;
    modalRef.componentInstance.message2 = 'Change Status to: '+this.getCashoutStatusName(status);
    modalRef.componentInstance.confirmActionTitle = 'Proceed';
    modalRef.result.then((result) => {
      this.onUpdateWalletStatus()
    })
      .catch(e => { console.error(e); });
  }
  showAlert(isSuccess: boolean, message: string) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = isSuccess ? 'Success!' : 'Error';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmActionTitle = 'OK';
    modalRef.result.then((result) => {
      this.approvalStatus = {};
      return this.filterApplied(this.filter);
    })
      .catch(e => { console.error(e); });
  }
  /**
   * Export content as CSV
   */
  exportAsCSV() {
    if (this.cashoutRequests === undefined) { return; }
    const filename = () => 'Cashout_Request' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), ['MOBILE NUMBER','AMOUNT', 'DRIVER NAME']);
    exporter.exportCashouts(this.cashoutRequests as [CashoutRequest]);
  }
}
