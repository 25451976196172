import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TripListAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripStatusComponent } from 'src/app/modals/trip-status/trip-status.component';
import * as moment from 'moment';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-trip-management',
  templateUrl: './trip-hoursmanagement.component.html',
  styleUrls: ['./trip-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TripHoursManagementComponent implements OnInit {
  tripListAPI: TripListAPI;
  trips: Trip[];
  // tslint:disable-next-line: no-use-before-declare
  filter = new TripHistoryFilter();
  selectedHoursFilter: string = '1 Hours';
  hours: number = 1;
get selectedStatus(): string {
    return (this.filter.status) ? this.utils.tripHoursStatus(this.filter.status) : 'ALL TRIPS';
}
  constructor(private dbService: DatabaseService,
              public utils: UtilsService,
              private modalService: NgbModal,
              public permissions: PermissionService) {
    this.trips = [];
    this.tripListAPI = new TripListAPI(dbService, (trips) => {
      this.trips = trips;
    });
}
ngOnInit() {
}
applyFilter() {
    this.trips = [];
    const date = new Date();
    const tripEndDate = date;
    const tripStartDate = new Date(date.valueOf() - (this.hours) * 1000 * 60 * 60);
    this.tripListAPI.loadTripsHours(this.filter.status, tripStartDate, tripEndDate);
}
export() {
    const filename = () => 'trip_management' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.tripManagement());
    exporter.exportTripManagement(this.trips as Trip[]);
}
openTripInfoModal(trip: Trip) {
      const modalRef = this.modalService.open(TripStatusComponent, { size: 'xl', scrollable: true});
      modalRef.componentInstance.trip = trip;
}
isNull(path?: string) {
    if (path === null || path === undefined || path.length === 0) { return ''; }
    return path;
}
hoursFilter(hours: string, hour: number) {
    this.selectedHoursFilter = hours;
    this.hours = hour;
}
}
/** * FILTER for Trips */
class TripHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  setStatus(status: number) {
    this.status = status;
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
