import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { HolidaySurcharge, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-create-hm-holiday-surcharge',
  templateUrl: './create-holiday-surcharge.component.html'
})
export class CreateHmHolidaySurchargeComponent implements OnInit {
  holidaySurchargeForm: FormGroup;
  holidaySurchargeModel: HolidaySurcharge;
  alert: AlertModel;
  saving: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.createForm();
  }
  private createForm() {
    this.holidaySurchargeForm = this.fb.group({
      holiday_name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      surcharge_amount: [0],
      holiday_date: [null],
      description: [''],
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveHolidayFee(): boolean {
    return this.holidaySurchargeForm.valid && this.holidaySurchargeForm.dirty;
  }


  createHolidaySurcharge() {
    this.holidaySurchargeModel = {
      id: '',
      holiday_name: this.holidaySurchargeForm.controls.holiday_name.value,
      surcharge_amount: this.holidaySurchargeForm.controls.surcharge_amount.value,
      holiday_date: this.holidaySurchargeForm.controls.holiday_date.value,
      description: this.holidaySurchargeForm.controls.description.value,
      is_active: true,
      updated_at: new Date()
    };
    this.holidaySurchargeForm.disable();
    this.saving = true;
    this.dbService.hasHmHoliday(this.holidaySurchargeModel.holiday_date.toString())
    .then(result => {
      if (result) {
        this.showAlert('danger', 'The entered Holiday already exists in the database');
        this.holidaySurchargeForm.enable(); // Enable editing
        return Promise.reject();
      } else {
        return this.dbService.createNewHmHolidaySurcharge(this.holidaySurchargeModel);
      }
    })
      .then(() => {
        this.showAlert('success', 'Holiday created successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while creating the holiday - ' + e.message);
        this.holidaySurchargeForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
