import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { TricyclePickup, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-tricycle-pickup',
  templateUrl: './edit-tricycle-pickup.component.html'
})
export class EditTricyclePickupComponent implements OnInit {
  tricyclePickupForm: FormGroup;
  tricyclePickupModel: TricyclePickup;
  alert: AlertModel;
  saving: boolean;
  onboardingRegions = [];
  onboardingCity: SupportedRegions[];
  loadedData: any = [];
  pickupcitycode: any;
  pickupcity: any;
  region: any;
  latlong: any;
  docID: any;
  isactive: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.pickupcitycode = this.loadedData.pickup_city_code;
    this.pickupcity =  this.loadedData.pickup_city;
    this.region = this.loadedData.region;
    this.latlong = this.loadedData.latlong;
    this.docID = this.loadedData.id;
    this.isactive = this.loadedData.is_active;
    this.getRegions();
    this.createForm();
  }
  private createForm() {
    this.tricyclePickupForm = this.fb.group({
        pickup_city_code: ['', Validators.required],
        pickup_city: ['', Validators.required],
        region: ['', Validators.required],
        latlong: ['', [Validators.required, ValidatorService.cannotContainSpace]]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveTricyclePickup(): boolean {
    return this.tricyclePickupForm.valid && this.tricyclePickupForm.dirty;
  }


  createTricyclePickupCity() {
    this.tricyclePickupModel = {
      id: this.docID,
      pickup_city_code: this.tricyclePickupForm.controls.pickup_city_code.value,
      pickup_city: this.tricyclePickupForm.controls.pickup_city.value,
      region: this.tricyclePickupForm.controls.region.value,
      latlong: this.tricyclePickupForm.controls.latlong.value,
      is_active: this.isactive,
      updated_at: new Date()
    };
    this.tricyclePickupForm.disable();
    this.saving = true;
    this.dbService.editTricyclePickupCities(this.tricyclePickupModel, this.tricyclePickupForm.controls.region.value, this.docID)
      .then(() => {
        this.showAlert('success', 'Taxicle Pickup City Updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while Updating the Taxicle Pickup City - ' + e.message);
        this.tricyclePickupForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  getRegions() {
    this.onboardingRegions = uniqBy(this.onboardingCity, 'region');
  }
}
