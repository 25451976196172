import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alertmodal',
  templateUrl: './alertmodal.component.html',
  styleUrls: ['./alertmodal.component.css']
})
export class AlertModalComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() message1: string;
  @Input() message2: string;
  @Input() cancelActionTitle: string;
  @Input() confirmActionTitle: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}
