/**
 * Defines the properties and settings required for Angular Date Picker
 */
export class DatePickerModel {
date: Date = new Date();
settings = {
bigBanner: true,
timePicker: true,
format: 'dd-MM-yyyy hh:mm a',
defaultOpen: false
}
constructor(d: Date) {
this.date = d;
}
get selectedDate(): Date {
return new Date(this.date);
}
}