import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DriverProfile, AlertModel, OnboardingApplication } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DriverWalletAdjustmentAPI } from 'src/app/api/fareSettings.api';
import { ApiService } from 'src/app/services/api.service';
import { DriverProfileAPI, DriversWalletListAPI } from 'src/app/api/driverList.api';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-wallet-adjustment',
  templateUrl: './wallet-adjustment.component.html',
  styleUrls: ['./wallet-adjustment.component.css']
})
export class WalletAdjustmentComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  driverProfile: DriverProfile;
  walletHistory: WalletHistory[];
  walletAdjustmentForm: FormGroup;
  firstname = new FormControl('');
  uid = new FormControl('');
  phone = new FormControl('');
  alert: AlertModel;
  isVisiableWalletAdj = false;
  walletAdjustmentAPI: DriverWalletAdjustmentAPI;
  walletHistoryListener: DriverWalletHistoryListener;
  driverProfileAPI: DriverProfileAPI;
  driversListAPI: DriversWalletListAPI;
  isDisabledImport = false;
  filterBlock = (text: string, item: OnboardingApplication) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.personal.firstname, item.personal.lastname].join(' ');
    return item.form.id.toLocaleLowerCase().includes(searchText) ||
           item.personal.phone.toLocaleLowerCase().includes(searchText) ||
           name.toLocaleLowerCase().includes(searchText);
  }
 constructor(private fb: FormBuilder, private apiService: ApiService, private dbService: DatabaseService, private utils: UtilsService,
             public permissions: PermissionService  ) {
    this.walletAdjustmentAPI = new DriverWalletAdjustmentAPI(apiService, (success, message) => {
      const alertType = success ? 'success' : 'danger';
      this.showAlert(alertType, message);
      this.walletAdjustmentForm.enable();
      if (success) {
        this.walletAdjustmentForm.reset();
        this.walletAdjustmentForm.markAsPristine();
      }
    });
    this.driverProfileAPI = new DriverProfileAPI(this.dbService, true, (success, profile) => {
      if (success && profile) {
        this.driverProfile = profile;
        this.walletAdjustmentForm.controls.driverId.setValue(this.driverProfile.uid);
        this.walletHistoryListener.beginListening(profile.uid);
      // tslint:disable-next-line: max-line-length
      } else if (success) { this.showAlert('danger', 'No Biker was found with the searched ID'); } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Biker profile'); }
    });

    this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
      // tslint:disable-next-line: max-line-length
      if (success && history) { this.walletHistory = history; } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
    });

    this.driversListAPI = new DriversWalletListAPI(dbService, (drivers) => {
      this.paginator = new DriverProfilePaginator(1, 5, drivers, this.filterBlock);
    });
  }

  get applications(): OnboardingApplication[] {
    if (this.paginator) {
      return this.paginator.getItemsWithFilter('');
    } else {
      return null;
    }
  }
   ngOnInit() {
    this.createWalletAdjustmentForm();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
     this.driverProfileAPI.endListening();
     this.walletHistoryListener.endListening();
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  private async loadDriverApplications(uid?: string, firstname?: string, phone?: string) {
    this.driversListAPI.loadDriverWalletDetails(uid, firstname, phone);
  }
  search() {
    this.isVisiableWalletAdj = false;
    this.loadDriverApplications(this.uid.value, this.firstname.value, this.phone.value);
  }
  getWalletAssessmentInformation(driverId) {
    this.isVisiableWalletAdj = true;
    this.driverProfileAPI.fetchDriverProfile(driverId);
  }
  private createWalletAdjustmentForm() {
    this.walletAdjustmentForm = this.fb.group({
      driverId: ['', Validators.required],
      amount: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(5), Validators.max(9999)]],
      reason: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]]
    });
  }
  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.untouched || c.disabled || c.valid;
  }
  canSaveWalletAdjustments(): boolean {
    return this.walletAdjustmentForm.valid && this.walletAdjustmentForm.dirty;
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  saveWalletAdjustments(driverid: string) {
    this.walletAdjustmentForm.disable();
    // tslint:disable-next-line: radix
    this.walletAdjustmentForm.controls.amount.setValue(parseInt(this.walletAdjustmentForm.controls.amount.value));
    this.walletAdjustmentAPI.adjustWalletAmount(driverid, this.walletAdjustmentForm.value);
  }
  cancelWalletAdjustments() {
    this.driverProfileAPI.endListening();
    this.walletHistoryListener.endListening();
    this.isVisiableWalletAdj = false;
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
