import { Component, OnInit } from '@angular/core';
import { DriverProfilePaginator } from 'src/app/classes/paginator';
import { OnboardingApplication, DriverProfile, GCashCity, SupportedRegions } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverProfileComponent } from 'src/app/modals/driver/driver-profile/driver-profile.component';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { IDriverProfileFilter, IDriverProfileFilterSettings } from 'src/app/controls/driver-profile-filter/driver-profile-filter.component';
import * as moment from 'moment';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
@Component({
  selector: 'app-registered-drivers',
  templateUrl: './registered-drivers.component.html',
  styleUrls: ['./registered-drivers.component.css']
})
export class RegisteredDriversComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  api: DriversListAPI;
  selectedFilter = 0;
  search = new FormControl('');
  currentFilter: IDriverProfileFilter = {};
  GCashRegions: GCashCity[];
  canShowStatusFilter: boolean;
  onboardingCity: SupportedRegions[];
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  tripType = new FormControl();
  tripTypecombo = [];
  defaultTripType: string;
  filterSettings: IDriverProfileFilterSettings = {
    showRegistrationDate: true,
    showTrainingDate: true,
    showSubStatus: true,
    subStatuses: OnboardingUtils.subStatusesForMainStatus(this.selectedFilter)
  };
  OnboardingUtils:any;
  filterBlock = (text: string, item: OnboardingApplication) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.personal.firstname, item.personal.lastname].join(' ');
    return item.form.id.toLocaleLowerCase().includes(searchText) ||
      item.personal.phone.toLocaleLowerCase().includes(searchText) ||
      name.toLocaleLowerCase().includes(searchText) || item.uid.toLocaleLowerCase().includes(searchText);
  }
  constructor(private dbService: DatabaseService, private modalService: NgbModal, private utils: UtilsService,
              public permissions: PermissionService) {
    this.canShowStatusFilter = false;
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.tripTypecombo = OnboardingUtils.getTripVechicleType();
    this.defaultTripType = this.tripTypecombo[0];
    this.api = new DriversListAPI(dbService, (drivers) => {
    this.paginator = new DriverProfilePaginator(1, 10, drivers, this.filterBlock);
    this.OnboardingUtils = OnboardingUtils;
    });
  }
  ngOnInit() {
    this.getGcashRegionList();
    this.getRegionCityList();
  }
  getDriverVehicleName(status: string): string | null {
    return OnboardingUtils.getVehiclyName(status);
  }
  get canExport(): boolean {
    if (!this.paginator) { return false; }
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
  get filterName(): string {
    return this.getStatusName(this.selectedFilter);
  }
  get applications(): OnboardingApplication[] {
    if (this.paginator) {
      return this.paginator.getItemsWithFilter(this.search.value);
    } else {
      return null;
    }
  }
  get disablePagination(): boolean {
    return (this.search.value && this.search.value !== '') || this.paginator.isMoreFilterApplied;
  }
  export() {
    const drivers = this.paginator.collectionToExport(this.search.value);
    const filename = () => 'registered_drivers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.driverProfileTitles());
    exporter.exportDrivers(drivers as DriverProfile[]);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  filter(status: number) {
    this.selectedFilter = status;
    this.vechicleTypeFilterStatus(status, this.region.value);
    this.filterSettings.subStatuses = OnboardingUtils.subStatusesForMainStatus(this.selectedFilter);
  }
  applyFilter() {
    console.log(this.region.value)
    this.canShowStatusFilter = true;
    this.applyFilterAll()
  }
  applyFilterAll() {
    this.canShowStatusFilter = true;
    this.loadDriverApplications(this.selectedFilter,  this.region.value);
  }
  applyMoreFilters(f: IDriverProfileFilter) {
    this.paginator.setMoreFilter(f);
    this.currentFilter = f ? f : {};
    return false;
  }
  private async loadDriverApplications(status: number, region: string) {
    this.api.loadDriverProfiles(status, region);
  }
  private async vechicleTypeFilter() {
    if (this.tripType.value === 'All') {
      this.applyFilterAll();
 } else {
    this.api.loadDriverProfilesVechicle(this.selectedFilter, this.tripType.value.toLowerCase(), this.region.value);
 }
  }
  private async vechicleTypeFilterStatus(status: number, region: string) {
    if (this.tripType.value === 'All') {
         this.applyFilterAll();
    } else {
      this.api.loadDriverProfilesVechicle(this.selectedFilter, this.tripType.value.toLowerCase(), this.region.value);
    }
  }
  getVehicleName(status: string) {
    return OnboardingUtils.getVehiclyName(status);
  }
  openApplication(application: OnboardingApplication) {
    const modalRef = this.modalService.open(DriverProfileComponent, { size: 'xl', scrollable: true, backdrop : 'static', keyboard : false });
    modalRef.componentInstance.isOnboardingProfile = true;
    modalRef.componentInstance.driverProfile = application;
    modalRef.componentInstance.status = this.utils.mainStatusForNewDrivers;
    modalRef.componentInstance.subStatus = this.utils.subStatusForNewDrivers;
    modalRef.componentInstance.GCashCities = this.GCashRegions;
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.componentInstance.selectedFilter = this.selectedFilter;
    modalRef.result
    .then((result) => { }, (res) => { this.applyFilter();});
  }
  getGcashRegionList() {
    this.dbService.getGcashRegionList().then(regions => {
      this.GCashRegions = regions;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  getRegionCityList() {
    this.dbService.getOnboardingRegionList().then(regions => {
      const datafilter = 'regions';
      const data = Object(regions)[datafilter];
      this.onboardingCity = data;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
}
