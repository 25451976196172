import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { BaseAPI, APIState } from './base.api';
import { ApiService } from '../services/api.service';
import { AlertModel, LoadTransactionList, CustomerLoadTransactionFilter } from 'src/app/interfaces/interfaces';

export class CustomerLoadTransactionListListener extends BaseAPI{
    private handler: (success: boolean, date: LoadTransactionList) => void;
    success = 'isSuccess';
    constructor(apiService: ApiService, completion: (success: boolean, data: LoadTransactionList) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    loadCustomerLoadTransactionList(limit: number, from: string, to:string) {
        this.state = APIState.loading;
        this.apiService.getCustomerLoadTransaction(limit, from, to)        
        .then(response => {
            this.state = APIState.successWithData;
            const isSuccess = response[this.success];
            this.handler(response['total'], response['data']);
        }, error => {
            console.error(error);
        });
    }
}

export class DriverLoadTransactionListListener extends BaseAPI{
    private handler: (success: boolean, date: LoadTransactionList) => void;
    success = 'isSuccess';
    constructor(apiService: ApiService, completion: (success: boolean, data: LoadTransactionList) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    loadDriverLoadTransactionList(limit: number, from: string, to:string) {
        this.state = APIState.loading;
        this.apiService.getDriverLoadTransaction(limit, from, to)        
        .then(response => {
            this.state = APIState.successWithData;
            const isSuccess = response[this.success];
            this.handler(response['total'], response['data']);
        }, error => {
            console.error(error);
        });
    }
}