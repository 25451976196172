import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/home/children/dashboard/dashboard.component';
import { CustomerComponent } from './pages/home/children/customer/customer.component';
import { CustomerLoadTransactionComponent } from './pages/home/children/customer/customer-load-transaction.component';
import { CustomerRateBulkUpdateComponent } from './pages/home/children/customer/customer-rate-bulk-update.component';
import { DriverLoadTransactionComponent } from './pages/home/children/drivers/driver-load-transaction/driver-load-transaction.component';
import { PromoComponent } from './pages/home/children/promo/promo.component';
import { HmHolidaySurchargeComponent } from './pages/home/children/hm-holiday-surcharge/hm-holiday-surcharge.component';
import { FareComponent } from './pages/home/children/fare/fare.component';
import { DriverOnboardingComponent } from './pages/onboarding/driver/driver-onboarding.component';
import { DriverOnboardingJrhmComponent } from './pages/onboarding/driver/driver-onboarding-jrhm.component';
import { RegisteredDriversComponent } from './pages/home/children/drivers/registered-drivers/registered-drivers.component';
import { OnboardedDriversComponent } from './pages/home/children/drivers/onboarded-drivers/onboarded-drivers.component';
import { QuicksearchDriversComponent } from './pages/home/children/drivers/quicksearch-drivers/quicksearch-drivers.component';
import { OnboardingComponent } from './pages/home/children/drivers/onboarding/onboarding.component';
import { RegisteredDriversPOCComponent } from './pages/home/children/drivers/registered-drivers/registered-drivers-poc.component';
import { OnboardingExitGuard } from './guards/onboarding-exit.guard';
import { CashoutRequestComponent } from './pages/home/children/wallet/cashout-request/cashout-request.component';
import { TopupRequestComponent } from './pages/home/children/wallet/topup-request/topup-request.component';
import { TopupManualComponent } from './pages/home/children/wallet/topup-manual/topup-manual.component';
import { TripManagementComponent } from './pages/home/children/trip-management/trip-management.component';
import { TripQaComponent } from './pages/home/children/trip-management/trip-qa.component';
import { TripMgmtReportComponent } from './pages/home/children/trip-management/trip-management-report.component';
import { OnlineDriversComponent } from './pages/home/children/drivers/online-drivers/online-drivers.component';
import { DriverStatsComponent } from './pages/home/children/drivers/driver-stats/driver-stats.component';
import { WalletAdjustmentComponent } from './pages/home/children/wallet/wallet-adjustment/wallet-adjustment.component';
import { UserManagementComponent } from './pages/home/children/user-management/user-management.component';
import { UnauthorizedComponent } from './pages/home/children/unauthorized/unauthorized.component';
import { DriverGCashComponent } from './pages/home/children/drivers/driver-gcash/driver-gcash.component';
import { ExportWalletAdjustmentComponent } from './pages/home/children/wallet/export-wallet-adjustment/export-walletadjustment.component';
import { ExportWalletBalanceComponent } from './pages/home/children/wallet/export-wallet-balance/export-walletbalance.component';
import { SearchCretriyaComponent } from './pages/home/children/drivers/updateold-drivers/search-cretiriya.component';
import { ImportGcashDataComponent } from './pages/home/children/drivers/import-gcashdata/import-gcashdata.component';
import { DriverTripComponent } from './pages/home/children/trip-management/trip-management-driver-trips.component';
import { DriverTripAvailabilityComponent } from './pages/home/children/drivers/driver-tripavailability/driver-tripavailability.component';
import { DriverWalletHistoryComponent } from './pages/home/children/wallet/driver-wallet-history/driver-wallet-history.component';
import { ExportWalletCommissionComponent } from './pages/home/children/wallet/export-wallet-commission/export-wallet-commission.component';
import { DriverIndividualActivationComponent } from './pages/home/children/drivers/driver-individual-activation/driver-individual-activation.component';
import { DriverActiveReportComponent } from './pages/home/children/trip-management/driver-active-report.component';
import { DriverInactiveReportComponent } from './pages/home/children/trip-management/driver-inactive-report.component';
import { TripManagementSearchComponent } from './pages/home/children/trip-management/trip-managementsearch.component';
import { TripLocationsDriverComponent } from './pages/home/children/trip-management/driver-trip-locationstrack.component';
import { UpdateOldTripReportComponent } from './pages/home/children/drivers/updateold-drivers/updateold-tripreport.component';
import { WalletBalanceLowComponent } from './pages/home/children/wallet/wallet-balance-low/wallet-balance-low.component';
import { TripSummarySearchComponent } from './pages/home/children/trip-management/trip-summary-search.component';
import { CustomerSearchComponent } from './pages/home/children/customer/customer-search.component';
import { ImportTopupManualComponent } from './pages/home/children/wallet/topup-manual/import-topup.component';
import { DriverEmiSearchComponent } from './pages/home/children/emi/driver-emi-search/emi-search.component';
import { DriverEmiHistorySearchComponent } from './pages/home/children/emi/driver-emi-history/driver-emi-history.component';
import { EmiWalletHistoryComponent } from './pages/home/children/emi/emi-wallet-history/emi-wallet-history.component';
import { DriverEmiManagementComponent } from './pages/home/children/emi/emi-management/emi-management.component';
import { TripHoursManagementComponent } from './pages/home/children/trip-management/trip-hoursmanagement.component';
import { DriverEMIAdjustmentComponent } from './pages/home/children/emi/driver-emi-adjustment/driver-emi-adjustment.component';
import { DriverEmiAdjustmentBulkComponent } from './pages/home/children/emi/driver-emi-adjustment-bulk/driver-emi-adjustment-bulk.component';
import { CustomerSummaryComponent } from './pages/home/children/customer/customer-summary.component';
import { DriverLogComponent } from './pages/home/children/drivers/driver-log/driver-log.component';
import { DeliveryTermsComponent } from './pages/home/children/unauthorized/delivery-terms.component';
import { ServiceUnavailableComponent } from './pages/home/children/unauthorized/service-unavailable.component';
import { DriverDeactivationComponent } from './pages/home/children/drivers/driver-deactivation/driver-deactivation.component';
import { DriverUpdateServicesComponent } from './pages/home/children/drivers/driver-update-services/driver-update-services.component';
import { DriverUpdateBagsComponent } from './pages/home/children/drivers/driver-update-bags/driver-update-bags.component';
import { UserManagementPasswordComponent } from './pages/login/user-managementnew.component';
import { DriverAccountComponent } from './pages/home/children/metrobank/metrobank-drivers-account.component';
import { MetroTopupHistoryComponent } from './pages/home/children/metrobank/metrobank-topup-history.component';
import { MetroTopupInquiryComponent } from './pages/home/children/metrobank/metrobank-topupinquiry-history.component';
import { BindAccHistoryComponent } from './pages/home/children/metrobank/bindacc-history.component';
import { BindAccInquiryHistoryComponent } from './pages/home/children/metrobank/bindacc-inquiry-history.component';
import { UnBindAccHistoryComponent } from './pages/home/children/metrobank/unbindacc-history.component';
import { BindAccInquiryComponent } from './pages/home/children/metrobank/bindacc-inquiry.component';
import { MetroBankTopUpInquiryComponent } from './pages/home/children/metrobank/metrobank-topupinquiry.component';
import { DriverWorkingHoursComponent } from './pages/home/children/drivers/driver-workinghours/driver-workinghours.component';
import { PaymayaWalletLinkComponent } from './pages/home/children/paymaya-reports/paymaya-driver-walletlink.component';
import { PaymayaWalletLinkHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-walletlink-history.component';
import { PaymayTopupHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-topup-history.component';
import { PaymayTopupInquiryHistoryComponent } from './pages/home/children/paymaya-reports/paymaya-topup-inquiry.component';
import { DriverTripLocationCountComponent } from './pages/home/children/trip-management/driver-location-report.component';
import { TopupInquiryComponent } from './pages/home/children/paymaya-reports/topup-inquiry.component';
import { PaymayTransactionsFeesComponent } from './pages/home/children/paymaya-reports/paymaya-transactionsfees.component';
import { HolidaySurchargeComponent } from './pages/home/children/holiday-surcharge/holiday-surcharge.component';
import { AdditionalSurchargeComponent } from './pages/home/children/additional_surcharge/additional_surcharge.component';
import { FareSettingsComponent } from './pages/home/children/fare/faresettings.component';
import { VehicleAddonSettingsComponent } from './pages/home/children/vehicleaddon/vehicleaddon.component';
import { AfterhoursFeeComponent } from './pages/home/children/afterhours-surcharge/afterhours-surcharge.component';
import { TripOnlineReportComponent } from './pages/home/children/trip-management/driver-trip-location.component';
import { DriverOnlineReportComponent } from './pages/home/children/drivers/driver-online-log/driver-online-log.component';
import { TricyclePickpComponent } from './pages/home/children/tricycle/tricycle-pickup-cities.component';
import { TricycleDropoffComponent } from './pages/home/children/tricycle/tricycle-dropoff-cities.component';
import { ImportPermissionDataComponent } from './pages/home/children/drivers/updateold-drivers/import-permission-services.component';
import { DriverOnboardingTricycleComponent } from './pages/onboarding/driver/driver-onboarding-tricycle.component';
import { CustomerAppServiceComponent } from './pages/home/children/customerapp-service/customerapp-service.component';
import { PaymayaTopupWebhookComponent } from './pages/home/children/paymaya-reports/paymaya-topup-webhook-reports.component';
import { CustomerWalletAdjustmentComponent } from './pages/home/children/customer-wallet/export-adjustments/customer-adjustments.component';
import { CustomerWalletHistoryComponent } from './pages/home/children/customer-wallet/wallet-history/wallet-history.component';
import { CustomerWalletAdjustmentsComponent } from './pages/home/children/customer-wallet/wallet-adjustments/wallet-adjustments.component';
import { CustomerWalletBalanceComponent } from './pages/home/children/customer-wallet/export-balance/customer-export-balance.component';
import { CustomerWalletLowBalanceComponent } from './pages/home/children/customer-wallet/wallet-balance-low/customer-low-balance.component';
import { CustomerTopupHistoryComponent } from './pages/home/children/customer-wallet/customer-topup-history/customer-topup-history.component';
import { UserClaimsComponent } from './pages/home/children/user-management/user-claims.component';
import { UserClaimsRolesComponent } from './pages/home/children/user-management/user-claims-roles.component';
import { UserRolesComponent } from './pages/home/children/user-management/user-roles.component';
import { UserRolesManageComponent } from './pages/home/children/user-management/user-roles-manage.component';
import { PaymayaNumberUpdationComponent } from './pages/home/children/paymayanumber-updation/paymayanumber-updation.component';
import { NewsArticlesComponent } from './pages/home/children/news/news-articles.component';
import { CarouselBannerComponent } from './pages/home/children/carousel-banner/carousel-banner.component';
import { OtpProviderComponent } from './pages/home/children/otp-provider/otp-provider.component';
const routes: Routes = [
  { path: '', redirectTo: '/home/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component: UserManagementPasswordComponent },
{ path: 'deliveryterms', component: DeliveryTermsComponent },
{ path: 'serviceunavailable', component: ServiceUnavailableComponent },
  { path: 'onboarding', component: DriverOnboardingJrhmComponent, canDeactivate: [OnboardingExitGuard] },
  { path: 'onboardingtricycle', component: DriverOnboardingTricycleComponent, canDeactivate: [OnboardingExitGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'customer', component: CustomerComponent },
      { path: 'customerloadtransaction', component: CustomerLoadTransactionComponent },
      { path: 'customerratebulkupdate', component: CustomerRateBulkUpdateComponent },
      { path: 'driverloadtransaction', component: DriverLoadTransactionComponent },
      { path: 'promocodes', component: PromoComponent },
      { path: 'hm_holiday_surcharge', component: HmHolidaySurchargeComponent },
      { path: 'fareconfig', component: FareComponent },
      { path: 'registereddrivers', component: RegisteredDriversComponent },
      { path: 'registereddriverspoc', component: RegisteredDriversPOCComponent },
      { path: 'quicksearchdrivers', component: QuicksearchDriversComponent },
      { path: 'onboarding', component: OnboardingComponent },
      { path: 'onboardeddrivers', component: OnboardedDriversComponent },
      { path: 'onlinedrivers', component: OnlineDriversComponent },
      { path: 'driverstats', component: DriverStatsComponent },
      { path: 'searchcretriya', component: SearchCretriyaComponent },
      { path: 'exportgcash', component: DriverGCashComponent },
      { path: 'cashoutrequests', component: CashoutRequestComponent },
      { path: 'topuprequests', component: TopupRequestComponent },
      { path: 'topupmanual', component: TopupManualComponent },
      { path: 'walletAdjustments', component: WalletAdjustmentComponent },
      { path: 'tripmanagement', component: TripManagementComponent },
      { path: 'tripqa', component: TripQaComponent },
      { path: 'tripsummary', component: TripMgmtReportComponent },
      { path: 'usermanagement', component: UserManagementComponent },
      { path: 'exportwalletAdjustments', component: ExportWalletAdjustmentComponent},
      { path: 'exportwalletBalance' , component: ExportWalletBalanceComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'importgcashdata', component: ImportGcashDataComponent},
      { path: 'drivertrips', component: DriverTripComponent },
      { path: 'drivertripavailability', component: DriverTripAvailabilityComponent},
      { path: 'exportwalletcommission', component: ExportWalletCommissionComponent},
      { path: 'driverwallethistory', component: DriverWalletHistoryComponent},
      { path: 'driverindividualactivation', component: DriverIndividualActivationComponent},
      { path: 'driveractivereport', component: DriverActiveReportComponent},
      { path: 'driverinactivereport', component: DriverInactiveReportComponent},
      { path: 'tripmanagementsearch', component: TripManagementSearchComponent},
      { path: 'triplocationsdriver', component: TripLocationsDriverComponent},
      { path: 'updateoldtrip', component: UpdateOldTripReportComponent },
      { path: 'walletbalancelow', component: WalletBalanceLowComponent},
      { path: 'tripsummarysearch', component: TripSummarySearchComponent },
      { path: 'customersearch', component: CustomerSearchComponent },
      { path: 'importtopupmanual', component: ImportTopupManualComponent},
      { path: 'emisearch', component: DriverEmiSearchComponent },
      { path: 'emihistory', component: DriverEmiHistorySearchComponent },
      { path: 'emiwallethistory', component: EmiWalletHistoryComponent },
      { path: 'emimanagement', component: DriverEmiManagementComponent },
      { path: 'triphoursmanagement', component: TripHoursManagementComponent},
      { path: 'driveremipayment', component: DriverEMIAdjustmentComponent},
      { path: 'emibulkupdate', component: DriverEmiAdjustmentBulkComponent},
      { path: 'customersummary', component: CustomerSummaryComponent},
      { path: 'driverlog', component: DriverLogComponent},
      { path: 'driverdeactivation', component: DriverDeactivationComponent},
      { path: 'driverupdateservices', component: DriverUpdateServicesComponent},
      { path: 'driverupdatebags', component: DriverUpdateBagsComponent},
      { path: 'driversaccount', component: DriverAccountComponent},
      { path: 'topuphistory', component: MetroTopupHistoryComponent},
      { path: 'topuphistoryinquiry', component: MetroTopupInquiryComponent},
      { path: 'bindacchistory', component: BindAccHistoryComponent},
      { path: 'bindaccinquiryhistory', component: BindAccInquiryHistoryComponent},
      { path: 'unbindacchistory', component: UnBindAccHistoryComponent},
      { path: 'bindaccinquirypost', component: BindAccInquiryComponent },
      { path: 'metrobanktopupinquiry', component: MetroBankTopUpInquiryComponent},
      { path: 'driverwrokinghours', component: DriverWorkingHoursComponent },
      { path: 'paymayawalletlink', component: PaymayaWalletLinkComponent },
      { path: 'paymayawalletlinkhistory', component: PaymayaWalletLinkHistoryComponent },
      { path: 'paymayatopuphistory', component: PaymayTopupHistoryComponent },
      { path: 'paymayatopupinquiryhistory', component: PaymayTopupInquiryHistoryComponent },
      { path: 'locationscount', component: DriverTripLocationCountComponent },
      { path: 'additional_surcharge', component: AdditionalSurchargeComponent},
      { path: 'paymayatransactionfees', component: PaymayTransactionsFeesComponent},
      { path: 'holiday_fee', component: HolidaySurchargeComponent },
      { path: 'afterhours_fee', component: AfterhoursFeeComponent },
      { path: 'faresetting', component: FareSettingsComponent },
      { path: 'vehicleaddon', component: VehicleAddonSettingsComponent },
      { path: 'triponlinereport', component: TripOnlineReportComponent },
      { path: 'driveronlinereport', component: DriverOnlineReportComponent },
      { path: 'tricyclepickup', component: TricyclePickpComponent },
      { path: 'tricycledropoff', component: TricycleDropoffComponent },
      { path: 'importpermission', component: ImportPermissionDataComponent },
      { path: 'customerapp_services', component: CustomerAppServiceComponent },
      { path: 'paymayatopupwebhookreports', component: PaymayaTopupWebhookComponent },
      { path: 'customerexportwalletadjustments', component: CustomerWalletAdjustmentComponent },
      { path: 'customerexportwallethistory', component: CustomerWalletHistoryComponent },
      { path: 'customerwalletadjustments', component: CustomerWalletAdjustmentsComponent },
      { path: 'customerwalletbalance', component: CustomerWalletBalanceComponent },
      { path: 'customerlowwalletbalance', component: CustomerWalletLowBalanceComponent },
      { path: 'customerpaymayatopuphistory', component: CustomerTopupHistoryComponent },
      { path: 'userclaims', component: UserClaimsComponent },
      { path: 'userrolesmanage', component: UserRolesComponent },
      { path: 'userrolesclaims', component: UserClaimsRolesComponent },
      { path: 'userroles', component: UserRolesManageComponent },
      { path: 'paymayanumberupdation', component: PaymayaNumberUpdationComponent},
      { path: 'newsarticles', component: NewsArticlesComponent },
      { path: 'carouselbanner', component: CarouselBannerComponent },
      { path: 'otpprovider', component: OtpProviderComponent }
] },
  { path: '**', redirectTo: 'home/dashboard'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
