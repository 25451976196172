import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { Observable } from 'rxjs';

export enum DownloadState {
    NotStarted = 0,
    Downloading,
    Completed,
    Errored
}

export class DownloadTask {

    ref: AngularFireStorageReference;
    url: string;
    urlObservable: Observable<any>;
    state = DownloadState.NotStarted;

    get isLoading(): boolean { return this.state === DownloadState.Downloading; }

    constructor(path: string, storage: AngularFireStorage) {
        this.ref = storage.ref(path);
        this.urlObservable = this.ref.getDownloadURL();
        this.state = DownloadState.Downloading;
        this.ref.getDownloadURL().toPromise()
            .then(url => { this.url = url; this.state = DownloadState.Completed; })
            .catch(e => this.state = DownloadState.Errored);
    }
}
