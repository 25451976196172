import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateHolidaySurchargeComponent } from 'src/app/modals/holiday-surcharge/create-holiday-surcharge.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { EditCustomerAppServicesComponent } from 'src/app/modals/customerapp-services/edit-customerapp-services.component';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customerapp-service.component.html'
})
export class CustomerAppServiceComponent implements OnInit {
  customerAPPServices: any = [];
  loading: boolean = false;
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal) {
  }
  ngOnInit() {
    this.loadCustomerAPPServices();
  }

  createCustomerAppServices() {
    const modalRef =  this.modalService.open(CreateHolidaySurchargeComponent, { size: 'lg' });
    modalRef.result
    .then((result) => { this.loadCustomerAPPServices();  }, (reason) => { });
  }
  editCustomerAPP(data) {
    const modalRef =  this.modalService.open(EditCustomerAppServicesComponent, { size: 'lg' });
    modalRef.componentInstance.loadedData = data;
    modalRef.result
    .then((result) => { this.loadCustomerAPPServices(); }, (reason) => { });
  }
  async loadCustomerAPPServices() {
    this.loading = true;
    this.dbService.getCustomerAPPServices()
    .then(results => {
      this.customerAPPServices = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'DEACTIVATE';
      case false: return 'DISABLED';
      default: return '';
    }
  }
  deactivateHolidayStatus(data, index: number) {
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Deactivation Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to de-activate this Holiday:' + data.holiday_name +
    '? \nOnce de-activated, the holiday fee cannot be enabled again.';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deactivateHolidayStatus(data.id);
      })
      .then(() => {
        this.loadCustomerAPPServices();
      })
      .catch(e => {
        console.log(e);
      });
  }
  
}


