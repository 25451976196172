import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateUserClaimsComponent } from 'src/app/modals/user-claims/create-user-claim.component';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { EditUserClaimsComponent } from 'src/app/modals/user-claims/edit-user-claim.component';
import {AlertModel, Claim, ClaimsValue } from 'src/app/interfaces/interfaces';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserClaimsRolesAPI } from 'src/app/api/user.api';
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-claims-roles.component.html'
})
export class UserClaimsRolesComponent implements OnInit {
  userClaimsId: any = [];
  loading: boolean = false;
  public arrFinalData = [];
  isEditing = false;
  isEditingSave = false;
  alert: AlertModel;
  roleDisplayName: any;
  roleName  = new FormControl('');
  search = new FormControl('');
  id = new FormControl('');
  claimName = new FormControl('');
  private claimsNameObservable: Observable<Claim>;
  userClaimsRolesAPI: UserClaimsRolesAPI;
  userClaims: any = [];
  indexValue: any;
  disableStatus = false;
  rolesName: any[];
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal, private router: Router) {
    this.userClaims = [];
    this.paginationCount = 0;
    this.userClaimsRolesAPI = new UserClaimsRolesAPI(dbService, (claims) => {
        this.userClaims = claims;
        this.getPaginations();
        });
  }
  ngOnInit() {
    this.loadRoles();
    this.loadUserClaims();
  }

  getPaginations() {
    if (this.selectedFilter > this.userClaims.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }
  applyFilter(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.userClaimsRolesAPI.getUserClaimsSearch(this.claimName.value, this.id.value, Number(this.selectedFilter), buttonAction);
  }
  filterpage(status: number) {
    this.selectedFilter = status;
  }

  createUserClaim() {
    const modalRef =  this.modalService.open(CreateUserClaimsComponent, { size: 'lg' });
    modalRef.result
    .then((result) => { this.loadUserClaims();  }, (reason) => { });
  }

  async saveUserClaimRole() {
    this.disableStatus = true;
    this.loading = true;
    this.arrFinalData = this.userClaims;
    this.dbService.hasClaimDetails(this.roleName.value.toLowerCase())
    .then(result => {
      if (result) {
        this.claimUploadUpdate();
        return Promise.reject();
      } else {
        this.claimUpload();
      }
    });


  }

  async claimUpload() {
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      await this.saveClaimName(this.arrFinalData[i], i);
    }
    this.loading = false;
    this.showAlert('success', 'User Claim Role Saved Successfully');
    this.backtoPage();
  }
  async saveClaimName(arrFinalData: any, iVal) {
        await this.dbService.saveAdminClaim(arrFinalData, this.roleName.value.toLowerCase(), this.roleDisplayName);
  }
  async claimUploadUpdate() {
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      await this.saveClaimNameUpdate(this.arrFinalData[i], i);
    }
    this.loading = false;
    this.showAlert('success', 'User Claim Role Updated Successfully');
    this.backtoPage();
  }
  async saveClaimNameUpdate(arrFinalData: any, iVal) {
        await this.dbService.saveAdminClaimUpdate(arrFinalData, this.arrFinalData[iVal].id,
        this.roleName.value.toLowerCase(), this.roleDisplayName);
  }
  async loadUserClaims() {
    this.pageCount = 'load';  this.paginationCount = 0;
    this.userClaimsRolesAPI.getUserClaimsSearch(this.claimName.value, this.id.value,
    Number(this.selectedFilter), 'load');
  }
  async loadRoles() {
    this.dbService.getRoles()
    .then(results => {
      this.rolesName = results;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  getRoles(role: string) {
    console.log("Role", role);

  }
  valueGet(value) {
    let code = this.rolesName.filter(t => t.roleName === value);
    this.roleDisplayName = code[0].roleValue;
  }
  getActionButtonTitle(status: boolean) {
    switch (status) {
      case true: return 'ACTIVE';
      case false: return 'INACTIVE';
      default: return '';
    }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  backtoPage() {
    this.router.navigate(['/home/userrolesmanage']);
  }

}


