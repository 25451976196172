import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { NewUserPromo, NewPromoCode, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { PromoUtils, PromoDiscountType } from 'src/app/utils/promo.utils';
import { UtilsService } from 'src/app/services/utils.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'add-user-promo-code',
  templateUrl: './add-user-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.css']
})
export class AddUserPromoCodeComponent implements OnInit {
  promoUserForm: FormGroup;
  promoUserModel: NewUserPromo;
  alert: AlertModel;
  promoCode: any;
  id:any;
  allowedUsers: any;
  verified: boolean = false;
  saving: boolean;
  onboardingRegions = [];
  valid: any = '';
  onboardingCity: SupportedRegions[];
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService,
    public utils: UtilsService,
    private modalService: NgbModal
  ) {
    
  }
  ngOnInit() {
    this.createForm();
    this.loadCustomers();
    
  }
  loadCustomers() {
    this.dbService.getCustomersbyPromocode(this.promoCode.id)
      .then(result => {
        this.allowedUsers = result;
    })
  }
  private createForm() {
    this.promoUserForm = this.fb.group({
      phone: ['', Validators.min(10)]
    });
  }
  searchCustByPhone(event: any) {
    this.verified = false
    this.valid = 'is-invalid'
    const data = event.target.value;
    if(data.length == 10) {
      this.dbService.searchCustomersbyPhoneNo(data)
      .then(result => {
        if(result.length > 0) {
          this.verified = true
          this.valid = 'is-valid'
          this.promoUserModel = {
            id: result[0].uid,
            firstname: result[0].firstName,
            lastname: result[0].lastName,
            phone: result[0].phone
          };
        } else {
          this.valid = 'is-invalid'
        }
    })
    .catch(e => {
      this.valid = 'is-invalid'
    })
    }
  }
  addUserToPromo() {
    this.dbService.createUserPromoCode(this.promoUserModel, this.promoCode.id)
      .then(result => {
        this.loadCustomers()
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while saving user to promo code - ' + e.message);
      })
  }
  deleteUserPromo(userId: string, firstname: string, lastname: string) {
    const userName = firstname+' '+lastname;
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Removing Alert';
    modalRef.componentInstance.message =
    'Are you sure you want to remove ' +userName+ ' for promo '+this.promoCode.code+' ?';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        this.dbService.deleteUserPromo(userId, this.promoCode.id)
      })
      .then(() => {
        this.loadCustomers()
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while deleting user to promo code - ' + e.message);
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }

}
