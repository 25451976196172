import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database.service';
import { AdditionalSurcharge, AlertModel, SupportedRegions } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/services/validator.service';
import uniqBy from 'lodash/uniqBy';
@Component({
  selector: 'app-edit-additional-surcharge',
  templateUrl: './edit-additional-surcharge.component.html'
})
export class EditAdditionalSurchargeComponent implements OnInit {
  additionalSurchargeForm: FormGroup;
  additionalSurchargeModel: AdditionalSurcharge;
  alert: AlertModel;
  saving: boolean;
  loadedData: any = [];
  name: any;
  date: any;
  start_time: any;
  end_time: any;
  amount: any;
  status: any;
  docID: any;
  defaultVehicleType: any;
  defaultArea:any;
  defaultTripType:any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private dbService: DatabaseService,
    private validator: ValidatorService
  ) {
  }
  ngOnInit() {
    this.name = this.loadedData.name;
    this.date = this.loadedData.date;
    this.start_time = this.loadedData.start_time;
    this.end_time = this.loadedData.end_time;
    this.amount = this.loadedData.amount;
    this.status = this.loadedData.status;
    this.docID = this.loadedData.id;
    this.createForm();
  }
  private createForm() {
    this.additionalSurchargeForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
      amount: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(4)]],
      date: [null],
      start_time: [null],
      end_time: [null],
      status: [null]
    });
  }
  c(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid;
  }
  canSaveAdditionalSurcharge(): boolean {
    return this.additionalSurchargeForm.valid && this.additionalSurchargeForm.dirty;
  }

  editAdditionalSurcharge() {
    this.additionalSurchargeModel = {
      id: this.docID,
      name: this.additionalSurchargeForm.controls.name.value,
      amount: this.additionalSurchargeForm.controls.amount.value,
      date: this.additionalSurchargeForm.controls.date.value,
      start_time: this.additionalSurchargeForm.controls.start_time.value,
      end_time: this.additionalSurchargeForm.controls.end_time.value,
      status: 1
    };
    this.additionalSurchargeForm.disable();
    this.saving = true;
    const TripType = this.defaultTripType;
    const vehicle = this.defaultTripType === 'HMDelivery' ? this.defaultVehicleType : 'fares';
    this.dbService.editNewAdditionalSurcharge(this.additionalSurchargeModel,this.defaultArea, TripType, vehicle, this.docID)
      .then(() => {
        this.showAlert('success', 'Additional Surcharge updated successfully');
        setTimeout(() => this.activeModal.close(), 1500);
      })
      .catch(e => {
        this.showAlert('danger', 'There was an error while updating the additional surcharge - ' + e.message);
        this.additionalSurchargeForm.enable();
      })
      .finally(() => {
        this.saving = false;
      });
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
