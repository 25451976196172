import { Component, OnInit } from '@angular/core';
import { Paginator } from 'src/app/classes/paginator';
import { DriverProfile, GCashCity, SupportedRegions } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverProfileComponent } from 'src/app/modals/driver/driver-profile/driver-profile.component';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'moment';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-onboarded-drivers',
  templateUrl: './onboarded-drivers.component.html',
  styleUrls: ['./onboarded-drivers.component.css']
})
export class OnboardedDriversComponent implements OnInit {
  OnboardingUtils: any;
  paginator: Paginator<DriverProfile> = null;
  selectedFilter = 1;
  api: DriversListAPI;
  search = new FormControl('');
  GCashRegions: GCashCity[];
  onboardingCity: SupportedRegions[];
  canShowStatusFilter: boolean;
  region = new FormControl();
  onboardingRegions = [];
  defaultRegion: any;
  tripType = new FormControl();
  tripTypecombo = [];
  defaultTripType: number;
  filterBlock = (text: string, item: DriverProfile) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.personal.firstname, item.personal.lastname].join(' ');
    return item.form.id.toLocaleLowerCase().includes(searchText) ||
           item.uid.toLocaleLowerCase().includes(searchText) ||
           item.personal.phone.toLocaleLowerCase().includes(searchText) ||
           name.toLocaleLowerCase().includes(searchText);
  }

  constructor(private dbService: DatabaseService, private modalService: NgbModal,
              private utils: UtilsService, public permissions: PermissionService) {
    this.OnboardingUtils = OnboardingUtils;
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.tripTypecombo = OnboardingUtils.getTripVechicleType();
    this.defaultTripType = this.tripTypecombo[0];
    this.api = new DriversListAPI(dbService, (drivers) => {
      this.paginator = new Paginator(1, 10, drivers, this.filterBlock);
    });
  }

  ngOnInit() {
     this.getGcashRegionList();
     this.getRegionCityList();
  }

  getDriverVehicleName(status: string): string | null {
    return OnboardingUtils.getVehiclyName(status);
  }

  get filterName(): string {
    return this.getStatusName(this.selectedFilter);
  }

  get driverProfiles(): DriverProfile[] {
    return (this.paginator) ? this.paginator.getItems(this.search.value) : null;
  }

  get disablePagination(): boolean {
    return this.search.value !== '';
  }

  /**
   * Returns a boolean indicating whether the data can be exported or not
   */
  get canExport(): boolean {
    if (!this.paginator) { return false; }
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }
  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }
  filter(status: number) {
    this.selectedFilter = status;
    this.vechicleTypeFilterStatus(status, this.region.value);
  }
getVehicleName(status: string) {
  return OnboardingUtils.getVehiclyName(status);
}
applyFilter() {
  this.canShowStatusFilter = true;
  this.api.loadDriverProfilesVechicle(this.selectedFilter, 'tricycle', this.region.value);
}
applyFilterAll() {
  this.canShowStatusFilter = true;
  this.loadDrivers(this.selectedFilter, this.region.value);
}
  private async loadDrivers(status: number, region: string) {
    this.api.loadDriverProfiles(status, region);
  }
  private async vechicleTypeFilter() {
    if (this.tripType.value === 'All') {
         this.applyFilterAll();
    } else {
      this.api.loadDriverProfilesVechicle(this.selectedFilter, this.tripType.value.toLowerCase(), this.region.value);
    }
  }
  private async vechicleTypeFilterStatus(status: number, region: string) {
    if (this.tripType.value === 'All') {
         this.applyFilterAll();
    } else {
      this.api.loadDriverProfilesVechicle(this.selectedFilter, this.tripType.value.toLowerCase(), this.region.value);
    }
  }
  retry() {
    this.loadDrivers(this.selectedFilter, this.region.value);
  }
  openDriverProfile(driver: DriverProfile) {
    const modalRef = this.modalService.open(DriverProfileComponent, { size: 'xl', scrollable: true, backdrop : 'static', keyboard : false });
    modalRef.componentInstance.isOnboardingProfile = false;
    modalRef.componentInstance.driverProfile = driver;
    modalRef.componentInstance.status = this.utils.mainStatusForOnboardedDrivers;
    modalRef.componentInstance.subStatus = this.utils.subStatusForOnboardedDrivers;
    modalRef.componentInstance.GCashCities = this.GCashRegions;
    modalRef.componentInstance.onboardingCity = this.onboardingCity;
    modalRef.result
    .then((result) => { }, (res) => { this.applyFilter();});
  }
  export() {
    const drivers = this.paginator.collection;
    const filename = () => 'onboarded_drivers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.driverProfileTitles());
    exporter.exportDrivers(drivers as DriverProfile[]);
  }
  getGcashRegionList() {
    this.dbService.getGcashRegionList().then(regions => {
    this.GCashRegions = regions;
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
    }
    getRegionCityList() {
      this.dbService.getOnboardingRegionList().then(regions => {
      const datafilter = 'regions';
      const data = Object(regions)[datafilter];
      this.onboardingCity = data;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
      });
    }
}
