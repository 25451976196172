import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { DriversAccount, TopupHistory, MetroBankBindHistroy } from '../interfaces/interfaces';
import { ApiService } from '../services/api.service';
export class MetroBankDriversAccountAPI extends BaseAPI {
    private handler: ([DriversAccount]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([DriversAccount]) => void) {
        super(dbService);
        this.handler = completion;
    }
    driversAccount(phone?: string, referenceNumber?: string, fromDate?: Date, toDate?: Date,  limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.driversAccount(phone, referenceNumber, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [DriversAccount]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
    }
}
export class MetroBankTopupAPI extends BaseAPI {
    private handler: ([TopupHistory]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([TopupHistory]) => void) {
        super(dbService);
        this.handler = completion;
    }
    bindTopUpHistory(phone?: string, referenceNumber?: string, amount?: number, type?: string,
                     transactionCode?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.bindTopUpHistory(phone, referenceNumber, amount, type, transactionCode, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [TopupHistory]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
    }
    bindTopUpInquiry(phone?: string, referenceNumber?: string, amount?: number, type?: string,
                     transactionCode?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.bindTopUpInquiry(phone, referenceNumber, amount, type, transactionCode, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [TopupHistory]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
    }
}
export class MetroBankAPI extends BaseAPI {
    private handler: ([MetroBankBindHistroy]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([MetroBankBindHistroy]) => void) {
        super(dbService);
        this.handler = completion;
    }
    metroBankBindHistory(phone?: string, referenceNumber?: string, type?: string, fromDate?: Date, toDate?: Date,
                         limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.loadMetroBankBindHistory(phone, referenceNumber, type, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [MetroBankBindHistroy]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
    metroBankBindInquiryHistory(phone?: string, referenceNumber?: string, transactionCode?: string, fromDate?: Date, toDate?: Date,
                                limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.loadMetroBankBindInquiryHistory(phone, referenceNumber, transactionCode, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [MetroBankBindHistroy]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
    metroBankUnBindHistory(phone?: string, referenceNumber?: string, Type?: string, transactionCode?: string,
                           fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string  ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        this.dbService.loadMetroBankUnBindHistory(phone, referenceNumber, Type, transactionCode, fromDate, toDate,
        this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [MetroBankBindHistroy]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
    }
}
export class MetroBankBindAccountApi extends BaseAPI {
    private handler: (success: boolean, message: {}) => void;
    constructor(apiService: ApiService, completion: (success: boolean, message: {}) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    metroBankBindInquiry(phone: string, referenceNumber: string) {
        this.state = APIState.loading;
        this.apiService.bindAccInquiry(phone, referenceNumber)
        .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const successMessage = 'The status of the cashout requests were updated successfully.';
                const isSuccess = response;
                if (response) {
                    this.state = APIState.successWithData;
                    this.handler(true, response);
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
                return true;
            })
            .catch(err => {
                const failureMessage = 'There was an error while updating the cashout requests. Please try again.';
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, failureMessage + err);
            });
    }
    metroBanTopUpInquiry(phone: string, referenceNumber: string) {
        this.state = APIState.loading;
        this.apiService.topUpInquiry(phone, referenceNumber)
        .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const successMessage = 'The status of the cashout requests were updated successfully.';
                const isSuccess = response;
                if (response) {
                    this.state = APIState.successWithData;
                    this.handler(true, response);
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
                return true;
            })
            .catch(err => {
                const failureMessage = 'There was an error while updating the cashout requests. Please try again.';
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, failureMessage + err);
            });
    }
}
