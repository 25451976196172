import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Customer, NewPromoCode, PromoCode, OnboardingApplication, CashoutRequest,
  TopupRequest, WalletSettings, FareSettings, DriverIdentification, DriverSettings,
  DriverProfile, DriverLocation, GCashCity, SearchConditions, DriverTripStat, Tripsummary, EMIWallet,
  DriverLogSearch, MetroBankBindHistroy, DriversAccount, TopupHistory, DriverWrokingHours, PaymayaReports,
  PaymayaReportWallet, DriverLocationStat, HolidaySurcharge, AdditionalSurcharge, DriverTripStatus,
  TricyclePickup, TricycleDropoff, PermissionService, CustomerAPPServices, UserClaim, UserClaimAdd, Claim, CarouselBanner
, ClaimsValue, ClaimSettings, Roles, ClaimSettingsValue, PayMayaNumberUpdation, NewsArticle, NewUserPromo, Indentifications, CustomerSpecialRate} from '../interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { isNumber, isString } from 'util';
import {  combineLatest } from 'rxjs';
import { Trip, DriverTripSummaryReport, DriverTripLocations } from '../interfaces/trip-interfaces';
import { WalletRequestFilter } from '../classes/FilterModels';
import { AdminUser } from '../api/user.api';
import { UtilsService } from 'src/app/services/utils.service';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  constructor(private db: AngularFirestore, public utils: UtilsService) { }
  tripRef(tripId: string) {
    return this.db.collection<Trip>('trips').doc(tripId);
  }
  getFranchiseArea() {
    const ref = this.db.collection('franchise_area');
    const areas = ref.get().toPromise().then(data => {
      return data.docs.map(c => c.id);
    });
    return areas;
  }
  getMerchantRates() {
    const ref = this.db.doc('/global_config/merchant_rates').get().toPromise();
    return ref;
  }
  //Fares Joyride by trip type
  filterFranchiseAreaByTriptype(area: string, tripType: string) {
    const ref = this.db.doc('/franchise_area/' + area + '/'+ tripType +'/fares').get().toPromise();
    return ref;
  }
  //Fares Happy Move by vehicle
  filterFranchiseAreaByVehicletype(area: string, vehicle: string,) {
    const ref = this.db.doc('/franchise_area/'+area+'/HMDelivery/'+vehicle).get().toPromise();
    return ref;
  }
  //Fares Happy Move by add on
  filterFranchiseAreaByAddOn(area: string, vehicle: string,addon: string) {
    const ref = this.db.doc('/franchise_area/'+area+'/HMDelivery/'+vehicle+'/'+addon).get().toPromise();
    return ref;
  }
  async filterFranchiseAreaByVehicletypeAdditionalSurchargeHMDelivery(area: string, vehicle: string) {
    const ref = this.db.collection('/franchise_area/'+area+'/HMDelivery/'+vehicle+'/additional_surcharge');
     const codes = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as any[];
    });
    return codes;
  }
  async filterFranchiseAreaByVehicletypeAdditionalSurcharge(area: string, TripType: string) {
    const ref = this.db.collection('/franchise_area/'+area+'/'+TripType+'/fares/additional_surcharge');
    const codes = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as any[];
    });
    return codes;
  }
  deleteAdditionalSurcharge(area: string, TripType: string, vehicle: string, docID: string) {
    return this.db.collection('/franchise_area/'+area+'/'+TripType+'/'+vehicle+'/additional_surcharge').doc(docID).delete();
  }
  createNewAdditionalSurcharge(additional_surcharge: AdditionalSurcharge, area: string, TripType:string, vehicle: string) {
    const payload = additional_surcharge as AdditionalSurcharge;
    const docID = this.db.createId();
    payload.id = docID;
    return this.db.collection('/franchise_area/'+area+'/'+TripType+'/'+vehicle+'/additional_surcharge').doc(docID).set(payload);
  }
  editNewAdditionalSurcharge(additional_surcharge: AdditionalSurcharge, area: string, TripType:string, vehicle: string, docID: string) {
    const payload = additional_surcharge as AdditionalSurcharge;
    return this.db.collection('/franchise_area/'+area+'/'+TripType+'/'+vehicle+'/additional_surcharge').doc(docID).set(payload);
  }
  deactivateAdditionalSurcharge(area: string, TripType:string, vehicle: string, status: number,docID: string) {
    return this.db.collection('/franchise_area/'+area+'/'+TripType+'/'+vehicle+'/additional_surcharge').doc(docID).update({ status: status });
  }

  async getHolidaySurcharge(): Promise<any[]> {
    const ref = this.db.collection('holiday_fee');
    const codes = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as any[];
    });
    return codes;
  }

  async getHmHolidaySurcharge(): Promise<any[]> {
    const ref = this.db.collection('hmdeliver_holiday');
    const codes = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as any[];
    });
    return codes;
  }

  getBagSettings() {
    const ref = this.db.doc('/global_config/bag_settings').get().toPromise();
    return ref;
  }
  getEMIRemarks() {
    const ref = this.db.doc('/global_config/emi_remarks').get().toPromise();
    return ref;
  }

  allTimeStats() {
    return this.db.doc('/statistics/dashboard/stats/all-time');
  }
  allTimeDailyStats(date: string, region: string) {
     return this.db.doc('/statistics/dashboard/' + region + '/' + date);
  }
  allTimeStatsForDriver(driverId: string) {
    return this.db.doc('/statistics/' + driverId + '/stats/all-time');
  }
  dailyStatsForDriver(driverId: string, date: string) {
    return this.db.doc('/statistics/' + driverId + '/daily/' + date);
  }
  weeklyStatsForDriver(driverId: string, week: string) {
    return this.db.doc('/statistics/' + driverId + '/weekly/' + week);
  }
  monthlyStatForDriver(driverId: string, month: string) {
    return this.db.doc('/statistics/' + driverId + '/monthly/' + month);
  }
  globalFareSettings() {
    return this.db.collection('global_config').doc<FareSettings>('fare_settings');
  }
  driverProfileSubscription(driverId: string) {
    return this.db.collection('driver_registrations').doc<DriverProfile>(driverId).valueChanges();
  }
  allDriversLocation() {
    return this.db.collection<DriverLocation>('locations');
  }
  allAdminUsersSubscription() {
    return this.db.collection<AdminUser>('admin_users').valueChanges();
  }
  async getDriversbyPhone(phone: string): Promise<DriverProfile[]> {
    const ref = this.db.collection<DriverProfile>('driver_registrations').ref.where('personal.phone', '==', phone);
    const drivers = await ref.get().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return drivers;
  }
  async getCustomers(): Promise<Customer[]> {
    const ref = this.db.collection<Customer>('customer_profiles').ref.where('phone', '>=', '0');
    const customers = await ref.get().then(data => {
      return data.docs.map(c => c.data()) as Customer[];
    });
    return customers;
  }
  async getCustomersbyPhone(phone: string): Promise<Customer[]> {
    const ref = this.db.collection<Customer>('customer_profiles').ref.where('phone', '==', phone);
    const customers = await ref.get().then(data => {
      return data.docs.map(c => c.data()) as Customer[];
    });
    return customers;
  }
  async getCustomersbySearch(phone: string, firstname?: string, emailId?: string): Promise<Customer[]> {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc');
      if (phone) { query = query.where('phone', '==', phone); }
      if (firstname) { query = query.where('firstName', '==', firstname); }
      if (emailId) { query = query.where('email', '==', emailId); }
      return query;
    };
    const ref = this.db.collection<Customer>('customer_profiles', queryFn);
    const customers = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as Customer[];
    });
    return customers;
  }
  saveCustomerProfile(profile: Customer): Promise<void> {
    const ref = this.db.collection<Customer>('customer_profiles').doc(profile.uid);
    return ref.update(profile);
  }
  async getPromoCodes(code: string, region: string, status: number, from: string, to: string): Promise<PromoCode[]> {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('validity.to', 'desc').where('status', '==', status);
      if (from) { query = query.where('validity.to', '>=', from); }
      if (to) { query = query.where('validity.to', '<=', to); }
      if (code) { query = query.where('code', '==', code.toUpperCase()); }
      if (region) { query = query.where('region', '==', region); }
      return query;
    };
    const ref = this.db.collection<PromoCode>('promo_codes', queryFn);
    const codes = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as PromoCode[];
    });
    return codes;
  }
  async hasPromoCode(promoCode: string, region: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('promo_codes', ref => ref.where('code', '==', promoCode).where('region', '==', region))
      .get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  deactivatePromoCode(promoCodeID: string) {
    return this.db.collection('promo_codes').doc(promoCodeID).update({ status: 1 });
  }
  createNewPromoCode(promo: NewPromoCode) {
    const payload = promo as PromoCode;
    const docID = this.db.createId();
    payload.id = docID;
    return this.db.collection('promo_codes').doc(docID).set(payload);
  }
  // MARK: Fare Config
  updateFareSettings(newSettings: FareSettings) {
    newSettings.updated_at = new Date();
    return this.db.collection('global_config').doc('fare_settings').set(newSettings, { merge: true });
  }
  updateDriverSettings(driverId: string, newSettings: DriverSettings) {
    return this.db.collection('driver_registrations').doc(driverId).set({ settings: newSettings }, { merge: true });
  }
  updateDriverIdentification(driverId: string, docs: Indentifications) {
    return this.db.collection('driver_registrations').doc(driverId).set({ identification: docs }, { merge: true });
  }
  updateDriverIdentificationAuthLetter(newIdentification: DriverIdentification, id: string) {
    return this.db.collection('driver_registrations').doc(id).set({ identification: newIdentification }, { merge: true });
  }
  saveOnboardingForm(form: any, id: string) {
    return this.db.collection('driver_registrations').doc(id).set(form);
  }
  async getAllDriverRegistrations(status: number, region: string) {
    const queryFn: QueryFn = (isNumber(status)) ?
      (aRef) => aRef.where('form.mainStatus', '==', status).where('personal.region', '==', region) : undefined;
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  async getAllDriverRegistrationsVechicle(status: number, vehicletype: string, region: string) {
    const queryFn: QueryFn = (isString(vehicletype)) ?
      (aRef) => aRef.where('form.mainStatus', '==', status)
                    .where('personal.vehicle_type', '==', vehicletype)
                    .where('personal.region', '==', region) : undefined;
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  async getAllDriverRegistrationsStatus(status: number) {
    const queryFn: QueryFn = (isNumber(status)) ?
      (aRef) => aRef.where('form.mainStatus', '==', status) : undefined;
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  async getAllDriverRegistrationsbyQuickSearch(firstname?: string, lastname?: string, phone?: string, bikerid?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('form.appliedDate', 'desc');
      if (firstname) { query = query.where('personal.firstname', '==', firstname); }
      if (lastname) { query = query.where('personal.lastname', '==', lastname); }
      if (phone) { query = query.where('personal.phone', '==', phone); }
      if (bikerid) { query = query.where('form.bikerId', '==', bikerid); }
      return query;
    };
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  async getAllDriverWalletAdj(uid?: string, firstname?: string, phone?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('form.appliedDate', 'desc');
      if (firstname) { query = query.where('personal.firstname', '==', firstname); }
      if (uid) { query = query.where('uid', '==', uid); }
      if (phone) { query = query.where('personal.phone', '==', phone); }
      return query;
    };
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  getDriver(driverId: string) {
    const ref = this.db.collection<OnboardingApplication>('driver_registrations').doc(driverId);
    return ref;
  }
  getDriverLocation(driverId: string) {
    const ref = this.db.collection<DriverLocation>('locations').doc(driverId);
    return ref;
  }
  fetchDriverLocation(driverId?: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('d.uid', '==', driverId);
    const ref = this.db.collection<DriverLocation>('locations', queryFn);
    return ref;
  }
  getDriverByWalletAdj(driverId?: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('personal.firstname', '==', driverId);
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    return ref;
  }
  //CHARM
  allTripsRequestingToday(today, region) {
      const dateNow = moment(today).format('YYYY-MM-DD');
      const startDate = dateNow+' 00:00'; //moment().startOf('day').toDate();
      const endDate = dateNow+' 23:59'; //moment().endOf('day').toDate();
      const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.deliverySchedule.date', 'asc').limit(500);
      if(region) {query = query.where('region', '==', region);}
      query = query.where('tripType', '==', 5);
      query = query.where('status', 'in', [0,1,13]);
      query = query.where('trip.deliverySchedule.date', '>=', startDate)
      query = query.where('trip.deliverySchedule.date', '<=', endDate)
      return query;
    };
      return this.db.collection<Trip>('trips', queryFn);
  }
  allTripsOngoingToday(today, region) {
      const dateNow = moment(today).format('YYYY-MM-DD');
      const startDate = dateNow+' 00:00'; //moment().startOf('day').toDate();
      const endDate = dateNow+' 23:59'; //moment().endOf('day').toDate();
      const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.deliverySchedule.date', 'asc').limit(500);
      if(region) {query = query.where('region', '==', region);}
      query = query.where('tripType', '==', 5);
      query = query.where('status', 'in', [2,3,4]);
      query = query.where('trip.deliverySchedule.date', '>=', startDate)
      query = query.where('trip.deliverySchedule.date', '<=', endDate)
      return query;
    };
      return this.db.collection<Trip>('trips', queryFn);
  }
  allTripLatePickupTodayImmediate(today, region) {
      const todayDate10 = moment(today).subtract(15, 'minutes').toDate();
      //console.log('allTripLatePickupTodayImmediate: driver_assigned_time less than '+todayDate10)
      const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('timestamps.driver_assigned_time', 'asc').limit(500);
      if(region) {query = query.where('region', '==', region);}
      query = query.where('tripType', '==', 5);
      query = query.where('trip.deliverySchedule.type', '==', '2');
      query = query.where('status', 'in', [2]);
      query = query.where('timestamps.driver_assigned_time', '<=', todayDate10)
      return query;
    };
      return this.db.collection<Trip>('trips', queryFn);
  }
  allTripLatePickupTodayScheduled(today, region) {
      const todayDate70 = moment(today).add(70, 'minutes').format('YYYY-MM-DD HH:mm');
      const todayDate5 = moment(today).subtract(5, 'minutes').format('YYYY-MM-DD HH:mm');
      //console.log('allTripLatePickupTodayScheduled: '+todayDate70+ ' - '+todayDate5)
      const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.deliverySchedule.date', 'asc').limit(500);
      if(region) {query = query.where('region', '==', region);}
      query = query.where('tripType', '==', 5);
      query = query.where('trip.deliverySchedule.type', '==', '1');
      query = query.where('status', 'in', [2]);
      query = query.where('trip.deliverySchedule.date', '>', todayDate5)
      query = query.where('trip.deliverySchedule.date', '<=', todayDate70)
      return query;
    };
      return this.db.collection<Trip>('trips', queryFn);
  }
  allTripLatePickupForCancellation(today, region) {
      const todayDate = moment(today).format('YYYY-MM-DD HH:mm');
      const todayDate70 = moment(today).subtract(70, 'minutes').format('YYYY-MM-DD HH:mm');
      const todayDate5add = moment(today).add(5, 'minutes').format('YYYY-MM-DD HH:mm');
      //console.log('allTripLatePickupForCancellation: less than '+todayDate70)
      const queryFn: QueryFn = (aRef) => {
        let query = aRef.orderBy('trip.deliverySchedule.date', 'asc').limit(500);
        if(region) {query = query.where('region', '==', region);}
        query = query.where('tripType', '==', 5);
        query = query.where('status', 'in', [2]);
        query = query.where('trip.deliverySchedule.type', '==', '1');
        query = query.where('trip.deliverySchedule.date', '<', todayDate5add)
        return query;
      };
      return this.db.collection<Trip>('trips', queryFn);
  }

  async allTripLateDropoff(today, region){
    const trips = []
    const tripid = []
    const todayDate = moment(today).toDate();
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('timestamps.trip_start_time', 'asc').limit(500);
      query = query.where('tripType', '==', 5);
      query = query.where('status', 'in', [4]); //trip started
      return query;
    };
    const ref = this.db.collection<Trip>('trips', queryFn);
    const requestDocs = await ref.get().toPromise();
    const requests = requestDocs.docs.map(r => r.data()) as Trip[];

    for(let x=0;x<requests.length;x++){
      const start_time = this.utils.dateFromTimestamp(requests[x].timestamps.trip_start_time);
      const stops_count = requests[x].tripDetails.hmFare.stops + 2;
      const eta = requests[x].tripDetails.hmFare.eta;
      const total_eta = (stops_count * 5) + eta;
      const time_difference = Math.abs(todayDate.getTime() - start_time.getTime());
      var diffMinutes = Math.ceil(time_difference / (1000 * 60)); // get minutes
      if(eta < diffMinutes) {
        requests[x].total_eta = total_eta
        trips.push(requests[x])
      }
      if(x==requests.length-1){
        return trips;
      }
    }

  }
  // MARK: Trip History
  allTripsRef(dateFilter?: number, vehicleType?: string, franchise_area?: string, tripType?: any, status?: number, startDate?: Date, endDate?: Date, drivername?: string,
              customername?: string, driverphone?: string, customerphone?: string,
              promocode?: string, tripid?: string, driverId?: string, orderNumber?: string, merchantReference?:string) {
      const queryFn: QueryFn = (aRef) => {
      const start_date = moment(startDate).format('YYYY-MM-DD HH:mm')
      const end_date = moment(endDate).format('YYYY-MM-DD HH:mm')

      let query = aRef.limit(500);
      query = query.orderBy('trip.tripDate', 'desc')
       if (dateFilter > 0) { // delivery schedule
          if (startDate) {query = query.where('trip.deliverySchedule.date', '>=', start_date)}
          if (endDate) {query = query.where('trip.deliverySchedule.date', '<=', end_date); }
        } 
        if (dateFilter < 1) {  // date booked
          if (startDate) {query = query.where('trip.tripDate', '>=', startDate)}
          if (endDate) {query = query.where('trip.tripDate', '<=', endDate)};
        }
       if (merchantReference) { query = query.where('merchantReference', '==', merchantReference); }
       if (status) { query = query.where('status', '==', status); }
       if (drivername) { query = query.where('driver.name', '==', drivername); }
       if (customername) { query = query.where('rider.name', '==', customername); }
       if (driverphone) { query = query.where('driver.phone', '==', '0' + driverphone); }
       if (customerphone) { query = query.where('rider.phone', '==', customerphone); }
       if (promocode) { query = query.where('trip.promoCode', '==', promocode); }
       if (tripid) { query = query.where('tripId', '==', tripid); }
       if (driverId) { query = query.where('driver.bikerId', '==', driverId); }
       if (orderNumber) { query = query.where('orderNumber', '==', orderNumber); }
       if (tripType!=0) { query = query.where('tripType', '==', parseInt(tripType)); }
       if (vehicleType && vehicleType!='All') { query = query.where('vehicle', '==', vehicleType); }
       if(franchise_area === 'Metro Manila' || franchise_area === 'Metro Cebu' || franchise_area === 'Baguio') { 
        query = query.where('region', '==', franchise_area); 
      } else {
        query = query.where('trip.settings.franchiseArea', '==', franchise_area);
        query = query.where('trip.settings.isFranchise', '==', 1); 
      }
      return query;
    };
      return this.db.collection<Trip>('trips', queryFn);
  }
  tripsHours(status?: number, startDate?: Date, endDate?: Date) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.tripDate', 'desc').limit(5000);
      if (status) { query = query.where('status', '==', status); }
      if (startDate) { query = query.where('trip.tripDate', '>=', startDate); }
      if (endDate) { query = query.where('trip.tripDate', '<=', endDate); }
      return query;
    };
    return this.db.collection<Trip>('trips', queryFn);
  }
  allTripsForDriverRef(driverId: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('driver.id', '==', driverId).orderBy('trip.tripDate', 'desc');
    const ref = this.db.collection<Trip>('trips', queryFn);
    return ref;
  }
  getAllDriverTrips(startDate?: Date, endDate?: Date, driverphone?: string, driverUid?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.tripDate', 'desc').limit(500);
      if (driverphone) { query = query.where('driver.phone', '==', '0' + driverphone); }
      if (driverUid) { query = query.where('driver.id', '==', driverUid); }
      if (startDate) { query = query.where('trip.tripDate', '>=', startDate); }
      if (endDate) { query = query.where('trip.tripDate', '<=', endDate); }
      return query;
    };
    return this.db.collection<Trip>('trips', queryFn);
  }
  async getAllDriverTripsExport(locationsDate: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('count', 'desc');
      return query;
    };
    const ref = this.db.collection('reports').doc('triplocations').collection(locationsDate, queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverLocationStat[];
    });
    return applications;
}
  async getDriverTripAvailability(phone?: string, bikerid?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('form.appliedDate', 'desc');
      if (phone) { query = query.where('personal.phone', '==', phone); }
      if (bikerid) { query = query.where('form.bikerId', '==', bikerid); }
      return query;
    };
    const ref = this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as OnboardingApplication[];
    });
    return applications;
  }
  // MARK: Cashouts
  async getAllCashoutRequests1(filter: WalletRequestFilter) {
    const queryFn: QueryFn = filter.query();
    const ref = this.db.collection<CashoutRequest>('driver_cashout_requests', queryFn);
    const requestDocs = await ref.get().toPromise();
    const requests = requestDocs.docs.map(r => r.data()) as CashoutRequest[];
    return requests;
  }
  async getAllCashoutRequests(filter: WalletRequestFilter) {
    const queryFn: QueryFn = filter.query();
    const ref = this.db.collection<CashoutRequest>('driver_cashout_requests', queryFn);
    const requestDocs = await ref.get().toPromise();
    const requests = requestDocs.docs.map(r => r.data()) as CashoutRequest[];

    const loop_count = Math.floor(requests.length/10) + 1
    let start_count_per_page = 0
    let batch_limit = 0
    let drivers = [];

    for(let batch=0; batch<loop_count; batch++) {
      batch_limit+=10
      let uid = [];

      for(let x=start_count_per_page;x<batch_limit && x<requests.length;x++){
          uid.push(requests[x].uid)

          if(x < batch_limit && x==requests.length-1) {
             const ref_drivers = this.db.collection('driver_registrations', (aRef) => {return aRef.where('uid', "in", uid)})
             const requestDocs_drivers = await ref_drivers.get().toPromise()
             const requests_drivers = requestDocs_drivers.docs.map(r => r.data()); 

             //consolidate all record from driver_registrations less than 10 record per batch
             requests_drivers.forEach(r_d => { drivers.push(r_d)  })
          }
          
          if(x==batch_limit-1) {
               const ref_drivers = this.db.collection('driver_registrations', (aRef) => {return aRef.where('uid', "in", uid)})
               const requestDocs_drivers = await ref_drivers.get().toPromise()
               const requests_drivers = requestDocs_drivers.docs.map(r => r.data()); 

               //consolidate all record from driver_registrations 10 record per batch
               requests_drivers.forEach(r_d => { drivers.push(r_d)  })
               start_count_per_page+=10
          }

          //if all drivers batch has been consolidated from driver_registrations
          if(x==requests.length-1) {
               for(let i=0; i<requests.length;i++) {
                 requests[i].paymayaNumber=''
                 const items = drivers.find(r_d => r_d['uid'] === requests[i].uid)
                 if(items != undefined) {
                   requests[i].paymayaNumber = items.personal.paymayaNumber != undefined ? items.personal.paymayaNumber : ''
                   requests[i].driver_status = items.form.mainStatus

                 }
                 
               }
          }
      }
      
    }

    return requests;
  }
  
  async getAllTopupRequests(filter: WalletRequestFilter) {
    const queryFn: QueryFn = filter.query();
    const ref = this.db.collection<TopupRequest>('driver_topup_requests', queryFn);
    const requestDocs = await ref.get().toPromise();
    const requests = requestDocs.docs.map(r => r.data()) as TopupRequest[];
    return requests;
  }
  async getWalletConfig() {
    const ref = this.db.collection('global_config').doc('wallet_settings');
    const doc = await ref.get().toPromise();
    return doc.data();
  }
  async setWalletConfig(config: WalletSettings) {
    const ref = this.db.collection('global_config').doc('wallet_settings');
    await ref.update(config);
  }
  getWalletHistory(walletId: string) {
    const ref = this.db.collection<WalletHistory>('driver_wallet_history', (aRef) => {
      return aRef.where('walletId', '==', walletId)
        .orderBy('updated_at', 'desc')
        .limit(100);
    });
    return ref;
  }
  async getGcashRegionList() {
    const ref = this.db.collection<GCashCity>('gCash_Cities');
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as GCashCity[];
    });
    return applications;
  }
  async getGcashCitiesbyRegion(region: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('REGION', '==', region);
    const ref = this.db.collection<GCashCity>('gCash_Cities', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as GCashCity[];
    });
    return applications;
  }
  async getGcashTownbyCity(region: string, city: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('REGION', '==', region).where('CITY', '==', city);
    const ref = this.db.collection<GCashCity>('gCash_Cities', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as GCashCity[];
    });
    return applications;
  }
  searchDriverGCash(startDate?: Date, endDate?: Date, region?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('gCash.updated_at', 'desc');
      if (startDate) { query = query.where('gCash.updated_at', '>=', startDate); }
      if (endDate) { query = query.where('gCash.updated_at', '<=', endDate); }
      if (region) { query = query.where('personal.region', '==', region); }
      return query;
    };
    return this.db.collection<OnboardingApplication>('driver_registrations', queryFn);
  }

  async hasIMEINumber(Imei: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('driver_registrations', ref =>
        ref.where('personal.imei', '==', Imei)).get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  async hasDriverPhoneNumber(Phone: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('driver_registrations', ref =>
        ref.where('personal.phone', '==', Phone)).get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  async hasGCashNumber(GCashNo: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('driver_registrations', ref =>
        ref.where('gCash.gcashnumber', '==', GCashNo)).get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  async hasGCashRefNumber(gcashRefId: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('driver_wallet_history', ref =>
        ref.where('otherInfo.gCashRefId', '==', gcashRefId)).get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  async hasPaymayaRefNumber(paymayaRefId: string): Promise<boolean> {
    try {
      const snapshot = await this.db.collection('driver_wallet_history', ref =>
        ref.where('otherInfo.requestReferenceNumber', '==', paymayaRefId)).get().toPromise();
      return snapshot.docs.length > 0;
    } catch (e) {
      return false;
    }
  }
  async getAllDriverRegistrationsBalance(region: string): Promise<DriverProfile[]> {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('wallet.amount', 'desc').where('personal.region', '==', region);
      return query;
    };
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  async getAllDriverWalletBalanceLow(region: string): Promise<DriverProfile[]> {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('wallet.amount', 'desc').where('personal.region', '==', region);
      query = query.where('wallet.amount', '<', 100);
      return query;
    };
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  allCashoutRequest(startDate?: Date, endDate?: Date, gcashNo?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('created_at', 'desc');
      if (startDate) { query = query.where('created_at', '>=', startDate); }
      if (endDate) { query = query.where('created_at', '<=', endDate); }
      if (gcashNo) { query = query.where('gCashPhone', '==', gcashNo); }
      return query;
    };
    return this.db.collection<CashoutRequest>('driver_cashout_requests', queryFn);
  }
  async updateSearchCrediriya(conditions: SearchConditions, id: string) {
    return this.db.collection('driver_wallet_history').doc(id)
      .set({
        name: conditions.fullName,
        phone: conditions.phone,
      },
        { merge: true });
  }
  async getProfileInfo(phone: string, gcash: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.where('personal.phone', '==', phone);
      return query;
    };
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  getAllWalletHistory(startDate?: Date, endDate?: Date) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc');
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
  }
  getAllWalletHistorySearchRegion(startDate?: Date, endDate?: Date, region?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc').where('region', '==', region);
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
  }
  getAllWalletHistorySearch(startDate?: Date, endDate?: Date) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc');
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      query = query.where('historyType', '==', 5);
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
  }
  getAllWalletHistoryByDriver(startDate?: Date, endDate?: Date, gCashPhone?: string, walletId?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'asc');
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      if (gCashPhone) { query = query.where('phone', '==', gCashPhone); }
      if (walletId) { query = query.where('walletId', '==', walletId); }
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
  }
getAllWalletCommissionSearchRegion(startDate?: Date, endDate?: Date, region?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc').where('region', '==', region);
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      query = query.where('historyType', '==', 3);
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
}
getAllWalletCommissionSearch(startDate?: Date, endDate?: Date) {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('updated_at', 'desc');
    if (startDate) { query = query.where('updated_at', '>=', startDate); }
    if (endDate) { query = query.where('updated_at', '<=', endDate); }
    query = query.where('historyType', '==', 3);
    return query;
  };
  return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
}
  exportTopupRequest(startDate?: Date, endDate?: Date, gcashNo?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('created_at', 'desc');
      if (startDate) { query = query.where('created_at', '>=', startDate); }
      if (endDate) { query = query.where('created_at', '<=', endDate); }
      if (gcashNo) { query = query.where('gCashPhone', '==', gcashNo); }
      return query;
    };
    return this.db.collection<TopupRequest>('driver_topup_requests', queryFn);
  }
  getAllDriverTripReportsRegion(fromDate?: string, region?: string) {
    const queryFn: QueryFn = (aRef) => aRef.where('region', '==', region);
    return this.db.collection('reports').doc('tripsummary').collection<DriverTripSummaryReport>(fromDate, queryFn);
  }
  getAllDriverTripReports(fromDate?: string) {
    return this.db.collection('reports').doc('tripsummary').collection<DriverTripSummaryReport>(fromDate);
  }
  async getAllActivatedDriverRegistrations(mainStatus: number, subStatus: number) {
    const queryFn: QueryFn = (isNumber(mainStatus)) ?
    (aRef) => aRef.where('form.mainStatus', '==', mainStatus).where('form.subStatus', '==', subStatus) : undefined;
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  async getAllActivatedDriverRegistrationsRegion(mainStatus: number, subStatus: number, region: string) {
    const queryFn: QueryFn = (isNumber(mainStatus)) ?
    (aRef) => aRef.where('form.mainStatus', '==', mainStatus).where('form.subStatus', '==', subStatus)
    .where('personal.region', '==', region) : undefined;
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  async allTimelocations(driverId: string, tripId: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('d', 'desc');
      return query;
    };
    const promise = this.db.collection<DriverTripLocations>('driver_trip_locations').doc(driverId).collection(tripId, queryFn);
    const applications = await promise.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverTripLocations[];
    });
    return applications;
  }
  dailyStatsForDriverReport(driverId: string, date: string) {
    const ref = this.db.doc('/statistics/' + driverId + '/daily/' + date);
    const applications = ref.get().toPromise().then(snapshot => {
      if (snapshot.exists) {
        return snapshot.data() as DriverTripStat[];
      } else {
      }
    });
    return applications;
  }
  updateTripReport(driverId: string, date: string, tripInfo: DriverTripStat, name: string, mobileno: string) {
    const payload = tripInfo as Tripsummary;
    payload.driverId = driverId;
    payload.name = name;
    payload.phone = mobileno;
    return this.db.collection('reports').doc('tripsummary').collection(date).doc(driverId).set(payload, { merge: true });
  }
  async getAllDriverTripsExports(startDate?: Date, endDate?: Date) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('trip.tripDate', 'desc');
      if (startDate) { query = query.where('trip.tripDate', '>=', startDate); }
      if (endDate) { query = query.where('trip.tripDate', '<', endDate); }
      return query;
    };
    const ref = this.db.collection<Trip>('trips', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as Trip[];
    });
    return applications;
  }
  getAllCustomer(startDate?: Date, endDate?: Date, lastResponse?: any, prviousStart?: any, firstResonse?: any,
                 limit?: number, status?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
      if (status === 'load') {
        query = aRef.orderBy('created_at', 'desc').limit(limit);
      }
      if (status === 'next') {
        query = aRef.orderBy('created_at', 'desc').limit(limit).startAfter(lastResponse);
      }
      if (status === 'previous') {
        query = aRef.orderBy('created_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
      }
      if (startDate) { query = query.where('created_at', '>=', startDate); }
      if (endDate) { query = query.where('created_at', '<=', endDate); }
      return query;
    };
    return this.db.collection<Customer>('customer_profiles', queryFn);
  }
  async getProfileInfotopup(gcashphone: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.where('gCash.gcashnumber', '==', gcashphone);
      return query;
    };
    const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as DriverProfile[];
    });
    return applications;
  }
  async searchDriverEMI(activeDate?: string, driverId?: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('updated_at', 'desc');
      return query;
    };
    const ref = this.db.collection<EMIWallet>('emi_history').doc(activeDate).collection('emi_history_deactivated', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as EMIWallet[];
    });
    return applications;
  }
  async searchDriverEMIRegion(activeDate?: string, driverId?: string, region?: string) {
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('updated_at', 'desc').where('region', '==', region);
      return query;
    };
    const ref = this.db.collection<EMIWallet>('emi_history').doc(activeDate).collection('emi_history_deactivated', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as EMIWallet[];
    });
    return applications;
  }
  getAllWalletHistoryByEmi(startDate?: Date, endDate?: Date, phone?: string, driverId?: string) {
    const queryFn: QueryFn = (aRef) => {
      let query = aRef.orderBy('updated_at', 'desc');
      if (startDate) { query = query.where('updated_at', '>=', startDate); }
      if (endDate) { query = query.where('updated_at', '<=', endDate); }
      if (phone) { query = query.where('phone', '==', phone); }
      if (driverId) { query = query.where('walletId', '==', driverId); }
      return query;
    };
    return this.db.collection<WalletHistory>('driver_wallet_history', queryFn);
  }
  async getOnboardingRegionList() {
    const ref = this.db.collection('global_config').doc('supported_regions_new');
    const doc = await ref.get().toPromise();
    return doc.data();
  }
   async getTripsBeforeTwohours() {
    const date = new Date();
    const tripDate1 = new Date(date.valueOf() - 4 * 1000 * 60 * 60);
    const tripDate2 = new Date(date.valueOf() - 3 * 1000 * 60 * 60);
    const queryFn: QueryFn = (aRef) => {
      const query = aRef.orderBy('trip.tripDate', 'desc').where('trip.tripDate', '>=', tripDate1).where('trip.tripDate', '<=', tripDate2);
      return query;
    };
    const ref = this.db.collection<Trip>('trips', queryFn);
    const applications = await ref.get().toPromise().then(data => {
      return data.docs.map(c => c.data()) as Trip[];
    });
    return applications;
  }
  allTripsRefPage(dateFilter?:number, vehicleType?:string, franchise_area?: string, driverId?: string, tripType?: any, status?: number, startDate?: Date,
                  endDate?: Date, drivername?: string, customername?: string, driverphone?: string, customerphone?: string,
                  promocode?: string, tripid?: string, lastResponse?: any, prviousStart?: any, firstResonse?: any,
                  limit?: number, statusvalue?: string, orderNumber?:string, merchantReference?:string) {
    const start_date = moment(startDate).format('YYYY-MM-DD HH:mm')
    const end_date = moment(endDate).format('YYYY-MM-DD HH:mm')
    const queryFn: QueryFn = (aRef) => {
     let query = aRef.limit(500);
     query = query.orderBy('trip.tripDate', 'desc')
      if (statusvalue === 'load') {
        query = aRef.limit(limit);
      }
      if (statusvalue === 'next') {
        query = aRef.limit(limit).startAfter(lastResponse);
      }
      if (statusvalue === 'previous') {
        query = aRef.limit(limit).startAt(prviousStart).endBefore(firstResonse);
      }
      
      if(franchise_area === 'Metro Manila' || franchise_area === 'Metro Cebu' || franchise_area === 'Baguio') { 
        query = query.where('region', '==', franchise_area); 
      } else {
        query = query.where('trip.settings.franchiseArea', '==', franchise_area);
        query = query.where('trip.settings.isFranchise', '==', 1); 
      }
      
      if (dateFilter > 0) { // delivery schedule
        if (startDate) {query = query.where('trip.deliverySchedule.date', '>=', start_date)}
        if (endDate) {query = query.where('trip.deliverySchedule.date', '<=', end_date); }
      } 
      if (dateFilter < 1) {  // date booked
        if (startDate) {query = query.where('trip.tripDate', '>=', startDate)}
        if (endDate) {query = query.where('trip.tripDate', '<=', endDate)};
      }

      if (tripType!=0) { query = query.where('tripType', '==', parseInt(tripType)); }
      if (merchantReference) { query = query.where('merchantReference', '==', merchantReference); }
      if (status) { query = query.where('status', '==', status); }
      if (drivername) { query = query.where('driver.name', '==', drivername); }
      if (customername) { query = query.where('rider.name', '==', customername); }
      if (driverphone) { query = query.where('driver.phone', '==', '0' + driverphone); }
      if (customerphone) { query = query.where('rider.phone', '==', customerphone); }
      if (promocode) { query = query.where('trip.promoCode', '==', promocode); }
      if (tripid) { query = query.where('tripId', '==', tripid); }
      if (driverId) { query = query.where('driver.bikerId', '==', driverId); }
      if (orderNumber) { query = query.where('orderNumber', '==', orderNumber); }
      if (vehicleType && vehicleType!='All') { query = query.where('vehicle', '==', vehicleType); }
      return query;
    };
    return this.db.collection<Trip>('trips', queryFn);
  }
  allTripsRefPageExport(dateFilter?:number, vehicleType?:string, franchise_area?: string, driverId?: string, tripType?: any, status?: number, startDate?: Date,
                        endDate?: Date, drivername?: string, customername?: string, driverphone?: string, customerphone?: string,
                        promocode?: string, tripid?: string, orderNumber?:string) {
const start_date = moment(startDate).format('YYYY-MM-DD HH:mm')
const end_date = moment(endDate).format('YYYY-MM-DD HH:mm')
const queryFn: QueryFn = (aRef) => {
let query = aRef.limit(500);
query = query.orderBy('trip.tripDate', 'desc')

if(franchise_area === 'Metro Manila' || franchise_area === 'Metro Cebu' || franchise_area === 'Baguio') { 
  query = query.where('region', '==', franchise_area); 
} else {
  query = query.where('trip.settings.franchiseArea', '==', franchise_area);
  query = query.where('trip.settings.isFranchise', '==', 1); 
}
if (dateFilter > 0) { // delivery schedule
  if (startDate) {query = query.where('trip.deliverySchedule.date', '>=', start_date)}
  if (endDate) {query = query.where('trip.deliverySchedule.date', '<=', end_date); }
} 
if (dateFilter < 1) {  // date booked
  if (startDate) {query = query.where('trip.tripDate', '>=', startDate)}
  if (endDate) {query = query.where('trip.tripDate', '<=', endDate)};
}
if (tripType!=0) { query = query.where('tripType', '==', parseInt(tripType)); }
if (status) { query = query.where('status', '==', status); }
if (drivername) { query = query.where('driver.name', '==', drivername); }
if (customername) { query = query.where('rider.name', '==', customername); }
if (driverphone) { query = query.where('driver.phone', '==', '0' + driverphone); }
if (customerphone) { query = query.where('rider.phone', '==', customerphone); }
if (promocode) { query = query.where('trip.promoCode', '==', promocode); }
if (tripid) { query = query.where('tripId', '==', tripid); }
if (driverId) { query = query.where('driver.bikerId', '==', driverId); }
if (orderNumber) { query = query.where('orderNumber', '==', orderNumber); }
if (vehicleType && vehicleType!='All') { query = query.where('vehicle', '==', vehicleType); }
return query;
};
return this.db.collection<Trip>('trips', queryFn);
}
  getAllDriverTripReportsRegionPaging(fromDate?: string, region?: string, driverId?: string, lastResponse?: any, prviousStart?: any,
                                      firstResonse?: any, limit?: number, statusvalue?: string, tripDate?: Date) {

    let query;
    const queryFn: QueryFn = (aRef) => {
    if (statusvalue === 'load') {
    query = aRef.limit(limit);
    }
    if (statusvalue === 'next') {
    query = aRef.limit(limit).startAfter(lastResponse);
    }
    if (statusvalue === 'previous') {
    query = aRef.limit(limit).startAt(prviousStart).endBefore(firstResonse);
    }
    if (this.utils.checkRegionReleaseDatePrevious(tripDate) === true) {
      if (region) {query = query.where('region', '==', region); }
    }
    if (driverId) {
      query = query.where('driverId', '==', driverId);
    }
    return query;
    };
    return this.db.collection('reports').doc('tripsummary').collection<DriverTripSummaryReport>(fromDate, queryFn);
  }
  getAllActiveDriverTripsExport(fromDate?: string, region?: string, driverId?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
      query = aRef.where('region', '==', region);
      if (driverId) {
        query = query.where('driverId', '==', driverId);
      }
      return query;
    };
    return this.db.collection('reports').doc('tripsummary').collection<DriverTripSummaryReport>(fromDate, queryFn);
  }

  async  tripSummaryReportRegion(date: [], region: string) {
    const ref = this.db.collection('statistics').doc('dashboard').collection(region);
    const query = date.map(el =>  ref.doc(el).valueChanges());
    let applications;
    return new Promise(resolve => {combineLatest(...query)
            .subscribe(res => {
             applications = res as DriverTripStat[];
             resolve(applications);
            });
        });
  }
  async driverEMIHistrory(date: []) {
    const queries = date.map(el => this.db.collection('emi_history').doc(el).collection('emi').valueChanges());
    let applications;
    return new Promise(resolve => {combineLatest(...queries)
          .subscribe(res => {
           applications = res as EMIWallet[];
           resolve(applications);
          });
      });
}
async hasBikerId(BikerId: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('driver_registrations', ref =>
      ref.where('gearActivation.bikerId', '==', BikerId)).get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async getCustomersbySummary(phone: string, fromDate: Date, toDate: Date): Promise<Customer[]> {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('updated_at', 'desc');
    if (phone) { query = query.where('phone', '==', phone); }
   // if (fromDate) { query = query.where('updated_at', '>=', fromDate); }
   // if (toDate) { query = query.where('updated_at', '<=', toDate); }
    return query;
  };
  const ref = this.db.collection<Customer>('customer_profiles', queryFn);
  const customers = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as Customer[];
  });
  return customers;
}
allCustomerTripsRef(uid?: string, startDate?: Date, endDate?: Date) {
const queryFn: QueryFn = (aRef) => {
let query = aRef.orderBy('trip.tripDate', 'desc').where('rider.id', '==', uid);
if (startDate) { query = query.where('trip.tripDate', '>=', startDate); }
if (endDate) { query = query.where('trip.tripDate', '<=', endDate); }
return query;
};
return this.db.collection<Trip>('trips', queryFn);
}
async allDriverLogProfile(startDate?: Date, endDate?: Date) {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('updated_at', 'desc');
    if (startDate) { query = query.where('updated_at', '>=', startDate); }
    if (endDate) { query = query.where('updated_at', '<=', endDate); }
    return query;
  };
  const ref = this.db.collection('audit_profile_update', queryFn);
  const driver = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverLogSearch[];
  });
  return driver;
}
allTimeDailyActivateDriver(date: string) {
  return this.db.collection('reports').doc('tripsummary').collection<DriverTripSummaryReport>(date);
}

driversAccount(phone?: string, referenceNumber?: string, fromDate?: Date, toDate?: Date,
               lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
  let query;
  const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
         query = aRef.orderBy('createDate', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('createDate', '>=', fromDate); }
  if (toDate) { query = query.where('createDate', '<=', toDate); }
  if (phone) { query = query.where('phonenumber', '==', phone); }
  if  (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
  return query;
  };
  return this.db.collection<DriversAccount>('driver_metrobank_token', queryFn);
}
bindTopUpHistory(phone?: string, referenceNumber?: string, amount?: number, type?: string, transactionCode?: string, fromDate?: Date
               , toDate?: Date, lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
  let query;
  const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
         query = aRef.orderBy('createDate', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('createDate', '>=', fromDate); }
  if (toDate) { query = query.where('createDate', '<=', toDate); }
  if (phone) { query = query.where('phone', '==', phone); }
  if  (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
  if  (amount) { query = query.where('amount', '==', amount); }
  if  (type) { query = query.where('Type', '==', type); }
  if  (transactionCode) { query = query.where('transactionCode', '==', transactionCode); }
  return query;
  };
  return this.db.collection<TopupHistory>('metrobank_topup_history', queryFn);
}
bindTopUpInquiry(phone?: string, referenceNumber?: string, amount?: number, type?: string, transactionCode?: string, fromDate?: Date,
                 toDate?: Date, lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
  let query;
  const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
         query = aRef.orderBy('createDate', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
         query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('createDate', '>=', fromDate); }
  if (toDate) { query = query.where('createDate', '<=', toDate); }
  if (phone) { query = query.where('phone', '==', phone); }
  if  (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
  if  (amount) { query = query.where('amount', '==', amount); }
  if  (type) { query = query.where('Type', '==', type); }
  if  (transactionCode) { query = query.where('transactionCode', '==', transactionCode); }
  return query;
  };
  return this.db.collection<TopupHistory>('metrobank_topupinquiry_history', queryFn);
}
loadMetroBankBindHistory(phone?: string, referenceNumber?: string, type?: string, fromDate?: Date, toDate?: Date,
                         lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
    if (statusvalue === 'load') {
           query = aRef.orderBy('createDate', 'desc').limit(limit);
    }
    if (statusvalue === 'next') {
           query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
    }
    if (statusvalue === 'previous') {
           query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
    }
    if (fromDate) { query = query.where('createDate', '>=', fromDate); }
    if (toDate) { query = query.where('createDate', '<=', toDate); }
    if (phone) { query = query.where('phonenumber', '==', phone); }
    if (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
    if (type) { query = query.where('Type', '==', type); }
    return query;
  };
    return this.db.collection<MetroBankBindHistroy>('metrobank_bindapi_history', queryFn);
}
loadMetroBankBindInquiryHistory(phone?: string, referenceNumber?: string, transactionCode?: string, fromDate?: Date, toDate?: Date,
                                lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
    if (statusvalue === 'load') {
           query = aRef.orderBy('metroBank_response.updateDate', 'desc').limit(limit);
    }
    if (statusvalue === 'next') {
           query = aRef.orderBy('metroBank_response.updateDate', 'desc').limit(limit).startAfter(lastResponse);
    }
    if (statusvalue === 'previous') {
           query = aRef.orderBy('metroBank_response.updateDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
    }
    if (fromDate) { query = query.where('metroBank_response.updateDate', '>=', fromDate); }
    if (toDate) { query = query.where('metroBank_response.updateDate', '<=', toDate); }
    if (phone) { query = query.where('phonenumber', '==', phone); }
    if (referenceNumber) { query = query.where('metroBank_response.referenceNo', '==', referenceNumber); }
    if (transactionCode) { query = query.where('metroBank_response.transactionCode', '==', transactionCode); }
    return query;
  };
    return this.db.collection('metrobank_bindinquiry_history', queryFn);
}
loadMetroBankUnBindHistory(phone?: string, referenceNumber?: string, type?: string, transactionCode?: string, fromDate?: Date,
                           toDate?: Date,  lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number,
                           statusvalue?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
    if (statusvalue === 'load') {
           query = aRef.orderBy('createDate', 'desc').limit(limit);
    }
    if (statusvalue === 'next') {
           query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
    }
    if (statusvalue === 'previous') {
           query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
    }
    if (fromDate) { query = query.where('createDate', '>=', fromDate); }
    if (toDate) { query = query.where('createDate', '<=', toDate); }
    if (phone) { query = query.where('phone', '==', phone); }
    if (type) { query = query.where('Type', '==', type); }
    if (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
    if (transactionCode) { query = query.where('transactionCode', '==', transactionCode); }
    return query;
  };
    return this.db.collection<MetroBankBindHistroy>('metrobank_unbindapi_history', queryFn);
}
async loadDriverWorkingHours(phone?: string,  driverId?: string, tripDate?: string) {
const queryFn: QueryFn = (aRef) => {
let query = aRef.orderBy('name');
if (phone) { query = aRef.where('phone', '==',  '0' + phone); }
if (driverId) { query = query.where('driverId', '==', driverId); }
return query;
};
let ref;
if (this.utils.checkDriverWrokingHoursPrevious(tripDate) === true) {
  ref = this.db.collection('reports').doc('tripsummary').collection(tripDate, queryFn);
} else {
  ref = this.db.collection('reports').doc('driverworkinghours').collection(tripDate, queryFn);
}
const applications = await ref.get().toPromise().then(data => {
  return data.docs.map(c => c.data()) as DriverWrokingHours[];
});
return applications;
}
paymayaWalletLinkHistory(phone?: string, referenceNumber?: string, driverId?: string,
                         linkId?: string, type?: string, fromDate?: Date, toDate?: Date,
                         lastResponse?: any, prviousStart?: any, firstResonse?: any,
                         limit?: number, statusvalue?: string) {
    let query;
    const queryFn: QueryFn = (aRef) => {
      if (statusvalue === 'load') {
        query = aRef.orderBy('created_at', 'desc').limit(limit);
      }
      if (statusvalue === 'next') {
        query = aRef.orderBy('created_at', 'desc').limit(limit).startAfter(lastResponse);
      }
      if (statusvalue === 'previous') {
        query = aRef.orderBy('created_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
      }
      if (fromDate) { query = query.where('created_at', '>=', fromDate); }
      if (toDate) { query = query.where('created_at', '<=', toDate); }
      if (phone) { query = query.where('phone', '==', '0' + phone); }
      if (referenceNumber) { query = query.where('referenceNo', '==', referenceNumber); }
      if (driverId) { query = query.where('driverId', '==', driverId); }
      if (linkId) { query = query.where('linkId', '==', linkId); }
      if (type) { query = query.where('Type', '==', type); }
      return query;
  };
    return this.db.collection<PaymayaReports>('paymaya_driver_wallet_link', queryFn);
}
paymayaDriverWalletLinkHistory(phone?: string, type?: string, driverId?: string, linkId?: string, fromDate?: Date, toDate?: Date,
                               lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
 let query;
 const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
        query = aRef.orderBy('update_at', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
        query = aRef.orderBy('update_at', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
        query = aRef.orderBy('update_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('update_at', '>=', fromDate); }
  if (toDate) { query = query.where('update_at', '<=', toDate); }
  if (phone) { query = query.where('phone', '==', '0' + phone); }
  if (type) { query = query.where('Type', '==', type); }
  if (driverId) { query = query.where('driverId', '==', driverId); }
  if (linkId) { query = query.where('linkId', '==', linkId); }
  return query;
  };
 return this.db.collection<PaymayaReportWallet>('paymaya_wallet_link_history', queryFn);
}
paymayaTopupLinkHistory(uid?: string, phone?: string, fromDate?: Date, toDate?: Date,
                        lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
 let query;
 const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
        query = aRef.orderBy('createDate', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
        query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
        query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('createDate', '>=', fromDate); }
  if (toDate) { query = query.where('createDate', '<=', toDate); }
  if (uid) { query = query.where('uid', '==', uid); }
  if (phone) { query = query.where('phone', '==',  phone); }
  return query;
  };
 return this.db.collection<any>('paymaya_topup_history', queryFn);
}
paymayaInquiryHistory(uid?: string, phone?: string, fromDate?: Date, toDate?: Date,
                      lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
 let query;
 const queryFn: QueryFn = (aRef) => {
  if (statusvalue === 'load') {
        query = aRef.orderBy('createDate', 'desc').limit(limit);
  }
  if (statusvalue === 'next') {
        query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
  }
  if (statusvalue === 'previous') {
        query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
  }
  if (fromDate) { query = query.where('createDate', '>=', fromDate); }
  if (toDate) { query = query.where('createDate', '<=', toDate); }
  if (uid) { query = query.where('uid', '==', uid); }
  if (phone) { query = query.where('phone', '==', phone); }
  return query;
  };
 return this.db.collection<any>('paymaya_topupinquiry_history', queryFn);
}
paymayaTopupWebhookReports() {
let query;
const queryFn: QueryFn = (aRef) => {
  query = aRef.orderBy('updatedAt', 'desc');
  query = query.where('isWebHookProcess', '==', false);
  return query;
};
return this.db.collection<any>('paymaya_topup_webhook_history', queryFn);
}
editPromoCode(promo: NewPromoCode, docID: string) {
  const payload = promo as PromoCode;
  return this.db.collection('promo_codes').doc(docID).set(payload);
}
paymayaTransactionsFees(uid?: string, phone?: string, referenceNo?: string, fromDate?: Date, toDate?: Date,
                        lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('updated_at', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (fromDate) { query = query.where('updated_at', '>=', fromDate); }
if (toDate) { query = query.where('updated_at', '<=', toDate); }
if (uid) { query = query.where('walletId', '==', uid); }
if (phone) { query = query.where('phone', '==',  phone); }
if (referenceNo) {query = query.where('otherInfo.requestReferenceNumber', '==' , referenceNo); }
query = query.where('updateType', '==', 1).where('historyType', '==' , 7);
return query;
};
return this.db.collection<any>('driver_wallet_history', queryFn);
}
createNewHolidaySurcharge(holiday: HolidaySurcharge) {
  const payload = holiday as HolidaySurcharge;
  const docID = this.db.createId();
  payload.id = docID;
  return this.db.collection('holiday_fee').doc(docID).set(payload);
}

createNewHmHolidaySurcharge(holiday: HolidaySurcharge) {
  const payload = holiday as HolidaySurcharge;
  const docID = this.db.createId();
  payload.id = docID;
  return this.db.collection('hmdeliver_holiday').doc(docID).set(payload);
}

async getCustomerAPPServices(): Promise<any[]> {
  const ref = this.db.collection<any>('global_config')
        .doc('customerapp_service_availablity')
        .collection('services');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

editCustomerAPPServices(customerservice: CustomerAPPServices, docID: string) {
  const emailID =  firebase.auth().currentUser.email;
  const payload = customerservice as CustomerAPPServices;
  payload.updated_by = emailID;
  payload.updated_on = new Date();
  return this.db.collection<any>('global_config')
  .doc('customerapp_service_availablity')
  .collection('services').doc(docID).set(payload);
}
updatePaymayaNumberUpdationStatus(paymayaService: PayMayaNumberUpdation, docID: string) {
  const emailID =  firebase.auth().currentUser.email;
  const payload = paymayaService as PayMayaNumberUpdation;
  payload.updated_by = emailID;
  payload.updated_on = new Date();
  return this.db.collection<any>('global_config')
  .doc('paymaya_number_update_config')
  .set(payload);
}
async getPaymayaNumberUpdationStatus() {
  const ref = this.db.collection('global_config')
              .doc('paymaya_number_update_config');
  const doc = await ref.get().toPromise();
  return doc.data();
}

editNewHolidaySurcharge(holiday: HolidaySurcharge, docID: string) {
  const payload = holiday as HolidaySurcharge;
  return this.db.collection('holiday_fee').doc(docID).set(payload);
}
editNewHmHolidaySurcharge(holiday: HolidaySurcharge, docID: string) {
  const payload = holiday as HolidaySurcharge;
  return this.db.collection('hmdeliver_holiday').doc(docID).set(payload);
}
deactivateHolidayStatus(docID: string) {
  return this.db.collection('holiday_fee').doc(docID).update({ is_active: false });
}
deactivateHmHolidayStatus(docID: string) {
  return this.db.collection('hmdeliver_holiday').doc(docID).update({ is_active: false });
}
async hasHoliday(holidayDate: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('holiday_fee', ref => ref.where('holiday_date', '==', holidayDate))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async hasHmHoliday(holidayDate: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('hmdeliver_holiday', ref => ref.where('hmdeliver_holiday', '==', holidayDate))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
deleteHolidaySurcharge(docID: string) {
  return this.db.collection('holiday_fee').doc(docID).delete();
}
deleteHmHolidaySurcharge(docID: string) {
  return this.db.collection('hmdeliver_holiday').doc(docID).delete();
}
globalFareSettingsRegion(tripRegion?: string, tripType?: string, pcity?: string) {
  if (tripType !== 'Taxicle') {
  return this.db.collection('global_config').doc('fare_settings').collection(tripRegion).doc<FareSettings>(tripType);
  } else {
    // return this.db.collection('global_config').doc('fare_settings').collection(tripRegion).doc<FareSettings>('Tricycle');
    return this.db.collection('global_config').doc('fare_settings').collection(tripRegion).doc('Tricycle').collection('pickupcities').
    doc<FareSettings>(pcity);
  }
}
updateFareSettingsValues(newSettings: FareSettings, tripRegion?: string, tripType?: string, pcity?: string) {
  newSettings.updated_at = new Date();
  if (tripType !== 'Taxicle') {
  return this.db.collection('global_config').doc('fare_settings').collection(tripRegion).doc(tripType).set(newSettings, { merge: true });
  } else {
    return this.db.collection('global_config').doc('fare_settings').collection(tripRegion).
    doc('Tricycle').collection('pickupcities').doc(pcity).set(newSettings, { merge: true });
  }
}
updateFareSettingsHMDeliveryValues(newSettings: any, area?: string, vehicletype?: string) {
  newSettings.updated_at = new Date();
  newSettings.lastUpdated = new Date();
  return this.db.collection('franchise_area').doc(area).collection('HMDelivery').doc(vehicletype).set(newSettings, { merge: true });
}

async locationTripOnline() {
  const queryFn: QueryFn = (aRef) => {
  let query;
  query = aRef.where('d.online', '==', true);
  query = query.where('d.status', '==', 2);
  return query;
  };
  const ref = this.db.collection<any>('locations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverTripStatus[];
  });
  const returndata = applications.filter(item => item.d.tId === null);
  return returndata;
}
async locationTripOffline() {
  const queryFn: QueryFn = (aRef) => {
  let query ;
  query = aRef.where('d.online', '==', false);
  query = query.where('d.status', '==', 2);
  return query;
  };
  const ref = this.db.collection<any>('locations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverTripStatus[];
  });
  const returndata = applications.filter(item => item.d.tId !== undefined && item.d.tId !== null);
  return returndata;
}
async locationTripOnlineFalse() {
  const queryFn: QueryFn = (aRef) => {
  let query ;
  query = aRef.where('d.online', '==', false);
  query = query.where('d.status', '==', 2);
  return query;
  };
  const ref = this.db.collection<any>('locations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverTripStatus[];
  });
  const returndata = applications.filter(item => item.d.tId === null);
  return returndata;
}
async locationTripOnlineTrue() {
  const queryFn: QueryFn = (aRef) => {
  let query ;
  query = aRef.where('d.online', '==', true);
  return query;
  };
  const ref = this.db.collection<any>('locations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverTripStatus[];
  });
  const returndata = applications.filter(item => item.d.tId === null && item.d.region === null && item.d.coordinates === null);
  return returndata;
}
async createTricyclePickupCities(tricyclePickup: TricyclePickup, region: string) {
  const payload = tricyclePickup as TricyclePickup;
  const docID = this.db.createId();
  payload.id = docID;
  this.db.collection('global_config').doc('tricycle_pickup_cities').collection(region).doc(docID).set(payload);

  const ref = this.db.collection('global_config').doc('supported_regions_tricycle');
  const applications = await ref.get().toPromise().then(doc => {
    return doc.data() as any[];
  });
  if (!Object.keys(applications).some((key) => applications[key].includes(payload.pickup_city))) {
  Object.keys(applications).forEach(key => {
    const length = applications[key].length;
    applications[key][length] = payload.pickup_city;
    this.db.collection('global_config').doc('supported_regions_tricycle')
    .update(applications);

  });
  }
  return true;
}
editTricyclePickupCities(tricyclePickup: TricyclePickup, region: string, docID: string) {
  const payload = tricyclePickup as TricyclePickup;
  return this.db.collection('global_config').doc('tricycle_pickup_cities').collection(region).doc(docID).set(payload);
}
async hasPickupCity(region: string, city: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('global_config').doc('tricycle_pickup_cities')
    .collection(region, ref => ref.where('pickup_city', '==', city))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async hasPickupCityCode(region: string, code: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('global_config').doc('tricycle_pickup_cities')
    .collection(region, ref => ref.where('pickup_city_code', '==', code))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async getTricyclePickupCities(region: string): Promise<any[]> {
  const ref = this.db.collection<any>('global_config').doc('tricycle_pickup_cities').collection(region);
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

deactivateTricyclePickupCities(docID: string, region: string) {
  return this.db.collection('global_config').doc('tricycle_pickup_cities').collection(region).doc(docID).update({ is_active: false });
}

deleteTricyclePickupCities(docID: string, region: string) {
  return this.db.collection('global_config').doc('tricycle_pickup_cities').collection(region).doc(docID).delete();
}

async createTricycleDropoffCities(tricycleDropoff: TricycleDropoff, region: string) {
  const payload = tricycleDropoff as TricycleDropoff;
  const docID = this.db.createId();
  payload.id = docID;
  this.db.collection('global_config').doc('tricycle_dropoff_cities').collection(region).doc(docID).set(payload);
  const ref = this.db.collection('global_config').doc('supported_regions_tricycle');
  const applications = await ref.get().toPromise().then(doc => {
    return doc.data() as any[];
  });
  if (!Object.keys(applications).some((key) => applications[key].includes(payload.dropoff_city))) {
  Object.keys(applications).forEach(key => {
    const length = applications[key].length;
    applications[key][length] = payload.dropoff_city;
    this.db.collection('global_config').doc('supported_regions_tricycle')
    .update(applications);

  });
  }
  return true;
}
editTricycleDropoffCities(tricycleDropoff: TricycleDropoff, region: string, docID: string) {
  const payload = tricycleDropoff as TricycleDropoff;
  return this.db.collection('global_config').doc('tricycle_dropoff_cities').collection(region).doc(docID).set(payload);
}

async hasPickupCityCodeDropoff(region: string, pickucity: string, dropoffcity: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('global_config').doc('tricycle_dropoff_cities')
    .collection(region, ref => ref.where('pickup_city', '==', pickucity).where('dropoff_city', '==', dropoffcity))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async getTricycleDropoffCities(region: string): Promise<any[]> {
  const ref = this.db.collection<any>('global_config').doc('tricycle_dropoff_cities').collection(region);
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

deactivateTricycleDropoffCities(docID: string, region: string) {
  return this.db.collection('global_config').doc('tricycle_dropoff_cities').collection(region).doc(docID).update({ is_active: false });
}

deleteTricycleDropoffCities(docID: string, region: string) {
  return this.db.collection('global_config').doc('tricycle_dropoff_cities').collection(region).doc(docID).delete();
}
async getProfileInfoActive() {

  const queryFn: QueryFn = (aRef) => {
    let query;
    query = aRef.where('personal.region', '==', 'Metro Manila').limit(10000);
    // query = aRef.where('uid', '==', '1vdFTuXzsL1UfU6bUagB');
    query = aRef.where('form.mainStatus', '==', 0);
    query = aRef.where('permission_services.mctaxi', '==', true);
    // query = aRef.where('personal.vehicle_type', '==', 11);
   // query = aRef.orderBy('form.appliedDate', 'desc');
    return query;
  };
  const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverProfile[];
  });
  // const returndata = applications.filter(item => item.permission_services === undefined);
  // return returndata;
  return applications;
}
async getProfileInfoActiveNew(lastResponse: any) {

  const queryFn: QueryFn = (aRef) => {
    let query;
    query = aRef.where('form.mainStatus', '==', 1);
    query = aRef.where('form.subStatus', '==', 11);
    query = aRef.orderBy('form.activationDate', 'desc').limit(10).startAfter(lastResponse);
    return query;
  };
  const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverProfile[];
  });
  // const returndata = applications.filter(item => item.permission_services === undefined);
  // return returndata;
  return applications;
}

async updateServicePermission(driverId: string, phone: string, email: string, firstname: string, lastname: string, mctaxi: boolean,
                              delivery: boolean, pabili: boolean,
                              tricycle: boolean, remarks: string) {

    // await this.db.collection('driver_registrations').doc(driverId)
    // .update({
    //   'personal.vehicle_type': 'motorcycle'
    // });
  // await this.db.collection('driver_registrations').doc(driverId)
  //   .update({
  //     'permission_services.driverId': driverId,
  //     'permission_services.phone': phone,
  //     'permission_services.mctaxi': mctaxi,
  //     'permission_services.delivery': delivery,
  //     'permission_services.pabili': pabili,
  //     'permission_services.tricycle': tricycle,
  //     'permission_services.remarks': remarks,
  //   });
    await this.db.collection('driver_registrations').doc(driverId)
    .update({
      'permission_services.mctaxi': mctaxi,
    });
    await  this.db.collection('locations').doc(driverId).update({
  ['d.availablity.mctaxi']: firebase.firestore.FieldValue.delete()
});
    // await this.db.collection('locations').doc(driverId)
    // .update({
    //   'd.phone': phone,
    //   'd.firstName': firstname,
    //   'd.lastName': lastname,
    // });
    return Promise.resolve();
}

async getDriverInfo(uid: string) {
  const queryFn: QueryFn = (aRef) => {
    const query = aRef.where('uid', '==', uid);
    return query;
  };
  const ref = this.db.collection<DriverProfile>('driver_registrations', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as DriverProfile[];
  });
  return applications;
}
tripRefDriver(uid: string) {
  return this.db.collection<DriverProfile>('driver_registrations').doc(uid);
}
getCustomer(customerId: string) {
  const ref = this.db.collection<Customer>('customer_profiles').doc(customerId);
  return ref;
}
getCustomerByWalletAdj(customerId?: string) {
  const queryFn: QueryFn = (aRef) => aRef.where('uid', '==', customerId);
  const ref = this.db.collection<Customer>('customer_profiles', queryFn);
  return ref;
}
getCustomerWalletHistory(walletId: string) {
  const ref = this.db.collection<WalletHistory>('customer_wallet_history', (aRef) => {
    return aRef.where('walletId', '==', walletId)
      .orderBy('updated_at', 'desc')
      .limit(100);
  });
  return ref;
}
async getAllCustomerRegistrationsBalance(): Promise<Customer[]> {
  const queryFn: QueryFn = (aRef) => {
    const query = aRef.orderBy('amount', 'desc');
    return query;
  };
  const ref = this.db.collection<Customer>('customer_profiles', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as Customer[];
  });
  return applications;
}

async getAllCustomerRegistrationsLowBalance(): Promise<Customer[]> {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('amount', 'desc');
    query = query.where('amount', '<', 100);
    return query;
  };
  const ref = this.db.collection<Customer>('customer_profiles', queryFn);
  const applications = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as Customer[];
  });
  return applications;
}
paymayaCustomerTopupLinkHistory(uid?: string, phone?: string, fromDate?: Date, toDate?: Date,
                                lastResponse?: any, prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('createDate', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('createDate', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('createDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (fromDate) { query = query.where('createDate', '>=', fromDate); }
if (toDate) { query = query.where('createDate', '<=', toDate); }
if (uid) { query = query.where('uid', '==', uid); }
if (phone) { query = query.where('phone', '==',  phone); }
return query;
};
return this.db.collection<any>('customer_paymaya_topup_history', queryFn);
}
getAllCustomerWalletHistorySearchPage(startDate?: Date, endDate?: Date, lastResponse?: any
  ,                                   prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('updated_at', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (startDate) { query = query.where('updated_at', '>=', startDate); }
if (endDate) { query = query.where('updated_at', '<=', endDate); }
// query = query.where('historyType', '==', 8);
return query;
};
return this.db.collection<any>('customer_wallet_history', queryFn);

}
getAllCustomerWalletHistoryByCustomerPage(startDate?: Date, endDate?: Date, gCashPhone?: string, walletId?: string, lastResponse?: any,
                                          prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('updated_at', 'asc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updated_at', 'asc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updated_at', 'asc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (startDate) { query = query.where('updated_at', '>=', startDate); }
if (endDate) { query = query.where('updated_at', '<=', endDate); }
if (gCashPhone) { query = query.where('phone', '==', gCashPhone); }
if (walletId) { query = query.where('walletId', '==', walletId); }
return query;
};
return this.db.collection<any>('customer_wallet_history', queryFn);
}
async getUserClaimsId(): Promise<any[]> {
  const ref = this.db.collection<any>('user_claims_settings');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}
async claimData() {
  return this.db.collection('user_claims_settings').doc<Claim>('PduCRWQSp9hasnndrNmj');
}
async getUserClaims(name: string, Id: string): Promise<any[]> {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('updated_at', 'desc');
    if (name) {query = aRef.where('name', '==', name); }
    if (Id) {query = aRef.where('id', '==', Id); }
    return query;
  };
  const ref = this.db.collection<any>('user_claims_settings', queryFn);
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}
getUserClaimsSearch(name: string, Id: string, lastResponse?: any, 
                    prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('name', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('name', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('name', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (name) {query = aRef.where('name', '==', name); }
if (Id) {query = aRef.where('id', '==', Id); }
return query;
};
return this.db.collection<any>('user_claims_settings', queryFn);
}
deleteUserClaims(docID: string) {
  return this.db.collection('user_claims_settings').doc(docID).delete();
}
async hasClaimName(claimName: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('user_claims_settings', ref => ref.where('name', '==', claimName))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async hasRole(role: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('user_roles', ref => ref.where('roleName', '==', role))
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}
async createUserClaim(userClaim: UserClaimAdd) {
  const payload = userClaim as UserClaimAdd;
  const docID = this.db.createId();
  payload.id = docID;
  await this.db.collection('user_claims_settings').doc(docID).set(payload);
}
async editUserClaim(userClaim: UserClaimAdd, docID: string) {
  const payload = userClaim as UserClaimAdd;
  payload.id = docID;
  await this.db.collection('user_claims_settings').doc(docID).update(payload);
}
async hasClaimDetails(role: string): Promise<boolean> {
  try {
    const snapshot = await this.db.collection('user_role_settings').doc(role).collection('claims')
    .get().toPromise();
    return snapshot.docs.length > 0;
  } catch (e) {
    return false;
  }
}

//ADDED BY CHARM
async getCustomersbyPromocode(promoid: string){
  const ref = this.db.collection('promo_codes').doc(promoid).collection('customers');
  const requestDocs = await ref.get().toPromise();
  const requests = requestDocs.docs.map(r => r.data());
  return requests;
}

async searchCustomersbyPhoneNo(phone: string){
  const queryFn: QueryFn = (aRef) => aRef.where('phone', '==', phone);
  const ref = this.db.collection<Customer>('customer_profiles', queryFn);
  const requestDocs = await ref.get().toPromise();
  const requests = requestDocs.docs.map(r => r.data());
  return requests;  
}

async createUserPromoCode(users: NewUserPromo, promoid: string) {
  const payload = users as NewUserPromo;
  const promoId = promoid;
  const userId = payload.id;
  this.db.collection('promo_codes').doc(promoId).collection('customers').doc(userId).set(payload)
}

deleteUserPromo(userId: string, promoId:string) {
  return this.db.collection('promo_codes').doc(promoId).collection('customers').doc(userId).delete();
}

async saveAdminClaim(userClaim: ClaimSettingsValue, roleName: string, roleValue: string) {
  const payload = userClaim as ClaimSettingsValue;
  const docID = payload.id;
  payload.id = payload.id;
  payload.roleName = roleName;
  payload.roleValue = roleValue;
  await this.db.collection('user_role_settings').doc(roleName).collection('claims').doc(docID).set(payload);
}
async saveAdminClaimUpdate(userClaim: ClaimSettingsValue, docID: string, roleName: string, roleValue: string) {
  const payload = userClaim as ClaimSettingsValue;
  payload.roleName = roleName;
  payload.roleValue = roleValue;
  await this.db.collection('user_role_settings').doc(roleName).collection('claims').doc(payload.id).set(payload);
}
async getRoles(): Promise<any[]> {
  const ref = this.db.collection<any>('user_roles');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}
async getClaimSetting(role: string, name: string, Id: string): Promise<any[]> {
  const queryFn: QueryFn = (aRef) => {
    let query = aRef.orderBy('updated_at', 'desc');
    if (name) {query = aRef.where('name', '==', name); }
    if (Id) {query = aRef.where('id', '==', Id); }
    return query;
  };
  const ref = this.db.collection<any>('user_role_settings').doc(role).collection('claims', queryFn);
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}
getClaimSettingSearch(role: string, name: string, Id: string, lastResponse?: any,
                      prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('updated_at', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (name) {query = aRef.where('name', '==', name); }
if (Id) {query = aRef.where('id', '==', Id); }
return query;
};
return this.db.collection<any>('user_role_settings').doc(role).collection('claims', queryFn);
}
getRolesSearch(roleName: string, roleValue: string, Id: string, lastResponse?: any,
               prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('roleValue', 'asc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updated_at', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}
if (roleName) {query = aRef.where('roleName', '==', roleName); }
if (roleValue) {query = aRef.where('roleValue', '==', roleValue); }
if (Id) {query = aRef.where('id', '==', Id); }
return query;
};
return this.db.collection<any>('user_roles', queryFn);
}
async getClaimSettingNew(role: string): Promise<any[]> {
  const ref = this.db.collection<any>('user_role_settings').doc(role).collection('claims');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

deleteUserRoles(docID: string) {
  return this.db.collection('user_roles').doc(docID).delete();
}
async createUserRoles(roles: Roles) {
  const payload = roles as Roles;
  const docID = this.db.createId();
  payload.id = docID;
  await this.db.collection('user_roles').doc(docID).set(payload);
}
async editUserRoles(roles: Roles, docID: string) {
  const payload = roles as Roles;
  payload.id = docID;
  await this.db.collection('user_roles').doc(docID).update(payload);
}
async checkUserClaims(role: string, name: string): Promise<boolean> {
  try {
  const snapshot = await this.db.collection('user_role_settings').doc(role).collection('claims',
                   ref => ref.where('name', '==', name).where('status', '==', true)) .get().toPromise();
  return snapshot.docs.length > 0;
} catch (e) {
  return false;
}
}
async getCustomerSpecialRate(uid: string): Promise<CustomerSpecialRate[]> {
  const ref = this.db.collection<CustomerSpecialRate>('user_special_rates').doc(uid).collection('customRates');
  const customersRate = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as CustomerSpecialRate[];
  });
  return customersRate;
}

async updateBulkCustomerSpecialRate(user_special_rates: any) {
  const user_rate = user_special_rates;
  let payload = [];
  for(let x=0; x<user_rate.length; x++){
    const ref = this.db.collection('customer_profiles', (aRef) =>aRef.where('phone', "==", user_rate[x].phone_no.toString()).limit(1));
    const ref_docs = await ref.get().toPromise();
    const requests = ref_docs.docs.map(r => r.data());
    const uid = requests[0].uid;
    user_rate[x].uid = uid;
    user_rate[x].success = 'SUCCESS'
    this.processCustomerRateBulk(user_rate[x], uid)
  }
}

async processCustomerRateBulk(payload: CustomerSpecialRate, uid: string) {  
  try {
  const snapshot = await this.db.collection('user_special_rates').doc(uid).collection('customRates',
                   ref => ref
                   .where('location', '==', payload.location)
                   .where('rateType', '==', payload.rateType)
                   .where('service', '==', payload.service)
                   .where('vehicle', '==', payload.vehicle))
                   .get().toPromise();

    if(snapshot.docs.length == 0) {
      this.insertNewCustomerRate(payload, uid)
    } else {
     const requests = snapshot.docs.map(r => r.data());
     this.updateCustomerRate(requests[0] as CustomerSpecialRate, payload.value,  uid)
    }
  } catch (e) {
    return false;
  }
}

async createNewCustomerRate(payload: CustomerSpecialRate, uid: string) {
  try {
  const snapshot = await this.db.collection('user_special_rates').doc(uid).collection('customRates',
                   ref => ref
                   .where('location', '==', payload.location)
                   .where('rateType', '==', payload.rateType)
                   .where('service', '==', payload.service)
                   .where('vehicle', '==', payload.vehicle))
                   .get().toPromise();

    if(snapshot.docs.length == 0) {
      this.insertNewCustomerRate(payload, uid)
    } else {
      return snapshot.docs.length;
    }
  } catch (e) {
    return false;
  }
}

insertNewCustomerRate(payload: CustomerSpecialRate, uid: string) {
  const data = {};
  data['location'] =  payload.location
  data['rateId'] = payload.rateId
  data['rateType'] = payload.rateType
  data['service'] = payload.service
  data['status'] = payload.status
  data['value'] = payload.value
  data['vehicle'] = payload.vehicle
  const docID = this.db.createId();
  data['rateId'] = docID;
  data['status'] = 1;

  return this.db.collection('user_special_rates').doc(uid).collection('customRates').doc(docID).set(data);
}

updateCustomerRate(payload: CustomerSpecialRate, value: number, uid: string) {
  const docID = payload.rateId;
  return this.db.collection('user_special_rates').doc(uid).collection('customRates').doc(docID).update({value: value });
}

deleteCustomerRate(uid: string, docID: string) {
  return this.db.collection('user_special_rates').doc(uid).collection('customRates').doc(docID).delete();
}

async createNewsArticles(newsArticle: NewsArticle) {
  const payload = newsArticle as NewsArticle;
  const docID = this.db.createId();
  payload.id = docID;
  this.db.collection('news_articles').doc(docID).set(payload);
}

async editNewsArticles(newsArticle: NewsArticle, docID: any) {
  const payload = newsArticle as NewsArticle;
  this.db.collection('news_articles').doc(docID).set(payload);
}

deleteNewsArticles(docID: string) {
  return this.db.collection('news_articles').doc(docID).delete();
}

async getNewsArticles(): Promise<any[]> {
  const ref = this.db.collection<any>('news_articles');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

async getCarouselBanner(): Promise<any[]> {
  const ref = this.db.collection<any>('carousel_banner');
  const codes = await ref.get().toPromise().then(data => {
    return data.docs.map(c => c.data()) as any[];
  });
  return codes;
}

async createCarouselBanner(carouselBanner: CarouselBanner) {
  const payload = carouselBanner as CarouselBanner;
  const docID = this.db.createId();
  payload.bannerName = payload.bannerName.toUpperCase();
  payload.id = docID;
  this.db.collection('carousel_banner').doc(docID).set(payload);
}

async editCarouselBanner(carouselBanner: CarouselBanner, docID: string) {
  const payload = carouselBanner as CarouselBanner;
  payload.bannerName = payload.bannerName.toUpperCase();
  this.db.collection('carousel_banner').doc(docID).set(payload);
}

deleteCarouselBanner(docID: string) {
  return this.db.collection('carousel_banner').doc(docID).delete();
}

getNewsArticleSearch(id: string, newsAuthor: string, newsType: string, newsUserType: string, newsStatus: string, newsRegion: string, lastResponse?: any,
                     prviousStart?: any, firstResonse?: any, limit?: number, statusvalue?: string) {
let query;
const queryFn: QueryFn = (aRef) => {
if (statusvalue === 'load') {
query = aRef.orderBy('createDate', 'desc').limit(limit);
}
if (statusvalue === 'next') {
query = aRef.orderBy('updateDate', 'desc').limit(limit).startAfter(lastResponse);
}
if (statusvalue === 'previous') {
query = aRef.orderBy('updateDate', 'desc').limit(limit).startAt(prviousStart).endBefore(firstResonse);
}

if (id) {query = aRef.where('id', '==', id); }
if (newsAuthor) {query = aRef.where('newsAuthor', '==', newsAuthor); }
if (newsType) {query = aRef.where('newsType', '==', newsType); }
if (newsUserType) {query = aRef.where('newsUserType', '==', newsUserType); }
if (newsStatus === 'active') {query = aRef.where('isActive', '==', true); }
if (newsStatus === 'inactive') {query = aRef.where('isActive', '==', false); }
if (newsRegion) {query = aRef.where('region', '==', newsRegion); }

return query;
};

return this.db.collection<any>('news_articles', queryFn);
}

getCarouselBannerSearch(status: string, bannerName?: string, region?:string) {
let query;
const queryFn: QueryFn = (aRef) => {
  query = aRef.orderBy('createDate', 'desc').limit(500)
  if (!status) {query = aRef.where('isActive', 'in', [true, false]); }
  if (status) {query = aRef.where('isActive', '==', this.utils.convertStatusBoolean(status)); }
  if (bannerName) {
    query = query.where('bannerName', '>=', bannerName.toUpperCase())
    query = query.where('bannerName', '<=', bannerName.toUpperCase()+ "\uf8ff")
  }
  if (region) {
    query = query.where('region', '==', region)
  }

  return query;
};

return this.db.collection<any>('carousel_banner', queryFn);

}

getOtpSettings() {
  const ref = this.db.doc('/global_config/otp_settings').get().toPromise();
  return ref;
}

saveOtpSettings(provider: string) {
  return this.db.collection('global_config').doc('otp_settings').update({ otp_provider: provider });
}


}
