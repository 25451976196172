import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder } from '@angular/forms';
import {  AlertModel } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { CustomerWalletHistoryListener } from 'src/app/api/customer-wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import {  DriverProfilePaginator } from 'src/app/classes/paginator';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
@Component({
  selector: 'app-customer-export-wallet-adjustment',
  templateUrl: './customer-adjustments.component.html'
})
export class CustomerWalletAdjustmentComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  walletHistory: WalletHistory[];
  filter = new WalletHistoryFilter();
  alert: AlertModel;
  searchConditionsForm: FormGroup;
  walletHistoryListener: CustomerWalletHistoryListener;
  wallethistoryLoading: boolean;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  selectedFilter = 50;
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  pageCount: any;
  constructor(private fb: FormBuilder, private dbService: DatabaseService, private utils: UtilsService,
              public permissions: PermissionService) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.wallethistoryLoading = false;
    this.walletHistory = [];
    this.paginationCount = 0;
    this.walletHistoryListener = new CustomerWalletHistoryListener(this.dbService, (success, history) => {
      if (success && history) {
        //  this.walletHistory = history.filter(val => val.historyType >= 5);
         this.walletHistory = history;
         this.getPaginations();
         this.wallethistoryLoading = false;
     } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
    });
  }
  ngOnInit() {
    this.searchConditionsForm = this.fb.group({
      fullName: [],
      phone: []
    });
  }
  getPaginations() {
    if (this.selectedFilter > this.walletHistory.length) {
      this.disableInitial = false;
    }
    if (this.pageCount === 'previous') {
      this.paginationCount--;
    } else if (this.pageCount === 'next') {
      this.paginationCount++;
    }
    this.disableLoad = false;
    this.disableNext = false;
    this.disablePrevious = false;
  }
  applyFilter(buttonAction: string) {
    this.wallethistoryLoading = true;
    this.disableLoad = true;
    this.disableInitial = true;
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.walletHistoryListener.getallWalletHistorysearchPage(this.filter.from.selectedDate,
      this.filter.to.selectedDate, Number(this.selectedFilter), buttonAction);
  }
  filterpage(status: number) {
    this.selectedFilter = status;
  }
export() {
    const filename = () => 'Wallet Adjustment and Topup Manuals' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.customerwalletHistory());
    exporter.exportCustomerWalletAdjustmentandTopupManual(this.walletHistory as WalletHistory[]);
  }
  commentsForWalletHistory(historyItem: WalletHistory) {
    if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
  }
  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
}
class WalletHistoryFilter {
  status: number;
  from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
  to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());
  constructor() {
  }
  reset() {
    this.from = new DatePickerModel(moment().startOf('day').toDate());
    this.to = new DatePickerModel(moment().endOf('day').toDate());
    this.status = undefined;
  }
}
