import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { CreateAdminUserAPI, CreateAdminUserRequest } from 'src/app/api/user.api';
import { ApiService } from 'src/app/services/api.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  newUserForm: FormGroup;
  alert: AlertModel;
  defaultRegion: any;
  onboardingRegions = [];

  // APIs
  createUserAPI: CreateAdminUserAPI;

  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder) {
      this.createUserAPI = new CreateAdminUserAPI(apiService, (success, message) => {
        this.showAlert(success, message);
        this.newUserForm.enable();
      });
      this.onboardingRegions = OnboardingUtils.getRegions();
      this.defaultRegion = this.onboardingRegions[0];
  }

  ngOnInit() {
    this.createNewUserForm();
  }

  // Private

  canSave(): boolean {
    return this.newUserForm.valid && this.newUserForm.dirty;
  }

  createNewUserForm() {
    this.newUserForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      region: ['']
    });
  }

  canHideSupportText(control: AbstractControl) {
    const c = control as FormControl;
    return c.disabled || c.valid || !c.touched;
  }

  private showAlert(success: boolean, message: string) {
    const type = success ? 'success' : 'danger';
    this.alert = { type, message } as AlertModel;
    setTimeout(() => {
      this.alert = null;
      this.activeModal.close('success');
    }, 3000);
  }

  // Actions

  createUser() {
    const payload = this.newUserForm.value;
    this.newUserForm.disable();
    this.createUserAPI.createUser(payload as CreateAdminUserRequest);
  }
}
