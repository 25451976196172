import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { GCashImport, PermissionService , ServiceSettings} from 'src/app/interfaces/interfaces';
import { DriversListAPI, DriverProfileUpdateAPI } from 'src/app/api/driverList.api';
import { DriverProfile } from 'src/app/interfaces/interfaces';
import * as fs from 'file-saver';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-import-permission',
  templateUrl: './import-permission-services.component.html'
})
export class ImportPermissionDataComponent implements OnInit {
  public arrFinalData = [];
  public arrFinalStatusWithData = [];
  importGCashForm: FormGroup;
  loopCount = 0;
  data: any;
  isDisabledImport = false;
  isConfirm = false;
  isConfirmDisable = true;
  isConfirmExport = true;
  serviceSettings: ServiceSettings;
  isLoad = false;
  isLoadSuccess = false;
  tempDriverUid: any;
  lastInResponse: any = [];
  constructor(
    private dbService: DatabaseService,
    private apiService: ApiService, private fb: FormBuilder, private db: AngularFirestore) {
  }
  ngOnInit() {

  }

  async confirmData() {
    this.isLoad = true;
    this.isLoadSuccess = true;
    await this.dbService.getProfileInfoActive().then(forms => {
        console.log('Total Drivers', forms);
        this.arrFinalData = forms;

      }).catch(err => {
        console.log(err);
      });
    for (let i = 0; i <= this.arrFinalData.length - 1; i++) {
      console.log('Drivers', i);
      await this.importDriverProfileData(this.arrFinalData[i], i);
    }
    this.isConfirmExport = true;
    this.isLoad = false;
    await this.timer();
  }


  async importDriverProfileData(arrFinalData: any, iVal) {
    // tslint:disable-next-line: max-line-length
    if (this.arrFinalData[iVal].personal.phone !== undefined) {
        // this.serviceSettings = {
        //   driverId: '' ,
        //   phone: '',
        //   mctaxi: true,
        //   delivery: true,
        //   pabili: true,
        //   tricycle: false,
        //   remarks: '',
        // };
        // await this.apiService.updateServiceSettings(this.arrFinalData[iVal].uid,
        //   this.arrFinalData[iVal].personal.phone,  this.serviceSettings);

        await this.dbService.updateServicePermission(this.arrFinalData[iVal].uid,
            this.arrFinalData[iVal].personal.phone, this.arrFinalData[iVal].personal.email, this.arrFinalData[iVal].personal.firstname,
            this.arrFinalData[iVal].personal.lastname, Boolean(false),  Boolean(true),  Boolean(true),
            Boolean(false), '');

    }
  }
  async timer() {
  setTimeout(() => {
    alert('Successfully Updated');
     // this.confirmData();
  }, 5000);
}

}
