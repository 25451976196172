import { BaseAPI, APIState } from './base.api';
import { DatabaseService } from '../services/database.service';
import { Subscription } from 'rxjs';
import { PaymayaReports, PaymayaReportWallet } from '../interfaces/interfaces';
import { ApiService } from '../services/api.service';

export class PayMayaAPI extends BaseAPI {
    private handler: ([PaymayaReports]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([PaymayaReports]) => void) {
        super(dbService);
        this.handler = completion;
    }
    bindWalletLink(phone?: string, referenceNumber?: string, driverId?: string, linkId?: string,
                   type?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaWalletLinkHistory(phone, referenceNumber, driverId, linkId, type,
            fromDate, toDate, this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
            .then(response => {
                this.firstInResponse = response.docs[0];
                this.lastInResponse = response.docs[response.docs.length - 1];
                this.previousStart.push(this.firstInResponse);
                if (statusValue === 'next') {
                    this.paginationCount++;
                }
                this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
                if (statusValue === 'previous') {
                    this.paginationCount--;
                    this.previousStart.forEach(element => {
                        if (this.firstInResponse.data().id === element.data().id) {
                            element = null;
                        }
                    });
                }
                this.handler(response.docs.map(c => c.data()) as [PaymayaReports]);
            }).catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }

}

export class PayMayaWalletAPI extends BaseAPI {
    private handler: ([PaymayaReportWallet]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([PaymayaReportWallet]) => void) {
        super(dbService);
        this.handler = completion;
    }
    bindWalletLinkHistory(phone?: string, type?: string, driverId?: string, linkId?: string, fromDate?: Date,
                          toDate?: Date, limit?: number, statusValue?: string ) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaDriverWalletLinkHistory(phone, type, driverId, linkId, fromDate, toDate,
            this.lastInResponse, previousValue, this.firstInResponse, limit, statusValue).get().toPromise()
        .then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [PaymayaReports]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}

}
export class PayMayaTopupHistoryAPI extends BaseAPI {
    private handler: ([any]) => void;
    private unsubscribe: Subscription;
    public firstInResponse: any;
    public lastInResponse: any;
    public previousStart: any = [];
    public paginationCount = 0;
    constructor(dbService: DatabaseService, completion: ([any]) => void) {
        super(dbService);
        this.handler = completion;
    }
    bindTopupHistory(uid?: string, phone?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaTopupLinkHistory(uid, phone, fromDate, toDate, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
    bindTopupInquiry(uid?: string,  phone?: string, fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaInquiryHistory(uid, phone, fromDate, toDate, this.lastInResponse, previousValue,
            this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
                this.firstInResponse = response.docs[0];
                this.lastInResponse = response.docs[response.docs.length - 1];
                this.previousStart.push(this.firstInResponse);
                if (statusValue === 'next') {
                    this.paginationCount++;
                }
                this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
                if (statusValue === 'previous') {
                    this.paginationCount--;
                    this.previousStart.forEach(element => {
                        if (this.firstInResponse.data().id === element.data().id) {
                            element = null;
                        }
                    });
                }
                this.handler(response.docs.map(c => c.data()) as [any]);
            }).catch(e => {
                console.error(e);
                this.state = APIState.failed;
            });
    }
paymayaTransactionsFees(uid?: string, phone?: string, referenceNo?: string,
                        fromDate?: Date, toDate?: Date, limit?: number, statusValue?: string) {
        this.state = APIState.loading;
        let previousValue;
        if (statusValue === 'previous') {
            if (this.previousStart.length > (this.paginationCount + 1)) {
                previousValue = this.previousStart.splice(this.previousStart.length - 2, this.previousStart.length - 1);
            }
            previousValue = this.previousStart[this.paginationCount - 1];
        }
        if (statusValue === 'load') {
            this.firstInResponse = [];
            this.lastInResponse = [];
            this.previousStart = [];
        }
        this.dbService.paymayaTransactionsFees(uid, phone, referenceNo, fromDate, toDate, this.lastInResponse, previousValue,
        this.firstInResponse, limit, statusValue).get().toPromise().then(response => {
            this.firstInResponse = response.docs[0];
            this.lastInResponse = response.docs[response.docs.length - 1];
            this.previousStart.push(this.firstInResponse);
            if (statusValue === 'next') {
                this.paginationCount++;
            }
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;
            if (statusValue === 'previous') {
                this.paginationCount--;
                this.previousStart.forEach(element => {
                    if (this.firstInResponse.data().id === element.data().id) {
                        element = null;
                    }
                });
            }
            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
bindTopupWebhook() {
    this.state = APIState.loading;

    this.dbService.paymayaTopupWebhookReports().get().toPromise().then(response => {
            this.state = (response.docs.map(c => c.data()).length === 0) ? APIState.successWithoutData : APIState.successWithData;

            this.handler(response.docs.map(c => c.data()) as [any]);
        }).catch(e => {
            console.error(e);
            this.state = APIState.failed;
        });
}
}


export class PaymayaTopupInquiry extends BaseAPI {
    private handler: (success: boolean, message: {}) => void;
    constructor(apiService: ApiService, completion: (success: boolean, message: {}) => void) {
        super(null, apiService);
        this.handler = completion;
    }
   payamayaTopupInquiry(referenceNumber: string) {
        this.state = APIState.loading;
        this.apiService.paymayaTopupInquiry(referenceNumber)
        .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const successMessage = 'The status of the  requests were updated successfully.';
                const isSuccess = response;
                if (response) {
                    this.state = APIState.successWithData;
                    this.handler(true, response);
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
                return true;
            })
            .catch(err => {
                const failureMessage = 'There was an error while updating the  requests. Please try again.';
                console.error(err);
                this.state = APIState.failed;
                this.handler(false, failureMessage + err);
            });
    }

}
export class PaymayaWebhookUpdateAPI extends BaseAPI {
    handler: (success: boolean, message: string) => void;

    constructor(apiService: ApiService, completion: (success: boolean, message: string) => void) {
        super(null, apiService);
        this.handler = completion;
    }
    updatePaymayawebhook(webhook: any) {

        this.state = APIState.loading;
        this.apiService.updateTopupWebhook(webhook)
            .then(response => {
                const success = 'isSuccess';
                const error = 'error';
                const message = 'message';
                const isSuccess = response[success];
                if (isSuccess) {
                    this.state = APIState.successWithData;
                    this.handler(true, 'The biker\'s paymaya topup webhook details were updated successfully');
                } else {
                    this.state = APIState.failed;
                    this.handler(false, response[error][message]);
                }
            })
            .catch(e => {
                console.error(e);
                this.state = APIState.failed;
                this.handler(false, 'There was an error while saving  paymaya topup webhook. Please try again');
            });
    }
}
