import { Component, OnInit } from '@angular/core';
import {  TripListReportAPI } from 'src/app/api/trip.api';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverLocationStat, DriverProfile, LocationDriver } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { Trip } from 'src/app/interfaces/trip-interfaces';
import { CSVExporter } from 'src/app/classes/CSVExporter';
@Component({
  selector:  'app-driver-trip-location',
  templateUrl: './driver-trip-location.component.html',
  styleUrls: ['./trip-summary-search.component.css']
})
export class TripOnlineReportComponent implements OnInit {
    tripListReportAPI: TripListReportAPI;
    // trips: DriverLocationStat[];
    trips: LocationDriver[];
    drivers: Trip[];
    phoneNumber: any;
   constructor(private dbService: DatabaseService, public utils: UtilsService, private modalService: NgbModal,
               public permissions: PermissionService) {
      this.trips = [];
      this.tripListReportAPI = new TripListReportAPI(dbService, (trips) => {
        this.trips = [];
        this.trips = trips;
     });
     }
  ngOnInit() {
    this.tripListReportAPI.getDriverTripsStatus();
    }
    // applyFilter() {
    //   this.trips = [];
    //   this.tripListReportAPI.getDriverTripsStatus();
    // }
    getStatusName(status: number): string | null {
      return OnboardingUtils.getStatusName(status);
    }
    export() {
      const filename = () => 'Driver Online Stuck Report' + (new Date()).toLocaleDateString();
      const exporter = new CSVExporter(filename(), CSVExporter.driverOnlineStuckReport());
      exporter.exportDriverOnlineStuckReport(this.trips as LocationDriver[]);
    }
     async getDriverName(uid: string) {
      if(uid !== undefined) {
      await this.dbService.getDriverInfo(uid).then(drivers => {
      const datafilter = 'personal';
      this.phoneNumber = Object(drivers)[datafilter].email;
      if (this.phoneNumber !== undefined && this.phoneNumber !== '' && this.phoneNumber !== null) {
        return this.phoneNumber;
      } else {
        return '';
      }
      }).catch(err => {
        return '';
        console.log(err);
      }).finally(() => {
      });
    } else {
      return '';
    }
  }
// async Check(uid: string) {
//   await this.dbService.tripRefDriver(uid).get()
//   .subscribe(driver => {
//     console.log("Value", driver);
//     return '';
   
//   }, e => {
//       console.error(e);
//   });
// }

}
