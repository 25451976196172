import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { Customer } from 'src/app/interfaces/interfaces';
import { Paginator } from 'src/app/classes/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerProfileComponent } from 'src/app/modals/customer/customer-profile/customer-profile.component';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { PermissionService } from 'src/app/services/permission.service';
import { CustomerAPI } from 'src/app/api/customer.api';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  paginator: Paginator<Customer> = null;
  loading: boolean = false;
  filter = new FormControl('');
  customerphone  = new FormControl('');
  firstName  = new FormControl('');
  emailId  = new FormControl('');
  api: CustomerAPI;
  filterBlock = (text: string, item: Customer) => {
    const searchText = text.toLocaleLowerCase();
    const name = [item.firstName, item.lastName].join(' ');
    return name.toLocaleLowerCase().includes(searchText)
       || item.phone.toLocaleLowerCase().includes(searchText);
  }
  constructor(private dbService: DatabaseService, private modalService: NgbModal,
              public utils: UtilsService, public permissions: PermissionService,
              public userService: UserService) {
                this.api = new CustomerAPI(dbService, (customer) => {
                  this.paginator = new Paginator(1, 10, customer, this.filterBlock);
                });
   }
  get customers(): Customer[] {
    return (this.paginator) ? this.paginator.getItems(this.filter.value) : null;
  }
   get disablePagination(): boolean {
    return this.filter.value !== '';
  }
  rowNumber(index: number): number {
    return this.paginator.itemNumberForIndex(index);
  }
  createdDate(customer: Customer): string {
    if (customer.created_at) { return this.utils.formatDate(customer.created_at.toDate()); } else { return ''; }
  }
  ngOnInit() {
  }
  loadCustomers() {
      this.loading = true;
      this.api.loadCustomerSearch(this.customerphone.value, this.firstName.value, this.emailId.value);
  }
  openCustomer(customer: Customer) {
    const modalRef = this.modalService.open(CustomerProfileComponent, { size: 'lg' });
    modalRef.componentInstance.customer = customer;
  }
  export() {
    const customers =   (this.filter.value) ? this.paginator.getItems(this.filter.value) : this.paginator.collection;
    const filename = () => 'registered_customers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.customerProfileTitles());
    exporter.exportCustomers(customers as Customer[]);
  }
  get canExport(): boolean {
    if (!this.paginator) { return false; }
    const allRequests = this.paginator.collection;
    return (allRequests && allRequests.length > 0);
  }
}
