import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DriverProfile, AlertModel } from 'src/app/interfaces/interfaces';
import { WalletHistory } from 'src/app/interfaces/wallet.interfaces';
import { DriverWalletAdjustmentAPI } from 'src/app/api/fareSettings.api';
import { ApiService } from 'src/app/services/api.service';
import { DriverProfileAPI, DriversListAPI } from 'src/app/api/driverList.api';
import { DatabaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DriverWalletHistoryListener } from 'src/app/api/wallet.api';
import { PermissionService } from 'src/app/services/permission.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
@Component({
    selector: 'app-driver-emi-adjustment',
    templateUrl: './driver-emi-adjustment.component.html',
    styleUrls: ['./driver-emi-adjustment.component.css']
})
export class DriverEMIAdjustmentComponent implements OnInit {
    driverProfile: DriverProfile;
    walletHistory: WalletHistory[];
    emiAdjustmentForm: FormGroup;
    phone = new FormControl('');
    alert: AlertModel;
    emiAdjustmentAPI: DriverWalletAdjustmentAPI;
    walletHistoryListener: DriverWalletHistoryListener;
    driverProfileAPI: DriverProfileAPI;
    driversListAPI: DriversListAPI;
    driverId: any;
    constEMIOutstandingtotal: any;
    checkEMIOutstandingTotal: boolean;
    emiOutStandingToal: any;
    checkAmoutGraterEMITotal: boolean;
    loading: any;
    constructor(private fb: FormBuilder, private apiService: ApiService, private dbService: DatabaseService, private utils: UtilsService,
                public permissions: PermissionService) {
        this.walletHistory = [];
        this.checkEMIOutstandingTotal = true;
        this.emiAdjustmentAPI = new DriverWalletAdjustmentAPI(apiService, (success, message) => {
            const alertType = success ? 'success' : 'danger';
            this.showAlert(alertType, message);
            this.emiAdjustmentForm.enable();
            if (success) {
                this.emiAdjustmentForm.reset();
                this.emiAdjustmentForm.markAsPristine();
            }
        });
        this.driverProfileAPI = new DriverProfileAPI(this.dbService, true, (success, profile) => {
            if (success && profile) {
                this.driverProfile = profile;
                this.loading = false;
                this.emiAdjustmentForm.controls.driverId.setValue(this.driverProfile.uid);
                this.walletHistoryListener.beginListening(profile.uid);
                this.constEMIOutstandingtotal = OnboardingUtils.getEMIConstAmount(this.driverProfile.personal.region);
                this.verifyEMITotal();
                } else if (success) {
                this.showAlert('danger', 'No Biker was found with the searched ID');
                } else { this.showAlert('danger', 'There was an unexpected error while retrieving the Biker profile'); }
        });
        this.walletHistoryListener = new DriverWalletHistoryListener(this.dbService, (success, history) => {
            this.walletHistory = [];
            if (success && history) { this.walletHistory = history;
            } else { this.showAlert('danger', 'There was an unexpected error while fetching the Wallet History.'); }
        });
        this.driversListAPI = new DriversListAPI(dbService, (drivers) => {
            if (drivers[0] === undefined) { return; } else {
            this.driverId =  drivers[0].uid;
            this.getEMIAdjustmentInformation(this.driverId);
            }
        });
    }
    ngOnInit() {
        this.createEMIAdjustmentForm();
    }
    ngOnDestroy(): void {
        this.driverProfileAPI.endListening();
        this.walletHistoryListener.endListening();
    }
    verifyEMITotal() {
          if (this.driverProfile.emi_details !== undefined) {
          if (this.driverProfile.emi_details.outstandingTotal === 0) {
                this.checkEMIOutstandingTotal = false;
                this.emiOutStandingToal = this.driverProfile.emi_details.outstandingTotal;
            } else {
                this.checkEMIOutstandingTotal = true;
                this.emiOutStandingToal = this.driverProfile.emi_details.outstandingTotal;
            }
        } else {
            this.checkEMIOutstandingTotal = true;
            this.emiOutStandingToal = this.constEMIOutstandingtotal;
        }
    }
    private async loadDriverApplications(phone?: string, gcash?: string) {
        this.driversListAPI.loadDriverProfile(phone, gcash);
    }
    search() {
         this.loading = true;
         this.loadDriverApplications(this.phone.value, this.phone.value);
    }
    getEMIAdjustmentInformation(driverId) {
       this.driverProfileAPI.fetchDriverProfile(driverId);
    }
    private createEMIAdjustmentForm() {
        this.emiAdjustmentForm = this.fb.group({
            driverId: ['', Validators.required],
            amount: [0, [Validators.required, Validators.minLength(2), Validators.maxLength(4)]],
            reason: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]]
        });
    }
    canHideSupportText(control: AbstractControl) {
        const c = control as FormControl;
        return c.untouched || c.disabled || c.valid;
    }
     canSaveEMIAdjustments(): boolean {
        return this.emiAdjustmentForm.valid && this.emiAdjustmentForm.dirty &&
         this.emiOutStandingToal >= this.emiAdjustmentForm.controls.amount.value;
    }
      commentsForWalletHistory(historyItem: WalletHistory) {
        if (!historyItem.otherInfo) { return 'N/A'; } else { return this.utils.optional(historyItem.otherInfo.reason, 'N/A'); }
    }
    saveEMIAdjustments(driverid: string) {
        this.emiAdjustmentForm.disable();
        this.emiAdjustmentForm.controls.amount.setValue(parseInt(this.emiAdjustmentForm.controls.amount.value));
        this.emiAdjustmentAPI.adjustEMIAmount(driverid, this.emiAdjustmentForm.value);
    }
    cancelEMIAdjustments() {
        this.driverProfileAPI.endListening();
        this.walletHistoryListener.endListening();
        this.createEMIAdjustmentForm();
    }
    showAlert(type: string, message: string) {
        this.alert = { type, message } as AlertModel;
        setTimeout(() => this.alert = null, 3000);
    }
    getStatusName(status: number): string | null {
        return OnboardingUtils.getStatusName(status);
      }
      getSubStatusName(status: number): string | null {
        return OnboardingUtils.getSubStatusName(status);
      }
}
