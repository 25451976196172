import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { DriverTripReportAPI, DriverTripReportExportAPI } from 'src/app/api/trip.api';
import { DriverTripSummaryReport } from 'src/app/interfaces/trip-interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import { FormControl } from '@angular/forms';
import { PermissionService } from 'src/app/services/permission.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-driver-report',
  templateUrl: './driver-active-report.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.css']
})
export class DriverActiveReportComponent implements OnInit {
  dailyStatDate: Date = new Date();
  tripListAPI: DriverTripReportAPI;
  driverTripReportExportAPI: DriverTripReportExportAPI;
  trips = [];
  selectedFilter = 50;
  firstInResponse: any = [];
  lastInResponse: any = [];
  previousStart: any = [];
  paginationCount = 0;
  disableNext = false;
  disablePrevious = false;
  disableLoad = false;
  disableInitial = true;
  onboardingRegions = [];
  region = new FormControl();
  defaultRegion: any;
  tripType = new FormControl();
  driverId = new FormControl('');
  defaultTripType: any;
  tripTypeValue: number;
  pageCount: any;
  tripTotalValue = [];
  tripExport = [];
  tripsExportAll = [];
  constructor(private dbService: DatabaseService, public utils: UtilsService, public datepipe: DatePipe,public permissions: PermissionService
  ) {
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.defaultRegion = this.onboardingRegions[0];
    this.trips = [];
    this.defaultTripType = this.utils.tripTypes[0];
    this.tripTypeValue = this.defaultTripType;
    this.tripListAPI = new DriverTripReportAPI(dbService, (trips) => {
      this.tripTotalValue = trips.filter(val => val.name !== undefined);
      this.trips = this.tripTotalValue.filter(val => val.numTripsCompleted >= 1 || val.numTripsCompleted_delivery >= 1
      || val.numTripsCompleted_pabili >= 1);
      this.getPaginations();
    });
    this.driverTripReportExportAPI = new DriverTripReportExportAPI(dbService, (tripsexport) => {
      this.tripExport = tripsexport.filter(val => val.name !== undefined);
      this.tripsExportAll = this.tripExport.filter(val => val.numTripsCompleted >= 1 || val.numTripsCompleted_delivery >= 1
      || val.numTripsCompleted_pabili >= 1);
    });
  }
  ngOnInit() {
  }
  filterpage(status: number) {
    this.selectedFilter = status;
}
   searchDriverTripReport(buttonAction: string) {
    this.disableLoad = true;
    this.disableInitial = true;
    const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
    this.tripTypeValue = Number(this.tripType.value);
    if (buttonAction === 'load') {  this.pageCount = 'load';  this.paginationCount = 0; }
    if (buttonAction === 'previous') {  this.pageCount = 'previous', this.disablePrevious = true; }
    if (buttonAction === 'next') {  this.pageCount = 'next', this.disableNext = true; }
    this.tripListAPI.getDriverTripReportsPaging(dayId, this.region.value, this.driverId.value,
    this.selectedFilter, buttonAction, this.dailyStatDate);
  }
  /*fillterTypeValues() {
      if (Number(this.tripType.value) === 1) {
      this.trips = this.tripTotalValue.filter(val => val.numTripsCompleted >= 1);
      this.trips = this.trips.filter(val => val.name !== undefined);
      this.getPaginations();
      } else if (Number(this.tripType.value) === 2) {
      this.trips = this.tripTotalValue.filter(val => val.numTripsCompleted_delivery >= 1);
      this.trips = this.trips.filter(val => val.name !== undefined);
      this.getPaginations();
      } else if (Number(this.tripType.value) === 3) {
        this.trips = this.tripTotalValue.filter(val => val.numTripsCompleted_pabili >= 1);
        this.trips = this.trips.filter(val => val.name !== undefined);
        this.getPaginations();
        }
      this.tripTypeValue = Number(this.tripType.value);
  }*/
  getPaginations() {
      if (this.selectedFilter > this.tripTotalValue.length) {
        this.disableInitial = false;
      }
      if (this.pageCount === 'previous') {
        this.paginationCount--;
      } else if (this.pageCount === 'next') {
        this.paginationCount++;
      }
      this.previousStart = [];
      this.disableLoad = false;
      this.disableNext = false;
      this.disablePrevious = false;
  }
  export() {
    const filename = () => 'Active_Drivers_Report_MCTaxi' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.drivertripReport());
    exporter.exportActiveDriverSummary(this.trips as DriverTripSummaryReport[]);
    /*if (this.tripTypeValue === this.utils.tripTypes[0]) {
    } else if (this.tripTypeValue === this.utils.tripTypes[1]) {
      const filename = () => 'Active_Drivers_Report_Delivery' + (new Date()).toLocaleDateString();
      const exporter = new CSVExporter(filename(), CSVExporter.drivertripReport());
      exporter.exportActiveDriverSummaryDelivery(this.trips as DriverTripSummaryReport[]);
    } else if (this.tripTypeValue === this.utils.tripTypes[2]) {
      const filename = () => 'Active_Drivers_Report_Pabili' + (new Date()).toLocaleDateString();
      const exporter = new CSVExporter(filename(), CSVExporter.drivertripReport());
      exporter.exportActiveDriverSummaryPabili(this.trips as DriverTripSummaryReport[]);
    }*/
  }
  async exportAll() {
    const dayId = moment(this.dailyStatDate).format('YYYY-MM-DD');
    await this.driverTripReportExportAPI.getDriverTripReportsExport(dayId, this.region.value, this.driverId.value);
    this.allExportValues();
  }
  allExportValues() {
    setTimeout(() => {
    const filename = () => 'Active_Drivers_Report_MCTaxi' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.drivertripReport());
    exporter.exportActiveDriverSummary(this.tripsExportAll as DriverTripSummaryReport[]);
  }, 2000);
  }
  optional(val?: any, defaultVal?: string) {
    const defaultValue = (defaultVal) ? defaultVal : ' ';
    return (val !== null && val !== undefined) ? val : defaultValue;
  }
  optionalNumber(val?: number) {
    return (val == null || val === undefined) ? '' : Number(val.toFixed(2));
  }
  optionalround(val: number, round: boolean = false, defaultVal: string = '') {
    return (val !== undefined) ? (round ? val.toFixed(2) : val.toFixed(2)) : defaultVal;
  }
  additions(val1: number, val2: number, val3: number, val4: number) {
    if (val1 === undefined) { val1 = 0; }
    if (val2 === undefined) { val2 = 0; }
    if (val3 === undefined) { val3 = 0; }
    if (val4 === undefined) { val4 = 0; }
    const sum = val1 + val2 + val3 + val4;
    return (sum !== 0) ? sum : '';
  }
}

