import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminUser, CreateAdminUserAPI, RoleUser } from 'src/app/api/user.api';
import { AlertModel } from 'src/app/interfaces/interfaces';
import { DatabaseService } from 'src/app/services/database.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.css']
})
export class EditRolesComponent implements OnInit {
  user: AdminUser;
  roleUser: RoleUser;
  selectedRoles: string[];
  knownRoles: any[];
  // knownRoles = [
  //   { ['id']: 'SUPERADMIN', ['value']: 'Super Admin', ['desc']: ''},
  //   { ['id']: 'ADMIN-L3', ['value']: 'Admin Level 3', ['desc']: ''},
  //   { ['id']: 'ADMIN-L2', ['value']: 'Admin Level 2', ['desc']: ''},
  //   { ['id']: 'ADMIN-L1', ['value']: 'Admin Level 1', ['desc']: ''},
  //   { ['id']: 'OPS-L4', ['value']: 'Ops Level 4', ['desc']: ''},
  //   { ['id']: 'OPS-L3', ['value']: 'Ops Level 3', ['desc']: ''},
  //   { ['id']: 'OPS-L2', ['value']: 'Ops Level 2', ['desc']: ''},
  //   { ['id']: 'OPS-L1', ['value']: 'Ops Level 1', ['desc']: ''},
  // ];
  rolesForm: FormGroup;
  alert: AlertModel;
  updateRolesAPI: CreateAdminUserAPI;
  nameValue  = new FormControl('');
 constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder, private dbService: DatabaseService,
  ) {
    this.selectedRoles = [];
    this.updateRolesAPI = new CreateAdminUserAPI(apiService, (success, message) => {
      this.showAlert(success, message);
      if (!success) { this.rolesForm.enable(); }
    });
  }
  ngOnInit() {
    this.selectedRoles = this.user.roles ? this.user.roles : [];
    this.loadRoles();
   // this.createRolesForm();

  }
  private createRolesForm() {
    const controls = {};
    for (const role of this.knownRoles) {
      controls[role.id] = [this.selectedRoles.indexOf(role.id) !== -1];
    }
    this.rolesForm = this.fb.group(controls);
  }
  async loadRoles() {
    await this.dbService.getRoles()
    .then(results => {
      this.knownRoles = results;
      this.createRolesForm();
    }).catch(err => {
      console.log(err);
    }).finally(() => {
    });
  }
  canSave() {
    return this.rolesForm.valid && this.rolesForm.dirty;
  }
  getSelectedRoles(): string[] {
    const roles = [];
    // Object.keys(this.rolesForm.value).forEach(key => {
    //   if (this.rolesForm.value[key] === true) {
    //     roles.push(key);
    //     console.log("jhjh", roles);
    //   }
    // });
    roles.push(this.nameValue.value);
   // ["OPS-L4"]
    return roles;
  }
  updateRoles() {
    this.updateRolesAPI.updateRoles(this.user.uid,  this.getSelectedRoles());
  }
  private showAlert(success: boolean, message: string) {
    const type = success ? 'success' : 'danger';
    this.alert = { type, message } as AlertModel;
    setTimeout(() => {
      this.alert = null;
      this.activeModal.close('success');
    }, 3000);
  }
}
