import { Component, OnInit } from '@angular/core';
import { Paginator, DriverProfilePaginator} from 'src/app/classes/paginator';
import { DatabaseService } from 'src/app/services/database.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TripListAPI, DriverTripReportAPI } from 'src/app/api/trip.api';
import { DriverTripSummaryReport } from 'src/app/interfaces/trip-interfaces';
import { DriversListAPI } from 'src/app/api/driverList.api';
import { UtilsService } from 'src/app/services/utils.service';
import { DatePickerModel } from 'src/app/classes/DatePickerModel';
import { OnboardingApplication, DriverProfile, GCashCity, Tripsummary } from 'src/app/interfaces/interfaces';
import { FormControl } from '@angular/forms';
import { CSVExporter } from 'src/app/classes/CSVExporter';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { filter } from 'rxjs/operators';
import { DriverDailyStatsAPI } from 'src/app/api/driver-stats.api';
import { DriverTripStat } from 'src/app/interfaces/interfaces';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import {  ApiService } from 'src/app/services/api.service';
// import * as datageolocation from './datageo.json';


@Component({
  selector: 'app-updateold-drivers',
  templateUrl: './updateold-tripreport.component.html',
  styleUrls: ['./search-cretiriya.component.css']
  })
export class UpdateOldTripReportComponent implements OnInit {
  paginator: DriverProfilePaginator = null;
  api: DriversListAPI;
  selectedFilter = 0;
  tripSearchForm = new FormControl('');
  public startDate: string;
  public endDate: string;
  tripListAPI: DriverTripReportAPI;
  trips: DriverTripSummaryReport[];
  driverProfile: DriverProfile[];
  tripNotTakenDriver: DriverProfile[];
  dailyStatsAPI: DriverDailyStatsAPI;
  dailyStat: DriverTripStat[];
  driverIdtripStatus: [];
  objecttemp = [];
  daliy: DriverTripStat;
  payload: Tripsummary;
  driverId: any;
  url: any;
  outputvalues: any;

    constructor(private dbService: DatabaseService, public apiService: ApiService,
                public utils: UtilsService, public datepipe: DatePipe, private db: AngularFirestore
                ) {
                    this.tripNotTakenDriver = [];
                    this.trips = [];

  }

    ngOnInit() {
     //   this.loadDriverApplications();
    }


    searchDriverTripReport() {
    /*   console.log(datageolocation.Sheet1.length);
    var sDate = new Date();
    console.log('Start', sDate);

    for (let i = 0; i < datageolocation.Sheet1.length ; i++) {
          this.url = datageolocation.Sheet1[i].URL;
          this.apiService.processGoogleDistAPI(this.url)
         .then(response => {
          this.outputvalues = response;
          console.log(i);
       })
       .catch(err => {
         console.error(err);
          });
        }
    var eDate = new Date();
    console.log('End Date', eDate); */
      }
getTripNotTakenDriver() {
        if ((this.driverProfile !== undefined)) {
            const driverId = [];
            let j = 0;
            for (let i = 0; i <= this.driverProfile.length; i++) {
              if (this.driverProfile[i] !== undefined) {
                if (this.driverProfile[i].uid === undefined || this.driverProfile[i].uid.trim().length === 0) { return; }
                this.dbService.dailyStatsForDriverReport(this.driverProfile[i].uid, this.startDate).then(regions => {
                  this.dailyStat = regions;

                  if (this.dailyStat !== undefined) {
                  let dalitystatus = {};
                  dalitystatus =  this.dailyStat;
                  const name = this.driverProfile[i].personal.firstname + ' ' + this.driverProfile[i].personal.lastname;
                  const mobileno = this.driverProfile[i].personal.phone;
                  this.payload = dalitystatus as Tripsummary;
                  this.payload.driverId = this.driverProfile[i].uid;
                  this.payload.name = name;
                  this.payload.phone = mobileno;
                  j = j + 1;
                  // this.db.collection('reports').doc('tripsummary').collection(this.startDate).doc(this.driverProfile[i].uid)
                  //             .set(this.payload, { merge: true });
                  console.log(j);
                  const result = this.dbService.updateTripReport(this.driverProfile[i].uid, this.startDate, dalitystatus, name, mobileno);
                  // if (result) {
                  //   console.log(i);
                  //   //alert('Successfully Updated the Documents');
                  // }
                }
                }).catch(err => {
                  console.log(err);
                  }).finally(() => {
                  });
             }
          }

        }
      // alert('Successfully Updated the Documents');
    }
updateTripReport() {

//  console.log(this.objecttemp);
}

get canExport(): boolean {
     const allRequests = this.tripNotTakenDriver;
     return (allRequests && allRequests.length > 0);
  }
  get canSearch(): boolean {
    const allRequests = this.driverProfile;
    return (allRequests && allRequests.length > 0);
  }
export() {
    const filename = () => 'Trip Not Taken Drivers' + (new Date()).toLocaleDateString();
    const exporter = new CSVExporter(filename(), CSVExporter.driverProfileTitles());
    exporter.exportDrivers(this.tripNotTakenDriver as DriverProfile[]);
  }

    private async loadDriverApplications() {
        // this.api.loadActivatedDriverProfiles(1, 11);
      }
 get applications(): OnboardingApplication[] {
        if (this.paginator) {
          return this.paginator.getItemsWithFilter();
        } else {
          return null;
        }
  }
  getStatusName(status: number): string | null {
    return OnboardingUtils.getStatusName(status);
  }

  getSubStatusName(status: number): string | null {
    return OnboardingUtils.getSubStatusName(status);
  }

  }
 /** * FILTER for Trips   */
class DriverTripReportFilter {

    status: number;
    from: DatePickerModel = new DatePickerModel(moment().startOf('day').toDate());
    to: DatePickerModel = new DatePickerModel(moment().endOf('day').toDate());

    constructor() {

    }

    reset() {
      this.from = new DatePickerModel(moment().startOf('day').toDate());
      this.to = new DatePickerModel(moment().endOf('day').toDate());

    }
  }
