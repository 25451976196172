import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { RouterPermissionService } from '../services/router-permission.service';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public afAuth: AngularFireAuth,
    public rp: RouterPermissionService,
    public userService: UserService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
        .then(user => {
          return resolve(true);
        }, err => {
          this.router.navigate(['/login']);
          return resolve(true);
        });
    });
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
    UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const canNavigate = this.canNavigateToChildRoute(state.url);
    if (!canNavigate) { this.router.navigate(['/home/unauthorized']); }
    return canNavigate;
  }
  canNavigateToChildRoute(route: string): boolean {
    switch (route.toLocaleLowerCase()) {
      case '/home/dashboard': return this.rp.canShowDashBoardMenu;
      case '/home/unauthorized': return true;
      case '/home/customer': return this.rp.canShowCustomersSubMenu;
      case '/home/customerloadtransaction': return this.rp.canShowCustomerLoadTransactionSubMenu;
      case '/home/driverloadtransaction': return this.rp.canShowDriverLoadTransactionSubMenu;
      case '/home/promocodes': return this.rp.canShowPromoCodeSubMenu;
      case '/home/fareconfig': return this.rp.canShowFareConfigSubMenu;
      case '/home/faresetting': return this.rp.canShowFareConfigSubMenu;
      case '/home/vehicleaddon': return this.rp.canShowFareConfigSubMenu;
      case '/home/afterhours_fee': return this.rp.canShowFareConfigSubMenu;
      case '/home/additional_surcharge': return this.rp.canShowFareConfigSubMenu;
      case '/home/registereddrivers': return this.rp.canShowNewRegistrationsSubMenu;
      case '/home/registereddriverspoc': return this.rp.canShowFixBikerNumberSearchSubMenu;
      case '/home/quicksearchdrivers': return this.rp.canShowQuickRegistrationsSubMenu;
      case '/home/onboarding': return this.rp.canShowOnboardingSubMenu;
      case '/home/onboardeddrivers': return this.rp.canShowOnboardedDriversSubMenu;
      case '/home/onlinedrivers': return this.rp.canShowOnlineDriversSubMenu;
      case '/home/driverstats': return this.rp.canShowDriverStatsSubMenu;
      case '/home/exportgcash': return this.rp.canShowDriverGCashSubMenu;
      case '/home/cashoutrequests': return this.rp.canShowCashoutReqSubMenu;
      case '/home/topuprequests': return this.rp.canShowTopupReqSubMenu;
      case '/home/topupmanual': return this.rp.canShowTopupManualSubMenu;
      case '/home/walletadjustments': return this.rp.canShowWalletAdjustmentSubMenu;
      case '/home/tripmanagement': return this.rp.canShowManageTripsSubMenu;
      case '/home/tripqa': return this.rp.canShowSearchTripsQA;
      case '/home/tripsummary': return this.rp.canShowTripSummarySubMenu;
      case '/home/usermanagement': return this.rp.canShowUserMainMenu;
      case '/home/exportwalletadjustments': return this.rp.canShowexportWalletAdjustmentSubMenu;
      case '/home/exportwalletbalance': return this.rp.canShowexportWalletBalanceSubMenu;
      case '/home/exportcashout': return this.rp.canShowCashoutReqSubMenu;
      case '/home/searchcretriya': return this.rp.canShowDriverGCashSubMenu;
      case '/home/importgcashdata': return this.rp.canShowBulkActivationSubMenu;
      case '/home/drivertrips': return this.rp.canShowBikerTripMgmtSubMenu;
      case '/home/drivertripavailability': return this.rp.canShowDriverTripAvailabiltySubMenu;
      case '/home/exportwalletcommission': return this.rp.canShowexportWalletCommissionSubMenu;
      case '/home/exporttopup': return this.rp.canShowTopupReqSubMenu;
      case '/home/driverwallethistory': return this.rp.canShowexportDriverWalletHistorySubMenu;
      case '/home/driverindividualactivation': return this.rp.canShowNewRegistrationsSubMenu;
      case '/home/driveractivereport': return this.rp.canShowActiveDriverReportSubMenu;
      case '/home/driverinactivereport': return this.rp.canShowInactiveDriverReportSubMenu;
      case '/home/alldrivertriplocationcount': return this.rp.canShowTripLocationTrackSubMenu;
      case '/home/tripmanagementsearch': return this.rp.canShowSearchTripsSubMenu;
      case '/home/triplocationsdriver': return this.rp.canShowTripLocationTrackSubMenu;
      case '/home/updateoldtrip': return this.rp.canShowDriverGCashSubMenu;
      case '/home/walletbalancelow': return this.rp.canShowlowWalletBalanceSubMenu;
      case '/home/tripsummarysearch': return this.rp.canShowTripSummarySubMenu;
      case '/home/customersearch': return this.rp.canShowCustomersSearchSubMenu;
      case '/home/importtopupmanual': return this.rp.canShowBulkTopupSubMenu;
      case '/home/emisearch': return this.rp.canShowemiHistorySearchSubMenu;
      case '/home/emihistory': return this.rp.canShowDriveremiWalletHistorySubMenu;
      case '/home/emiwallethistory': return this.rp.canShowDriveremiWalletHistorySubMenu;
      case '/home/emimanagement': return this.rp.canShowemiHistorySearchSubMenu;
      case '/home/triphoursmanagement': return this.rp.canShowTripMgmtBasedonHoursSubMenu;
      case '/home/driveremipayment': return this.rp.canShowDriveremiPendingWalletHistorySubMenu;
      
      /* CHARM: Not Assigned Claims - same with driver emi payment */
      case '/home/emibulkupdate': return this.rp.canShowDriveremiPendingWalletHistorySubMenu;
      case '/home/customersummary': return this.rp.canShowCustomersSearchSubMenu;


      /* Not Assigned Claims - default is SuperAdmin */
      case '/home/driverlog': return this.rp.canShowUserMainMenu;

      case '/home/driverupdatebags': return this.rp.canShowUserMainMenu;
      case '/home/driverupdateservices': return this.rp.canShowUserMainMenu;
      case '/home/driverdeactivation': return this.rp.canShowUserMainMenu;
      case '/home/driversaccount': return this.rp.canShowUserMainMenu;
      case '/home/topuphistory': return this.rp.canShowUserMainMenu;
      case '/home/topuphistoryinquiry': return this.rp.canShowUserMainMenu;
      case '/home/bindacchistory': return this.rp.canShowUserMainMenu;
      case '/home/bindaccinquiryhistory': return this.rp.canShowUserMainMenu;
      case '/home/unbindacchistory': return this.rp.canShowUserMainMenu;
      case '/home/bindaccinquirypost': return this.rp.canShowUserMainMenu;
      case '/home/metrobanktopupinquiry': return this.rp.canShowUserMainMenu;
      case '/home/driverwrokinghours': return this.rp.canShowUserMainMenu;
      case '/home/locationscount': return this.rp.canShowTripLocationTrackSubMenu;
      case '/home/paymayawalletlink': return this.rp.canShowUserMainMenu;
      case '/home/paymayawalletlinkhistory': return this.rp.canShowUserMainMenu;
      case '/home/paymayatopuphistory': return this.rp.canShowUserMainMenu;
      case '/home/paymayatopupinquiryhistory': return this.rp.canShowUserMainMenu;
      case '/home/topupinquiry': return this.rp.canShowUserMainMenu;
      case '/home/paymayatransactionfees': return this.rp.canShowUserMainMenu;
      case '/home/holiday_fee': return this.rp.canShowUserMainMenu;
      case '/home/hm_holiday_surcharge': return this.rp.canShowUserMainMenu;
      case '/home/triponlinereport': return this.rp.canShowUserMainMenu;
      case '/home/driveronlinereport': return this.rp.canShowUserMainMenu;
      case '/home/tricyclepickup': return this.rp.canShowUserMainMenu;
      case '/home/tricycledropoff': return this.rp.canShowUserMainMenu;
      case '/home/importpermission': return this.rp.canShowUserMainMenu;
      case '/home/customerapp_services': return this.rp.canShowUserMainMenu;
      case '/home/paymayatopupwebhookreports': return this.rp.canShowUserMainMenu;
      case '/home/customerexportwalletadjustments': return this.rp.canShowUserMainMenu;
      case '/home/customerexportwallethistory': return this.rp.canShowUserMainMenu;
      case '/home/customerwalletadjustments': return this.rp.canShowUserMainMenu;
      case '/home/customerwalletbalance': return this.rp.canShowUserMainMenu;
      case '/home/customerlowwalletbalance': return this.rp.canShowUserMainMenu;
      case '/home/customerpaymayatopuphistory': return this.rp.canShowUserMainMenu;
      case '/home/userclaims': return this.rp.canShowUserMainMenu;
      case '/home/userrolesclaims': return this.rp.canShowUserMainMenu;
      case '/home/userrolesmanage': return this.rp.canShowUserMainMenu;
      case '/home/userroles': return this.rp.canShowUserMainMenu;
      case '/home/paymayanumberupdation': return this.rp.canShowUserMainMenu;
      case '/home/newsarticles': return this.rp.canShowNewsArticlesMainMenu;
      case '/home/carouselbanner': return this.rp.canShowCarouselBannerMainMenu;
      case '/home/customerratebulkupdate': return this.rp.canShowCustomerRateBulkUpdateSubMenu;
      case '/home/otpprovider': return this.rp.canShowOtpProvider;
      default: return false;
    }
  }
}
