import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatabaseService } from 'src/app/services/database.service';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { OnboardingUtils } from 'src/app/pages/onboarding/driver/onboarding.utils';
import { AlertModel, FareSettings, FareSlab, FareSettingsFranchiseArea } from 'src/app/interfaces/interfaces';
import { UpdateFareSettingsAPI } from 'src/app/api/fareSettings.api';
import { AlertModalComponent } from 'src/app/controls/alertmodal/alertmodal.component';
import { EditAdditionalSurchargeComponent } from 'src/app/modals/additional-surcharge/edit-additional-surcharge.component';
import { CreateAdditionalSurchargeComponent } from 'src/app/modals/additional-surcharge/create-additional-surcharge.component';


@Component({
  selector: 'app-additional-surcharge',
  templateUrl: './additional_surcharge.component.html',
  styleUrls: ['./additional_surcharge.component.css']
})
export class AdditionalSurchargeComponent implements OnInit {
  additionalSurcharge:any = [];
  areas: any;
  areaForm = new FormControl(); 
  tripTypeForm = new FormControl(); 
  defaultArea: any;
  areaTripType: any;
  fareSettingsFranchiseArea: any;
  private fareSettingsObservable: Observable<FareSettings>;
  private updateFareSettingsAPI: UpdateFareSettingsAPI;
  FareSettingsFranchiseArea: FareSettingsFranchiseArea;
  isSaving = true;
  isEditing = false;
  isLoading = false;
  isFareTableValid = true;
  onboardingRegions = [];
  tripTypecombo = [];
  vehicleTypecombo = [];
  addoncombo = [];
  fareByVehicle:any = '';
  region = new FormControl();
  pickupcity = new FormControl();
  tricyclePickup: any[];
  canShowregion: boolean;
  canShowCity: boolean;
  defaultRegion: any;
  tripType = new FormControl();
  defaultTripType: string;
  defaultVehicleType: string;
  defaultFareType: string;
  defaultAddOn: string;
  selectedRegionFilter: any;
  selectedRegionTypeFilter: any;
  selectedPickupCity: any;
  OnboardingUtils:any;
  get disableField(): boolean {
    return this.updateFareSettingsAPI.isLoading;
  }
  get fareTable(): FareSlab[] {
    return this.fareSettings.fareTable;
  }
  alert: AlertModel;
  fareSettings: any;
  fareSettingsCopy: any;
  addOnfareSettings: any;
  constructor(private modalService: NgbModal, private dbService: DatabaseService, private apiService: ApiService,  public utils: UtilsService) {
    this.canShowregion = false;
    this.canShowCity = false;
    this.OnboardingUtils = OnboardingUtils;
    this.onboardingRegions = OnboardingUtils.getRegions();
    this.tripTypecombo = OnboardingUtils.getTripTypeFare();
    this.vehicleTypecombo = OnboardingUtils.getFranchiseAreaVehicleType();
    this.addoncombo = OnboardingUtils.getFranchiseAreaVehicleTypeAddons();
    this.defaultRegion = this.onboardingRegions[0];
    this.defaultTripType = this.tripTypecombo[0];
    this.defaultVehicleType = this.vehicleTypecombo[0];
    this.selectedRegionFilter =  'Metro Manila';
    this.selectedRegionTypeFilter = 'MCTaxi';
    this.selectedPickupCity = 'Antipolo';
    this.updateFareSettingsAPI = new UpdateFareSettingsAPI(dbService, (success) => {
      this.isEditing = !success;
      const alertType = (success) ? 'success' : 'danger';
      const alertMsg = (success) ? 'Fare settings updated successfully' : 'Updating fare settings failed';
      this.showAlert(alertType, alertMsg);
    });
    this.initializeSubscribers();
  }
 ngOnInit() {
   this.loadFranchiseArea();
   let date = new Date("23:00");
    console.log(date.getTime());
 }

 editAdditionalSurcharge(data) {
    const modalRef =  this.modalService.open(EditAdditionalSurchargeComponent, { size: 'lg' });
    modalRef.componentInstance.loadedData = data;
    modalRef.componentInstance.defaultTripType = this.defaultTripType;
    modalRef.componentInstance.defaultArea = this.defaultArea;
    modalRef.componentInstance.defaultVehicleType = this.defaultVehicleType;
    modalRef.result
    .then((result) => { this.loadFranchiseArea(); }, (reason) => { });
  }

  createAdditionalSurcharge() {
    const modalRef =  this.modalService.open(CreateAdditionalSurchargeComponent, { size: 'lg' });
    modalRef.componentInstance.defaultTripType = this.defaultTripType;
    modalRef.componentInstance.defaultArea = this.defaultArea;
    modalRef.componentInstance.defaultVehicleType = this.defaultVehicleType;
    modalRef.result
    .then((result) => { this.loadFranchiseArea();  }, (reason) => { });
  }

  deleteAdditionalSurcharge(data, index: number) {
    const TripType = this.defaultTripType;
    const vehicle = this.defaultTripType === 'HMDelivery' ? this.defaultVehicleType : 'fares';
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = 'Delete Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want to delete this additional surcharge '+data.name+'?';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        return this.dbService.deleteAdditionalSurcharge(this.defaultArea, TripType, vehicle, data.id);
      })
      .then(() => {
        this.loadFranchiseArea();
      })
      .catch(e => {
        console.log(e);
      });
  }

  deactivateAdditionalSurcharge(data, index: number) {
    const status = data.status === 1 ? 0 : 1;
    const TripType = this.defaultTripType;
    const vehicle = this.defaultTripType === 'HMDelivery' ? this.defaultVehicleType : 'fares';
    const modalRef = this.modalService.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.title = this.getActionAlertTitle(data.status)+' Alert';
    modalRef.componentInstance.message =
    'Are you sure that you want '+this.getActionButtonTitle(data.status)+' this Additional Surcharge: ' + data.name +
    '?';
    modalRef.componentInstance.cancelActionTitle = 'Cancel';
    modalRef.componentInstance.confirmActionTitle = 'Confirm';
    modalRef.result
      .then((result) => {
        data.status = status;
        return this.dbService.deactivateAdditionalSurcharge(this.defaultArea, TripType, vehicle, status, data.id);
      })
      .then(() => {
        this.loadFranchiseArea()
      })
      .catch(e => {
        console.log(e);
      });
  }

 loadFranchiseArea() {
    this.dbService.getFranchiseArea().then(results => {
      this.areas = results
      this.defaultArea = this.defaultArea!=undefined?this.defaultArea:this.areas[0];
      this.areaFilter();
    }).catch(err => {
      console.log(err);
    })
 }

 areaFilter() {
   this.isLoading = true
   const area = this.defaultArea
   const tripType = this.defaultTripType
     this.dbService.filterFranchiseAreaByTriptype(area, tripType).then(res => {
            this.isSaving = true
            this.isLoading = false
            if(tripType === 'HMDelivery') {
              this.vehicleFilter();
            } else {
              this.jrserviceFilter();
            }
            
        })
        .catch(e => {
            this.isLoading = false
            this.isSaving = false
            this.fareSettings = 1;
        });
 }

 vehicleFilter() {
   this.isLoading = true
   const area = this.defaultArea
   const vehicle = this.defaultVehicleType;
   this.dbService.filterFranchiseAreaByVehicletypeAdditionalSurchargeHMDelivery(area, vehicle).then(res => {
          this.isSaving = true
          this.isLoading = false
          this.additionalSurcharge = res;
      })
      .catch(e => {
          this.isLoading = false
          this.isSaving = false
          this.fareSettings = 1;
      });
 }

 jrserviceFilter() {
   this.isLoading = true
   const area = this.defaultArea
   const TripType = this.defaultTripType;
   this.dbService.filterFranchiseAreaByVehicletypeAdditionalSurcharge(area, TripType).then(res => {
          this.isSaving = true
          this.isLoading = false
          this.additionalSurcharge = res;
          console.log(res)
      })
      .catch(e => {
          this.isLoading = false
          this.isSaving = false
          this.fareSettings = 1;
      });
 }

 getActionButtonTitle(status: number) {
    switch (status) {
      case 1: return 'DEACTIVATE';
      case 0: return 'ACTIVATE';
      default: return '';
    }
  }

  getActionAlertTitle(status: number) {
    switch (status) {
      case 1: return 'DEACTIVATION';
      case 0: return 'ACTIVATION';
      default: return '';
    }
  }

regionFilter() {
  this.canShowregion = false;
  this.selectedRegionFilter = this.region.value;
  this.initializeSubscribers();
}
regionTypeFilter() {
  if (this.tripType.value === 'Taxicle' ) {
    this.canShowCity = true;
    this.loadTricyclePickupCities();
    this.canShowregion = false;
   // this.selectedPickupCity = this.pickupcity.value;
    this.selectedRegionTypeFilter = this.tripType.value;
    this.initializeSubscribers();
  } else {
  this.canShowregion = false;
  this.canShowCity = false;
  this.selectedRegionTypeFilter = this.tripType.value;
  this.initializeSubscribers();
  }
}
pickupFilter() {
  this.canShowCity = true;
  this.loadTricyclePickupCities();
  this.canShowregion = false;
  this.selectedPickupCity = this.pickupcity.value;
  this.selectedRegionTypeFilter = this.tripType.value;
  this.initializeSubscribers();
}
 initializeSubscribers() {
    this.canShowregion = true;
  }
  editFare() {
    this.isEditing = true;
  }
  btnAddPriceKm() {
    const valueAdd = {base: 0, from: 0, id: 0, perKm: 10, perMin: 0, to: 0};
    this.fareTable.push(valueAdd);
  }
  btnRemovePriceKm() {
   this.fareTable.splice(this.fareTable.length - 1, 1);
  }
  resetFare() {
    this.fareSettings = this.clone(this.fareSettingsCopy);
    this.isEditing = false;
  }
  async loadTricyclePickupCities() {
    this.dbService.getTricyclePickupCities(this.region.value)
    .then(results => {
      this.tricyclePickup = results;
    }).catch(err => {
      console.log(err);
    })
  }

  showAlert(type: string, message: string) {
    this.alert = { type, message } as AlertModel;
    setTimeout(() => this.alert = null, 3000);
  }
  clone(obj: any): any {
    return (JSON.parse(JSON.stringify(obj)));
  }
  validateFare() {
    const count = this.fareTable.length;
    if (count === 0) { return false; }
    const isFirst = (index) => {
      return index === 0; };
    const isLast = (index) => {
      return index === this.fareTable.length - 1; };
    for (let index = 0; index < count; index++) {
      if (isFirst(index) && this.fareTable[index].from !== 0) {
        this.showAlert('danger', 'The "from" value for first entry must be zero.');
        return false;
      }
      if (isLast(index) && this.fareTable[index].to !== 999) {
        this.showAlert('danger', 'The "to" value for last entry must be 999.');
        return false;
      }
      if (this.fareTable[index].base === null || this.fareTable[index].perKm === null ||
        this.fareTable[index].perMin === null) {
        this.showAlert('danger', 'Empty values are not accepted. Please enter zero instead');
        return false;
      }
      if (this.fareTable[index].from >= this.fareTable[index].to) {
        this.showAlert('danger', '"From value" cannot be greater than or equal to "To value"');
        return false;
      }
      if (this.fareTable[index].base !== 0 && this.fareTable[index].perKm !== 0) {
        this.showAlert('danger', 'Cannot define both base fare and price per Km.');
        return false;
      }
      if (!isFirst(index)) {
        const prevRow = this.fareTable[index - 1];
        if (prevRow.to !== this.fareTable[index].from) {
          this.showAlert('danger', 'The "from" value must be equal to "to" value of previous row');
          return false;
        }
      }
    }
    return true;
  }
}
